import {UPDATE_ALERT} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedPut from "../../apis/authorizedPut";

export default (id) => async (dispatch) => {
  
  await loadingCall(
    dispatch,
    authorizedPut(
      `/alerts/${id}`
    ),
    simpleSuccess(UPDATE_ALERT),
    null,
    []
  );
};