import {DELETE_LOCATION_SECTION} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import modalDismiss from "../modal/modalDismiss";
import authorizedDelete from "../../apis/authorizedDelete";

export default (monthlyReportId) => async (dispatch) => {
  await loadingCall(
    dispatch,
    authorizedDelete(
      `/monthly_reports/${monthlyReportId}/location_section`
    ),
    simpleSuccess(DELETE_LOCATION_SECTION),
    null,
    [modalDismiss]
  );
};