import {CREATE_RAIN_ENTRY} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedPost from "../../apis/authorizedPost";
import modalDismiss from "../modal/modalDismiss";
import {formDataHelper} from "../functions/formDataHelper";

export default (formValues, rainSectionId) => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedPost(
          `/rain_sections/${rainSectionId}/entry`,
          formDataHelper(formValues, 'rain_section', [{name: 'avatar', key: '[rain_entries_attributes][][avatar]'}])
      ),
      simpleSuccess(CREATE_RAIN_ENTRY),
      null,
      [modalDismiss]);
};