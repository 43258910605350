import React from 'react';
import {Alert, Button, Card, Col, Row, Steps} from "antd";
import './wizard.css';

class WizardHelper extends React.Component {
  state = {
    totalSteps: this.props.steps.length,
    current: 0
  };
  
  next = () => {
    const current = this.state.current + 1;
    this.setState({current});
  };

  prev = () => {
    const current = this.state.current - 1;
    this.setState({current});
  };
  
  renderActions = () => {
    return (
      <Row type="flex" gutter={16} className={'p-20'} justify="center">
        <Col>
          {this.renderPrev(this.state.current)}
        </Col>
        <Col>
          {this.renderNext(this.state.current)}
        </Col>
        <Col>
          {this.renderDone(this.state.current)}
        </Col>
      </Row>
    );
  };

  renderNext = (index) => {
    if (index < this.state.totalSteps - 1)
      return (<Button type="primary" htmlType={'submit'}>Siguiente</Button>);
  };

  renderDone = (index) => {
    if (index === this.state.totalSteps - 1)
      return (<Button type="primary" htmlType={'submit'}>Terminar</Button>);
  };

  renderPrev = (index) => {
    if (index > 0)
      return (<Button type={'warning'} style={{marginLeft: 8}} onClick={() => this.prev()}>Anterior</Button>);
  };

  renderTitle = () => {
    let {steps} = this.props;
    return (
      <div>
        <Steps className={'wizard-form-steps'} size={'small'} current={this.state.current}>
          {steps.map(item => <Steps.Step key={item.title} title={item.title}/>)}
        </Steps>
        <div className="wizard-form-title">
          <span>{steps[this.state.current].title}</span>
          <span className={'float-right'}>{this.state.current + 1} / {this.state.totalSteps}</span>
        </div>
      </div>
    );
  };
  
  renderComponent = () => {
    let step = this.props.steps[this.state.current];
    let Component = step.component;
    return <Component onSubmit={this.state.current === (this.state.totalSteps - 1) ? this.props.onSubmit : this.next}
                      actions={this.renderActions()}
                      {...step.content}/>
  };

  render() {
    return (
      <Card className={'wizard-form'} bordered={false} bodyStyle={{padding: 20}} title={this.renderTitle()}>
        {this.props.steps[this.state.current].message &&
        <Alert message={this.props.steps[this.state.current].message} className={'mb-10'}/>}
        {this.renderComponent()}
      </Card>
    );
  }
}

export default WizardHelper;