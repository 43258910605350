import {CREATE_INDIRECT_SUB_ENTRY} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedPost from "../../apis/authorizedPost";
import {formDataHelper} from "../functions/formDataHelper";
import _ from "lodash";

export default (formValues, indirectEntryId, callback) => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedPost(
          `/indirect_entries/${indirectEntryId}/entry`,
          formDataHelper(
              formValues,
              'indirect_entry',
              _.times(2, (index) => {return {name: `image${index}`, key: '[indirect_sub_entries_attributes][][images][]'}})
          )
      ),
      simpleSuccess(CREATE_INDIRECT_SUB_ENTRY),
      null,
      [],
      [callback]);
};