import {UPDATE_RECOMMENDATION_SECTION} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedPut from "../../apis/authorizedPut";
import modalDismiss from "../modal/modalDismiss";

export default (recommendationIds, requirementIds, recommendationSectionId) => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedPut(
          `/recommendation_sections/${recommendationSectionId}`,
          {recommendation_section: {recommendation_ids: recommendationIds, requirement_ids: requirementIds}}
      ),
      simpleSuccess(UPDATE_RECOMMENDATION_SECTION),
      null,
      [modalDismiss]
  );
};