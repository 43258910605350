import React from 'react';
import {Button, Card, Row, Table} from "antd";
import {connect} from "react-redux";
import getProjectDocuments from "../../actions/projects/getProjectDocuments";
import {PROJECTS_DOCUMENTS_MODAL} from "../Feedback/Modal/types";
import modalShow from "../../actions/modal/modalShow";
import getProjectDocument from "../../actions/projects/getProjectDocument";

class Documents extends React.Component {
  
  componentDidMount() {
    if (this.props.projectId) {
      this.props.getProjectDocuments(this.props.projectId);
    }
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.projectId !== prevProps.projectId && this.props.projectId) {
      this.props.getProjectDocuments(this.props.projectId);
    }
  }
  
  columns = () => {
    return [{
      title: '#',
      dataIndex: 'id',
      key: 'id'
    }, {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name'
    }, {
      title: 'Tipo',
      dataIndex: 'kind',
      key: 'kind'
    }, {
      title: 'Fecha',
      dataIndex: 'date',
      key: 'date'
    }, {
      title: 'Archivo',
      key: 'archive',
      render: (text, record) =>
        <Button htmlType={'button'} type={'ghost'} onClick={() => this.openDocModal(true, true, record)}>Ver</Button>
    }]
  };
  
  openDocModal = (selected, update, entry = null) => {
    if (entry) {
      if (this.props.isClient) {
        window.open(entry.archive)
      } else {
        this.props.getProjectDocument(this.props.projectId, entry.id).then((data) => {
          this.props.modalShow({
            modalType: PROJECTS_DOCUMENTS_MODAL,
            modalProps: {
              selected,
              update,
              user: true,
              projectId: this.props.projectId,
              entry: this.props.selected_entry
            }
          })
        });
      }
    } else {
      this.props.modalShow({
        modalType: PROJECTS_DOCUMENTS_MODAL,
        modalProps: {
          selected,
          update,
          user: true,
          projectId: this.props.projectId
        }
      })
    }
    
  };
  
  render() {
    const {modalList, isClient} = this.props;
    
    return (
      <Card title={'Documentos del proyecto'} bodyStyle={{padding: 0}}
            extra={isClient ? '' :
              <Button type={'primary'} onClick={() => this.openDocModal(true, false)}>Agregar</Button>}>
        <Row>
          <Table scroll={{x: '100%'}} dataSource={modalList.kind === 'documents' ? modalList.entries : []} rowKey={'id'}
                 columns={this.columns()}/>
        </Row>
      </Card>
    );
  }
}

const mapStateToProps = state => {
  return ({
    projectId: state.auth.target_id,
    modalList: state.modalList,
    isClient: state.auth.client,
    selected_entry: state.modalList.selected_entry
  });
};

export default connect(mapStateToProps, {getProjectDocuments, modalShow, getProjectDocument})(Documents);