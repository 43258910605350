import {DELETE_ANCHORS_ENTRY_IMAGE} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedPut from "../../apis/authorizedPut";

export default (anchorsEntryId, anchorsId, imageId, callback) => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedPut(
          `/anchors_sub_sections/${anchorsId}/entry_image`,{anchors_sub_section: {entry_id: anchorsEntryId, image_id: imageId}}
      ),
      simpleSuccess(DELETE_ANCHORS_ENTRY_IMAGE),
      null,
      [],
      [callback]);
};