import React from "react";
import {Button, Card, Col, Icon, Row, Table} from 'antd';
import 'antd/lib/button/style/css';
import 'antd/lib/card/style/css';
import 'antd/lib/col/style/css';
import 'antd/lib/icon/style/css';
import 'antd/lib/input/style/css';
import 'antd/lib/row/style/css';
import 'antd/lib/table/style/css';
import {connect} from "react-redux";
import modalShow from '../../actions/modal/modalShow';
import {NETWORK_ENTRY_MODAL} from "../Feedback/Modal/types";
import getNetworkSection from "../../actions/networkSection/getNetworkSection";
import {MONTHLY_VIEW} from "../../paths";
import {push} from 'connected-react-router';
import createNetworkEntry from "../../actions/networkSection/createNetworkEntry";
import updateNetworkEntry from "../../actions/networkSection/updateNetworkEntry";
import getNetworkEntry from "../../actions/networkEntry/getNetworkEntry";
import {marginRight} from "../../const/styles";
import deleteNetworkEntry from "../../actions/networkSection/deleteNetworkEntry";
import {confirmDelete} from "../../apis/authorizedDelete";

class Network extends React.Component {
  
  componentDidMount() {
    this.props.getNetworkSection(this.props.match.params.network_id);
  };
  
  handleCreate = (formValues) => {
    this.props.createNetworkEntry(formValues, this.props.match.params.network_id);
  };
  
  handleUpdate = (formValues) => {
    this.props.updateNetworkEntry(formValues, this.props.match.params.network_id);
  };
  
  fetchEntry = (networkEntryId) => {
    this.props.getNetworkEntry(networkEntryId).then(() => this.openEntryModal(null, null));
  };
  
  openEntryModal = (entry, onSubmit) => {
    this.props.modalShow({
      modalType: NETWORK_ENTRY_MODAL,
      modalProps: {networkEntry: entry, onSubmit: onSubmit}
    });
  };
  
  columns = () => {
    return [{
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    }, {
      title: 'Titulo',
      dataIndex: 'title',
      key: 'title',
      render: (text) => {
        return <Icon type={text && text.length > 0 ? 'check' : 'close'}/>;
      }
    }, {
      title: 'Contenido',
      dataIndex: 'content',
      key: 'content',
      render: (text) => {
        return <Icon type={text && text.length > 15 ? 'check' : 'close'}/>;
      }
    }, {
      title: 'Imagen',
      dataIndex: 'avatar',
      key: 'avatar',
      render: (text) => {
        return <Icon type={text ? 'check' : 'close'}/>;
      }
    }, {
      title: 'Comentario',
      dataIndex: 'comment',
      key: 'comment',
      render: (text) => {
        return <Icon type={text && text.length > 0 ? 'check' : 'close'}/>;
      }
    }, {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record) => {
        return (
          <div>
            <Button onClick={() => this.openEntryModal(record, this.handleUpdate)} style={marginRight} type={"primary"}
                    htmlType={'button'}>
              <Icon type="eye"/>
            </Button>
            <Button style={marginRight} onClick={() => this.fetchEntry(record.id)} type={"ghost"} htmlType={'button'}>
              Sub entradas
            </Button>
            <Button
              onClick={() => confirmDelete(() => this.props.deleteNetworkEntry(this.props.match.params.network_id, record.id))}
              type={"danger"}
              style={marginRight}
              htmlType={'button'}>
              <Icon type="delete"/>
            </Button>
          </div>
        );
      }
    }]
  };
  
  render() {
    return (
      <Card bodyStyle={{padding: 0}}
            extra={
              <Button htmlType={'button'}
                      type={'ghost'}
                      onClick={() => this.props.push(MONTHLY_VIEW(this.props.match.params.id))}>Volver</Button>
            }
            title={'Sección de redes'}
            >
        <Row gutter={16} className={'p-10'}>
          <Col span={24}>
            <Button type={'primary float-right'}
                    style={marginRight}
                    htmlType={'button'}
                    onClick={() => this.openEntryModal(null, this.handleCreate)}>
              Agregar
            </Button>
          </Col>
        </Row>
        <Row>
          <Table scroll={{x: '100%'}}
            dataSource={this.props.networkSection.kind === 'network_entries' ? this.props.networkSection.entries : []}
            rowKey={'id'} columns={this.columns()}/>
        </Row>
      </Card>
    );
  }
}


const mapStateToProps = state => {
  return {
    networkSection: state.userList.selected_entry
  };
};


export default connect(mapStateToProps, {
  push,
  modalShow,
  getNetworkSection,
  createNetworkEntry,
  deleteNetworkEntry,
  updateNetworkEntry,
  getNetworkEntry
})(Network);