import React from "react";
import { Button, Col, Form, Row } from "antd";
import { Field, reduxForm } from "redux-form"
import { CreateFileField } from "../Helpers/Input/InputCreator";
import { fileRequired } from "../Helpers/Input/validations";

const UploadMediaForm = ({onSubmit, handleSubmit, change}) => {
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
                <Col>
                    <Field
                        name={'mediaFile'}
                        component={CreateFileField}
                        type='file'
                        change={change}
                        validate={[fileRequired]}
                        accept=".png,.jpg,.jpeg,.mpg,.mpeg,.avi,.mov,.mp4,.mkv,.wmv"
                        hasFeedbak
                    />
                </Col>         
            </Row>
            <Row>
                <Col>
                    <Button type={'primary float-right'} htmlType={'submit'}>Cargar</Button>
                </Col>
            </Row>
        </Form>
    );
}

export default reduxForm({
    form: 'upload_media_form',
    touchOnChange: true
})(UploadMediaForm);