import React from "react";
import {Button, Card, Col, Row, Transfer} from 'antd';
import 'antd/lib/button/style/css';
import 'antd/lib/card/style/css';
import 'antd/lib/col/style/css';
import 'antd/lib/icon/style/css';
import 'antd/lib/input/style/css';
import 'antd/lib/row/style/css';
import 'antd/lib/table/style/css';
import {connect} from "react-redux";
import modalShow from '../../actions/modal/modalShow';
import {PROTOCOL_VIEW_MODAL} from "../Feedback/Modal/types";
import {MONTHLY_VIEW} from "../../paths";
import {push} from 'connected-react-router';
import updateProtocolSection from "../../actions/protocolSection/updateProtocolSection";
import getProtocolSection from "../../actions/protocolSection/getProtocolSection";
import {marginRight} from "../../const/styles";
import {reducedText} from "../Helpers/Input/format";

class Protcool extends React.Component {

  state = { protocolKeys: [] };

  componentDidMount() {
    this.props.getProtocolSection(this.props.match.params.protocol_id).then(() => {
      this.setState({
        protocolKeys: this.props.protocolSection.protocol_ids,
      })
    });
  };

  openModal = record => {
    this.props.modalShow({
      modalType: PROTOCOL_VIEW_MODAL,
      modalProps: {record: record}
    });
  };

  handleChange = (targetKeys) => {
    let newKeys = {};
    newKeys.protocolKeys = targetKeys;
    this.setState(newKeys);
  };

  renderTitle = () => {
    return (
      <Row>
        <Col span={24}>
          Sección de Protocolos
        </Col>
        <Col span={24} className={'mt-10'}>
          <Row gutter={8} type={'flex'} justify={'end'}>
            <Col xs={24} md={12} lg={6}>
              <Button block style={marginRight} htmlType={'button'} type={'ghost'}
                      onClick={() => this.props.push(MONTHLY_VIEW(this.props.match.params.id))}>Volver</Button>
            </Col>
            <Col xs={24} md={12} lg={6}>
              <Button block style={marginRight} type={'primary'}
                      htmlType={'button'}
                      onClick={() => this.props.updateProtocolSection(this.state.protocolKeys, this.props.match.params.protocol_id)}>
                Guardar
              </Button>
            </Col>

          </Row>
        </Col>
      </Row>
    )
  };

  render() {
    return (
      <Card title={this.renderTitle()}>
        <Row className={'mb-10 text-center'}>
          <h2>Protocolos</h2>
          <Transfer
            dataSource={this.props.protocolSection.protocols}
            showSearch
            rowKey={record => record.id}
            targetKeys={this.state.protocolKeys}
            onChange={(targetKeys) => this.handleChange(targetKeys)}
            render={
              item => <span>{reducedText(item.name, 15)} <Button size={'small'} htmlType={'button'} type={'link float-right'}
                                                     onClick={() => this.openModal(item)}>Ver</Button></span>}
            listStyle={{width: 250, height: 300, textAlign: 'left'}}
            filterOption={(inputValue, option) => option.name.toLowerCase().includes(inputValue.toLowerCase())}
            locale={{
              itemUnit: 'Protocolo(s)',
              itemsUnit: 'Protocolos',
              notFoundContent: 'No hay protocolos',
              searchPlaceholder: 'Buscar por nombre'
            }}
          />
        </Row>
      </Card>
    );
  }
}


const mapStateToProps = state => {
  return {
    protocolSection: state.userList.selected_entry
  };
};


export default connect(mapStateToProps, {
  push,
  modalShow,
  getProtocolSection,
  updateProtocolSection
})(Protcool);