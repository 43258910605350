import React from "react";
import {Button, Card, Col, Icon, Row, Table} from 'antd';
import 'antd/lib/button/style/css';
import 'antd/lib/card/style/css';
import 'antd/lib/col/style/css';
import 'antd/lib/icon/style/css';
import 'antd/lib/input/style/css';
import 'antd/lib/row/style/css';
import 'antd/lib/table/style/css';
import {connect} from "react-redux";
import modalShow from '../../actions/modal/modalShow';
import {SECURITY_ENTRY_MODAL} from "../Feedback/Modal/types";
import getSecuritySection from "../../actions/securitySection/getSecuritySection";
import {MONTHLY_VIEW} from "../../paths";
import {push} from 'connected-react-router';
import createSecurityEntry from "../../actions/securitySection/createSecurityEntry";
import updateSecurityEntry from "../../actions/securitySection/updateSecurityEntry";
import {marginRight} from "../../const/styles";
import deleteSecurityEntry from "../../actions/securitySection/deleteSecurityEntry";
import {confirmDelete} from "../../apis/authorizedDelete";

class Security extends React.Component {
  
  componentDidMount() {
    this.props.getSecuritySection(this.props.match.params.security_id);
  };
  
  handleCreate = (formValues) => {
    this.props.createSecurityEntry(formValues, this.props.match.params.security_id);
  };
  
  handleUpdate = (formValues) => {
    this.props.updateSecurityEntry(formValues, this.props.match.params.security_id);
  };
  
  openEntryModal = (entry, onSubmit) => {
    this.props.modalShow({
      modalType: SECURITY_ENTRY_MODAL,
      modalProps: {securityEntry: entry, onSubmit: onSubmit}
    });
  };
  
  columns = () => {
    return [{
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    }, {
      title: 'Titulo',
      dataIndex: 'title',
      key: 'title',
      render: (text) => {
        return <Icon type={text && text.length > 0 ? 'check' : 'close'}/>;
      }
    }, {
      title: 'Contenido',
      dataIndex: 'content',
      key: 'content',
      render: (text) => {
        return <Icon type={text && text.length > 15 ? 'check' : 'close'}/>;
      }
    }, {
      title: 'Imagen',
      dataIndex: 'avatar',
      key: 'avatar',
      render: (text) => {
        return <Icon type={text ? 'check' : 'close'}/>;
      }
    }, {
      title: 'Comentario',
      dataIndex: 'comment',
      key: 'comment',
      render: (text) => {
        return <Icon type={text && text.length > 0 ? 'check' : 'close'}/>;
      }
    }, {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record) => {
        return (
          <div>
            <Button onClick={() => this.openEntryModal(record, this.handleUpdate)} type={"primary"} htmlType={'button'}>
              <Icon type="eye"/>
            </Button>
            <Button
              onClick={() => confirmDelete(() => this.props.deleteSecurityEntry(this.props.match.params.security_id, record.id))}
              type={"danger"}
              style={marginRight}
              htmlType={'button'}>
              <Icon type="delete"/>
            </Button>
          </div>
        );
      }
    }]
  };
  
  render() {
    return (
      <Card bodyStyle={{padding: 0}}
            extra={<Button htmlType={'button'} type={'ghost'}
                           onClick={() => this.props.push(MONTHLY_VIEW(this.props.match.params.id))}>Volver</Button>}
            title={`Sección de seguridad - ${this.props.securitySection.status}`}
            >
        <Row gutter={16} className={'p-10'}>
          <Col span={24}>
            <Button type={'primary float-right'}
                    style={marginRight}
                    htmlType={'button'}
                    onClick={() => this.openEntryModal(null, this.handleCreate)}>
              Agregar
            </Button>
          </Col>
        </Row>
        <Row>
          <Table scroll={{x: '100%'}}
            dataSource={this.props.securitySection.kind === 'security_entries' ? this.props.securitySection.entries : []}
            rowKey={'id'} columns={this.columns()}/>
        </Row>
      </Card>
    );
  }
}


const mapStateToProps = state => {
  return {
    securitySection: state.userList.selected_entry
  };
};


export default connect(mapStateToProps, {
  push,
  modalShow,
  getSecuritySection,
  createSecurityEntry,
  deleteSecurityEntry,
  updateSecurityEntry
})(Security);