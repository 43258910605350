import {DELETE_MONTHLY_WEEK_ENTRY} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedDelete from "../../apis/authorizedDelete";

export default (tracingSectionId, entryId) => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedDelete(`/tracing_sections/${tracingSectionId}/week_entry/${entryId}`),
      simpleSuccess(DELETE_MONTHLY_WEEK_ENTRY));
};