import React from 'react';
import {Modal} from "antd";
import 'antd/lib/modal/style/css';
import FindingResume from "./FindingResume";
import FindingForm from "./FindingForm";
import FindingClosureForm from "./FindingClosureForm";
import {noPadding} from "../../const/styles";
import {connect} from "react-redux";

class FindingModal extends React.Component {

  handleRender = () => {
    if(this.props.kind === 'view') {
      return (
          <FindingResume findingEntry={this.props.userList.selected_entry}/>
      );
    } else if (this.props.kind === 'create') {
      return (
          <FindingForm onSubmit={this.props.createFindingEntry}/>
      );
    } else {
      return (
          <FindingClosureForm onSubmit={(formValues) => this.props.updateFindingEntry(this.props.userList.selected_entry.id, formValues)}/>
      );
    }
  };

  render() {
    const {modalType, modalProps} = this.props;
    return (
      <Modal bodyStyle={noPadding} footer={null} className={'content-wrapper'} visible={modalType !== null} style={{ top: 30 }}
             width={720} {...modalProps}>
        {this.handleRender()}
      </Modal>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    userList: state.userList
  };
}

export default connect(mapStateToProps)(FindingModal);