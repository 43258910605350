import React from 'react';
import {reduxForm} from "redux-form";
import {Button, Card, Form, Row} from "antd";
import 'antd/lib/button/style/css';
import 'antd/lib/form/style/css';
import 'antd/lib/row/style/css';
import FindingClosureFields from "./FindingClosureFields";

const FindingClosureForm = ({handleSubmit, change, onSubmit}) => {
  return (
    <Card title={'Cierre'}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FindingClosureFields change={change}/>
        <Row className={'p-20'}>
          <Button type={'primary float-right'} htmlType={'submit'}>Guardar</Button>
        </Row>
      </Form>
    </Card>
  );
};

export default reduxForm({
  form: 'findings_form',
  touchOnChange: true
})(FindingClosureForm);