import {
  UPDATE_INSTRUMENTATION_ENTRY
} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import modalDismiss from "../modal/modalDismiss";
import {formDataHelper} from "../functions/formDataHelper";
import authorizedPut from "../../apis/authorizedPut";
import _ from "lodash";

export default (formValues, subSectionId) => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedPut(
          `/instrumentation_sub_sections/${subSectionId}/entry`,
          formDataHelper(
              formValues,
              'instrumentation_sub_section',
              _.times(2, (index) => {return {name: `image${index}`, key: '[instrumentation_entries_attributes][][images][]'}})
          )
          ),
      simpleSuccess(UPDATE_INSTRUMENTATION_ENTRY),
      null,
      [modalDismiss]);
};