import React from "react";
import {Button, Card, Col, Icon, Row, Table } from 'antd';
import {connect} from "react-redux";
import modalShow from '../../actions/modal/modalShow';
import getFindingEntriesPanel from "../../actions/findingEntries/getFindingEntriesPanel";
import createFindingEntry from "../../actions/findingEntries/createFindingEntry";
import getFindingEntry from "../../actions/findingEntries/getFindingEntry";
import StatusHelper from "../Helpers/StatusHelper";
import {FINDING_MODAL} from "../Feedback/Modal/types";
import updateFindingEntry from "../../actions/findingEntries/updateFindingEntry";
import getFindingsReport from "../../actions/findingEntries/getFindingsReport";
import {marginRight} from "../../const/styles";
import FindingDateForm from "./FindingDateForm";

export const FindingsIndexList = ({userList, getFindingEntry, getProjectFindingsReport, modalShow}) => {

  const openFindingModal = (findingEntry, kind) => {
    modalShow({
      modalType: FINDING_MODAL,
      modalProps: {
        findingEntry: findingEntry,
        kind: kind
      }
    });
  };

  const handleGetEntry = (findingEntryId, kind) => {
    getFindingEntry(findingEntryId).then(() => openFindingModal(userList.selected_entry, kind));
  };

  const columns = () => {
    return [{
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      className: 'mobile-hide'
    }, {
      title: 'Referencia',
      dataIndex: 'reference',
      key: 'reference',
    }, {
      title: 'Responsable',
      dataIndex: 'responsible',
      key: 'responsible',
    }, {
      title: 'Fecha de inicio',
      dataIndex: 'start_date',
      key: 'start_date',
      className: 'mobile-hide'
    }, {
      title: 'Estado',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => <StatusHelper type={'finding'} position={'right'}
                                              status={record.end_date ? 'finished' : 'unfinished'}/>
    }, {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record) => {
        return (
          <div>
            <Button style={marginRight} onClick={() => handleGetEntry(record.id, 'view')} type={"primary"}
                    htmlType={'button'}>
              <Icon type="eye"/>
            </Button>
          </div>
        );
      }
    }]
  };

  return (
    <div>
      <FindingDateForm onSubmit={getProjectFindingsReport} />
      <Table scroll={{x: '100%'}}
             dataSource={userList.kind === 'finding_entries' ? userList.entries : []} rowKey={'id'}
             columns={columns()}/>
    </div>
  )
};

class Findings extends React.Component {

  componentDidMount() {
    this.props.getFindingEntriesPanel();
  };

  openFindingModal = (findingEntry, kind) => {
    this.props.modalShow({
      modalType: FINDING_MODAL,
      modalProps: {
        findingEntry: findingEntry,
        kind: kind,
        createFindingEntry: this.props.createFindingEntry,
        updateFindingEntry: this.props.updateFindingEntry
      }
    });
  };

  handleGetEntry = (findingEntryId, kind) => {
    this.props.getFindingEntry(findingEntryId).then(() => this.openFindingModal(this.props.userList.selected_entry, kind));
  };

  columns = () => {
    return [{
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      className: 'mobile-hide'
    }, {
      title: 'Referencia',
      dataIndex: 'reference',
      key: 'reference',
    }, {
      title: 'Responsable',
      dataIndex: 'responsible',
      key: 'responsible',
    }, {
      title: 'Fecha de inicio',
      dataIndex: 'start_date',
      key: 'start_date',
      className: 'mobile-hide'
    }, {
      title: 'Estado',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => <StatusHelper type={'finding'} position={'right'} status={record.end_date ? 'finished' : 'unfinished'}/>
    }, {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record) => {
        return (
            <div>
              <Button style={marginRight} onClick={() => this.handleGetEntry(record.id, 'view')} type={"primary"}
                      htmlType={'button'}>
                <Icon type="eye"/>
              </Button>
              {this.renderEditButton(record)}
            </div>
        );
      }
    }]
  };

  renderEditButton = (findingEntry) => {
    if(!findingEntry.end_date && !this.props.client)
      return (
        <Button htmlType={'button'} type={'ghost'} onClick={() => this.handleGetEntry(findingEntry.id, 'update')}>
          Cierre
        </Button>
      );
  };

  renderAddButton = () => {
    if(!this.props.client)
      return (
          <Col span={8}>
            <Button type={'primary'}
                    htmlType={'button'}
                    onClick={() => this.openFindingModal(null, 'create')}>
              Agregar
            </Button>
          </Col>
      );
  };

  render() {
    return (
      <Card bodyStyle={{padding: 0}} title={'Hallazgos'} extra={this.renderAddButton()}>
        <FindingDateForm onSubmit={this.props.getFindingsReport} />
        <Row>
          <Table scroll={{x: '100%'}}  dataSource={this.props.userList.kind === 'finding_entries' ? this.props.userList.entries : []} rowKey={'id'} columns={this.columns()}/>
        </Row>
      </Card>
    );
  }
}


const mapStateToProps = state => {
  return {
    client: state.auth.client,
    userList: state.userList
  };
};


export default connect(mapStateToProps, {modalShow, getFindingEntriesPanel, createFindingEntry, updateFindingEntry, getFindingEntry, getFindingsReport})(Findings);