import React from 'react';
import {Field, reduxForm} from "redux-form";
import {Button, Card, Col, Form, Row} from "antd";
import _ from 'lodash';
import QuillTextArea from "../Helpers/QuillTextArea";

class AnchorForm extends React.Component {
  
  state = {avatar: null};

  componentDidMount() {
    if(this.props.anchors_sub_section)
      this.props.initialize({anchors_sub_section: _.pick(this.props.anchors_sub_section, ['conclusions', 'content'])})
  }

  render() {
    const {handleSubmit, onSubmit, change, anchors_sub_section} = this.props;
    return (
      <Card title={'Informacion general'}>
        <Form onSubmit={handleSubmit((formValues) => onSubmit(formValues, anchors_sub_section.id))}>
          <Row gutter={16}>
            <Col xs={24} sm={24}>
              <Field label={'Contenido'}
                     name={`anchors_sub_section[content]`}
                     component={QuillTextArea}
                     change={change} />
            </Col>
            <Col xs={24} sm={24}>
              <Field label={'Conclusiones'}
                     name={`anchors_sub_section[conclusions]`}
                     component={QuillTextArea}
                     change={change} />
            </Col>
          </Row>
          <Row className={'p-20'}>
            <Button type={'primary float-right'} htmlType={'submit'}>Guardar</Button>
          </Row>
        </Form>
      </Card>
    );
  }
}

export default reduxForm({
  form: 'anchors_form',
  touchOnChange: false
})(AnchorForm);