import React from 'react';
import {Field, reduxForm} from "redux-form";
import {CreateFileField} from "../Helpers/Input/InputCreator";
import {fileDoc, fileRequired, fileVerification} from "../Helpers/Input/validations";
import {Button, Card, Col, Form, Modal, Row} from "antd";
import 'antd/lib/button/style/css';
import 'antd/lib/card/style/css';
import 'antd/lib/col/style/css';
import 'antd/lib/form/style/css';
import 'antd/lib/modal/style/css';
import 'antd/lib/row/style/css';
import 'antd/lib/select/style/css';
import _ from 'lodash';
import {marginRight, noPadding} from "../../const/styles";
import StatusHelper from "../Helpers/StatusHelper";
import {connect} from "react-redux";
import finishConceptSection from "../../actions/conceptSection/finishConceptSection";
import finishNetworkSection from "../../actions/networkSection/finishNetworkSection";
import finishNotStructuralSection from "../../actions/notStructuralSection/finishNotStructuralSection";
import finishRecommendationSection from "../../actions/recommendationSection/finishRecommendationSection";
import finishProtocolSection from "../../actions/protocolSection/finishProtocolSection";
import finishSecuritySection from "../../actions/securitySection/finishSecuritySection";
import finishTracingSection from "../../actions/tracingSection/finishTracingSection";
import fixMonthlyReport from "../../actions/monthlyReports/fixMonthlyReport";
import finishMonthlyReport from "../../actions/monthlyReports/finishMonthlyReport";
import finishAdministrativeSection from "../../actions/administrativeSection/finishAdministrativeSection";
import finishIndirectSection from "../../actions/indirectSection/finishIndirectSection";
import finishRainSection from "../../actions/rainSection/finishRainSection";
import finishResultSection from "../../actions/resultSection/finishResultSection";

const CorrectionsModal = ({
                            modalType, modalProps, handleSubmit, monthlyReport, change, finishConceptSection,
                            finishNetworkSection, finishNotStructuralSection, finishRecommendationSection, finishProtocolSection,
                            finishSecuritySection, finishTracingSection, fixMonthlyReport, finishMonthlyReport,
                            finishAdministrativeSection, finishIndirectSection, finishRainSection, finishResultSection
                          }) => {

  const renderCards = () => {
    return (
      Object.keys(_.omit(monthlyReport, ['id', 'date', 'status', 'ids', 'kind', 'document', 'corrections'])).map((property) => {
        if (monthlyReport.hasOwnProperty(property))
          return (
            <Col className={'mb-10'} md={12} key={property}>
              <Card bodyStyle={{minHeight: 140}} title={handleTitle(property)}>
                {renderState(property, monthlyReport[property], monthlyReport['ids'][property])}
              </Card>
            </Col>
          );
        else return ''
      })
    );
  };

  const handleTitle = (property) => {
    switch (property) {
      case 'location_section':
        return 'Ubicación';
      case 'network_section':
        return 'Redes';
      case 'not_structural_section':
        return 'Elementos no estructurales';
      case 'concept_section':
        return 'Conceptos';
      case 'security_section':
        return 'Seguridad';
      case 'tracing_section':
        return 'Seguimiento';
      case 'recommendation_section':
        return 'Recomendaciones';
      case 'administrative_section':
        return 'Administrativo';
      case 'indirect_section':
        return 'Indirecto';
      case 'rain_section':
        return 'Lluvia';
      case 'result_section':
        return 'Resultados';
      case 'protocol_section':
        return 'Protocolos';
      default:
        return null;
    }
  };

  const renderState = (property, state, id) => {
    if (state === null) {
      return (
        <div>
          <h2>
            <StatusHelper type={'monthly_property'} status={'inactive'} position={'top'}/> No creado
          </h2>
        </div>
      );
    } else if (state === 'unfinished') {
      return (
        <div>
          <h2>
            <StatusHelper type={'monthly_property'} status={state} position={'top'}/> Pendiente
          </h2>
          <Button htmlType={'button'} type={'ghost'} onClick={() => handleFinish(property, id)}>
            Terminar
          </Button>
        </div>
      );
    } else if (state === 'finished') {
      return (
        <h2>
          <StatusHelper type={'monthly_property'} status={state} position={'top'}/> <br/>
          Sección terminada
        </h2>
      );
    }
  };

  const handleFinish = (property, id) => {
    switch (property) {
      case 'location_section':
        break;
      case 'network_section':
        finishNetworkSection(id, monthlyReport.id);
        break;
      case 'not_structural_section':
        finishNotStructuralSection(id, monthlyReport.id);
        break;
      case 'concept_section':
        finishConceptSection(id, monthlyReport.id);
        break;
      case 'security_section':
        finishSecuritySection(id, monthlyReport.id);
        break;
      case 'tracing_section':
        finishTracingSection(id, monthlyReport.id);
        break;
      case 'recommendation_section':
        finishRecommendationSection(id, monthlyReport.id);
        break;
      case 'administrative_section':
        finishAdministrativeSection(id, monthlyReport.id);
        break;
      case 'indirect_section':
        finishIndirectSection(id, monthlyReport.id);
        break;
      case 'rain_section':
        finishRainSection(id, monthlyReport.id);
        break;
      case 'result_section':
        finishResultSection(id, monthlyReport.id);
        break;
      case 'protocol_section':
        finishProtocolSection(id, monthlyReport.id);
        break;
      default:
        return null;
    }
  };

  const renderCorrectionsBtn = () => {
    if(monthlyReport.corrections)
      return (
          <Button style={marginRight} htmlType={'button'} type={'ghost'} onClick={() => window.open(monthlyReport.corrections)}>
            Ver Correcciones actuales
          </Button>
      );
  }

  return (
    <Modal bodyStyle={noPadding} footer={null} className={'content-wrapper'} visible={modalType !== null}
           width={720} {...modalProps}>
      <Card title={'Correcciones de reporte'} extra={
        <div>
          <Button htmlType={'button'} type={'primary'} style={marginRight} onClick={() => finishMonthlyReport(monthlyReport.id)}>
            APROBAR
          </Button>
        </div>
      }>
        <Row gutter={16}>
          <Col xs={24}>
            <Form onSubmit={handleSubmit((formValues) => fixMonthlyReport(monthlyReport.id, formValues))}>
          <Row gutter={16}>
              <Col span={24}>
                <Button style={marginRight} htmlType={'button'} type={'ghost'} onClick={() => window.open(monthlyReport.document)}>
                  Ver Report actual
                </Button>
                {renderCorrectionsBtn()}
                <Button type={'primary'} htmlType={'submit'}>CORREGIR</Button>
              </Col>
              <Col span={12}>
                  <Field label={'Subir documento de correcciones'}
                         name={'corrections'}
                         component={CreateFileField}
                         validate={[fileRequired, fileVerification, fileDoc]}
                         change={change}
                         isFile={true}
                         type='file'
                         hasFeedback
                  />
              </Col>
            </Row>
            </Form>
          </Col>
          <Col xs={24}>
            <Row gutter={16}>
              {renderCards()}
            </Row>
          </Col>
        </Row>
      </Card>
    </Modal>
  );
};

const mapStateToProps = state => {
  return {
    monthlyReport: state.userList.selected_entry
  }
};

export default connect(mapStateToProps, {
  finishConceptSection,
  finishNetworkSection,
  finishNotStructuralSection,
  finishRecommendationSection,
  finishSecuritySection,
  finishTracingSection,
  fixMonthlyReport,
  finishMonthlyReport,
  finishAdministrativeSection,
  finishIndirectSection,
  finishRainSection,
  finishResultSection,
  finishProtocolSection
})(reduxForm({
  form: 'corrections_form',
  touchOnChange: true
})(CorrectionsModal));