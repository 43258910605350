import React from 'react';
import {Field, reduxForm} from "redux-form";
import {
  CreateCheckbox,
  CreateFileField,
  CreatePasswordField,
  CreateSelectField,
  CreateTextField
} from "../Helpers/Input/InputCreator";
import {
  email, fileImage, fileRequired, fileVerification,
  maxLength10,
  maxLength20,
  maxLength200,
  maxLength30,
  maxLength50,
  minLength10,
  minLength5,
  minLength6,
  minLength7,
  passwordMatch,
  required
} from "../Helpers/Input/validations";
import {Button, Card, Col, Form, Modal, Row, Select} from "antd";
import _ from 'lodash';
import {noPadding} from "../../const/styles";

class UsersModal extends React.Component {
  
  state = {avatar: null};

  componentDidMount() {
    if(this.props.user)
      this.props.initialize({user: _.omit(this.props.user, ['signature'])})
  }

  renderStatusSelect = () => {
    if(this.props.user)
      return (
        <Col xs={24} md={12}>
          <Field label={'Estado'}
                 name="user[status]"
                 component={CreateSelectField}
                 validate={[required]}
                 hasFeedback>
            <Select.Option key={1} value={'active'}>Activo</Select.Option>
            <Select.Option key={2} value={'inactive'}>Inactivo</Select.Option>
          </Field>
        </Col>
      );
  };
  
  render() {
    const {modalType, modalProps, handleSubmit, onSubmit, user, change} = this.props;
    return (
      <Modal bodyStyle={noPadding} footer={null} className={'content-wrapper'} visible={modalType !== null} style={{ top: 30 }}
             width={720} {...modalProps}>
        <Card title={'Usuario'}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <Field label={'Usuario'}
                       name={`user[username]`}
                       component={CreateTextField}
                       placeholder="Ususario"
                       validate={[required, minLength5, maxLength30]}
                       hasFeedback/>
              </Col>
              <Col xs={24} sm={12}>
                <Field name='user[admin]'
                       component={CreateCheckbox}
                       hasFeedback>
                  Administrador
                </Field>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <Field label={'Contraseña'}
                       name={`user[password]`}
                       component={CreatePasswordField}
                       placeholder="Contraseña"
                       validate={user ? [minLength6] : [required, minLength6]}
                       hasFeedback/>
              </Col>
              <Col xs={24} sm={12}>
                <Field label={'Confirmar contraseña'}
                       name={`user[confirm_password]`}
                       component={CreatePasswordField}
                       placeholder="Confirmar contraseña"
                       validate={[passwordMatch]}
                       hasFeedback/>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <Field label={'Nombre'}
                       name={`user[profile_attributes][name]`}
                       component={CreateTextField}
                       placeholder="Nombre"
                       validate={[required, minLength5, maxLength200]}
                       hasFeedback/>
              </Col>
              <Col xs={24} sm={12}>
                <Field label={'Email'}
                       name={`user[profile_attributes][email]`}
                       component={CreateTextField}
                       placeholder="Email"
                       validate={[required, email, minLength10, maxLength200]}
                       hasFeedback/>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <Field label={'Telefono'}
                       name={`user[profile_attributes][phone]`}
                       component={CreateTextField}
                       placeholder="Telefono"
                       validate={[required, minLength7, maxLength20]}
                       hasFeedback/>
              </Col>
              <Col xs={24} sm={12}>
                <Field label={'Cedula'}
                       name={`user[profile_attributes][cc_nit]`}
                       component={CreateTextField}
                       placeholder="Cedula"
                       validate={[required, minLength5, maxLength10]}
                       hasFeedback/>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <Field label={'Cargo'}
                       name={`user[profile_attributes][position]`}
                       component={CreateTextField}
                       placeholder="Cargo"
                       validate={[required, minLength7, maxLength50]}
                       hasFeedback/>
              </Col>
              {this.renderStatusSelect()}
              <Col xs={24} sm={12}>
                <Field label={'Firma virtual'}
                       name={'signature'}
                       preview={user ? user.signature : null}
                       component={CreateFileField}
                       validate={user ? [fileVerification, fileImage] : [fileRequired, fileVerification, fileImage]}
                       change={change}
                       isImage
                       type='file'
                       hasFeedback
                />
              </Col>
            </Row>
            <Row className={'p-20'}>
              <Button type={'primary float-right'} htmlType={'submit'}>Guardar</Button>
            </Row>
          </Form>
        </Card>
      </Modal>
    );
  }
}

export default reduxForm({
  form: 'users_form',
  touchOnChange: true
})(UsersModal);