import {CREATE_USER} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import {formDataHelper} from "../functions/formDataHelper";
import authorizedPost from "../../apis/authorizedPost";
import modalDismiss from "../modal/modalDismiss";

export default (formValues) => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedPost('/members', formDataHelper(formValues, 'user', [{name: 'signature', key: '[profile_attributes][signature]'}])),
      simpleSuccess(CREATE_USER),
      null,
      [modalDismiss]
  );
};