import React, { useEffect } from 'react';
import {Field, reduxForm} from "redux-form";
import {CreateDateRangeField} from "../Helpers/Input/InputCreator";
import { required } from "../Helpers/Input/validations";
import {Button, Form, Row, Col} from "antd";
import moment from 'moment';
import './index.scss';

const FindingDateForm = ({handleSubmit, change, initialize, onSubmit}) => {
  useEffect(() => {
    initialize({ date_range: [moment().add(-1, "month"), moment()] });
  }, []);
  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="finding-date-form">
      <Row className="p-20">
        <Col span={8}>
          <Field
            name={`date_range`}
            component={CreateDateRangeField}
            placeholder={["Fecha Inicio", "Fecha Fin"]}
            validate={[required]}
            hasFeedback
            allowClear={false}
            onFocus={(e) => e.preventDefault()}
            onBlur={(e) => e.preventDefault()}
          />
        </Col>
        <Col span={6}>
          <Button type={'primary float-right'} htmlType={'submit'}>Generar Reporte</Button>
        </Col>
      </Row>
    </Form>
  );
};

export default reduxForm({
  form: 'findings_date_form'
})(FindingDateForm);