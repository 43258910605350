import React from 'react';
import Users from './Users';
import './chat.css';

class Chat extends React.Component {

  decideLayout = () => {
    return <Users projectId={this.props.match.params.id} />;
  };

  render() {
    return (
      this.decideLayout()
    );
  }
}

export default Chat;