import {FINISH_RECOMMENDATION_SECTION} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedPut from "../../apis/authorizedPut";

export default (recommendationSectionId) => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedPut(`/recommendation_sections/${recommendationSectionId}/finish`),
    simpleSuccess(FINISH_RECOMMENDATION_SECTION)
  );
};