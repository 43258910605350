import React from 'react';
import {Button, Card, Col, List, Modal, Row, Tabs} from "antd";
import {marginRight} from "../../const/styles";

const DailyReportResume = ({client, dailyReport, handleCreate, handleUpdate, handleSuggestions, handleFinish, handleGenerate, handleImageDelete}) => {
  
  
  const confirmFinish = () => {
    Modal.confirm({
      title: 'Espera!',
      content: 'Seguro que quieres terminar el reporte diario?',
      onOk: handleFinish,
      okText: 'Terminar'
    })
  };
  
  const renderAddButton = () => {
    if(dailyReport.entries.length <= 3 && dailyReport.status === 'unfinished' && !client)
      return (
        <Col xs={24} md={8} lg={6}>
          <Button block style={marginRight} htmlType={'button'} type={'primary'} onClick={handleCreate}>Agregar</Button>
        </Col>
      );
  };

  const renderUnfinishedButtons = () => {
    if(dailyReport.status === 'unfinished' && !client) {
      return (
        <React.Fragment>
          <Col xs={24} md={8} lg={6}>
            <Button block htmlType={'button'} type={'danger'} onClick={confirmFinish}>Terminar</Button>
          </Col>
          <Col xs={24} md={8} lg={6}>
            <Button block htmlType={'button'} type={'ghost'} onClick={handleGenerate}>Pre visualizar</Button>
          </Col>
        </React.Fragment>
      );
    } else if(dailyReport.status === 'finished') {
      return (
        <Col xs={24} md={8} lg={6}>
          <Button block style={marginRight} htmlType={'button'} type={'ghost'}
                  onClick={() => window.open(dailyReport.document)}>Ver PDF</Button>
        </Col>
      );
    }
  };
  
  const renderSuggestionsBtn = () => {
    if(client) {
      return null
    } else {
      return (
        <Col xs={24} md={8} lg={6}>
          <Button block htmlType={'button'} type={'ghost'}
                  onClick={handleSuggestions}>Sugerencias ({dailyReport.suggestions.length})</Button>
        </Col>
      )
    }
  };
  
  const renderTitle = () => {
    return (
      <Row>
        <Col span={24}>
          {`Reporte del ${dailyReport.date}`}
        </Col>
        <Col span={24} className={'mt-10'}>
          <Row gutter={8} justify={'start'}>
            {renderUnfinishedButtons()}
            {renderAddButton()}
            {renderSuggestionsBtn()}
          </Row>
        </Col>
      </Row>
    )
  };

  return (
    <Card bodyStyle={{padding: 0}} title={renderTitle()}>
        <Tabs
            defaultActiveKey={dailyReport.entries.length.toString()}
            tabPosition={'left'}
            style={{height: '100%'}}
        >
          {dailyReport.entries.map((item, index) => {
            return (
                <Tabs.TabPane key={index + 1} tab={index + 1}>
                  <Card
                    title={item.time}
                    extra={dailyReport.status === 'unfinished' && !client ? <Button htmlType={'button'} type={'primary'}
                                                                                    onClick={() => handleUpdate(item)}>Editar</Button> : null}>
                    <Row>
                      <p>{item.content}</p>
                    </Row>
                    <List
                      itemLayout="horizontal"
                      grid={{gutter: 16, xs: 1, lg: 2}}
                      dataSource={item.images}
                      renderItem={image => (
                        <List.Item>
                          <div>
                              {dailyReport.status === 'unfinished' && !client ?
                                <div className={'p-20 float-left'}>
                                  <Button htmlType={'button'} type={'danger '}
                                          onClick={() => handleImageDelete(item.id, image.id)}>Eliminar</Button>
                                </div> : null}
                              <img src={image.url} alt={'Imagen'} style={{width: '100%'}} />
                          </div>
                        </List.Item>
                      )}
                    />
                  </Card>
                </Tabs.TabPane>
            );
          })}
        </Tabs>
      </Card>
  );
};

export default DailyReportResume;