import {CREATE_LOCATION_SECTION} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedPost from "../../apis/authorizedPost";
import {formDataHelper} from "../functions/formDataHelper";
import modalDismiss from "../modal/modalDismiss";

export default (monthlyReportId, formValues) => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedPost(
          `/monthly_reports/${monthlyReportId}/location_section`,
          formDataHelper(
              formValues,
              'monthly_report',
              [
                {name: 'plane1', key: '[location_section_attributes][plane1]'},
                {name: 'plane2', key: '[location_section_attributes][plane2]'}
              ]
          )
      ),
      simpleSuccess(CREATE_LOCATION_SECTION),
      null,
      [modalDismiss]
  );
};