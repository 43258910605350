import {UPDATE_PLANT_SUB_ENTRY} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedPut from "../../apis/authorizedPut";
import modalDismiss from "../modal/modalDismiss";
import {formDataHelper} from "../functions/formDataHelper";

export default (formValues, monthlyWeekEntryId) => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedPut(
          `/monthly_week_entries/${monthlyWeekEntryId}/plant_entry`,
          formDataHelper(formValues, 'monthly_week_entry', [{name: 'avatar', key: '[plant_sub_entries_attributes][][avatar]'}])
      ),
      simpleSuccess(UPDATE_PLANT_SUB_ENTRY),
      null,
      [modalDismiss]
  );
};