import React from 'react';
import {Button, Card, Col, Modal, Row, Transfer} from "antd";
import 'antd/lib/button/style/css';
import 'antd/lib/card/style/css';
import 'antd/lib/modal/style/css';
import 'antd/lib/row/style/css';
import 'antd/lib/transfer/style/css';
import {marginRight, noPadding} from "../../const/styles";

class ProjectsUsersModal extends React.Component {

  state = {
    targetKeys: this.props.selectedEntry.user_ids
  };

  handleChange = targetKeys => {
    this.setState({ targetKeys });
  };

  render() {
    const {modalType, modalProps, onSubmit, selectedEntry} = this.props;
    return (
      <Modal footer={null} bodyStyle={noPadding} className={'content-wrapper'} visible={modalType !== null}
             style={{top: 30}}
             width={800} {...modalProps}>
        <Card title={'Usuarios del proyecto'} extra={<Button htmlType={'button'} type={'primary'} style={marginRight}
                                                              onClick={() => onSubmit(this.state.targetKeys)}>Guardar</Button>}>
          <Row type={'flex'} justify={'center'}>
            <Col xs={24} md={18}>
              <Transfer
                dataSource={selectedEntry.users}
                showSearch
                rowKey={record => record.id}
                targetKeys={this.state.targetKeys}
                onChange={this.handleChange}
                render={item => <span
                  style={{color: item.status === 'active' ? 'GREEN' : 'RED'}}>{item.username}</span>}
                listStyle={{width: 250, height: 300}}
                filterOption={(inputValue, option) => option.username.toLowerCase().includes(inputValue.toLowerCase())}
                locale={{
                  itemUnit: 'Usuario(s)',
                  itemsUnit: 'Usuario',
                  notFoundContent: 'No hay usuarios',
                  searchPlaceholder: 'Buscar por nombre'
                }}
            />
            </Col>
          </Row>
        </Card>
      </Modal>
    );
  }
}

export default ProjectsUsersModal;