import React from 'react';
import {Button, Card, Modal, Row, Transfer} from "antd";
import 'antd/lib/button/style/css';
import 'antd/lib/card/style/css';
import 'antd/lib/modal/style/css';
import 'antd/lib/row/style/css';
import 'antd/lib/transfer/style/css';
import {marginRight, noPadding} from "../../const/styles";

class ProjectsProtocolsModal extends React.Component {

  state = {
    targetKeys: this.props.selectedEntry.protocol_ids
  };

  handleChange = targetKeys => {
    this.setState({ targetKeys });
  };

  render() {
    const {modalType, modalProps, onSubmit, selectedEntry} = this.props;
    return (
      <Modal footer={null} bodyStyle={noPadding} className={'content-wrapper'} visible={modalType !== null} style={{ top: 30 }}
             width={720} {...modalProps}>
        <Card title={'Protocolos del proyecto'} extra={<Button htmlType={'button'} style={marginRight} type={'primary'}
                                                               onClick={() => onSubmit(this.state.targetKeys)}>Guardar</Button>}>
          <Row>
            <Transfer
                dataSource={selectedEntry.protocols}
                showSearch
                rowKey={record => record.id}
                targetKeys={this.state.targetKeys}
                onChange={this.handleChange}
                render={item => <span style={{color: item.status === 'active' ? 'GREEN' : 'RED'}}>{item.name}</span>}
                listStyle={{width: 250, height: 300}}
                filterOption={(inputValue, option) => option.name.toLowerCase().includes(inputValue.toLowerCase())}
                locale={{ itemUnit: 'Protocolo(s)', itemsUnit: 'Protocolos', notFoundContent: 'No hay protocolos', searchPlaceholder: 'Buscar por nombre' }}
            />
          </Row>
        </Card>
      </Modal>
    );
  }
}

export default ProjectsProtocolsModal;