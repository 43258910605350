import { Button, Card, Icon, Table } from "antd";
import React from "react";
import { connect } from "react-redux";
import getProjectsMedia from "../../actions/projectsmedia/getProjectsMedia";
import modalShow from "../../actions/modal/modalShow";
import { MEDIA_UPLOAD_MODAL } from "../Feedback/Modal/types";
import {push} from 'connected-react-router';
import { PROJECTS_MEDIA_ASSETS } from '../../paths';

const { Column } = Table;

class PhotosVideos extends React.Component {

    componentDidMount() {
        this.loadRecords();
    }

    loadRecords() {
        const { match } = this.props;
        const { id } = match.params;
        this.props.getProjectsMedia(id || this.props.projectId);
    }

    modalShow() {
        const { match } = this.props;
        const { id } = match.params;
        this.props.modalShow({
            modalType: MEDIA_UPLOAD_MODAL,
            modalProps: {
                projectId: id || this.props.projectId
            }
        });
    }

    renderCardButton(){
        return(
            <Button type={'primary'} htmlType={'button'} onClick={() => this.modalShow()}>
                <Icon type='upload'/>Cargar archivo
            </Button>
        );
    }

    renderActions(record) {
        return(
            <Button type={'primary'} htmlType={'button'} disabled={record.videos === 0 && record.photos === 0} 
                    onClick={() => {
                        const { match } = this.props;
                        const { id } = match.params;
                        this.props.push(PROJECTS_MEDIA_ASSETS(id || this.props.projectId, record.id))
                        }
                    }>
                <Icon type='eye' />
            </Button>
        );
    }

    render(){
        return(
            <Card title={'Fotos y videos'} extra={this.renderCardButton()}>
                <Table dataSource={this.props.projects_medias} rowKey='id'>
                    <Column key="1" title="Mes" dataIndex="description" />
                    <Column key="2" title="Fotos" dataIndex="photos" />
                    <Column key="3" title="Videos" dataIndex="videos" />
                    <Column key="4" title="Acciones" render={(text, record) => { return this.renderActions(record)}} />
                </Table>
            </Card>
        );
    }
}

const mapStateToProps = state => {
    return {
        projectId: state.auth.target_id,
        projects_medias: state.userList.entries
    }
}

export default connect(mapStateToProps, {
    getProjectsMedia,
    modalShow,
    push
})(PhotosVideos);
