import {UPDATE_INDIRECT_ENTRY} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedPut from "../../apis/authorizedPut";
import modalDismiss from "../modal/modalDismiss";
import {formDataHelper} from "../functions/formDataHelper";

export default (formValues, indirectSectionId) => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedPut(
          `/indirect_sections/${indirectSectionId}/entry`,
          formDataHelper(formValues, 'indirect_section', [{name: 'avatar', key: '[indirect_entries_attributes][][avatar]'}])
      ),
      simpleSuccess(UPDATE_INDIRECT_ENTRY),
      null,
      [modalDismiss]
  );
};