import React from "react";
import {Button, Card, Icon, Row, Table} from 'antd';
import {connect} from "react-redux";
import modalShow from '../../actions/modal/modalShow';
import getDailyReportsPanel from "../../actions/dailyReports/getDailyReportsPanel";
import StatusHelper from "../Helpers/StatusHelper";
import createDailyReport from "../../actions/dailyReports/createDailyReport";
import {USER_DAILY_MODAL} from "../Feedback/Modal/types";
import getDailyReport from "../../actions/dailyReports/getDailyReport";
import {marginRight} from "../../const/styles";
import moment from "moment";

export const DailyIndexTable = ({admin, data, getDailyReport, modalShow, generateDailyReport}) => {
  
  const openDailyModal = (dailyReportId) => {
    getDailyReport(dailyReportId).then(() => {
      modalShow({
        modalType: USER_DAILY_MODAL,
        modalProps: {}
      });
    });
  };
  
  const columns = () => {
    return [{
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    }, {
      title: 'Fecha',
      dataIndex: 'date',
      key: 'date',
      render: (text) => moment(text).diff(moment(), "days") === 0 ? 'Hoy' : text
    }, {
      title: 'Estado',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => <StatusHelper type={'daily'} position={'left'} status={record.status}/>
    }, {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record) => {
        return (
          <div>
            {!admin &&
              <Button style={marginRight} onClick={() => openDailyModal(admin ? record : record.id)} type={"primary"} htmlType={'button'}>
                <Icon type="eye"/>
              </Button>}
            {renderViewButton(record)}
          </div>
        );
      }
    }]
  };
  
  const renderViewButton = (dailyReport) => {
    if (dailyReport.status === 'finished') {
      return (
          <Button htmlType={'button'} type={'ghost'} onClick={() => window.open(dailyReport.document)}>
            <Icon type="file-pdf"/>
          </Button>
      );
    } else if (dailyReport.status === 'unfinished' && admin) {
      return (
          <Button htmlType={'button'} type={'ghost'} onClick={() => generateDailyReport(dailyReport.id)}>
            <Icon type={'eye'}/>
          </Button>
      );
    }
  };
  
  return (
    <Table scroll={{x: '100%'}}
           dataSource={data.kind === 'daily_reports' ? data.entries : []} rowKey={'id'}
           columns={columns()}/>
  )
};

class UsersDaily extends React.Component {
  
  componentDidMount() {
    this.props.getDailyReportsPanel();
  };
  
  renderAddButton = () => {
    if (!this.props.client)
      return (
        <Button type={'primary'}
                htmlType={'button'}
                onClick={this.props.createDailyReport}>
          Agregar
        </Button>
      );
  };
  
  render() {
    const {modalShow, userList, getDailyReport} = this.props;

    return (
      <Card bodyStyle={{padding: 0}} extra={this.renderAddButton()} title={'Reportes Diarios'}>
        <Row>
          <DailyIndexTable modalShow={modalShow} data={userList} getDailyReport={getDailyReport}/>
        </Row>
      </Card>
    );
  }
}

const mapStateToProps = state => {
  return {
    client: state.auth.client,
    userList: state.userList
  };
};


export default connect(mapStateToProps, {modalShow, getDailyReportsPanel, createDailyReport, getDailyReport})(UsersDaily);