import {DELETE_NETWORK_SUB_ENTRY} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedDelete from "../../apis/authorizedDelete";

export default (networkEntryId, entryId) => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedDelete(`/network_entries/${networkEntryId}/entry/${entryId}`),
      simpleSuccess(DELETE_NETWORK_SUB_ENTRY));
};