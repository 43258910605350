import {UPDATE_DAILY_ENTRY} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import {formDataHelper} from "../functions/formDataHelper";
import _ from 'lodash';
import authorizedPut from "../../apis/authorizedPut";

export default (dailyReportId, formValues, callback) => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedPut(
        `/daily_reports/${dailyReportId}/entry`,
        formDataHelper(
            formValues,
            'daily_report',
            _.times(2, (index) => {return {name: `image${index}`, key: '[daily_entries_attributes][][images][]'}})
        )
      ),
      simpleSuccess(UPDATE_DAILY_ENTRY),
      null,
      [],
      [callback]
  );
};