import {Menu} from "antd";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const createMenu = (items, status, push, toggle) => {
  
  return (
    items.map((item, index) => {
      if (item.menus !== undefined) {
        if ('active' === status) {
          return (
            <Menu.SubMenu key={index} style={item.style} title={item.title}>
              {item.menus.map((menu) => {
                return (
                  renderItem(menu, null, push, toggle)
                );
              })}
            </Menu.SubMenu>
          );
        } else {
          return null;
        }
      } else {
        return (
          renderItem(item, status, push, toggle)
        );
      }
    })
  );
  
};

const renderItem = (item, status, push, toggle) => {
  if ('active' === status) {
    return (
      <Menu.Item key={item.path} style={item.style} onClick={() => {push(item.path); toggle()}}>
        {item.icon ? <i><FontAwesomeIcon icon={item.icon}/></i> : ''}
          <span className={'menu-title'}> {item.title}</span>
      </Menu.Item>
    );
  }
};