import {LOAD_END, LOAD_START, LOG_OUT} from "../actions/types";

const INITIAL_STATE = {
  loading: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_START:
      return {...state, loading: true};
    case LOAD_END:
    case LOG_OUT:
      return {...state, loading: false};
    default:
      return state;
  }
};