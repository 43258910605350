import React from "react";
import {Button, Card, Collapse, Icon, Row, Table} from 'antd';
import 'antd/lib/button/style/css';
import 'antd/lib/card/style/css';
import 'antd/lib/col/style/css';
import 'antd/lib/icon/style/css';
import 'antd/lib/input/style/css';
import 'antd/lib/row/style/css';
import 'antd/lib/table/style/css';
import {connect} from "react-redux";
import modalShow from '../../actions/modal/modalShow';
import getTracingSection from "../../actions/tracingSection/getTracingSection";
import {MONTHLY_VIEW, MONTHLY_WEEK_VIEW} from "../../paths";
import {push} from 'connected-react-router';
import {ADVANCE_ENTRY_MODAL, MONTHLY_WEEK_ENTRY_MODAL} from "../Feedback/Modal/types";
import updateAdvanceEntry from "../../actions/tracingSection/updateAdvanceEntry";
import createAdvanceEntry from "../../actions/tracingSection/createAdvanceEntry";
import createMonthlyWeekEntry from "../../actions/tracingSection/createMonthlyWeekEntry";
import updateMonthlyWeekEntry from "../../actions/tracingSection/updateMonthlyWeekEntry";
import {marginRight, noPadding} from "../../const/styles";
import deleteAdvanceEntry from "../../actions/tracingSection/deleteAdvanceEntry";
import deleteMonthlyWeekEntry from "../../actions/tracingSection/deleteMonthlyWeekEntry";
import {confirmDelete} from "../../apis/authorizedDelete";

class Tracing extends React.Component {
  
  componentDidMount() {
    this.props.getTracingSection(this.props.match.params.tracing_id);
  };
  
  handleAdvanceCreate = (formValues) => {
    this.props.createAdvanceEntry(formValues, this.props.match.params.tracing_id);
  };
  
  handleAdvanceUpdate = (formValues) => {
    this.props.updateAdvanceEntry(formValues, this.props.match.params.tracing_id);
  };
  
  openAdvanceModal = (entry, onSubmit) => {
    this.props.modalShow({
      modalType: ADVANCE_ENTRY_MODAL,
      modalProps: {advanceEntry: entry, onSubmit: onSubmit}
    });
  };
  
  handleWeekCreate = (formValues) => {
    this.props.createMonthlyWeekEntry(formValues, this.props.match.params.tracing_id);
  };
  
  handleWeekUpdate = (formValues) => {
    this.props.updateMonthlyWeekEntry(formValues, this.props.match.params.tracing_id);
  };
  
  openWeekModal = (entry, onSubmit) => {
    this.props.modalShow({
      modalType: MONTHLY_WEEK_ENTRY_MODAL,
      modalProps: {monthlyWeekEntry: entry, onSubmit: onSubmit}
    });
  };
  
  advanceColumns = () => {
    return [{
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    }, {
      title: 'Contenido',
      dataIndex: 'content',
      key: 'content',
      render: (text) => {
        return <Icon type={text && text.length > 15 ? 'check' : 'close'}/>;
      }
    }, {
      title: 'Imagen',
      dataIndex: 'avatar',
      key: 'avatar',
      render: (text) => {
        return <Icon type={text ? 'check' : 'close'}/>;
      }
    }, {
      title: 'Comentario',
      dataIndex: 'comment',
      key: 'comment',
      render: (text) => {
        return <Icon type={text && text.length > 0 ? 'check' : 'close'}/>;
      }
    }, {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record) => {
        return (
          <div>
            <Button onClick={() => this.openAdvanceModal(record, this.handleAdvanceUpdate)} type={"primary"}
                    htmlType={'button'}>
              <Icon type="eye"/>
            </Button>
            <Button
              onClick={() => confirmDelete(() => this.props.deleteAdvanceEntry(this.props.match.params.tracing_id, record.id))}
              type={"danger"}
              style={marginRight}
              htmlType={'button'}>
              <Icon type="delete"/>
            </Button>
          </div>
        );
      }
    }]
  };
  
  weekColumns = () => {
    return [{
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    }, {
      title: 'Titulo',
      dataIndex: 'title',
      key: 'title',
      render: (text) => {
        return <Icon type={text && text.length > 0 ? 'check' : 'close'}/>;
      }
    }, {
      title: 'Imagen',
      dataIndex: 'avatar',
      key: 'avatar',
      render: (text) => {
        return <Icon type={text ? 'check' : 'close'}/>;
      }
    }, {
      title: 'Comentario',
      dataIndex: 'comment',
      key: 'comment',
      render: (text) => {
        return <Icon type={text && text.length > 0 ? 'check' : 'close'}/>;
      }
    }, {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record) => {
        return (
          <div>
            <Button style={marginRight} onClick={() => this.openWeekModal(record, this.handleWeekUpdate)}
                    type={"primary"}
                    htmlType={'button'}>
              <Icon type="eye"/>
            </Button>
            <Button
              style={marginRight}
              onClick={() => this.props.push(MONTHLY_WEEK_VIEW(this.props.match.params.id, this.props.match.params.tracing_id, record.id))}
              type={"ghost"} htmlType={'button'}>
              Entradas
            </Button>
            <Button
              onClick={() => confirmDelete(() => this.props.deleteMonthlyWeekEntry(this.props.match.params.tracing_id, record.id))}
              type={"danger"}
              style={marginRight}
              htmlType={'button'}>
              <Icon type="delete"/>
            </Button>
          </div>
        );
      }
    }]
  };
  
  render() {
    return (
      <Card bodyStyle={{padding: 0}}
            extra={
              <div>
                <Button htmlType={'button'} type={'ghost'} style={marginRight}
                        onClick={() => this.props.push(MONTHLY_VIEW(this.props.match.params.id))}>Volver</Button>
              </div>
            }
            title={'Sección de seguimiento'}>
        <div>
          <Collapse>
            <Collapse.Panel header={'Entradas de avances'} key="1">
              <Card
                  bodyStyle={noPadding} title={'Entradas de avances'}
                  extra={
                    <Button type={'primary float-right'}
                            htmlType={'button'}
                            onClick={() => this.openAdvanceModal(null, this.handleAdvanceCreate)}>
                      Agregar
                    </Button>
                  }
              >
                <Row>
                  <Table scroll={{x: '100%'}}
                    dataSource={this.props.tracingSection.kind === 'tracing_entries' ? this.props.tracingSection.advance_entries : []}
                    rowKey={'id'} columns={this.advanceColumns()}/>
                </Row>
              </Card>
            </Collapse.Panel>
            <Collapse.Panel header={'Entradas semanales'} key="2">
              <Card
                  bodyStyle={noPadding} title={'Entradas semanales'}
                  extra={
                    <Button type={'primary float-right'}
                            htmlType={'button'}
                            onClick={() => this.openWeekModal(null, this.handleWeekCreate)}>
                      Agregar
                    </Button>
                  }
              >
                <Row>
                  <Table scroll={{x: '100%'}}
                    dataSource={this.props.tracingSection.kind === 'tracing_entries' ? this.props.tracingSection.monthly_week_entries : []}
                    rowKey={'id'} columns={this.weekColumns()}/>
                </Row>
              </Card>
            </Collapse.Panel>
          </Collapse>
        </div>
      </Card>
    );
  }
}


const mapStateToProps = state => {
  return {
    tracingSection: state.userList.selected_entry
  };
};


export default connect(mapStateToProps, {
  push,
  modalShow,
  getTracingSection,
  createAdvanceEntry,
  deleteAdvanceEntry,
  updateAdvanceEntry,
  createMonthlyWeekEntry,
  deleteMonthlyWeekEntry,
  updateMonthlyWeekEntry
})(Tracing);