import React from 'react';
import {connect} from "react-redux";
import modalShow from "../../actions/modal/modalShow";
import getProject from "../../actions/projects/getProject";
import getProjects from "../../actions/projects/getProjects";
import createProject from "../../actions/projects/createProject";
import updateProject from "../../actions/projects/updateProject";
import getProjectUsers from "../../actions/projects/getProjectUsers";
import updateProjectUsers from "../../actions/projects/updateProjectUsers";
import updateProjectClients from "../../actions/projects/updateProjectClients";
import getProjectClients from "../../actions/projects/getProjectClients";
import StatusHelper from "../Helpers/StatusHelper";
import {
  PROJECT_LOCATIONS,
  PROJECTS_CLIENTS_MODAL,
  PROJECTS_DOCUMENTS_MODAL,
  PROJECTS_MODAL,
  PROJECTS_USERS_MODAL
} from "../Feedback/Modal/types";
import {Button, Card, Col, Dropdown, Icon, Menu, Row, Table} from "antd";
import getProjectDocuments from "../../actions/projects/getProjectDocuments";
import getProjectLocations from "../../actions/projects/getProjectLocations";
import { push } from 'connected-react-router';
import {PROJECTS_VIEW, PROJECTS_CHAT} from "../../paths";

class Projects extends React.Component {

  state = { active: true }

  componentDidMount() {
    this.props.getProjects(this.state.active);
  }

  fetchProject = (projectId) => {
    this.props.getProject(projectId).then(() => this.openProjectModal(
        this.props.userList.selected_entry,
        (formValues) => {
          this.setState({ active: true });
          this.props.updateProject(formValues, projectId);
        }
    ));
  };

  openProjectModal = (project, onSubmit) => {
    this.props.modalShow({
      modalType: PROJECTS_MODAL,
      modalProps: {project: project, onSubmit: onSubmit}
    });
  };

  fetchProjectClients = (projectId) => {
    this.props.getProjectClients(projectId).then(() => this.props.modalShow({
      modalType: PROJECTS_CLIENTS_MODAL,
      modalProps: {selectedEntry: this.props.userList.selected_entry, onSubmit: (userIds) => {
        this.setState({ active: true });;
        this.props.updateProjectClients(userIds, projectId);
      }}
    }));
  };

  fetchProjectDocuments = (projectId) => {
    this.props.getProjectDocuments(projectId).then(() => this.props.modalShow({modalType: PROJECTS_DOCUMENTS_MODAL, modalProps: {projectId: projectId}}));
  };

  fetchProjectLocations = (projectId) => {
    this.props.getProjectLocations(projectId).then(() => this.props.modalShow({
      modalType: PROJECT_LOCATIONS,
      modalProps: {projectId: projectId}
    }));
  };

  fetchProjectUsers = (projectId) => {
    this.props.getProjectUsers(projectId).then(() => this.props.modalShow({
      modalType: PROJECTS_USERS_MODAL,
      modalProps: {selectedEntry: this.props.userList.selected_entry, onSubmit: (userIds) => {
        this.setState({ active: true });
        this.props.updateProjectUsers(userIds, projectId);
      }}
    }));
  };

  menu = (record) => {
    return (
      <Menu>
        <Menu.Item key="1" onClick={() => this.fetchProjectUsers(record.id)}>
          <span><Icon type="user"/> Usuarios</span>
        </Menu.Item>
        <Menu.Item key="3" onClick={() => this.fetchProjectClients(record.id)}>
          <span><Icon type="audit"/> Clientes</span>
        </Menu.Item>
        <Menu.Item key="4" onClick={() => this.fetchProjectDocuments(record.id)}>
          <span><Icon type="file"/> Documentos</span>
        </Menu.Item>
        <Menu.Item key="5" onClick={() => this.fetchProjectLocations(record.id)}>
          <span><Icon type="environment"/> Ubicaciones</span>
        </Menu.Item>
        <Menu.Item key="6" onClick={() => this.props.push(PROJECTS_VIEW(record.id))}>
          <span><Icon type="pie-chart"/> Reportes</span>
        </Menu.Item>
        <Menu.Item key="7" onClick={() => this.props.push(PROJECTS_CHAT(record.id))}>
          <span><Icon type="message"/> Chat</span>
        </Menu.Item>
      </Menu>
    );
  };

  columns = () => {
    return [{
      title: '#',
      dataIndex: 'id',
      key: 'id'
    }, {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name'
    }, {
      title: 'Estado',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => <StatusHelper type={'project'} position={'right'} status={record.status}/>
    }, {
      title: 'Usuarios',
      dataIndex: 'active_users',
      key: 'active_users'
    }, {
      title: 'Clientes',
      dataIndex: 'active_clients',
      key: 'active_clients'
    }, {
      title: 'Último Mensaje',
      dataIndex: 'last_message',
      key: 'last_message'
    }, {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record) => (
        <Row gutter={{xs: 0, md: 8}}>
          <Col xs={24} md={8}>
            <Button block type={'primary'} icon={'eye'} onClick={() => this.fetchProject(record.id)}/>
          </Col>
          <Col xs={24} md={8}>
            <Dropdown overlay={() => this.menu(record)} trigger={['click']}>
              <Button block type={'ghost'} icon={'more'}/>
            </Dropdown>
          </Col>
        </Row>
      )
    }]
  };

  render() {
    return (
        <Card
            bodyStyle={{padding: 0}} title={'Proyectos'}
            extra={
              <div>
                <Button
                  type={'ghost'}
                  htmlType={'button'}
                  style={{marginRight: 10}}
                  onClick={() => this.setState({ active: !this.state.active }, () => this.props.getProjects(this.state.active))}
                >
                  { this.state.active ? 'Mostrar Inactivos' : 'Mostrar activos' }
                </Button>
                <Button
                  type={'primary'}
                  htmlType={'button'}
                  onClick={() => this.openProjectModal(null, this.props.createProject)}
                >
                  Agregar
                </Button>
              </div>
            }
        >
          <Row>
            <Table scroll={{x: '100%'}}
                   dataSource={this.props.userList.kind === 'projects' ? this.props.userList.entries : []} rowKey={'id'}
                   columns={this.columns()}/>
          </Row>
        </Card>
    );
  }
}

const mapStateToProps = state => {
  return {
    userList: state.userList
  };
};

export default connect(mapStateToProps, {
  modalShow,
  getProject,
  getProjects,
  createProject,
  updateProject,
  getProjectUsers,
  updateProjectUsers,
  updateProjectClients,
  getProjectClients,
  getProjectDocuments,
  getProjectLocations,
  push
})(Projects);