import {GENERATE_WEEKLY_REPORT} from "../types";
import loadingCall from "../functions/loadingCall";
import authorizedGet from "../../apis/authorizedGet";

export default (weeklyReportId) => async (dispatch) => {
  await loadingCall(dispatch, authorizedGet(`/weekly_reports/${weeklyReportId}/generate`), onSuccess);
};

const onSuccess = (dispatch, data) => {
  window.open(data.report);
  dispatch({type: GENERATE_WEEKLY_REPORT, payload: data});
};