import React from "react";
import {Button, Card, Collapse, Icon, Table} from 'antd';
import 'antd/lib/button/style/css';
import 'antd/lib/card/style/css';
import 'antd/lib/col/style/css';
import 'antd/lib/icon/style/css';
import 'antd/lib/input/style/css';
import 'antd/lib/row/style/css';
import 'antd/lib/table/style/css';
import {connect} from "react-redux";
import modalShow from '../../actions/modal/modalShow';
import {STEELS_ENTRY_MODAL} from "../Feedback/Modal/types";
import {RESULTS_VIEW} from "../../paths";
import {push} from 'connected-react-router';
import SteelsForm from "./SteelForm";
import getSteelsSubSection from "../../actions/steelsSubSection/getSteelsSubSection";
import updateSteelsSubSection from "../../actions/steelsSubSection/updateSteelsSubSection";
import createSteelsEntry from "../../actions/steelsSubSection/createSteelsEntry";
import updateSteelsEntry from "../../actions/steelsSubSection/updateSteelsEntry";
import deleteSteelsEntry from "../../actions/steelsSubSection/deleteSteelsEntry";
import {marginRight} from "../../const/styles";
import {confirmDelete} from "../../apis/authorizedDelete";

class Steels extends React.Component {

  componentDidMount() {
    this.props.getSteelsSubSection(this.props.match.params.steel_id);
  };

  openEntryModal = (entry, onSubmit) => {
    this.props.modalShow({
      modalType: STEELS_ENTRY_MODAL,
      modalProps: {steelsEntry: entry, onSubmit: onSubmit}
    });
  };

  handleEntryCreate = (formValues) => {
    this.props.createSteelsEntry(formValues, this.props.match.params.steel_id);
  };

  handleEntryUpdate = (formValues) => {
    this.props.updateSteelsEntry(formValues, this.props.match.params.steel_id);
  };
  
  entryColumns = () => {
    return [{
      title: 'Contenido',
      dataIndex: 'content',
      key: 'content',
      render: (text) => {
        return <Icon type={text && text.length > 15 ? 'check' : 'close'}/>;
      }
    }, {
      title: 'Imagen',
      dataIndex: 'avatar',
      key: 'avatar',
      render: (text) => {
        return <Icon type={text ? 'check' : 'close'}/>;
      }
    }, {
      title: 'Comentario',
      dataIndex: 'comment',
      key: 'comment',
      render: (text) => {
        return <Icon type={text && text.length > 0 ? 'check' : 'close'}/>;
      }
    }, {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record) => {
        return (
            <div>
              <Button onClick={() => this.openEntryModal(record, this.handleEntryUpdate)} type={"primary"}
                      htmlType={'button'}>
                <Icon type="eye"/>
              </Button>
              <Button
                onClick={() => confirmDelete(() => this.props.deleteSteelsEntry(this.props.match.params.steel_id, record.id))}
                type={"danger"}
                style={marginRight}
                htmlType={'button'}>
                <Icon type="delete"/>
              </Button>
            </div>
        );
      }
    }]
  };

  render() {
    return (
        <Card title={'Sección de aceros'}
              bodyStyle={{padding: 0}}
              extra={
                <Button htmlType={'button'}
                        type={'ghost'}
                        onClick={() => this.props.push(RESULTS_VIEW(this.props.match.params.id, this.props.match.params.results_id))}>Volver</Button>
              }>
            <Collapse>
              <Collapse.Panel header={'Información general'} key="1">
                <SteelsForm onSubmit={this.props.updateSteelsSubSection} steels_sub_section={this.props.steels_sub_section} />
              </Collapse.Panel>
              <Collapse.Panel header={'Entradas'} key="2">
                <Card title={'Entradas'}
                      bodyStyle={{padding: 0}}
                      extra={
                        <Button type={'primary'}
                                htmlType={'button'}
                                onClick={() => this.openEntryModal(null, this.handleEntryCreate)}>
                          Agregar
                        </Button>
                      }
                >
                  <Table scroll={{x: '100%'}}
                      dataSource={this.props.steels_sub_section.kind === 'steel_entries' ? this.props.steels_sub_section.entries : []}
                      rowKey={'id'} columns={this.entryColumns()}/>
                </Card>
              </Collapse.Panel>
            </Collapse>

        </Card>
    );
  }
}

const mapStateToProps = state => {
  return {
    steels_sub_section: state.userList.selected_entry
  };
};

export default connect(
    mapStateToProps,
    {
      push,
      modalShow,
      updateSteelsSubSection,
      getSteelsSubSection,
      createSteelsEntry,
      deleteSteelsEntry,
      updateSteelsEntry,
    })(Steels);