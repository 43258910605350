import React from 'react';
import {Button, Col, Form, Modal, Row} from "antd";
import 'antd/lib/modal/style/css';
import 'antd/lib/col/style/css';
import 'antd/lib/row/style/css';
import 'antd/lib/form/style/css';
import {Field, reduxForm} from "redux-form";
import {CreatePasswordField} from "../../Helpers/Input/InputCreator";
import {minLength6, passwordMatch, required} from "../../Helpers/Input/validations";

const PasswordModal = ({modalType, modalProps, handleSubmit, onSubmit}) => {
    return (
      <Modal footer={null} className={'content-wrapper'} visible={modalType !== null}
             width={720} {...modalProps}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Field label={'Contraseña'}
                     name={'user[password]'}
                     component={CreatePasswordField}
                     placeholder="Contraseña"
                     validate={[required, minLength6]}
                     hasFeedback/>
            </Col>
            <Col xs={24} sm={12}>
              <Field label={'Confirmar contraseña'}
                     name={'user[confirm_password]'}
                     component={CreatePasswordField}
                     placeholder="Confirmar contraseña"
                     validate={[passwordMatch]}
                     hasFeedback/>
            </Col>
          </Row>
          <Row className={'p-20'}>
            <Button type={'primary float-right'} htmlType={'submit'}>Guardar</Button>
          </Row>
        </Form>
      </Modal>
    );
}


export default reduxForm({
  form: 'password_form'
})(PasswordModal);