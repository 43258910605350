import {CREATE_PROJECT_LOCATIONS} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedPost from "../../apis/authorizedPost";

export default (formValues, projectId, callback) => async (dispatch) => {
  await loadingCall(
    dispatch,
    authorizedPost(`/projects/${projectId}/locations`, formValues),
    simpleSuccess(CREATE_PROJECT_LOCATIONS),
    null,
    [],
    [callback]);
};