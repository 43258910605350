import React from 'react'
import ReduxContainer from "./ReduxContainer";
import './App.css';

export default () => {
  
  return (
    <ReduxContainer/>
  );
  
}