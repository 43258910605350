import {FINISH_NOT_STRUCTURAL_SECTION} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedPut from "../../apis/authorizedPut";

export default (notStructuralSectionId) => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedPut(`/not_structural_sections/${notStructuralSectionId}/finish`),
    simpleSuccess(FINISH_NOT_STRUCTURAL_SECTION)
  );
};