import {CREATE_WEEKLY_ENTRY} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedPost from "../../apis/authorizedPost";
import {formDataHelper} from "../functions/formDataHelper";
import _ from "lodash";

export default (weeklyReportId, formValues, callback) => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedPost(
        `/weekly_reports/${weeklyReportId}/entry`,
        formDataHelper(
            formValues,
            'weekly_report',
            _.times(4, (index) => {return {name: `image${index}`, key: '[weekly_entries_attributes][][images][]'}})
        )
      ),
      simpleSuccess(CREATE_WEEKLY_ENTRY),
      null,
      [],
      [callback]
  );
};