import server from "./server";
import {Modal} from "antd";

export const confirmDelete = (action) => {
  Modal.confirm({
    title: 'Espera!',
    content: 'Se eliminará permanentemente',
    okType: 'danger',
    okText: 'Eliminar',
    onOk: () => action()
  })
};

export default (route) => {
  return server.delete(route, {
    headers: {
      'Authorization': 'Bearer ' + (localStorage.getItem('jwt_token') !== undefined ? localStorage.getItem('jwt_token') : '')
    }
  });
};