import React from 'react';
import {connect} from 'react-redux';
import infoDismiss from '../../../actions/info/infoDismiss'
import {notification} from "antd";

class NotificationCreator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false
    }
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    this.renderNotification();
  }
  
  renderNotification() {
    if (this.props.message !== null && !this.state.active) {
      this.setState({active: true});
      notification[this.props.type]({
        message: this.props.message,
        duration: 10,
        onClose: () => {
          this.props.infoDismiss();
          this.setState({active: false});
        }
      });
    }
  }
  
  render() {
    return (
      <React.Fragment/>
    );
  }
  
}

const mapStateToProps = state => {
  return {
    message: state.info.message,
    type: state.info.type
  };
};

export default connect(mapStateToProps, {infoDismiss})(NotificationCreator);