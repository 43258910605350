import {DELETE_RECOMMENDATION_SECTION} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedDelete from "../../apis/authorizedDelete";

export default (monthlyReportId) => async (dispatch) => {
  await loadingCall(
    dispatch,
    authorizedDelete(`/monthly_reports/${monthlyReportId}/recommendation_section`),
    simpleSuccess(DELETE_RECOMMENDATION_SECTION)
  );
};