//HELPERS
export const VERIFY_TOKEN = 'VERIFY_TOKEN';
export const GET_DASHBOARD = 'GET_DASHBOARD';

//SESSIONS
export const LOG_IN = 'LOG_IN';

//USERS
export const GET_USERS = 'GET_USERS';
export const GET_USER = 'GET_USER';
export const CREATE_USER = 'CREATE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const CHANGE_TARGET = 'CHANGE_TARGET';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';

//CLIENTS
export const GET_CLIENTS = 'GET_CLIENTS';
export const GET_CLIENT = 'GET_CLIENT';
export const CREATE_CLIENT = 'CREATE_CLIENT';
export const UPDATE_CLIENT = 'UPDATE_CLIENT';
export const CHANGE_CLIENT_TARGET = 'CHANGE_CLIENT_TARGET';

//PROJECT
export const GET_PROJECTS = 'GET_PROJECTS';
export const GET_PROJECT = 'GET_PROJECT';
export const CREATE_PROJECT = 'CREATE_PROJECT';
export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const GET_PROJECT_USERS = 'GET_PROJECT_USERS';
export const UPDATE_PROJECT_USERS = 'UPDATE_PROJECT_USERS';
export const GET_PROJECT_PROTOCOLS = 'GET_PROJECT_PROTOCOLS';
export const UPDATE_PROJECT_PROTOCOLS = 'UPDATE_PROJECT_PROTOCOLS';
export const GET_PROJECT_CLIENTS = 'GET_PROJECT_CLIENTS';
export const GET_PROJECT_CLIENT = 'GET_PROJECT_CLIENT';
export const UPDATE_PROJECT_CLIENTS = 'UPDATE_PROJECT_CLIENTS';
export const CREATE_PROJECT_CLIENT = 'CREATE_PROJECT_CLIENT';
export const GET_PROJECT_DOCUMENTS = 'GET_PROJECT_DOCUMENTS';
export const GET_PROJECT_DAILY = 'GET_PROJECT_DAILY';
export const GET_PROJECT_WEEKLY = 'GET_PROJECT_WEEKLY';
export const GET_PROJECT_MONTHLY = 'GET_PROJECT_MONTHLY';
export const GET_PROJECT_FINDINGS = 'GET_PROJECT_FINDINGS';
export const GET_PROJECT_DOCUMENT = 'GET_PROJECT_DOCUMENT';
export const UPDATE_PROJECT_DOCUMENT = 'UPDATE_PROJECT_DOCUMENT';
export const CREATE_PROJECT_DOCUMENT = 'CREATE_PROJECT_DOCUMENT';

export const GET_PROJECT_LOCATIONS = 'GET_PROJECT_LOCATIONS';
export const UPDATE_PROJECT_LOCATIONS = 'UPDATE_PROJECT_LOCATIONS';
export const CREATE_PROJECT_LOCATIONS = 'CREATE_PROJECT_LOCATIONS';

export const GET_PROJECT_MEDIA = 'GET_PROJECT_MEDIA';
export const GET_PHOTOS_VIDEOS = 'GET_PHOTOS_VIDEOS';
export const UPLOAD_PROJECT_MEDIA = 'UPLOAD_PROJECT_MEDIA';
export const GET_PROJECTS_ASSETS_RESUME = 'GET_PROJECTS_ASSETS_RESUME';

//PROTOCOLS
export const GET_PROTOCOLS = 'GET_PROTOCOLS';
export const GET_PROTOCOL = 'GET_PROTOCOL';
export const CREATE_PROTOCOL = 'CREATE_PROTOCOL';
export const UPDATE_PROTOCOL = 'UPDATE_PROTOCOL';

//RECOMMENDATIONS
export const GET_RECOMMENDATIONS = 'GET_RECOMMENDATIONS';
export const GET_RECOMMENDATION = 'GET_RECOMMENDATION';
export const CREATE_RECOMMENDATION = 'CREATE_RECOMMENDATION';
export const UPDATE_RECOMMENDATION = 'UPDATE_RECOMMENDATION';

//REQUIREMENTS
export const GET_REQUIREMENTS = 'GET_REQUIREMENTS';
export const GET_REQUIREMENT = 'GET_REQUIREMENT';
export const CREATE_REQUIREMENT = 'CREATE_REQUIREMENT';
export const UPDATE_REQUIREMENT = 'UPDATE_REQUIREMENT';

//DAILY REPORTS
export const GET_DAILY_REPORTS_PANEL = 'GET_DAILY_REPORTS_PANEL';
export const GENERATE_DAILY_REPORT = 'GENERATE_DAILY_REPORT';
export const GET_DAILY_REPORT = 'GET_DAILY_REPORT';
export const CREATE_DAILY_REPORT = 'CREATE_DAILY_REPORT';
export const FINISH_DAILY_REPORT = 'FINISH_DAILY_REPORT';
export const CREATE_DAILY_ENTRY = 'CREATE_DAILY_ENTRY';
export const UPDATE_DAILY_ENTRY = 'UPDATE_DAILY_ENTRY';
export const CREATE_DAILY_SUGGESTION = 'CREATE_DAILY_SUGGESTION';
export const UPDATE_DAILY_SUGGESTION = 'UPDATE_DAILY_SUGGESTION';
export const DELETE_DAILY_ENTRY_IMAGE = 'DELETE_DAILY_ENTRY_IMAGE';

//WEEKLY REPORTS
export const GET_WEEKLY_REPORTS_PANEL = 'GET_WEEKLY_REPORTS_PANEL';
export const GENERATE_WEEKLY_REPORT = 'GENERATE_WEEKLY_REPORT';
export const GET_WEEKLY_REPORT = 'GET_WEEKLY_REPORT';
export const CREATE_WEEKLY_REPORT = 'CREATE_WEEKLY_REPORT';
export const FINISH_WEEKLY_REPORT = 'FINISH_WEEKLY_REPORT';
export const CREATE_WEEKLY_ENTRY = 'CREATE_WEEKLY_ENTRY';
export const UPDATE_WEEKLY_ENTRY = 'UPDATE_WEEKLY_ENTRY';
export const CREATE_WEEKLY_SUGGESTION = 'CREATE_WEEKLY_SUGGESTION';
export const UPDATE_WEEKLY_SUGGESTION = 'UPDATE_WEEKLY_SUGGESTION';
export const DELETE_WEEKLY_ENTRY_IMAGE = 'DELETE_WEEKLY_ENTRY_IMAGE';

//FINDING ENTRIES
export const GET_FINDING_ENTRIES_PANEL = 'GET_FINDING_ENTRIES_PANEL';
export const CREATE_FINDING_ENTRY = 'CREATE_FINDING_ENTRY';
export const UPDATE_FINDING_ENTRY = 'UPDATE_FINDING_ENTRY';
export const GET_FINDING_ENTRY = 'GET_FINDING_ENTRY';

//MONTHLY REPORTS
export const GENERATE_MONTHLY_REPORT = 'GENERATE_MONTHLY_REPORT';
export const CHECK_MONTHLY_REPORT = 'CHECK_MONTHLY_REPORT';
export const FIX_MONTHLY_REPORT = 'FIX_MONTHLY_REPORT';
export const REVISE_MONTHLY_REPORT = 'REVISE_MONTHLY_REPORT';
export const FINISH_MONTHLY_REPORT = 'FINISH_MONTHLY_REPORT';
export const GET_MONTHLY_REPORTS_PANEL = 'GET_MONTHLY_REPORTS_PANEL';
export const CREATE_MONTHLY_REPORT = 'CREATE_MONTHLY_REPORT';
export const GET_MONTHLY_REPORT = 'GET_MONTHLY_REPORT';
export const CREATE_LOCATION_SECTION = 'CREATE_LOCATION_SECTION';
export const CREATE_NETWORK_SECTION = 'CREATE_NETWORK_SECTION';
export const CREATE_RECOMMENDATION_SECTION = 'CREATE_RECOMMENDATION_SECTION';
export const CREATE_PROTOCOL_SECTION = 'CREATE_PROTOCOL_SECTION';
export const CREATE_NOT_STRUCTURAL_SECTION = 'CREATE_NOT_STRUCTURAL_SECTION';
export const CREATE_CONCEPT_SECTION = 'CREATE_CONCEPT_SECTION';
export const CREATE_SECURITY_SECTION = 'CREATE_SECURITY_SECTION';
export const CREATE_TRACING_SECTION = 'CREATE_TRACING_SECTION';
export const CREATE_ADMINISTRATIVE_SECTION = 'CREATE_ADMINISTRATIVE_SECTION';
export const CREATE_INDIRECT_SECTION = 'CREATE_INDIRECT_SECTION';
export const CREATE_RAIN_SECTION = 'CREATE_RAIN_SECTION';
export const CREATE_RESULT_SECTION = 'CREATE_RESULT_SECTION';
export const DELETE_LOCATION_SECTION = 'DELETE_LOCATION_SECTION';
export const DELETE_NETWORK_SECTION = 'DELETE_NETWORK_SECTION';
export const DELETE_RECOMMENDATION_SECTION = 'DELETE_RECOMMENDATION_SECTION';
export const DELETE_PROTOCOL_SECTION = 'DELETE_PROTOCOL_SECTION';
export const DELETE_NOT_STRUCTURAL_SECTION = 'DELETE_NOT_STRUCTURAL_SECTION';
export const DELETE_CONCEPT_SECTION = 'DELETE_CONCEPT_SECTION';
export const DELETE_SECURITY_SECTION = 'DELETE_SECURITY_SECTION';
export const DELETE_TRACING_SECTION = 'DELETE_TRACING_SECTION';
export const DELETE_ADMINISTRATIVE_SECTION = 'DELETE_ADMINISTRATIVE_SECTION';
export const DELETE_INDIRECT_SECTION = 'DELETE_INDIRECT_SECTION';
export const DELETE_RAIN_SECTION = 'DELETE_RAIN_SECTION';
export const DELETE_RESULT_SECTION = 'DELETE_RESULT_SECTION';

//RESULT SECTION
export const FINISH_RESULT_SECTION = 'FINISH_RESULT_SECTION';
export const CREATE_CONCRETE = 'CREATE_CONCRETE';
export const CREATE_MORTARS = 'CREATE_MORTARS';
export const CREATE_STEELS = 'CREATE_STEELS';
export const CREATE_ANCHORS = 'CREATE_ANCHORS';
export const CREATE_INSTRUMENTS = 'CREATE_INSTRUMENTS';
export const CREATE_FREE = 'CREATE_FREE';
export const CREATE_CONNECTORS = 'CREATE_CONNECTORS';
export const DELETE_CONCRETE = 'DELETE_CONCRETE';
export const DELETE_MORTARS = 'DELETE_MORTARS';
export const DELETE_STEELS = 'DELETE_STEELS';
export const DELETE_ANCHORS = 'DELETE_ANCHORS';
export const DELETE_INSTRUMENTS = 'DELETE_INSTRUMENTS';
export const DELETE_FREE = 'DELETE_FREE';
export const DELETE_CONNECTORS = 'DELETE_CONNECTORS';
export const GET_RESULT_SECTION = 'GET_RESULT_SECTION';

//NETWORK SECTIONS
export const GET_NETWORK_SECTION = 'GET_NETWORK_SECTION';
export const CREATE_NETWORK_ENTRY = 'CREATE_NETWORK_ENTRY';
export const DELETE_NETWORK_ENTRY = 'DELETE_NETWORK_ENTRY';
export const UPDATE_NETWORK_ENTRY = 'UPDATE_NETWORK_ENTRY';
export const FINISH_NETWORK_SECTION = 'FINISH_NETWORK_SECTION';

//NETWORK ENTRIES
export const GET_NETWORK_ENTRY = 'GET_NETWORK_ENTRY';
export const CREATE_NETWORK_SUB_ENTRY = 'CREATE_NETWORK_SUB_ENTRY';
export const DELETE_NETWORK_SUB_ENTRY = 'DELETE_NETWORK_SUB_ENTRY';
export const UPDATE_NETWORK_SUB_ENTRY = 'UPDATE_NETWORK_SUB_ENTRY';
export const DELETE_NETWORK_SUB_ENTRY_IMAGE = 'DELETE_NETWORK_SUB_ENTRY_IMAGE';

//INDIRECT SECTIONS
export const GET_INDIRECT_SECTION = 'GET_INDIRECT_SECTION';
export const CREATE_INDIRECT_ENTRY = 'CREATE_INDIRECT_ENTRY';
export const DELETE_INDIRECT_ENTRY = 'DELETE_INDIRECT_ENTRY';
export const UPDATE_INDIRECT_ENTRY = 'UPDATE_INDIRECT_ENTRY';
export const FINISH_INDIRECT_SECTION = 'FINISH_INDIRECT_SECTION';

//INDIRECT ENTRIES
export const GET_INDIRECT_ENTRY = 'GET_INDIRECT_ENTRY';
export const CREATE_INDIRECT_SUB_ENTRY = 'CREATE_INDIRECT_SUB_ENTRY';
export const DELETE_INDIRECT_SUB_ENTRY = 'DELETE_INDIRECT_SUB_ENTRY';
export const UPDATE_INDIRECT_SUB_ENTRY = 'UPDATE_INDIRECT_SUB_ENTRY';
export const DELETE_INDIRECT_SUB_ENTRY_IMAGE = 'DELETE_INDIRECT_SUB_ENTRY_IMAGE';

//NOT STRUCTURAL SECTIONS
export const GET_NOT_STRUCTURAL_SECTION = 'GET_NOT_STRUCTURAL_SECTION';
export const CREATE_NOT_STRUCTURAL_ENTRY = 'CREATE_NOT_STRUCTURAL_ENTRY';
export const DELETE_NOT_STRUCTURAL_ENTRY = 'DELETE_NOT_STRUCTURAL_ENTRY';
export const UPDATE_NOT_STRUCTURAL_ENTRY = 'UPDATE_NOT_STRUCTURAL_ENTRY';
export const FINISH_NOT_STRUCTURAL_SECTION = 'FINISH_NOT_STRUCTURAL_SECTION';

//RAIN SECTIONS
export const GET_RAIN_SECTION = 'GET_RAIN_SECTION';
export const FINISH_RAIN_SECTION = 'FINISH_RAIN_SECTION';
export const CREATE_RAIN_ENTRY = 'CREATE_RAIN_ENTRY';
export const DELETE_RAIN_ENTRY = 'DELETE_RAIN_ENTRY';
export const UPDATE_RAIN_ENTRY = 'UPDATE_RAIN_ENTRY';

//NOT STRUCTURAL ENTRIES
export const GET_NOT_STRUCTURAL_ENTRY = 'GET_NOT_STRUCTURAL_ENTRY';
export const CREATE_NOT_STRUCTURAL_SUB_ENTRY = 'CREATE_NOT_STRUCTURAL_SUB_ENTRY';
export const DELETE_NOT_STRUCTURAL_SUB_ENTRY = 'DELETE_NOT_STRUCTURAL_SUB_ENTRY';
export const UPDATE_NOT_STRUCTURAL_SUB_ENTRY = 'UPDATE_NOT_STRUCTURAL_SUB_ENTRY';
export const DELETE_NOT_STRUCTURAL_SUB_ENTRY_IMAGE = 'DELETE_NOT_STRUCTURAL_SUB_ENTRY_IMAGE';

//CONCEPT SECTIONS
export const GET_CONCEPT_SECTION = 'GET_CONCEPT_SECTION';
export const CREATE_CONCEPT_ENTRY = 'CREATE_CONCEPT_ENTRY';
export const DELETE_CONCEPT_ENTRY = 'DELETE_CONCEPT_ENTRY';
export const UPDATE_CONCEPT_ENTRY = 'UPDATE_CONCEPT_ENTRY';
export const FINISH_CONCEPT_SECTION = 'FINISH_CONCEPT_SECTION';

//CONCRETE SUB SECTIONS
export const GET_CONCRETE_SUB_SECTION = 'GET_CONCRETE_SUB_SECTION';
export const UPDATE_CONCRETE_SUB_SECTION = 'UPDATE_CONCRETE_SUB_SECTION';
export const CREATE_CONCRETE_GRAPH = 'CREATE_CONCRETE_GRAPH';
export const DELETE_CONCRETE_GRAPH = 'DELETE_CONCRETE_GRAPH';
export const UPDATE_CONCRETE_GRAPH = 'UPDATE_CONCRETE_GRAPH';
export const CREATE_CONCRETE_TABLE = 'CREATE_CONCRETE_TABLE';
export const DELETE_CONCRETE_TABLE = 'DELETE_CONCRETE_TABLE';
export const UPDATE_CONCRETE_TABLE = 'UPDATE_CONCRETE_TABLE';

//MORTAR SUB SECTIONS
export const GET_MORTAR_SUB_SECTION = 'GET_MORTAR_SUB_SECTION';
export const UPDATE_MORTAR_SUB_SECTION = 'UPDATE_MORTAR_SUB_SECTION';
export const CREATE_MORTAR_GRAPH = 'CREATE_MORTAR_GRAPH';
export const DELETE_MORTAR_GRAPH = 'DELETE_MORTAR_GRAPH';
export const UPDATE_MORTAR_GRAPH = 'UPDATE_MORTAR_GRAPH';
export const CREATE_MORTAR_TABLE = 'CREATE_MORTAR_TABLE';
export const DELETE_MORTAR_TABLE = 'DELETE_MORTAR_TABLE';
export const UPDATE_MORTAR_TABLE = 'UPDATE_MORTAR_TABLE';

//CONNECTORS SUB SECTIONS
export const GET_CONNECTORS_SUB_SECTION = 'GET_CONNECTORS_SUB_SECTION';
export const UPDATE_CONNECTORS_SUB_SECTION = 'UPDATE_CONNECTORS_SUB_SECTION';
export const CREATE_CONNECTORS_ENTRY = 'CREATE_CONNECTORS_ENTRY';
export const DELETE_CONNECTORS_ENTRY = 'DELETE_CONNECTORS_ENTRY';
export const UPDATE_CONNECTORS_ENTRY = 'UPDATE_CONNECTORS_ENTRY';

//ANCHORS SUB SECTIONS
export const GET_ANCHORS_SUB_SECTION = 'GET_ANCHORS_SUB_SECTION';
export const UPDATE_ANCHORS_SUB_SECTION = 'UPDATE_ANCHORS_SUB_SECTION';
export const CREATE_ANCHORS_ENTRY = 'CREATE_ANCHORS_ENTRY';
export const DELETE_ANCHORS_ENTRY = 'DELETE_ANCHORS_ENTRY';
export const UPDATE_ANCHORS_ENTRY = 'UPDATE_ANCHORS_ENTRY';
export const DELETE_ANCHORS_ENTRY_IMAGE = 'DELETE_ANCHORS_ENTRY_IMAGE';

//INSTRUMENTATION SUB SECTIONS
export const GET_INSTRUMENTATION_SUB_SECTION = 'GET_INSTRUMENTATION_SUB_SECTION';
export const CREATE_INSTRUMENTATION_ENTRY = 'CREATE_INSTRUMENTATION_ENTRY';
export const DELETE_INSTRUMENTATION_ENTRY = 'DELETE_INSTRUMENTATION_ENTRY';
export const UPDATE_INSTRUMENTATION_ENTRY = 'UPDATE_INSTRUMENTATION_ENTRY';
export const DELETE_INSTRUMENTATION_ENTRY_IMAGE = 'DELETE_INSTRUMENTATION_ENTRY_IMAGE';

//FREE SUB SECTIONS
export const GET_FREE_SUB_SECTION = 'GET_FREE_SUB_SECTION';
export const CREATE_FREE_ENTRY = 'CREATE_FREE_ENTRY';
export const DELETE_FREE_ENTRY = 'DELETE_FREE_ENTRY';
export const UPDATE_FREE_ENTRY = 'UPDATE_FREE_ENTRY';
export const DELETE_FREE_ENTRY_IMAGE = 'DELETE_FREE_ENTRY_IMAGE';

//STEELS SUB SECTIONS
export const GET_STEELS_SUB_SECTION = 'GET_STEELS_SUB_SECTION';
export const UPDATE_STEELS_SUB_SECTION = 'UPDATE_STEELS_SUB_SECTION';
export const CREATE_STEELS_ENTRY = 'CREATE_STEELS_ENTRY';
export const DELETE_STEELS_ENTRY = 'DELETE_STEELS_ENTRY';
export const UPDATE_STEELS_ENTRY = 'UPDATE_STEELS_ENTRY';

//RECOMMENDATION SECTIONS
export const GET_RECOMMENDATION_SECTION = 'GET_RECOMMENDATION_SECTION';
export const UPDATE_RECOMMENDATION_SECTION = 'UPDATE_RECOMMENDATION_SECTION';
export const FINISH_RECOMMENDATION_SECTION = 'FINISH_RECOMMENDATION_SECTION';

//PROTOCOL SECTIONS
export const GET_PROTOCOL_SECTION = 'GET_PROTOCOL_SECTION';
export const UPDATE_PROTOCOL_SECTION = 'UPDATE_PROTOCOL_SECTION';
export const FINISH_PROTOCOL_SECTION = 'FINISH_PROTOCOL_SECTION';

//SECURITY SECTIONS
export const GET_SECURITY_SECTION = 'GET_SECURITY_SECTION';
export const CREATE_SECURITY_ENTRY = 'CREATE_SECURITY_ENTRY';
export const DELETE_SECURITY_ENTRY = 'DELETE_SECURITY_ENTRY';
export const UPDATE_SECURITY_ENTRY = 'UPDATE_SECURITY_ENTRY';
export const FINISH_SECURITY_SECTION = 'FINISH_SECURITY_SECTION';

//TRACING SECTIONS
export const GET_TRACING_SECTION = 'GET_TRACING_SECTION';
export const FINISH_TRACING_SECTION = 'FINISH_TRACING_SECTION';
export const CREATE_ADVANCE_ENTRY = 'CREATE_ADVANCE_ENTRY';
export const DELETE_ADVANCE_ENTRY = 'DELETE_ADVANCE_ENTRY';
export const UPDATE_ADVANCE_ENTRY = 'UPDATE_ADVANCE_ENTRY';
export const CREATE_MONTHLY_WEEK_ENTRY = 'CREATE_MONTHLY_WEEK_ENTRY';
export const DELETE_MONTHLY_WEEK_ENTRY = 'DELETE_MONTHLY_WEEK_ENTRY';
export const UPDATE_MONTHLY_WEEK_ENTRY = 'UPDATE_MONTHLY_WEEK_ENTRY';

//ADMINISTRATIVE SECTIONS
export const GET_ADMINISTRATIVE_SECTION = 'GET_ADMINISTRATIVE_SECTION';
export const FINISH_ADMINISTRATIVE_SECTION = 'FINISH_ADMINISTRATIVE_SECTION';
export const CREATE_CONTRACT_ENTRY = 'CREATE_CONTRACT_ENTRY';
export const DELETE_CONTRACT_ENTRY = 'DELETE_CONTRACT_ENTRY';
export const UPDATE_CONTRACT_ENTRY = 'UPDATE_CONTRACT_ENTRY';
export const CREATE_AGREEMENT_ENTRY = 'CREATE_AGREEMENT_ENTRY';
export const DELETE_AGREEMENT_ENTRY = 'DELETE_AGREEMENT_ENTRY';
export const UPDATE_AGREEMENT_ENTRY = 'UPDATE_AGREEMENT_ENTRY';
export const CREATE_BUDGET_ENTRY = 'CREATE_BUDGET_ENTRY';
export const DELETE_BUDGET_ENTRY = 'DELETE_BUDGET_ENTRY';
export const UPDATE_BUDGET_ENTRY = 'UPDATE_BUDGET_ENTRY';
export const CREATE_PROGRAMMING_ENTRY = 'CREATE_PROGRAMMING_ENTRY';
export const DELETE_PROGRAMMING_ENTRY = 'DELETE_PROGRAMMING_ENTRY';
export const UPDATE_PROGRAMMING_ENTRY = 'UPDATE_PROGRAMMING_ENTRY';


//MONTHLY WEEK ENTRIES
export const GET_MONTHLY_WEEK_ENTRY = 'GET_MONTHLY_WEEK_ENTRY';
export const CREATE_PLANT_SUB_ENTRY = 'CREATE_PLANT_SUB_ENTRY';
export const DELETE_PLANT_SUB_ENTRY = 'DELETE_PLANT_SUB_ENTRY';
export const UPDATE_PLANT_SUB_ENTRY = 'UPDATE_PLANT_SUB_ENTRY';
export const CREATE_RECOMMENDATION_SUB_ENTRY = 'CREATE_RECOMMENDATION_SUB_ENTRY';
export const DELETE_RECOMMENDATION_SUB_ENTRY = 'DELETE_RECOMMENDATION_SUB_ENTRY';
export const UPDATE_RECOMMENDATION_SUB_ENTRY = 'UPDATE_RECOMMENDATION_SUB_ENTRY';
export const CREATE_ELEMENT_SUB_ENTRY = 'CREATE_ELEMENT_SUB_ENTRY';
export const DELETE_ELEMENT_SUB_ENTRY = 'DELETE_ELEMENT_SUB_ENTRY';
export const UPDATE_ELEMENT_SUB_ENTRY = 'UPDATE_ELEMENT_SUB_ENTRY';

//ELEMENT SUB ENTRIES
export const GET_ELEMENT_SUB_ENTRY = 'GET_ELEMENT_SUB_ENTRY';
export const CREATE_ELEMENT_SUB_SUB_ENTRY = 'CREATE_ELEMENT_SUB_SUB_ENTRY';
export const DELETE_ELEMENT_SUB_SUB_ENTRY = 'DELETE_ELEMENT_SUB_SUB_ENTRY';
export const UPDATE_ELEMENT_SUB_SUB_ENTRY = 'UPDATE_ELEMENT_SUB_SUB_ENTRY';
export const DELETE_ELEMENT_SUB_SUB_ENTRY_IMAGE = 'DELETE_ELEMENT_SUB_SUB_ENTRY_IMAGE';

//INNER
export const LOG_OUT = 'LOG_OUT';

//MESSAGES
export const GET_MESSAGES = 'GET_MESSAGES';
export const CREATE_MESSAGE = 'CREATE_MESSAGE';

//ALERTS
export const GET_ALERTS = 'GET_ALERTS';
export const CREATE_ALERT = 'CREATE_ALERT';
export const UPDATE_ALERT = 'UPDATE_ALERT';

//FEEDBACK
export const LOAD_START = 'LOAD_START';
export const LOAD_END = 'LOAD_END';
export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
export const INFO_SHOW = 'INFO_SHOW';
export const INFO_DISMISS = 'INFO_DISMISS';
