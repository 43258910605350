import {CREATE_TRACING_SECTION} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedPost from "../../apis/authorizedPost";

export default (monthlyReportId) => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedPost(`/monthly_reports/${monthlyReportId}/tracing_section`),
      simpleSuccess(CREATE_TRACING_SECTION)
  );
};