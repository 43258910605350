import {UPDATE_RAIN_ENTRY} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedPut from "../../apis/authorizedPut";
import modalDismiss from "../modal/modalDismiss";
import {formDataHelper} from "../functions/formDataHelper";

export default (formValues, rainSectionId) => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedPut(
          `/rain_sections/${rainSectionId}/entry`,
          formDataHelper(formValues, 'rain_section', [{name: 'avatar', key: '[rain_entries_attributes][][avatar]'}])
      ),
      simpleSuccess(UPDATE_RAIN_ENTRY),
      null,
      [modalDismiss]
  );
};