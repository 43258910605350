import authorizedGet from "../../apis/authorizedGet";
import loadingCall from "../functions/loadingCall"
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import { GET_PHOTOS_VIDEOS } from "../types";

export default (id, asset_id) => async(dispatch) => {
    await loadingCall(
        dispatch,
        authorizedGet(`/projects/${id}/medias/${asset_id}/assets`),
        simpleSuccess(GET_PHOTOS_VIDEOS)
    );
};
