import authorizedGet from "../../apis/authorizedGet";
import { LOAD_START, LOAD_END } from "../types";

export default (projectId, formValues) => async (dispatch) => {
  dispatch({type: LOAD_START});
  const params = {
    start_date: formValues.date_range[0].format(),
    end_date: formValues.date_range[1].format(),
  };

  const response = await authorizedGet(`projects/${projectId}/findings_report`, params);
  const pdf = response.data;
  const linkSource = `data:application/pdf;base64,${pdf}`;
  const downloadLink = document.createElement("a");
  const fileName = "vct_illustration.pdf";

  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();

  dispatch({type: LOAD_END});
};