import React from "react";
import {Button, Card, Collapse, Icon, Table} from 'antd';
import 'antd/lib/button/style/css';
import 'antd/lib/card/style/css';
import 'antd/lib/col/style/css';
import 'antd/lib/icon/style/css';
import 'antd/lib/input/style/css';
import 'antd/lib/row/style/css';
import 'antd/lib/table/style/css';
import {connect} from "react-redux";
import modalShow from '../../actions/modal/modalShow';
import {MORTARS_GRAPH_MODAL, MORTARS_TABLE_MODAL} from "../Feedback/Modal/types";
import {RESULTS_VIEW} from "../../paths";
import {push} from 'connected-react-router';
import MortarForm from "./MortarForm";
import getMortarSubSection from "../../actions/mortarsSubSection/getMortarSubSection";
import updateMortarSubSection from "../../actions/mortarsSubSection/updateMortarSubSection";
import createMortarGraph from "../../actions/mortarsSubSection/createMortarGraph";
import createMortarTable from "../../actions/mortarsSubSection/createMortarTable";
import updateMortarGraph from "../../actions/mortarsSubSection/updateMortarGraph";
import updateMortarTable from "../../actions/mortarsSubSection/updateMortarTable";
import deleteMortarGraph from "../../actions/mortarsSubSection/deleteMortarGraph";
import deleteMortarTable from "../../actions/mortarsSubSection/deleteMortarTable";
import {marginRight} from "../../const/styles";
import {confirmDelete} from "../../apis/authorizedDelete";

class Mortar extends React.Component {

  componentDidMount() {
    this.props.getMortarSubSection(this.props.match.params.mortars_id);
  };

  openTableModal = (entry, onSubmit) => {
    this.props.modalShow({
      modalType: MORTARS_TABLE_MODAL,
      modalProps: {mortarsTable: entry, onSubmit: onSubmit}
    });
  };

  handleTableCreate = (formValues) => {
    this.props.createMortarTable(formValues, this.props.match.params.mortars_id);
  };

  handleTableUpdate = (formValues) => {
    this.props.updateMortarTable(formValues, this.props.match.params.mortars_id);
  };

  openGraphModal = (entry, onSubmit) => {
    this.props.modalShow({
      modalType: MORTARS_GRAPH_MODAL,
      modalProps: {mortarsGraph: entry, onSubmit: onSubmit}
    });
  };

  handleGraphCreate = (formValues) => {
    this.props.createMortarGraph(formValues, this.props.match.params.mortars_id);
  };

  handleGraphUpdate = (formValues) => {
    this.props.updateMortarGraph(formValues, this.props.match.params.mortars_id);
  };

  tableColumns = () => {
    return [{
      title: 'Contenido',
      dataIndex: 'content',
      key: 'content',
      render: (text) => {
        return <Icon type={text && text.length > 15 ? 'check' : 'close'}/>;
      }
    }, {
      title: 'Imagen',
      dataIndex: 'avatar',
      key: 'avatar',
      render: (text) => {
        return <Icon type={text ? 'check' : 'close'}/>;
      }
    }, {
      title: 'Comentario',
      dataIndex: 'comment',
      key: 'comment',
      render: (text) => {
        return <Icon type={text && text.length > 0 ? 'check' : 'close'}/>;
      }
    }, {
      title: 'Salto de pág.',
      dataIndex: 'pagebreak',
      key: 'pagebreak',
      render: (text) => {
        return <Icon type={text ? 'check' : 'close'}/>;
      }
    }, {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record) => {
        return (
            <div>
              <Button onClick={() => this.openTableModal(record, this.handleTableUpdate)} type={"primary"}
                      htmlType={'button'}>
                <Icon type="eye"/>
              </Button>
              <Button
                onClick={() => confirmDelete(() => this.props.deleteMortarTable(this.props.match.params.mortars_id, record.id))}
                type={"danger"}
                style={marginRight}
                htmlType={'button'}>
                <Icon type="delete"/>
              </Button>
            </div>
        );
      }
    }]
  };

  graphColumns = () => {
    return [{
      title: 'Imagen',
      dataIndex: 'avatar',
      key: 'avatar',
      render: (text) => {
        return <Icon type={text ? 'check' : 'close'}/>;
      }
    }, {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record) => {
        return (
            <div>
              <Button onClick={() => this.openGraphModal(record, this.handleGraphUpdate)} type={"primary"}
                      htmlType={'button'}>
                <Icon type="eye"/>
              </Button>
              <Button
                onClick={() => confirmDelete(() => this.props.deleteMortarGraph(this.props.match.params.mortars_id, record.id))}
                type={"danger"}
                style={marginRight}
                htmlType={'button'}>
                <Icon type="delete"/>
              </Button>
            </div>
        );
      }
    }]
  };

  render() {
    return (
        <Card title={'Sección de morteros'}
              bodyStyle={{padding: 0}}
              extra={
                <Button htmlType={'button'}
                        type={'ghost'}
                        onClick={() => this.props.push(RESULTS_VIEW(this.props.match.params.id, this.props.match.params.results_id))}>Volver</Button>
              }>
            <Collapse>
              <Collapse.Panel header={'Información general'} key="1">
                <MortarForm onSubmit={this.props.updateMortarSubSection} mortars_sub_section={this.props.mortars_sub_section} />
              </Collapse.Panel>
              <Collapse.Panel header={'Tablas'} key="2">
                <Card title={'Tablas'}
                      bodyStyle={{padding: 0}}
                      extra={
                        <Button type={'primary'}
                                htmlType={'button'}
                                onClick={() => this.openTableModal(null, this.handleTableCreate)}>
                          Agregar
                        </Button>
                      }
                >
                  <Table scroll={{x: '100%'}}
                      dataSource={this.props.mortars_sub_section.kind === 'mortar_entries' ? this.props.mortars_sub_section.tables : []}
                      rowKey={'id'} columns={this.tableColumns()}/>
                </Card>
              </Collapse.Panel>
              <Collapse.Panel header={'Gráficas'} key="3">
                <Card title={'Gráficas'}
                      bodyStyle={{padding: 0}}
                      extra={
                        <Button type={'primary'}
                                htmlType={'button'}
                                onClick={() => this.openGraphModal(null, this.handleGraphCreate)}>
                          Agregar
                        </Button>
                      }
                >
                  <Table scroll={{x: '100%'}}
                      dataSource={this.props.mortars_sub_section.kind === 'mortar_entries' ? this.props.mortars_sub_section.graphs : []}
                      rowKey={'id'} columns={this.graphColumns()}/>
                </Card>
              </Collapse.Panel>
            </Collapse>

        </Card>
    );
  }
}

const mapStateToProps = state => {
  return {
    mortars_sub_section: state.userList.selected_entry
  };
};

export default connect(
    mapStateToProps,
    {
      push,
      modalShow,
      updateMortarSubSection,
      getMortarSubSection,
      createMortarGraph,
      deleteMortarGraph,
      updateMortarGraph,
      createMortarTable,
      deleteMortarTable,
      updateMortarTable,
    })(Mortar);