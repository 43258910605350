import React, {useEffect, useState} from 'react';
import {Field, FieldArray, reduxForm} from "redux-form";
import {Button, Card, Col, Form, Row, Spin} from "antd";
import {CreateTextField} from "../Helpers/Input/InputCreator";
import {maxLength30, minLength5, required} from "../Helpers/Input/validations";
import {marginRight} from "../../const/styles";
import {DrawingManager, GoogleMap, Polygon, useLoadScript} from "@react-google-maps/api";

const googleMapsApiKey = 'AIzaSyDX0Q6Yx_yRbiS2IMrQ50FuR4ARWM4X5qM';
const initCoords = {lat: 4.624335, lng: -74.063644};

const LocationsForm = ({fields, change, handleReturn, location}) => {

  const bootstrapURLKeys = {
    googleMapsApiKey: googleMapsApiKey,
    libraries: ['drawing']
  };
  const {isLoaded} = useLoadScript(bootstrapURLKeys);
  const [polygon, setPolygon] = useState(null);
  
  const polygonEnded = (polygon) => {
    let len = polygon.getPath().getLength();
    let coordinates = [];
    for (let i = 0; i < len; i++) {
      coordinates.push({lat: polygon.getPath().getAt(i).lat(), lng: polygon.getPath().getAt(i).lng()})
    }
    change('project[locations_attributes][0][coordinates_attributes]', coordinates);
    setPolygon(coordinates[0].lat)
  };
  
  useEffect(() => {
    if (fields.length === 0)
      fields.push({});
  }, []);
  
  return (
    fields.map((locations_attributes, index) => {
      return (
        <div key={index}>
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Field label={'Título de la ubicación'}
                     name={`${locations_attributes}.title`}
                     component={CreateTextField}
                     placeholder="Título de la ubicación"
                     validate={[required, minLength5, maxLength30]}
                     hasFeedback/>
            </Col>
          </Row>
          <Row>
            {isLoaded ?
              <GoogleMap
                id="locations"
                mapContainerStyle={{height: "400px", width: "100%"}}
                zoom={12}
                center={initCoords}
              >
                <DrawingManager onPolygonComplete={polygonEnded}
                                drawingMode={polygon ? null : 'polygon'}
                                options={{
                                  drawingControlOptions: {
                                    drawingModes: polygon ? [] : ['polygon'],
                                    strokeColor: "red",
                                  }
                                }}
                />
                {location ?
                  <Polygon
                    paths={location.coordinates}
                    options={{
                      strokeColor: "red",
                      strokeOpacity: 1,
                      clickable: false,
                      draggable: false,
                      editable: false,
                      geodesic: false,
                      zIndex: 1
                    }}
                  /> : ''}
              </GoogleMap> : <Spin/>
            }
          </Row>
          {polygon ?
            <Row className={'p-20'}>
              <Button type={'primary float-right'} htmlType={'submit'}>Guardar</Button>
              <Button style={marginRight} htmlType={'button'} type={'ghost float-right'}
                      onClick={handleReturn}>Cancelar</Button>
            </Row> : ''}
        </div>
      )
    })
  );
};

const ProjectsLocationsForm = ({handleReturn, handleSubmit, initialize, onSubmit, location, change}) => {
  
  useEffect(() => {
    if (location)
      initialize({project: {locations_attributes: [{...location}]}});
  }, []);
  
  return (
    <Card title={'Agregar ubicaciones al proyecto'}
          extra={<Button style={marginRight} htmlType={'button'} type={'ghost'}
                         onClick={handleReturn}>Regresar</Button>}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FieldArray
          name={'project[locations_attributes]'}
          component={LocationsForm}
          change={change} location={location} handleReturn={handleReturn}/>
      </Form>
    </Card>
  );
};

export default reduxForm({
  form: 'locations_form',
  touchOnChange: true
})(ProjectsLocationsForm);