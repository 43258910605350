import React, {useState} from 'react';
import {Button, Card, Col, Icon, Row, Table} from "antd";
import 'antd/lib/button/style/css';
import 'antd/lib/card/style/css';
import 'antd/lib/col/style/css';
import 'antd/lib/icon/style/css';
import 'antd/lib/row/style/css';
import 'antd/lib/table/style/css';
import {marginRight, noPadding} from "../../const/styles";
import DailySuggestionForm from "./DailySuggestionForm";

const DailySuggestions = ({dailyReport, handleReturn, suggestions, createDailySuggestion, updateDailySuggestion}) => {

  const [create, setCreate] = useState(false);
  const [suggestion, setSuggestion] = useState(null);
  
  const callBack = () => {
    setCreate(false);
    setSuggestion(null);
  };
  
  const onSubmit = (formValues) => {
    if(suggestion) {
      updateDailySuggestion(dailyReport.id, formValues, callBack);
    } else {
      createDailySuggestion(dailyReport.id, formValues, callBack);
    }
  };

  const columns = () => {
    return [{
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    }, {
      title: 'Contenido',
      dataIndex: 'content',
      key: 'content',
      render: (text) => {
        return `${text.length > 30 ? text.substring(0, 30) + '...' : text}`;
      }
    }, {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record) => {
        return (
          <div>
            <Button onClick={() => {setSuggestion(record); setCreate(true)}} type={"primary"} htmlType={'button'}>
              <Icon type="eye"/>
            </Button>
          </div>
        );
      }
    }]
  };

  const render = () => {
    if(create) {
      return (
          <Row>
            <Col xs={24} md={24}>
              <DailySuggestionForm reportStatus={dailyReport.status} onSubmit={onSubmit} suggestion={suggestion}/>
            </Col>
          </Row>
      );
    } else {
      return (
          <Row>
            <Table scroll={{x: '100%'}}  dataSource={suggestions} rowKey={'id'} columns={columns()}/>
          </Row>
      );
    }
  };
  
  const renderExtra = () => {
    return (
      <Row style={marginRight}>
        <Col span={12}>
          <Button htmlType={'button'} type={'ghost'} onClick={handleReturn}>Volver</Button>
        </Col>
        <Col span={12}>
          {create ?
            dailyReport.status === 'finished' ? '' :
            <Button htmlType={'button'} type={'primary'} onClick={() => {
              setCreate(false);
              setSuggestion(null)
            }}>Cancelar</Button>
            :
            dailyReport.status !== 'finished' ?
            <Button htmlType={'button'} type={'primary'} onClick={() => {
              setCreate(true);
            }}>Agregar</Button> : ''
          }
        </Col>
      </Row>
    )
    
  };

  return (
      <Card
        title={'Sugerencias'}
        bodyStyle={noPadding}
        extra={renderExtra()}>
        {render()}
      </Card>
  );
};

export default DailySuggestions;