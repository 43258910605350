import React, {useState} from 'react';
import {Button, Card, Icon, Table} from "antd";
import 'antd/lib/button/style/css';
import 'antd/lib/card/style/css';
import 'antd/lib/icon/style/css';
import 'antd/lib/table/style/css';
import NetworkSubEntryForm from "./NetworkSubEntryForm";
import NetworkSubEntryResume from "./NetworkSubEntryResume";
import {connect} from "react-redux";
import createNetworkSubEntry from "../../actions/networkEntry/createNetworkSubEntry";
import updateNetworkSubEntry from "../../actions/networkEntry/updateNetworkSubEntry";
import deleteNetworkSubEntryImage from "../../actions/networkEntry/deleteNetworkSubEntryImage";
import _ from 'lodash';
import {marginRight, noPadding} from "../../const/styles";
import deleteNetworkSubEntry from "../../actions/networkEntry/deleteNetworkSubEntry";
import {confirmDelete} from "../../apis/authorizedDelete";

const NetworkEntryResume = ({networkEntry, createNetworkSubEntry, updateNetworkSubEntry, deleteNetworkSubEntryImage, deleteNetworkSubEntry}) => {
  
  const [networkSubEntry, setNetworkSubEntry] = useState(null);
  const [create, setCreate] = useState(false);
  
  const handleCreate = (formValues) => {
    createNetworkSubEntry(formValues, networkEntry.id, () => setCreate(false));
  };
  
  const handleUpdate = (formValues) => {
    updateNetworkSubEntry(formValues, networkEntry.id, () => setNetworkSubEntry(null));
  };
  
  const handleImageDelete = (networkSubEntryId, imageId) => {
    deleteNetworkSubEntryImage(networkEntry.id, networkSubEntryId, imageId, (data) => handleCallback(networkSubEntryId, data));
  };
  
  const handleCallback = (networkSubEntryId, data) => {
    setNetworkSubEntry(_.find(data.modal_list.selected_entry.entries, (entry) => entry.id === networkSubEntryId));
  };
  
  const columns = () => {
    return ([
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
        }, {
          title: 'Contenido',
          dataIndex: 'content',
          key: 'content',
          render: (text) => {
            return <Icon type={text && text.length > 15 ? 'check' : 'close'}/>;
          }
        }, {
          title: 'Imágenes',
          dataIndex: 'images',
          key: 'images',
          render: (text, record) => {
            return record.images.length;
          }
        }, {
          title: 'Comentario',
          dataIndex: 'comment',
          key: 'comment',
          render: (text) => {
            return <Icon type={text && text.length > 0 ? 'check' : 'close'}/>;
          }
        }, {
          title: 'Acciones',
          dataIndex: 'actions',
          key: 'actions',
          render: (text, record) => {
            return (
              <div>
                <Button onClick={() => setNetworkSubEntry(record)} type={"primary"} htmlType={'button'}>
                  <Icon type="eye"/>
                </Button>
                <Button
                  onClick={() => confirmDelete(() => deleteNetworkSubEntry(networkEntry.id, record.id))}
                  type={"danger"}
                  style={marginRight}
                  htmlType={'button'}>
                  <Icon type="delete"/>
                </Button>
              </div>
            );
          }
        }]
    )
  };
  
  const handleRender = () => {
    if (create) {
      return <NetworkSubEntryForm handleReturn={() => setCreate(false)}
                                  onSubmit={handleCreate}/>;
    } else if (networkSubEntry) {
      return <NetworkSubEntryResume handleReturn={() => setNetworkSubEntry(null)}
                                    networkSubEntry={networkSubEntry}
                                    handleUpdate={handleUpdate}
                                    handleImageDelete={handleImageDelete}/>;
    } else {
      return (
        <Card
          title={`Sub entrada de la sección de redes`}
          bodyStyle={noPadding}
          extra={
            <Button htmlType={'button'} style={marginRight} type={'ghost'}
                    onClick={() => setCreate(true)}>Crear</Button>
          }>
          <Table scroll={{x: '100%'}}  dataSource={networkEntry.entries} rowKey={'id'} columns={columns()}/>
        </Card>
      );
    }
  };
  
  return (
    handleRender()
  );
};

const mapStateToProps = state => {
  return ({
    networkEntry: state.modalList.selected_entry
  });
};

export default connect(mapStateToProps, {
  createNetworkSubEntry,
  deleteNetworkSubEntry,
  updateNetworkSubEntry,
  deleteNetworkSubEntryImage
})(NetworkEntryResume);