import {DELETE_NOT_STRUCTURAL_SECTION} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedDelete from "../../apis/authorizedDelete";

export default (monthlyReportId) => async (dispatch) => {
  await loadingCall(
    dispatch,
    authorizedDelete(`/monthly_reports/${monthlyReportId}/not_structural_section`),
    simpleSuccess(DELETE_NOT_STRUCTURAL_SECTION)
  );
};