import React from 'react';
import {Field, reduxForm} from "redux-form";
import {CreateFileField, CreateSelectField, CreateTextArea, CreateTextField} from "../Helpers/Input/InputCreator";
import {
  fileImage,
  fileVerification, maxLength150,
  minLength5,
  required
} from "../Helpers/Input/validations";
import {Button, Card, Col, Form, Modal, Row, Select} from "antd";
import _ from 'lodash';
import {noPadding} from "../../const/styles";

class RequirementsModal extends React.Component {
  
  state = {avatar: null};

  componentDidMount() {
    if(this.props.requirement)
      this.props.initialize({requirement: _.pick(this.props.requirement, ['name', 'content', 'status'])})
  }
  
  renderStatusSelect = () => {
    if(this.props.requirement)
      return (
        <Col xs={24} md={12}>
          <Field label={'Estado'}
                 name="requirement[status]"
                 component={CreateSelectField}
                 validate={[required]}
                 hasFeedback>
            <Select.Option key={1} value={'active'}>Activo</Select.Option>
            <Select.Option key={2} value={'inactive'}>Inactivo</Select.Option>
          </Field>
        </Col>
      );
  };
  
  render() {
    const {modalType, modalProps, handleSubmit, onSubmit, requirement, change} = this.props;
    return (
      <Modal bodyStyle={noPadding} footer={null} className={'content-wrapper'} visible={modalType !== null} style={{ top: 30 }}
             width={720} {...modalProps}>
        <Card title={'Requerimiento'}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <Field label={'Título del requerimiento'}
                       name={`requirement[name]`}
                       component={CreateTextField}
                       placeholder="Titulo del requerimiento"
                       validate={[required, minLength5, maxLength150]}
                       hasFeedback/>
              </Col>
              <Col xs={24} sm={12}>
                <Field label={'Contenido del requerimiento'}
                       name={`requirement[content]`}
                       component={CreateTextArea}
                       placeholder="Conenido del requerimiento"
                       validate={[required]}
                       hasFeedback/>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <Field label={'Imagen del requerimiento (opcional)'}
                       name={'avatar'}
                       preview={requirement ? requirement.avatar : null}
                       component={CreateFileField}
                       validate={[fileVerification, fileImage]}
                       change={change}
                       isImage
                       type='file'
                       hasFeedback
                />
              </Col>
              {this.renderStatusSelect()}
            </Row>
            <Row className={'p-20'}>
              <Button type={'primary float-right'} htmlType={'submit'}>Guardar</Button>
            </Row>
          </Form>
        </Card>
      </Modal>
    );
  }
}

export default reduxForm({
  form: 'requirements_form',
  touchOnChange: true
})(RequirementsModal);