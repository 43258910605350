import React, {useEffect} from 'react';
import {Field, FieldArray, reduxForm} from "redux-form";
import {Button, Card, Col, Form, Row} from "antd";
import 'antd/lib/button/style/css';
import 'antd/lib/card/style/css';
import 'antd/lib/col/style/css';
import 'antd/lib/form/style/css';
import 'antd/lib/row/style/css';
import _ from 'lodash';
import {CreateFileField, CreateTextArea, CreateTextField} from "../Helpers/Input/InputCreator";
import {fileImage, fileVerification, maxLength50, minLength5,} from "../Helpers/Input/validations";
import QuillTextArea from "../Helpers/QuillTextArea";

const ConceptForm = ({fields, change, conceptEntry}) => {
  
  useEffect(() => {
    if (fields.length === 0)
      fields.push({});
  }, []);
  
  return (
    fields.map((concept_entries_attributes, index) => {
      return (
        <div key={index}>
          <Row gutter={16}>
            <Col xs={24} sm={24}>
              <Field label={'Titulo'}
                     name={`${concept_entries_attributes}.title`}
                     component={CreateTextField}
                     placeholder="Titulo"
                     validate={[minLength5, maxLength50]}
                     hasFeedback/>
            </Col>
            <Col xs={24} sm={24}>
              <Field label={'Contenido'}
                     name={`${concept_entries_attributes}.content`}
                     component={QuillTextArea}
                     change={change} />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Field label={'Imagen del concepto '}
                     name={'avatar'}
                     preview={conceptEntry ? conceptEntry.avatar : null}
                     component={CreateFileField}
                     validate={[fileVerification, fileImage]}
                     change={change}
                     isImage
                     type='file'
                     hasFeedback
              />
            </Col>
            <Col xs={24} sm={12}>
              <Field label={'Comentario de las imagenes'}
                     name={`${concept_entries_attributes}.comment`}
                     component={CreateTextArea}
                     placeholder="Comentario de las imagenes"
                     hasFeedback/>
            </Col>
          </Row>
          <Row className={'p-20'}>
            <Button type={'primary float-right'} htmlType={'submit'}>Guardar</Button>
          </Row>
        </div>
      )
    })
  );
};

const ConceptEntryForm = ({handleSubmit, initialize, change, onSubmit, conceptEntry}) => {
  
  useEffect(() => {
    if (conceptEntry)
      initialize({concept_section: {concept_entries_attributes: [_.omit(conceptEntry, ['avatar'])]}});
  }, []);
  
  return (
    <Card title={'Concepto'}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FieldArray name={'concept_section[concept_entries_attributes]'} component={ConceptForm}
                    props={{conceptEntry: conceptEntry, change: change}}/>
      </Form>
    </Card>
  );
};

export default reduxForm({
  form: 'concept_entry_form',
  touchOnChange: true
})(ConceptEntryForm);