

export const currency = value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
export const lowerCase = value => value ? value.toLowerCase() : '';

//Parse
export const parser = value => value.replace(/^[A-Z]+$/i, '');

//Date
export const dateFormat = (value) => {
  if (!value) {
    return value
  }
  return value
    .substr(0, 10)
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1/$2/$3')
    .substr(0, 10)
};

export const monthFormat = (value) => {
  if (!value) {
    return value
  }
  return value
    .substr(0, 7)
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1/$2')
    .substr(0, 7)
};

//NumOnly
export const numOnly = (value) => {
  value = value + '';
  if (!value) {
    return value
  }
  return value.replace(/\D+/g, "");
};

//NumPositive
export const numPositive = (value) => {
  if (!value) {
    return value
  }
  if (value < 0) {
    return ''
  }
  value = value + '';
  return value.replace(/\D+/g, "");
};

//ReducedText
export const reducedText = (text, num) => text.length > num ? text.substr(0, num) + '...' : text;