import {DELETE_NETWORK_SUB_ENTRY_IMAGE} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedPut from "../../apis/authorizedPut";

export default (networkEntryId, networkSubEntryId, imageId, callback) => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedPut(
          `/network_entries/${networkEntryId}/entry_image`,{network_entry: {entry_id: networkSubEntryId, image_id: imageId}}
      ),
      simpleSuccess(DELETE_NETWORK_SUB_ENTRY_IMAGE),
      null,
      [],
      [callback]);
};