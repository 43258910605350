import {CREATE_FREE_ENTRY} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedPost from "../../apis/authorizedPost";
import modalDismiss from "../modal/modalDismiss";
import {formDataHelper} from "../functions/formDataHelper";
import _ from "lodash";

export default (formValues, subSectionId) => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedPost(
          `/free_sub_sections/${subSectionId}/entry`,
      formDataHelper(
        formValues,
        'free_sub_section',
        _.times(2, (index) => {return {name: `image${index}`, key: '[free_entries_attributes][][images][]'}})
      )
      ),
      simpleSuccess(CREATE_FREE_ENTRY),
      null,
      [modalDismiss]);
};