import {DELETE_DAILY_ENTRY_IMAGE} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedPut from "../../apis/authorizedPut";

export default (dailyReportId, dailyEntryId, imageId) => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedPut(
        `/daily_reports/${dailyReportId}/entry_image`, {daily_report: {entry_id: dailyEntryId, image_id: imageId}}
      ),
      simpleSuccess(DELETE_DAILY_ENTRY_IMAGE)
  );
};