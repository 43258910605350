import React, {useEffect} from 'react';
import {Button, Card, Col, Form, Row} from "antd";
import {Field, FieldArray, reduxForm} from "redux-form";
import {CreateFileField, CreateTextArea} from "../Helpers/Input/InputCreator";
import {fileImage, fileVerification, maxLength300, minLength1, required} from "../Helpers/Input/validations";
import _ from 'lodash';
import {marginRight} from "../../const/styles";

const EntryForm = ({fields, change, dailyEntry}) => {

  useEffect(() => {
    if (fields.length === 0)
      fields.push({});
  }, []);

  return (
      fields.map((daily_entries_attributes, index) => {
        return (
            <div key={index}>
              <Row gutter={16}>
                <Col xs={24} sm={12}>
                  <Field label={'Contenido'}
                         name={`${daily_entries_attributes}.content`}
                         component={CreateTextArea}
                         placeholder="Contenido"
                         validate={[required, minLength1, maxLength300]}
                         hasFeedback/>
                </Col>
                {_.times(dailyEntry && dailyEntry.images ? 2 - dailyEntry.images.length : 2, (index) => {
                  return (
                    <Col xs={24} sm={12} key={index}>
                      <Field label={`Imagen ${index + 1} (Opcional)`}
                             name={`image${index}`}
                             preview={null}
                             change={change}
                             isImage
                             component={CreateFileField}
                             validate={[fileVerification, fileImage]}
                             type='file'
                             hasFeedback
                      />
                    </Col>
                  );
                })}
              </Row>
              <Row className={'p-20'}>
                <Button type={'primary float-right'} htmlType={'submit'}>Guardar</Button>
              </Row>
            </div>
        )
      })
  );
};

const DailyEntryForm = ({handleReturn, handleSubmit, initialize, onSubmit, dailyEntry, change}) => {

  useEffect(() => {
    if(dailyEntry)
      initialize({daily_report: {daily_entries_attributes: [_.pick(dailyEntry, ['id', 'content'])]}});
  }, []);

  return (
    <Card title={'Agregar entrada al reporte diario'}
          extra={<Button style={marginRight} htmlType={'button'} type={'primary'}
                         onClick={handleReturn}>Regresar</Button>}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FieldArray name={'daily_report[daily_entries_attributes]'} change={change} component={EntryForm} props={{dailyEntry: dailyEntry}}/>
        </Form>
      </Card>
  );
};

export default reduxForm({
  form: 'daily_entry_form',
  touchOnChange: true
})(DailyEntryForm);