import React, {useState} from 'react';
import {Button, Card, Icon, Table} from "antd";
import 'antd/lib/button/style/css';
import 'antd/lib/card/style/css';
import 'antd/lib/icon/style/css';
import 'antd/lib/table/style/css';
import IndirectSubEntryForm from "./IndirectSubEntryForm";
import IndirectSubEntryResume from "./IndirectSubEntryResume";
import {connect} from "react-redux";
import createIndirectSubEntry from "../../actions/indirectEntry/createIndirectSubEntry";
import updateIndirectSubEntry from "../../actions/indirectEntry/updateIndirectSubEntry";
import deleteIndirectSubEntryImage from "../../actions/indirectEntry/deleteIndirectSubEntryImage";
import _ from 'lodash';
import {marginRight, noPadding} from "../../const/styles";
import deleteIndirectSubEntry from "../../actions/indirectEntry/deleteIndirectSubEntry";
import {confirmDelete} from "../../apis/authorizedDelete";

const IndirectEntryResume = ({indirectEntry, createIndirectSubEntry, updateIndirectSubEntry, deleteIndirectSubEntryImage, deleteIndirectSubEntry}) => {
  
  const [indirectSubEntry, setIndirectSubEntry] = useState(null);
  const [create, setCreate] = useState(false);
  
  const handleCreate = (formValues) => {
    createIndirectSubEntry(formValues, indirectEntry.id, () => setCreate(false));
  };
  
  const handleUpdate = (formValues) => {
    updateIndirectSubEntry(formValues, indirectEntry.id, () => setIndirectSubEntry(null));
  };
  
  const handleImageDelete = (indirectSubEntryId, imageId) => {
    deleteIndirectSubEntryImage(indirectEntry.id, indirectSubEntryId, imageId, (data) => handleCallback(indirectSubEntryId, data));
  };
  
  const handleCallback = (indirectSubEntryId, data) => {
    setIndirectSubEntry(_.find(data.modal_list.selected_entry.entries, (entry) => entry.id === indirectSubEntryId));
  };
  
  const columns = () => {
    return ([
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
        }, {
            title: 'Contenido',
            dataIndex: 'content',
            key: 'content',
            render: (text) => {
              return <Icon type={text && text.length > 15 ? 'check' : 'close'}/>;
            }
          }, {
            title: 'Imágenes',
            dataIndex: 'images',
            key: 'images',
            render: (text, record) => {
              return record.images.length;
            }
          }, {
            title: 'Comentario',
            dataIndex: 'comment',
            key: 'comment',
            render: (text) => {
              return <Icon type={text && text.length > 0 ? 'check' : 'close'}/>;
            }
          }, {
          title: 'Acciones',
          dataIndex: 'actions',
          key: 'actions',
          render: (text, record) => {
            return (
              <div>
                <Button onClick={() => setIndirectSubEntry(record)} type={"primary"} htmlType={'button'}>
                  <Icon type="eye"/>
                </Button>
                <Button onClick={() => confirmDelete(() => deleteIndirectSubEntry(indirectEntry.id, record.id))}
                        type={"danger"}
                        htmlType={'button'}>
                  <Icon type="delete"/>
                </Button>
              </div>
            );
          }
        }]
    )
  };
  
  const handleRender = () => {
    if (create) {
      return <IndirectSubEntryForm handleReturn={() => setCreate(false)}
                                  onSubmit={handleCreate}/>;
    } else if (indirectSubEntry) {
      return <IndirectSubEntryResume handleReturn={() => setIndirectSubEntry(null)}
                                     indirectSubEntry={indirectSubEntry}
                                     handleUpdate={handleUpdate}
                                     handleImageDelete={handleImageDelete}/>;
    } else {
      return (
        <Card
          title={`Sub entrada de la sección de indirectos`}
          bodyStyle={noPadding}
          extra={
            <Button htmlType={'button'} style={marginRight} type={'ghost'}
                    onClick={() => setCreate(true)}>Crear</Button>
          }>
          <Table scroll={{x: '100%'}}  dataSource={indirectEntry.entries} rowKey={'id'} columns={columns()}/>
        </Card>
      );
    }
  };
  
  return (
    handleRender()
  );
};

const mapStateToProps = state => {
  return ({
    indirectEntry: state.modalList.selected_entry
  });
};

export default connect(mapStateToProps, {
  createIndirectSubEntry,
  deleteIndirectSubEntry,
  updateIndirectSubEntry,
  deleteIndirectSubEntryImage
})(IndirectEntryResume);