import React from 'react';
import {connect} from "react-redux";
import ProjectsLocationsTable from "./ProjectsLocationsTable";
import ProjectsLocationsForm from "./ProjectsLocationsForm";
import createProjectLocation from "../../actions/projects/createProjectLocations";
import getProjectLocations from "../../actions/projects/getProjectLocations";
import updateProjectLocation from "../../actions/projects/updateProjectLocations";
import {Modal} from "antd";
import 'antd/lib/modal/style/css';
import {noPadding} from "../../const/styles";

class ProjectsLocationsModal extends React.Component {
  state = {
    selected: false,
    update: false
  };
  
  handleCreate = () => {
    this.setState({selected: true});
  };
  
  handleUpdate = (location) => {
    this.setState({
      selected: location,
      update: true
    });
  };
  
  handleReturn = () => {
    this.setState({selected: false, update: false});
  };
  
  onSubmit = (formValues) => {
    if (this.state.update) {
      this.props.updateProjectLocation(formValues, this.props.projectId, this.handleReturn)
    } else {
      this.props.createProjectLocation(formValues, this.props.projectId, this.handleReturn)
    }
  };
  
  handleRender = () => {
    if (this.state.selected) {
      return (
        <ProjectsLocationsForm
          location={this.state.update ? this.state.selected : null}
          handleReturn={this.handleReturn}
          onSubmit={this.onSubmit}
        />
      );
    } else {
      return (
        <ProjectsLocationsTable
          handleCreate={this.handleCreate}
          handleUpdate={this.handleUpdate}
          locations={this.props.modalList.kind === 'locations' ? this.props.modalList.entries : []}/>
      );
    }
  };
  
  render() {
    const {modalType, modalProps} = this.props;
    return (
      <Modal bodyStyle={noPadding} footer={null} className={'content-wrapper'} visible={modalType !== null}
             style={{top: 30}}
             width={720} {...modalProps}>
        {this.handleRender()}
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return ({
    modalList: state.modalList
  });
};

export default connect(mapStateToProps, {
  createProjectLocation,
  getProjectLocations,
  updateProjectLocation
})(ProjectsLocationsModal);