import { Modal} from "antd";
import React from "react";
import { connect } from "react-redux";
import UploadMediaForm from "./UploadMediaForm";
import uploadMediaFile from "../../actions/projectsmedia/uploadMediaFile"

class UploadMediaModal extends React.Component {

    render(){
        const {modalType, modalProps} = this.props;
        return (
            <Modal title={'Cargar archivo'} visible={modalType !== null} centered {...modalProps} footer={null}>
                <UploadMediaForm onSubmit={(formValues) => {
                        this.props.uploadMediaFile(formValues, this.props.projectId)
                    }
                } />
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return ({
    });
  };

export default connect(mapStateToProps, {
    uploadMediaFile
})(UploadMediaModal);