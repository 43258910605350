import {UPDATE_PROJECT_LOCATIONS} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedPut from "../../apis/authorizedPut";

export default (formValues, projectId, callback) => async (dispatch) => {
  await loadingCall(
    dispatch,
    authorizedPut(`/projects/${projectId}/locations`, formValues),
    simpleSuccess(UPDATE_PROJECT_LOCATIONS),
    null,
    [],
    [callback]
  );
};