import React, {useState} from 'react';
import {Button, Card, Icon, Table} from "antd";
import NotStructuralSubEntryForm from "./NotStructuralSubEntryForm";
import NotStructuralSubEntryResume from "./NotStructuralSubEntryResume";
import {connect} from "react-redux";
import createNotStructuralSubEntry from "../../actions/notStructuralEntry/createNotStructuralSubEntry";
import updateNotStructuralSubEntry from "../../actions/notStructuralEntry/updateNotStructuralSubEntry";
import deleteNotStructuralSubEntryImage from "../../actions/notStructuralEntry/deleteNotStructuralSubEntryImage";
import _ from 'lodash';
import {marginRight, noPadding} from "../../const/styles";
import deleteNotStructuralSubEntry from "../../actions/notStructuralEntry/deleteNotStructuralSubEntry";
import {confirmDelete} from "../../apis/authorizedDelete";

const NotStructuralEntryResume = ({notStructuralEntry, createNotStructuralSubEntry, updateNotStructuralSubEntry, deleteNotStructuralSubEntryImage, deleteNotStructuralSubEntry}) => {
  
  const [notStructuralSubEntry, setNotStructuralSubEntry] = useState(null);
  const [create, setCreate] = useState(false);
  
  const handleCreate = (formValues) => {
    createNotStructuralSubEntry(formValues, notStructuralEntry.id, () => setCreate(false));
  };
  
  const handleUpdate = (formValues) => {
    updateNotStructuralSubEntry(formValues, notStructuralEntry.id, () => setNotStructuralSubEntry(null));
  };
  
  const handleImageDelete = (notStructuralSubEntryId, imageId) => {
    deleteNotStructuralSubEntryImage(notStructuralEntry.id, notStructuralSubEntryId, imageId, (data) => handleCallback(notStructuralSubEntryId, data));
  };
  
  const handleCallback = (notStructuralSubEntryId, data) => {
    setNotStructuralSubEntry(_.find(data.modal_list.selected_entry.entries, (entry) => entry.id === notStructuralSubEntryId));
  };
  
  const columns = () => {
    return ([
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
        }, {
            title: 'Contenido',
            dataIndex: 'content',
            key: 'content',
            render: (text) => {
              return <Icon type={text && text.length > 15 ? 'check' : 'close'}/>;
            }
          }, {
            title: 'Imagenes',
            dataIndex: 'images',
            key: 'images',
            render: (text, record) => {
              return record.images.length;
            }
          }, {
            title: 'Comentario',
            dataIndex: 'comment',
            key: 'comment',
            render: (text) => {
              return <Icon type={text && text.length > 0 ? 'check' : 'close'}/>;
            }
          }, {
          title: 'Acciones',
          dataIndex: 'actions',
          key: 'actions',
          render: (text, record) => {
            return (
              <div>
                <Button onClick={() => setNotStructuralSubEntry(record)} type={"primary"} htmlType={'button'}>
                  <Icon type="eye"/>
                </Button>
                <Button
                  onClick={() => confirmDelete(() => deleteNotStructuralSubEntry(notStructuralEntry.id, record.id))}
                  type={"danger"}
                  style={marginRight}
                  htmlType={'button'}>
                  <Icon type="delete"/>
                </Button>
              </div>
            );
          }
        }]
    )
  };
  
  const handleRender = () => {
    if (create) {
      return <NotStructuralSubEntryForm handleReturn={() => setCreate(false)}
                                        onSubmit={handleCreate}/>;
    } else if (notStructuralSubEntry) {
      return <NotStructuralSubEntryResume handleReturn={() => setNotStructuralSubEntry(null)}
                                          notStructuralSubEntry={notStructuralSubEntry}
                                          handleUpdate={handleUpdate}
                                          handleImageDelete={handleImageDelete}/>;
    } else {
      return (
        <Card
          bodyStyle={noPadding}
          title={`Sub entradas de la sección de elementos no estructurales`}
          extra={
            <Button style={marginRight} htmlType={'button'} type={'ghost'}
                    onClick={() => setCreate(true)}>Crear</Button>
          }>
          <Table scroll={{x: '100%'}}  dataSource={notStructuralEntry.entries} rowKey={'id'} columns={columns()}/>
        </Card>
      );
    }
  };
  
  return (
    handleRender()
  );
};

const mapStateToProps = state => {
  return ({
    notStructuralEntry: state.modalList.selected_entry
  });
};

export default connect(mapStateToProps, {
  createNotStructuralSubEntry,
  deleteNotStructuralSubEntry,
  updateNotStructuralSubEntry,
  deleteNotStructuralSubEntryImage
})(NotStructuralEntryResume);