import {FINISH_INDIRECT_SECTION} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedPut from "../../apis/authorizedPut";

export default (indirectSectionId) => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedPut(`/indirect_sections/${indirectSectionId}/finish`),
    simpleSuccess(FINISH_INDIRECT_SECTION)
  );
};