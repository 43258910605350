import React from 'react';
import {connect} from "react-redux";
import modalShow from "../../actions/modal/modalShow";
import getProtocol from "../../actions/protocols/getProtocol";
import getProtocols from "../../actions/protocols/getProtocols";
import createProtocol from "../../actions/protocols/createProtocol";
import updateProtocol from "../../actions/protocols/updateProtocol";
import {PROTOCOLS_MODAL} from "../Feedback/Modal/types";
import {Button, Card, Icon, Row, Table} from "antd";
import StatusHelper from "../Helpers/StatusHelper";

class Protocols extends React.Component {

  componentDidMount() {
    this.props.getProtocols();
  }

  fetchProtocol = (protocolId) => {
    this.props.getProtocol(protocolId).then(() => this.openProtocolModal(
        this.props.userList.selected_entry,
        (formValues) => this.props.updateProtocol(formValues, protocolId)
    ));
  };

  openProtocolModal = (protocol, onSubmit) => {
    this.props.modalShow({
      modalType: PROTOCOLS_MODAL,
      modalProps: {protocol: protocol, onSubmit: onSubmit}
    });
  };

  columns = () => {
    return [{
      title: '#',
      dataIndex: 'id',
      key: 'id'
    }, {
      title: 'Titulo',
      dataIndex: 'name',
      key: 'name'
    }, {
      title: 'Estado',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => <StatusHelper type={'protocol'} position={'right'} status={record.status}/>
    }, {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record) => {
        return (
          <Button type={"primary"}
                  htmlType={'button'}
                  onClick={() => this.fetchProtocol(record.id)}>
            <span><Icon type="eye"/> Ver</span>
          </Button>
        );
      }
    }]
  };

  render() {
    return (
        <Card
            bodyStyle={{padding: 0}} title={'Protocolos'}
            extra={
              <Button type={'primary'}
                      htmlType={'button'}
                      onClick={() => this.openProtocolModal(null, this.props.createProtocol)}>
                Agregar
              </Button>
            }
        >
          <Row>
            <Table scroll={{x: '100%'}}  dataSource={this.props.userList.kind === 'protocols' ? this.props.userList.entries : []} rowKey={'id'} columns={this.columns()}/>
          </Row>
        </Card>
    );
  }
};

const mapStateToProps = state => {
  return {
    userList: state.userList
  };
};

export default connect(mapStateToProps, {modalShow, getProtocol, getProtocols, createProtocol, updateProtocol})(Protocols);