import {CREATE_RECOMMENDATION_SUB_ENTRY} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedPost from "../../apis/authorizedPost";
import modalDismiss from "../modal/modalDismiss";

export default (formValues, monthlyWeekEntryId) => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedPost(
          `/monthly_week_entries/${monthlyWeekEntryId}/recommendation_entry`, formValues),
      simpleSuccess(CREATE_RECOMMENDATION_SUB_ENTRY),
      null,
      [modalDismiss]);
};