import React from "react";
import {Button, Card, Col, Icon, Row, Table} from 'antd';
import {connect} from "react-redux";
import modalShow from '../../actions/modal/modalShow';
import getWeeklyReportsPanel from "../../actions/weeklyReports/getWeeklyReportsPanel";
import StatusHelper from "../Helpers/StatusHelper";
import createWeeklyReport from "../../actions/weeklyReports/createWeeklyReport";
import getWeeklyReport from "../../actions/weeklyReports/getWeeklyReport";
import {USER_WEEKLY_MODAL} from "../Feedback/Modal/types";
import {marginRight} from "../../const/styles";

export const WeeklyIndexTable = ({admin, getWeeklyReport, modalShow, data, generateWeeklyReport}) => {
  const openWeeklyModal = (weeklyReportId) => {
    getWeeklyReport(weeklyReportId).then(() => {
      modalShow({
        modalType: USER_WEEKLY_MODAL,
        modalProps: {}
      });
      }
    );
  };

  const columns = () => {
    return [{
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      className: 'mobile-hide'
    }, {
      title: 'Fecha inicial',
      dataIndex: 'start_date',
      key: 'start_date',
    }, {
      title: 'Fecha final',
      dataIndex: 'end_date',
      key: 'end_date',
    }, {
      title: 'Consecutivo',
      dataIndex: 'consecutive',
      key: 'consecutive',
    }, {
      title: 'Estado',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => <StatusHelper type={'weekly'} position={'left'} status={record.status}/>
    }, {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record) => {
        return (
          <div>
            {!admin &&
              <Button style={marginRight} onClick={() => openWeeklyModal(record.id)} type={"primary"} htmlType={'button'}>
                <Icon type="eye"/>
              </Button>}
            {renderViewButton(record)}
          </div>
        );
      }
    }]
  };

  const renderViewButton = (weeklyReport) => {
    if (weeklyReport.status === 'finished'){
      return (
        <Button htmlType={'button'} type={'ghost'} onClick={() => window.open(weeklyReport.document)}>
          <Icon type="file-pdf"/>
        </Button>
      );
    } else if (weeklyReport.status === 'unfinished' && admin) {
      return (
          <Button htmlType={'button'} type={'ghost'} onClick={() => generateWeeklyReport(weeklyReport.id)}>
            <Icon type={'eye'}/>
          </Button>
      );
    }
  };

  return (
    <Table scroll={{x: '100%'}} dataSource={data.kind === 'weekly_reports' ? data.entries : []}
           rowKey={'id'} columns={columns()}/>
  )
};

class UsersWeekly extends React.Component {

  componentDidMount() {
    this.props.getWeeklyReportsPanel();
  };

  renderAddButton = () => {
    if(!this.props.client)
      return (
          <Col span={8}>
            <Button type={'primary'}
                    htmlType={'button'}
                    onClick={this.props.createWeeklyReport}>
              Agregar
            </Button>
          </Col>
      );
  };

  render() {
    const {modalShow, getWeeklyReport, userList} = this.props;
    return (
      <Card bodyStyle={{padding: 0}} title={'Reportes Semanales'} extra={this.renderAddButton()}>
        <Row>
          <WeeklyIndexTable data={userList} modalShow={modalShow} getWeeklyReport={getWeeklyReport}/>
        </Row>
      </Card>
    );
  }
}


const mapStateToProps = state => {
  return {
    client: state.auth.client,
    userList: state.userList
  };
};


export default connect(mapStateToProps, {modalShow, getWeeklyReportsPanel, createWeeklyReport, getWeeklyReport})(UsersWeekly);