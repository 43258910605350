import React, {useState} from 'react';
import {Field, reduxForm} from "redux-form";
import {CreateFileField, CreateTextArea, CreateTextField} from "../Helpers/Input/InputCreator";
import {
  fileImage,
  fileRequired,
  fileVerification,
  maxLength120,
  maxLength70,
  minLength5,
  required
} from "../Helpers/Input/validations";
import {Button, Card, Col, Form, Row, Popconfirm} from "antd";
import FindingClosureFields from "./FindingClosureFields";

const FindingForm = ({handleSubmit, change, onSubmit}) => {
  const [closure, setClosure] = useState(false);

  const renderClosureForm = () => {
    if(closure) {
      return <FindingClosureFields change={change}/>;
    } else {
      return <Button onClick={() => setClosure(!closure)} type={'primary'} htmlType={'button'}>Mostrar campos de cierre</Button>;
    }
  };

  return (
    <Card title={'Hallazgo'}>
      <Form onSubmit={handleSubmit(onSubmit)}>
      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Field label={'Referencia'}
                 name={`finding_entry[reference]`}
                 component={CreateTextField}
                 placeholder="Referencia"
                 validate={[required, minLength5, maxLength70]}
                 hasFeedback/>
        </Col>
        <Col xs={24} sm={12}>
          <Field label={'Responsable'}
                 name={`finding_entry[responsible]`}
                 component={CreateTextField}
                 placeholder="Responsable"
                 validate={[required, minLength5, maxLength70]}
                 hasFeedback/>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Field label={'Hecho'}
                 name={`finding_entry[fact]`}
                 component={CreateTextArea}
                 placeholder="Hecho"
                 validate={[required, minLength5, maxLength120]}
                 hasFeedback/>
        </Col>
        <Col xs={24} sm={12}>
          <Field label={'Impacto'}
                 name={`finding_entry[impact]`}
                 component={CreateTextArea}
                 placeholder="Impacto"
                 validate={[required, minLength5, maxLength120]}
                 hasFeedback/>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Field label={'Procedimiento'}
                 name={`finding_entry[procedure]`}
                 component={CreateTextArea}
                 placeholder="Procedimiento"
                 validate={[required, minLength5, maxLength120]}
                 hasFeedback/>
        </Col>
        <Col xs={24} sm={12}>
          <Field label={'Evidencia'}
                 name={'evidence'}
                 component={CreateFileField}
                 validate={[fileRequired, fileVerification, fileImage]}
                 change={change}
                 isImage
                 type='file'
                 hasFeedback
          />
        </Col>
      </Row>
      {renderClosureForm()}
      <Row className={'p-20'}>
        <Popconfirm
          title="No podras editar el hallazgo una vez creado."
          onConfirm={handleSubmit(onSubmit)}
          okText="Continuar"
          cancelText="Cancelar"
        >
          <Button type={'primary float-right'} htmlType={'submit'}>Guardar</Button>
        </Popconfirm>
      </Row>
    </Form>
    </Card>
  );
};

export default reduxForm({
  form: 'findings_form',
  touchOnChange: true
})(FindingForm);