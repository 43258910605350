import {UPDATE_FINDING_ENTRY} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import {formDataHelper} from "../functions/formDataHelper";
import authorizedPut from "../../apis/authorizedPut";
import modalDismiss from "../modal/modalDismiss";

export default (findingEntryId, formValues) => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedPut(
        `/finding_entries/${findingEntryId}`,
        formDataHelper(
          formValues,
          'finding_entry',
          [{name: 'closing', key: '[closing]'}]
        )
      ),
      simpleSuccess(UPDATE_FINDING_ENTRY),
      null,
      [modalDismiss]
  );
};