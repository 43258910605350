import React from 'react';
import {Modal} from "antd";
import 'antd/lib/modal/style/css';
import ConceptEntryForm from "./ConceptEntryForm";
import {noPadding} from "../../const/styles";

class ConceptEntryModal extends React.Component {
  render() {
    const {modalType, modalProps} = this.props;
    return (
      <Modal bodyStyle={noPadding} footer={null} className={'content-wrapper'} visible={modalType !== null} style={{ top: 30 }}
             width={720} {...modalProps}>
        <ConceptEntryForm
          conceptEntry={this.props.conceptEntry}
          onSubmit={this.props.onSubmit}
        />
      </Modal>
    );
  }
}

export default ConceptEntryModal;