import React, {useEffect} from 'react';
import {Button, Card, Col, Form, Modal, Row} from "antd";
import 'antd/lib/modal/style/css';
import {noPadding} from "../../const/styles";
import {Field, FieldArray, reduxForm} from "redux-form";
import {CreateCheckbox, CreateFileField, CreateTextArea} from "../Helpers/Input/InputCreator";
import {fileImage, fileVerification} from "../Helpers/Input/validations";
import _ from "lodash";
import QuillTextArea from "../Helpers/QuillTextArea";

const ProgrammingForm = ({fields, programingEntry, change}) => {
  
  useEffect(() => {
    if (fields.length === 0)
      fields.push({});
  }, []);
  
  return (
    fields.map((programing_entries_attributes, index) => {
      return (
        <div key={index}>
          <Row gutter={16}>
            <Col xs={24} sm={24}>
              <Field label={'Contenido'}
                     name={`${programing_entries_attributes}.content`}
                     component={QuillTextArea}
                     change={change} />
            </Col>
          </Row>
          <Row  gutter={16}>
            <Col xs={24} sm={12}>
              <Field label={'Imagen de la entrada'}
                     name={'avatar'}
                     preview={programingEntry ? programingEntry.avatar : null}
                     component={CreateFileField}
                     validate={[fileVerification, fileImage]}
                     change={change}
                     isImage
                     type='file'
                     hasFeedback
              />
            </Col>
            <Col xs={24} sm={12}>
              <Field label={'Comentario para la foto'}
                     name={`${programing_entries_attributes}.comment`}
                     component={CreateTextArea}
                     placeholder="Comentario para la foto"
                     hasFeedback />
              <Field name={`${programing_entries_attributes}.pagebreak`}
                     label={'Salto de página'}
                     component={CreateCheckbox}
                     hasFeedback />
            </Col>
          </Row>
          <Row className={'p-20'}>
            <Button type={'primary float-right'} htmlType={'submit'}>Guardar</Button>
          </Row>
        </div>
      )
    })
  );
};

const ProgramingEntryModal = ({programingEntry, modalType, modalProps, handleSubmit, initialize, change, onSubmit, securityEntry}) => {
  
  useEffect(() => {
    if (programingEntry) {
      initialize({administrative_section: {programing_entries_attributes: [_.omit(programingEntry, ['avatar'])]}});
    } else {
      initialize({administrative_section: {programing_entries_attributes: [ { pagebreak: true } ]}});
    }
  }, []);
  
  return (
    <Modal bodyStyle={noPadding} footer={null} className={'content-wrapper'} visible={modalType !== null}
           style={{top: 30}}
           width={720} {...modalProps}>
      <Card title={'Corte de programación'}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FieldArray name={'administrative_section[programing_entries_attributes]'} component={ProgrammingForm}
                      props={{programingEntry: programingEntry, change: change}}/>
        </Form>
      </Card>
    </Modal>
  );
};

export default reduxForm({
  form: 'programing_entry_form',
  touchOnChange: true
})(ProgramingEntryModal);