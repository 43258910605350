import React, {useEffect} from 'react';
import {Button, Card, Col, Form, Modal, Row} from "antd";
import 'antd/lib/modal/style/css';
import {noPadding} from "../../const/styles";
import {Field, FieldArray, reduxForm} from "redux-form";
import {CreateTextArea, CreateTextField} from "../Helpers/Input/InputCreator";
import {maxLength200, maxLength50, minLength1, minLength5, required} from "../Helpers/Input/validations";

const ContractForm = ({fields}) => {
  
  useEffect(() => {
    if (fields.length === 0)
      fields.push({});
  }, []);
  
  return (
    fields.map((contract_entries_attributes, index) => {
      return (
        <div key={index}>
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Field label={'Titulo'}
                     name={`${contract_entries_attributes}.title`}
                     component={CreateTextField}
                     placeholder="Titulo"
                     validate={[required, minLength5, maxLength50]}
                     hasFeedback/>
            </Col>
            <Col xs={24} sm={12}>
              <Field label={'Objeto del trabajo'}
                     name={`${contract_entries_attributes}.objective`}
                     component={CreateTextArea}
                     placeholder="Objeto del trabajo"
                     validate={[required, minLength1, maxLength200]}
                     hasFeedback/>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Field label={'Valor del contrato'}
                     name={`${contract_entries_attributes}.value`}
                     component={CreateTextArea}
                     placeholder="Valor del contrato"
                     validate={[required, minLength1, maxLength200]}
                     hasFeedback/>
            </Col>
            <Col xs={24} sm={12}>
              <Field label={'Tiempo de ejecución'}
                     name={`${contract_entries_attributes}.time`}
                     component={CreateTextArea}
                     placeholder="Tiempo de ejecución"
                     validate={[required, minLength1, maxLength200]}
                     hasFeedback/>
            </Col>
          </Row>
          <Row className={'p-20'}>
            <Button type={'primary float-right'} htmlType={'submit'}>Guardar</Button>
          </Row>
        </div>
      )
    })
  );
};

const ContractEntryModal = ({contractEntry, modalType, modalProps, handleSubmit, initialize, change, onSubmit, securityEntry}) => {
  
  useEffect(() => {
    if (contractEntry)
      initialize({administrative_section: {contract_entries_attributes: [contractEntry]}});
  }, []);
  
  return (
    <Modal bodyStyle={noPadding} footer={null} className={'content-wrapper'} visible={modalType !== null}
           style={{top: 30}}
           width={720} {...modalProps}>
      <Card title={'Contrato'}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FieldArray name={'administrative_section[contract_entries_attributes]'} component={ContractForm}
                      props={{contractEntry: contractEntry, change: change}}/>
        </Form>
      </Card>
    </Modal>
  );
};

export default reduxForm({
  form: 'contract_entry_form',
  touchOnChange: true
})(ContractEntryModal);