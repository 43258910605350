import {UPDATE_RECOMMENDATION} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import {formDataHelper} from "../functions/formDataHelper";
import authorizedPut from "../../apis/authorizedPut";
import modalDismiss from "../modal/modalDismiss";

export default (formValues, recommendationId) => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedPut('/recommendations/' + recommendationId, formDataHelper(formValues, 'recommendation', [{name: 'avatar', key: '[avatar]'}])),
      simpleSuccess(UPDATE_RECOMMENDATION),
      null,
      [modalDismiss]
  );
};