import {CREATE_MONTHLY_REPORT} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedPost from "../../apis/authorizedPost";

export default () => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedPost('/monthly_reports'),
      simpleSuccess(CREATE_MONTHLY_REPORT)
  );
};