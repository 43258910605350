import {UPDATE_PROJECT} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import {formDataHelper} from "../functions/formDataHelper";
import authorizedPut from "../../apis/authorizedPut";
import modalDismiss from "../modal/modalDismiss";

export default (formValues, projectId) => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedPut('/projects/' + projectId, formDataHelper(formValues, 'project',
          [
          {name: 'supervisor_logo', key: '[supervisor_logo]'},
          {name: 'constructor_logo', key: '[constructor_logo]'},
          {name: 'project_logo', key: '[project_logo]'}
        ])
      ),
      simpleSuccess(UPDATE_PROJECT),
      null,
      [modalDismiss]
  );
};