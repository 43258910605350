import moment from "moment";

export const required = value => value ? undefined : 'Campo requerido';
export const maxLength = max => value =>
  value && value.length > max ? `Debe contener máximo ${max} caracteres` : undefined;

export const minLength = min => value =>
  value && value.length < min ? `Debe contener al menos ${min} caracteres` : undefined;

export const fileVerification = file => (file && file.size > 100000000) ? 'El archivo es obligatorio y debe pesar menos de 100MB' : undefined;
export const fileRequired = file => file ? undefined : 'El archivo es obligatorio';

export const fileType = type => file => !file || !file instanceof File || type.includes(file.type.split('/')[1]) ? undefined : `Solo se permiten formatos ${type}`;

export const fileDoc = fileType('pdf, jpeg, jpg o png');

export const fileImage = fileType('jpeg, jpg o png');

export const mediaTypes = fileType('png, jpg, jpeg, mpg, mpeg, avi, mov, mp4, mkv o wmv');

export const validDate = value => value.length === 10 && moment(value, 'DD/MM/YYYY').isValid() ? undefined : 'Fecha invalida (DD/MM/YYYY)';
export const logicDate = value => value.length === 10 && moment(value, 'DD/MM/YYYY').isBetween('1900-01-01', moment().add(1, "day"), 'day') ? undefined : `Debe ser entre 1901 y hoy`;

export const maxLength10 = maxLength(10);
export const maxLength20 = maxLength(20);
export const maxLength30 = maxLength(30);
export const maxLength40 = maxLength(40);
export const maxLength50 = maxLength(50);
export const maxLength70 = maxLength(70);
export const maxLength100 = maxLength(100);
export const maxLength120 = maxLength(120);
export const maxLength150 = maxLength(150);
export const maxLength200 = maxLength(200);
export const maxLength300 = maxLength(300);
export const maxLength500 = maxLength(500);

export const minLength1 = minLength(1);
export const minLength5 = minLength(5);
export const minLength6 = minLength(6);
export const minLength7 = minLength(7);
export const minLength10 = minLength(10);

export const passwordMatch = (value, allValues) => allValues.user && value !== allValues.user.password ? "Las contraseñas deben coincidir" : undefined;
export const internPasswordMatch = (value, allValues) => {
  return allValues.project.users_attributes[0] && allValues.project.users_attributes[0].password && value !== allValues.project.users_attributes[0].password ? "Las contraseñas deben coincidir" : undefined;
};

export const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
    'Dirección de correo invalida' : undefined;


