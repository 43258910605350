import {
  UPDATE_CONCRETE_SUB_SECTION
} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import modalDismiss from "../modal/modalDismiss";
import {formDataHelper} from "../functions/formDataHelper";
import authorizedPut from "../../apis/authorizedPut";

export default (formValues, subSectionId) => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedPut(
          `/concrete_sub_sections/${subSectionId}/table`,
          formDataHelper(formValues, 'concrete_sub_section', [{name: 'avatar', key: '[concrete_tables_attributes][][avatar]'}])
      ),
      simpleSuccess(UPDATE_CONCRETE_SUB_SECTION),
      null,
      [modalDismiss]);
};