import React, {useEffect, useState} from 'react';
import {Button, Card, Col, Form, Modal, Row} from "antd";
import 'antd/lib/modal/style/css';
import {marginRight, noPadding} from "../../const/styles";
import {Field, FieldArray, reduxForm} from "redux-form";
import {CreateFileField, CreateTextArea, CreateTextField} from "../Helpers/Input/InputCreator";
import {fileImage, fileVerification, maxLength50, minLength5} from "../Helpers/Input/validations";
import QuillTextArea from "../Helpers/QuillTextArea";
import _ from 'lodash';

const InstrumentationEntryForm = ({fields, change, instrumentationEntry}) => {

  useEffect(() => {
    if (fields.length === 0)
      fields.push({});
  }, []);
  
  return (
    fields.map((instrumentation_table_attributes, index) => {
      return (
        <div key={index}>
          <Row gutter={16}>
            <Col xs={24} sm={24}>
              <Field label={'Titulo'}
                     name={`${instrumentation_table_attributes}.title`}
                     component={CreateTextField}
                     placeholder="Titulo"
                     validate={[minLength5, maxLength50]}
                     hasFeedback/>
            </Col>
            <Col xs={24} sm={24}>
              <Field label={'Contenido'}
                     name={`${instrumentation_table_attributes}.content`}
                     component={QuillTextArea}
                     change={change} />
            </Col>
            <Col xs={24} sm={24}>
              <Field label={'Comentario para la foto'}
                     name={`${instrumentation_table_attributes}.comment`}
                     component={CreateTextArea}
                     placeholder="Comentario para la foto"
                     hasFeedback/>
            </Col>
          </Row>
          <Row gutter={16}>
            {_.times(instrumentationEntry && instrumentationEntry.images ? 2 - instrumentationEntry.images.length : 2, (index) => {
              return (
                  <Col xs={24} sm={12} key={index}>
                    <Field label={`Imagen ${index + 1} (Opcional)`}
                           name={`image${index}`}
                           preview={null}
                           component={CreateFileField}
                           validate={[fileVerification, fileImage]}
                           change={change}
                           isImage
                           type='file'
                           hasFeedback
                    />
                  </Col>
              );
            })}
          </Row>
          <Row className={'p-20'}>
            <Button type={'primary float-right'} htmlType={'submit'}>Guardar</Button>
          </Row>
        </div>
      )
    })
  );
};

const InstrumentationEntryModal = ({instrumentationEntry, modalType, modalProps, handleSubmit, initialize, change, onSubmit, deleteEntryImage}) => {
  const [selectedEntry, setSelectedEntry] = useState(instrumentationEntry);
  useEffect(() => {
    if (instrumentationEntry)
      initialize({instrumentation_sub_section: {instrumentation_entries_attributes: [_.pick(instrumentationEntry, ['id', 'content', 'comment', 'title'])]}});
  }, []);

  const [edit, setEdit] = useState(false);

  const handleImageDelete = (entryId, imageId) => {
    deleteEntryImage(selectedEntry.id, entryId, imageId, (data) => handleCallback(entryId, data));
  };

  const handleCallback = (entryId, data) => {
    setSelectedEntry(_.find(data.user_list.selected_entry.entries, (entry) => entry.id === entryId));
  };

  const renderBackBtn = () => {
    if(edit)
      return (
          <Button htmlType={'button'} style={marginRight} type={'primary'}
                  onClick={() => setEdit(false)}>Volver</Button>
      );
  }

  const render = () => {
    if(!instrumentationEntry || edit) {
      return (
          <Card title={'Entradas'}
                extra={
                  <div>
                    {renderBackBtn()}
                  </div>
                }>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <FieldArray name={'instrumentation_sub_section[instrumentation_entries_attributes]'} component={InstrumentationEntryForm}
                          props={{instrumentationEntry: selectedEntry, change: change}}/>
            </Form>
          </Card>
      );
    } else {
      return (
          <Card
              title={`Entrada de anclaje`}
              extra={
                <div>
                  <Button htmlType={'button'} style={marginRight} type={'primary'}
                          onClick={() => setEdit(true)}>Editar</Button>
                </div>
              }>
            <Row>
              <label>Titulo:</label>
              <div className={'bordered-container'}>
                <h4>{selectedEntry.title}</h4>
              </div>
            </Row>
            <Row>
              <label>Contenido:</label>
              <div className={'bordered-container'} dangerouslySetInnerHTML={{ __html: selectedEntry.content}}/>
            </Row>
            <Row className={'mt-10'}>
              <label>Comentario imagenes:</label>
              <div className={'bordered-container'}>
                <p>{selectedEntry.comment}</p>
              </div>
            </Row>
            <Row className={'mt-10'}>
              {selectedEntry.images.map((image, index) => {
                return (
                    <Col key={index} span={12}>
                      <img src={image.url} alt={'Imagen'} style={{width: '100%'}}/>
                      <Button htmlType={'button'} type={'danger float-right mt-10'}
                              onClick={() => handleImageDelete(selectedEntry.id, image.id)}>Eliminar</Button>
                    </Col>
                );
              })}
            </Row>
          </Card>
      );
    }
  };
  
  return (
    <Modal bodyStyle={noPadding} footer={null} className={'content-wrapper'} visible={modalType !== null}
           style={{top: 30}}
           width={720} {...modalProps}>
      {render()}
    </Modal>
  );
};

export default reduxForm({
  form: 'instrumentation_table_form',
  touchOnChange: true
})(InstrumentationEntryModal);