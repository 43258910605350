import {FINISH_RAIN_SECTION} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedPut from "../../apis/authorizedPut";

export default (rainSectionId) => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedPut(`/rain_sections/${rainSectionId}/finish`),
    simpleSuccess(FINISH_RAIN_SECTION)
  );
};