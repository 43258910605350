import authorizedPost from "../../apis/authorizedPost";
import { formDataHelper } from "../functions/formDataHelper";
import loadingCall from "../functions/loadingCall"
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import modalDismiss from "../modal/modalDismiss";
import { UPLOAD_PROJECT_MEDIA } from "../types";

export default (formValues, projectId) => async (dispatch) => {
    await loadingCall(
        dispatch,
        authorizedPost(`/projects/${projectId}/medias`, formDataHelper(formValues, 'projectMedia', [{name: 'mediaFile', key: '[mediaFile]'}])),
        simpleSuccess(UPLOAD_PROJECT_MEDIA),
        null,
        [modalDismiss],
        [],
        true
    );
};
