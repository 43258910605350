import {CREATE_MONTHLY_WEEK_ENTRY} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedPost from "../../apis/authorizedPost";
import modalDismiss from "../modal/modalDismiss";
import {formDataHelper} from "../functions/formDataHelper";

export default (formValues, tracingSectionId) => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedPost(
          `/tracing_sections/${tracingSectionId}/week_entry`,
          formDataHelper(formValues, 'tracing_section', [{name: 'avatar', key: '[monthly_week_entries_attributes][][avatar]'}])
      ),
      simpleSuccess(CREATE_MONTHLY_WEEK_ENTRY),
      null,
      [modalDismiss]);
};