import {DELETE_INSTRUMENTS} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedDelete from "../../apis/authorizedDelete";

export default (resultSectionId) => async (dispatch) => {
  await loadingCall(
    dispatch,
    authorizedDelete(`/result_sections/${resultSectionId}/instruments`),
    simpleSuccess(DELETE_INSTRUMENTS)
  );
};