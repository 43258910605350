import React from "react";
import {Button, Card, Col, Modal, Row} from 'antd';
import 'antd/lib/card/style/css';
import {connect} from "react-redux";
import getMonthlyReport from "../../actions/monthlyReports/getMonthlyReport";
import _ from 'lodash';
import createNetworkSection from "../../actions/monthlyReports/createNetworkSection";
import createTracingSection from "../../actions/monthlyReports/createTracingSection";
import createSecuritySection from "../../actions/monthlyReports/createSecuritySection";
import createConceptSection from "../../actions/monthlyReports/createConceptSection";
import createNotStructuralSection from "../../actions/monthlyReports/createNotStructuralSection";
import {
  ADMINISTRATIVE_VIEW,
  CONCEPT_VIEW,
  INDIRECT_VIEW,
  NETWORK_VIEW,
  NOT_STRUCTURAL_VIEW,
  RAIN_VIEW,
  RECOMMENDATION_VIEW,
  RESULTS_VIEW,
  SECURITY_VIEW,
  TRACING_VIEW,
  PROTOCOL_VIEW
} from "../../paths";
import {push} from 'connected-react-router';
import modalShow from "../../actions/modal/modalShow";
import {LOCATION_SECTION_MODAL} from "../Feedback/Modal/types";
import createLocationSection from "../../actions/monthlyReports/createLocationSection";
import createRecommendationSection from "../../actions/monthlyReports/createRecommendationSection";
import createProtocolSection from "../../actions/monthlyReports/createProtocolSection";
import StatusHelper from "../Helpers/StatusHelper";
import generateMonthlyReport from "../../actions/monthlyReports/generateMonthlyReport";
import checkMonthlyReport from "../../actions/monthlyReports/checkMonthlyReport";
import reviseMonthlyReport from "../../actions/monthlyReports/reviseMonthlyReport";
import createRainSection from "../../actions/monthlyReports/createRainSection";
import createIndirectSection from "../../actions/monthlyReports/createIndirectSection";
import createAdministrativeSection from "../../actions/monthlyReports/createAdministrativeSection";
import createResultSection from "../../actions/monthlyReports/createResultSection";
import deleteNetworkSection from "../../actions/monthlyReports/deleteNetworkSection";
import deleteNotStructuralSection from "../../actions/monthlyReports/deleteNotStructuralSection";
import deleteConceptSection from "../../actions/monthlyReports/deleteConceptSection";
import deleteSecuritySection from "../../actions/monthlyReports/deleteSecuritySection";
import deleteTracingSection from "../../actions/monthlyReports/deleteTracingSection";
import deleteRecommendationSection from "../../actions/monthlyReports/deleteRecommendationSection";
import deleteAdministrativeSection from "../../actions/monthlyReports/deleteAdministrativeSection";
import deleteIndirectSection from "../../actions/monthlyReports/deleteIndirectSection";
import deleteRainSection from "../../actions/monthlyReports/deleteRainSection";
import deleteResultSection from "../../actions/monthlyReports/deleteResultSection";
import deleteLocationSection from "../../actions/monthlyReports/deleteLocationSection";
import deleteProtocolSection from "../../actions/monthlyReports/deleteProtocolSection";

class MonthlyView extends React.Component {

  componentDidMount() {
    this.props.getMonthlyReport(this.props.match.params.id);
  };

  renderState(property, state, id) {
    if (state === null) {
      return (
        <div>
          <h2>
            <StatusHelper type={'monthly_property'} status={'inactive'} position={'top'}/> No creado
          </h2>
          <Button htmlType={'button'} type={'primary'} onClick={() => this.confirmCreate(property)}>
            Crear sección
          </Button>
        </div>
      );
    } else if (state === 'unfinished') {
      return (
        <div>
          <h2>
            <StatusHelper type={'monthly_property'} status={state} position={'top'}/> Pendiente
          </h2>
          <Button htmlType={'button'} type={'ghost'} onClick={() => this.handleShow(property, id)}>
            Ver sección
          </Button>
          <Button htmlType={'button'} type={'danger'} onClick={() => this.handleDelete(property)}>
            Eliminar sección
          </Button>
        </div>
      );
    } else if (state === 'finished') {
      return (
        <h2>
          <StatusHelper type={'monthly_property'} status={state} position={'top'}/> <br/>
          Sección terminada
        </h2>
      );
    }
  };

  confirmCreate = (property) => {
    Modal.confirm({
      title: 'Espera!',
      content: `Seguro que quieres crear la sección ${this.handleTitle(property)}?`,
      onOk: () => this.handleCreate(property),
      okText: 'Crear sección'
    })
  };

  confirmRevise = (id) => {
    Modal.confirm({
      title: 'Espera!',
      content: `Seguro que quieres que el administrador revise las correcciones del reporte ?`,
      onOk: () => this.props.reviseMonthlyReport(id),
      okText: 'Revisar'
    })
  };

  confirmCorrect = (id) => {
    Modal.confirm({
      title: 'Espera!',
      content: `Seguro que quieres que el administrador corrija el reporte ?`,
      onOk: () => this.props.checkMonthlyReport(id),
      okText: 'Corregir'
    })
  };

  handleCreate(property) {
    switch (property) {
      case 'location_section':
        this.props.modalShow({
          modalType: LOCATION_SECTION_MODAL,
          modalProps: {onSubmit: (formValues) => this.props.createLocationSection(this.props.match.params.id, formValues)}
        });
        break;
      case 'network_section':
        this.props.createNetworkSection(this.props.match.params.id);
        break;
      case 'not_structural_section':
        this.props.createNotStructuralSection(this.props.match.params.id);
        break;
      case 'concept_section':
        this.props.createConceptSection(this.props.match.params.id);
        break;
      case 'security_section':
        this.props.createSecuritySection(this.props.match.params.id);
        break;
      case 'tracing_section':
        this.props.createTracingSection(this.props.match.params.id);
        break;
      case 'recommendation_section':
        this.props.createRecommendationSection(this.props.match.params.id);
        break;
      case 'protocol_section':
        this.props.createProtocolSection(this.props.match.params.id);
        break;
      case 'administrative_section':
        this.props.createAdministrativeSection(this.props.match.params.id);
        break;
      case 'indirect_section':
        this.props.createIndirectSection(this.props.match.params.id);
        break;
      case 'rain_section':
        this.props.createRainSection(this.props.match.params.id);
        break;
      case 'result_section':
        this.props.createResultSection(this.props.match.params.id);
        break;
      default:
        return null;
    }
  }

  handleDelete(property) {

    let deleteAction;

    switch (property) {
      case 'location_section':
        deleteAction = () => this.props.deleteLocationSection(this.props.match.params.id);
        break;
      case 'network_section':
        deleteAction = () => this.props.deleteNetworkSection(this.props.match.params.id);
        break;
      case 'not_structural_section':
        deleteAction = () => this.props.deleteNotStructuralSection(this.props.match.params.id);
        break;
      case 'concept_section':
        deleteAction = () => this.props.deleteConceptSection(this.props.match.params.id);
        break;
      case 'security_section':
        deleteAction = () => this.props.deleteSecuritySection(this.props.match.params.id);
        break;
      case 'tracing_section':
        deleteAction = () => this.props.deleteTracingSection(this.props.match.params.id);
        break;
      case 'recommendation_section':
        deleteAction = () => this.props.deleteRecommendationSection(this.props.match.params.id);
        break;
      case 'protocol_section':
        deleteAction = () => this.props.deleteProtocolSection(this.props.match.params.id);
        break;
      case 'administrative_section':
        deleteAction = () => this.props.deleteAdministrativeSection(this.props.match.params.id);
        break;
      case 'indirect_section':
        deleteAction = () => this.props.deleteIndirectSection(this.props.match.params.id);
        break;
      case 'rain_section':
        deleteAction = () => this.props.deleteRainSection(this.props.match.params.id);
        break;
      case 'result_section':
        deleteAction = () => this.props.deleteResultSection(this.props.match.params.id);
        break;
      default:
        return null;
    }

    Modal.confirm({
      title: 'Espera!',
      content: `Se eliminará la sección de ${this.handleTitle(property)}`,
      onOk: deleteAction,
      okText: 'Eliminar sección',
      okType: 'danger'
    })
  }

  handleShow(property, id) {
    switch (property) {
      case 'location_section':
        break;
      case 'network_section':
        this.props.push(NETWORK_VIEW(this.props.match.params.id, id));
        break;
      case 'not_structural_section':
        this.props.push(NOT_STRUCTURAL_VIEW(this.props.match.params.id, id));
        break;
      case 'concept_section':
        this.props.push(CONCEPT_VIEW(this.props.match.params.id, id));
        break;
      case 'security_section':
        this.props.push(SECURITY_VIEW(this.props.match.params.id, id));
        break;
      case 'tracing_section':
        this.props.push(TRACING_VIEW(this.props.match.params.id, id));
        break;
      case 'recommendation_section':
        this.props.push(RECOMMENDATION_VIEW(this.props.match.params.id, id));
        break;
      case 'protocol_section':
        this.props.push(PROTOCOL_VIEW(this.props.match.params.id, id));
        break;
      case 'administrative_section':
        this.props.push(ADMINISTRATIVE_VIEW(this.props.match.params.id, id));
        break;
      case 'indirect_section':
        this.props.push(INDIRECT_VIEW(this.props.match.params.id, id));
        break;
      case 'rain_section':
        this.props.push(RAIN_VIEW(this.props.match.params.id, id));
        break;
      case 'result_section':
        this.props.push(RESULTS_VIEW(this.props.match.params.id, id));
        break;
      default:
        return null;
    }
  }

  handleTitle(property) {
    switch (property) {
      case 'location_section':
        return 'Ubicación';
      case 'network_section':
        return 'Redes';
      case 'not_structural_section':
        return 'Elementos no estructurales';
      case 'concept_section':
        return 'Conceptos';
      case 'security_section':
        return 'Seguridad';
      case 'tracing_section':
        return 'Seguimiento';
      case 'recommendation_section':
        return 'Recomendaciones';
      case 'administrative_section':
        return 'Administrativo';
      case 'indirect_section':
        return 'Indirecto';
      case 'rain_section':
        return 'Lluvia';
      case 'result_section':
        return 'Análisis de resultados';
      case 'protocol_section':
        return 'Protocolos';
      default:
        return null;
    }
  }

  renderCards() {
    let monthlyReport = this.props.userList.selected_entry;
    if (monthlyReport.kind === 'monthly_report')
      return (
        Object.keys(_.omit(monthlyReport, ['id', 'date', 'status', 'ids', 'kind', 'document', 'corrections'])).map((property) => {
          if (monthlyReport.hasOwnProperty(property))
            return (
              <Col className={'mb-10'} xs={24} md={12} lg={8} key={property}>
                <Card title={this.handleTitle(property)} bodyStyle={{minHeight: 160}}>
                  {this.renderState(property, monthlyReport[property], monthlyReport['ids'][property])}
                </Card>
              </Col>
            );
          else return ''
        })
      );
  }

  renderFinishButton() {
    if (this.props.userList.selected_entry.status === 'unfinished') {
      return (
        <Row type={'flex'} justify={'end'}>
          <Col xs={12} md={4}>
            <Button htmlType={'button'} type={'ghost'}
                    onClick={() => this.confirmCorrect(this.props.match.params.id)}>Corregir</Button>
          </Col>
          <Col xs={12} md={4}>
            <Button htmlType={'button'} type={'primary'}
                    onClick={() => this.props.generateMonthlyReport(this.props.match.params.id)}>Generar</Button>
          </Col>
        </Row>
      );
    } else if (this.props.userList.selected_entry.status === 'fixing') {
      return (
        <Row type={'flex'} justify={'end'}>
          <Col xs={8} md={4}>
          <Button htmlType={'button'} type={'ghost'}
                  onClick={() => this.confirmRevise(this.props.match.params.id)}>Revisar</Button>
          </Col>
          <Col xs={8} md={4}>
            <Button htmlType={'button'} type={'primary'}
                    onClick={() => this.props.generateMonthlyReport(this.props.match.params.id)}>Generar</Button>
          </Col>
          <Col xs={8} md={4}>
            <Button htmlType={'button'} type={'primary'}
                    onClick={() => window.open(this.props.userList.selected_entry.corrections)}>Correcciones</Button>
          </Col>
        </Row>
      );
    }
  }

  renderTitle = () => {
    return (
      <Row>
        <Col xs={24}>Reporte Mensual</Col>
        <Col xs={24}>{this.renderFinishButton()}</Col>
      </Row>
    )
  };

  render() {
    return (
      <Card title={this.renderTitle()}>
        <Row gutter={8}>
          {this.renderCards()}
        </Row>
      </Card>
    );
  }
}


const mapStateToProps = state => {
  return {
    userList: state.userList
  };
};

export default connect(
  mapStateToProps,
  {
    push,
    modalShow,
    getMonthlyReport,
    createNetworkSection,
    createNotStructuralSection,
    createConceptSection,
    createSecuritySection,
    createTracingSection,
    createLocationSection,
    createRecommendationSection,
    createAdministrativeSection,
    createIndirectSection,
    createRainSection,
    deleteLocationSection,
    deleteNetworkSection,
    deleteNotStructuralSection,
    deleteConceptSection,
    deleteSecuritySection,
    deleteTracingSection,
    deleteRecommendationSection,
    deleteAdministrativeSection,
    deleteIndirectSection,
    deleteRainSection,
    deleteResultSection,
    generateMonthlyReport,
    checkMonthlyReport,
    reviseMonthlyReport,
    createResultSection,
    createProtocolSection,
    deleteProtocolSection
  })(MonthlyView);