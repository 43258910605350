import React from 'react';
import {Button, Card, Col, Form, Modal, Row} from "antd";
import 'antd/lib/modal/style/css';
import {Field, reduxForm} from "redux-form";
import {CreateFileField} from "../Helpers/Input/InputCreator";
import {fileImage, fileRequired, fileVerification} from "../Helpers/Input/validations";
import {noPadding} from "../../const/styles";

class LocationSectionModal extends React.Component {

  state = {plane1: null, plane2: null};

  render() {
    const {modalType, modalProps, onSubmit, locationSection, change} = this.props;
    return (
      <Modal footer={null} bodyStyle={noPadding} className={'content-wrapper'} visible={modalType !== null} style={{ top: 30 }} width={720} {...modalProps}>
        <Card title={'Sección de ubicación'}>
          <Form onSubmit={this.props.handleSubmit(onSubmit)}>
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <Field label={'Imagen del plano 1'}
                       name={'plane1'}
                       preview={locationSection ? locationSection.plane1 : null}
                       component={CreateFileField}
                       validate={locationSection ? [fileVerification, fileImage] : [fileRequired, fileVerification, fileImage]}
                       change={change}
                       isImage
                       type='file'
                       hasFeedback
                />
              </Col>
              <Col xs={24} sm={12}>
                <Field label={'Imagen del plano 2'}
                       name={'plane2'}
                       preview={locationSection ? locationSection.plane2 : null}
                       component={CreateFileField}
                       validate={locationSection ? [fileVerification, fileImage] : [fileRequired, fileVerification, fileImage]}
                       change={change}
                       isImage
                       type='file'
                       hasFeedback
                />
              </Col>
            </Row>
            <Row className={'p-20'}>
              <Button type={'primary float-right'} htmlType={'submit'}>Guardar</Button>
            </Row>
          </Form>
        </Card>
      </Modal>
    );
  }
}

export default reduxForm({
  form: 'location_section_form',
  touchOnChange: true
})(LocationSectionModal);