import {DELETE_CONCRETE} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedDelete from "../../apis/authorizedDelete";

export default (resultSectionId) => async (dispatch) => {
  await loadingCall(
    dispatch,
    authorizedDelete(`/result_sections/${resultSectionId}/concrete`),
    simpleSuccess(DELETE_CONCRETE)
  );
};