import React from 'react';
import {Card, Col, Row} from "antd";
import notFound from '../../resources/img/404.svg';

const NotFound = () => {
  
  return (
    <Card bordered={false} style={{backgroundColor: 'transparent'}}>
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <img src={notFound} alt=""/>
          </Col>
          <Col className={'text-center'} xs={24} md={12}>
            <h2 style={{fontSize: 50, fontWeight: 'bold'}}>404</h2>
            <h4 style={{fontSize: 30}}>Lo sentimos, la página que buscas no existe</h4>
          </Col>
        </Row>
      </Card>
  );
};

export default NotFound;