import {FIX_MONTHLY_REPORT} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedPut from "../../apis/authorizedPut";
import {formDataHelper} from "../functions/formDataHelper";
import modalDismiss from "../modal/modalDismiss";

export default (monthlyReportId, formValues) => async (dispatch) => {
  await loadingCall(
    dispatch,
    authorizedPut(`/monthly_reports/${monthlyReportId}/fix`,
      formDataHelper(
        formValues,
        'monthly_report',
        [
          {name: 'corrections', key: '[corrections]'}
        ]
      )
    ),
    simpleSuccess(FIX_MONTHLY_REPORT),
    null,
    [modalDismiss]
  );
};