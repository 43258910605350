import React from "react";
import {Field, Form, reduxForm} from "redux-form";
import {CreateTextField} from "../Helpers/Input/InputCreator";
import {maxLength200, maxLength50, minLength5, required} from "../Helpers/Input/validations";
import {Alert, Col, Row} from "antd";
import 'antd/lib/alert/style/css';
import 'antd/lib/col/style/css';
import 'antd/lib/row/style/css';

const ProjectBasic = ({onSubmit, handleSubmit, actions}) => {
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Alert message={'Completa los datos basicos del proyecto'}/>
      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Field label={'Nombre'}
                 name={`project[name]`}
                 component={CreateTextField}
                 placeholder="Nombre"
                 validate={[required, minLength5, maxLength200]}
                 hasFeedback/>
        </Col>
        <Col xs={24} sm={12}>
          <Field label={'Direccion'}
                 name={`project[address]`}
                 component={CreateTextField}
                 placeholder="Direccion"
                 validate={[required, minLength5, maxLength200]}
                 hasFeedback/>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Field label={'Sector'}
                 name={`project[sector]`}
                 component={CreateTextField}
                 placeholder="Sector"
                 validate={[required, minLength5, maxLength50]}
                 hasFeedback/>
        </Col>
        <Col xs={24} sm={12}>
          <Field label={'Localidad'}
                 name={`project[location]`}
                 component={CreateTextField}
                 placeholder="Location"
                 validate={[required, minLength5, maxLength50]}
                 hasFeedback/>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Field label={'Delimitacion norte'}
                 name={`project[north_delimitation]`}
                 component={CreateTextField}
                 placeholder="Delimitacion norte"
                 validate={[required, minLength5, maxLength50]}
                 hasFeedback/>
        </Col>
        <Col xs={24} sm={12}>
          <Field label={'Delimitacion este'}
                 name={`project[east_delimitation]`}
                 component={CreateTextField}
                 placeholder="Delimitacion este"
                 validate={[required, minLength5, maxLength50]}
                 hasFeedback/>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Field label={'Delimitacion sur'}
                 name={`project[south_delimitation]`}
                 component={CreateTextField}
                 placeholder="Delimitacion sur"
                 validate={[required, minLength5, maxLength50]}
                 hasFeedback/>
        </Col>
        <Col xs={24} sm={12}>
          <Field label={'Delimitacion oeste'}
                 name={`project[west_delimitation]`}
                 component={CreateTextField}
                 placeholder="Delimitacion oeste"
                 validate={[required, minLength5, maxLength50]}
                 hasFeedback/>
        </Col>
      </Row>
      {actions}
    </Form>
  );
};

export default reduxForm({
  form: 'projects_form',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  touchOnChange: true
})(ProjectBasic);