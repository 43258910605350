import {VERIFY_TOKEN} from "../types";
import loadingCall from "../functions/loadingCall";
import authorizedPost from "../../apis/authorizedPost";

export default () => async (dispatch, getState) => {
  if (localStorage.jwt_token && !getState().auth.jwt_token)
    await loadingCall(dispatch, authorizedPost('/verify-token'), initialSuccess);
};


const initialSuccess = (dispatch, data) => {
  localStorage.setItem('jwt_token', data.auth.jwt_token);
  dispatch({type: VERIFY_TOKEN, payload: data});
};