import React, {useState} from 'react';
import {Button, Card, Col, Row} from "antd";
import 'antd/lib/button/style/css';
import 'antd/lib/card/style/css';
import 'antd/lib/row/style/css';
import 'antd/lib/tabs/style/css';
import 'antd/lib/col/style/css';
import ElementSubSubEntryForm from "./ElementSubSubEntryForm";
import {marginRight} from "../../const/styles";

const ElementSubSubEntryResume = ({elementSubSubEntry, handleUpdate, handleReturn, handleImageDelete}) => {
  
  const [update, setUpdate] = useState(false);
  
  const handleRender = () => {
    if (update) {
      return <ElementSubSubEntryForm handleReturn={() => setUpdate(false)}
                                     elementSubSubEntry={elementSubSubEntry}
                                     onSubmit={handleUpdate}/>;
    } else {
      return (
        <Card
          title={`Sub entrada de se la sección de redes`}
          extra={
            <div>
              <Button htmlType={'button'} style={marginRight} type={'primary'}
                      onClick={() => setUpdate(true)}>Editar</Button>
              <Button htmlType={'button'} style={marginRight} type={'ghost'} onClick={handleReturn}>Volver</Button>
            </div>
          }>
          <Row>
            <label>Contenido:</label>
            <div className={'bordered-container'} dangerouslySetInnerHTML={{ __html: elementSubSubEntry.content}}/>
          </Row>
          <Row className={'mt-10'}>
            <label>Comentario imagenes:</label>
            <div className={'bordered-container'}>
              <p>{elementSubSubEntry.comment}</p>
            </div>
          </Row>
          <Row className={'mt-10'}>
            {elementSubSubEntry.images.map((image, index) => {
              return (
                <Col key={index} span={12}>
                  <Button htmlType={'button'} type={'danger'}
                          onClick={() => handleImageDelete(elementSubSubEntry.id, image.id)}>Eliminar</Button>
                  <img src={image.url} alt={'Imagen'} style={{width: '100%'}}/>
                </Col>
              );
            })}
          </Row>
        </Card>
      );
    }
  };
  
  return (
    handleRender()
  );
};

export default ElementSubSubEntryResume;