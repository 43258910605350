import {DELETE_INDIRECT_SUB_ENTRY_IMAGE} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedPut from "../../apis/authorizedPut";

export default (indirectEntryId, indirectSubEntryId, imageId, callback) => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedPut(
          `/indirect_entries/${indirectEntryId}/entry_image`,{indirect_entry: {entry_id: indirectSubEntryId, image_id: imageId}}
      ),
      simpleSuccess(DELETE_INDIRECT_SUB_ENTRY_IMAGE),
      null,
      [],
      [callback]);
};