import React from "react";
import './dropzone.css';
import {Icon, Upload} from 'antd';

class DropZoneField extends React.Component {
  
  filePreview = (text, icon) => (
    <div className={'file-preview p-20'}>
      <Icon type={icon}/>
      <p className={'placeholder'}>{this.props.placeholder}</p>
      <p className={'message'}>{text}</p>
    </div>
  );
  
  imagePreview = () => {
    if (this.props.isFile) {
      return this.filePreview(<span>Haz clic para reemplazarlo!</span>, 'reload')
    } else
      return (
        <div className="image-container">
          <img src={this.props.value ? URL.createObjectURL(this.props.value) : this.props.preview}
               alt={''}/>
        </div>
      )
  };
  
  renderPreview = () => {
    if (this.props.meta.error && this.props.meta.touched) {
      return (
        this.filePreview(this.props.meta.error, 'close')
      );
    } else if (this.props.value || this.props.preview) {
      if ((this.props.preview) || (this.props.value && this.props.value.type.includes('image'))) {
        return (
          this.imagePreview()
        );
      } else if (this.props.isFile) {
        return (
          this.filePreview(<span style={{
            width: '100%',
            wordBreak: 'break-all'
          }}>Se cargó con éxito <br/> {this.props.value.name}</span>, 'check')
        );
      } else {
        return (
          this.filePreview(<span>Haz clic para reemplazarlo!</span>, 'reload')
        )
      }
    } else {
      return (
        this.filePreview(<span>Haz clic para elegir un archivo!</span>, 'search')
      )
    }
  };
  
  render() {
    const props = {
      onRemove: () => {
        this.props.change(this.props.name, null);
      },
      onChange: (newFile) => {
        /*this.props.change(this.props.name, this.props.value ? this.props.value : newFile.file);*/
        this.props.change(this.props.name, newFile.file);
      },
      beforeUpload: () => false,
      fileList: this.props.value ? [this.props.value] : [],
      accept: this.props.accept ? this.props.accept : ''
    };
  
  
    return (
      <div className="preview-container">
        <Upload.Dragger {...props}>
          {this.renderPreview()}
        </Upload.Dragger>
        {this.props.isFile && this.props.preview ? <a href={this.props.preview} target={'_blank'}>Ver archivo original</a> : ''}
      </div>
    );
  }
  
}


export default DropZoneField;
