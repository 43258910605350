import React from 'react';
import {Button, Card, Icon, Table} from "antd";
import 'antd/lib/button/style/css';
import 'antd/lib/card/style/css';
import 'antd/lib/icon/style/css';
import 'antd/lib/table/style/css';
import StatusHelper from "../Helpers/StatusHelper";
import {FINDING_MODAL} from "../Feedback/Modal/types";
import {marginRight, noPadding} from "../../const/styles";

const WeeklyFindings = ({handleReturn, weeklyReport, modalShow}) => {

  const openFindingModal = (findingEntry) => {
    modalShow({
      modalType: FINDING_MODAL,
      modalProps: {
        findingEntry: findingEntry,
        kind: 'view'
      }
    });
  };

  const columns = () => {
    return [{
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    }, {
      title: 'Referencia',
      dataIndex: 'reference',
      key: 'reference',
    }, {
      title: 'Fecha de inicio',
      dataIndex: 'start_date',
      key: 'start_date',
    }, {
      title: 'Estado',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => <StatusHelper type={'finding'} position={'right'} status={record.end_date ? 'finished' : 'unfinished'}/>
    }, {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record) => {
        return (
          <div>
            <Button onClick={() => openFindingModal(record)} type={"primary"} htmlType={'button'}>
              <Icon type="eye"/>
            </Button>
          </div>
        );
      }
    }]
  };

  return (
      <Card
        title={'Hallazgos'}
        bodyStyle={noPadding}
        extra={
          <Button style={marginRight} htmlType={'button'} type={'ghost'} onClick={handleReturn}>Volver</Button>
          }>
        <Table scroll={{x: '100%'}}  dataSource={weeklyReport.findings} rowKey={'id'} columns={columns()}/>
      </Card>
  );
};

export default WeeklyFindings;