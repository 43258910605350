import _ from 'lodash';

export const formValuesAppender = (formValues, formData, preKey = null) => {
  _.reduce(formValues, function (formData, value, key) {
    let completeKey = preKey ? preKey + '[' + key + ']' : key;
    if (value && typeof value === 'object' && !value._isAMomentObject) {
      if (Array.isArray(value)) {
        formValuesAppender(value[0], formData, completeKey + '[]');
      } else {
        formValuesAppender(value, formData, completeKey);
      }
    } else {
      if(value !== 'undefined') {
        formData.append(completeKey, value);
      }
    }
    return formData;
  }, formData);
};

export const formDataHelper = (formValues, preKey, fileKeys) => {
  let formData = new FormData();
  let fileOmits = [];
  fileKeys.forEach((file) => {
    fileOmits.push(file.name);
    if(formValues[file.name])
      formData.append(`${preKey}${file.key}`, formValues[file.name]);
  });
  formValuesAppender(_.omit(formValues[preKey], fileOmits), formData, preKey);
  return formData;
};