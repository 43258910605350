import {CREATE_CONTRACT_ENTRY} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedPost from "../../apis/authorizedPost";
import modalDismiss from "../modal/modalDismiss";

export default (formValues, administrativeSectionId) => async (dispatch) => {
  await loadingCall(
    dispatch,
    authorizedPost(
      `/administrative_sections/${administrativeSectionId}/contract_entry`,
      formValues
    ),
    simpleSuccess(CREATE_CONTRACT_ENTRY),
    null,
    [modalDismiss]);
};