import React from 'react';
import {Button, Card, Row} from "antd";
import 'antd/lib/modal/style/css';

class ArrivalModal extends React.Component {
  
  render() {
    return (
      <Card title={'Registro de entrada'}>
        <Row>
          <Button htmlType={'button'} onClick={this.props.createAlert} type={"primary"}>Marcar entrada</Button>
        </Row>
      </Card>
    );
  }
}

export default ArrivalModal;