import {CREATE_ELEMENT_SUB_SUB_ENTRY} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedPost from "../../apis/authorizedPost";
import {formDataHelper} from "../functions/formDataHelper";
import _ from "lodash";

export default (formValues, elementSubEntryId, callback) => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedPost(
          `/element_sub_entries/${elementSubEntryId}/entry`,
          formDataHelper(
              formValues,
              'element_sub_entry',
              _.times(2, (index) => {return {name: `image${index}`, key: '[element_sub_sub_entries_attributes][][images][]'}})
          )
      ),
      simpleSuccess(CREATE_ELEMENT_SUB_SUB_ENTRY),
      null,
      [],
      [callback]);
};