import React from "react";
import {Button, Card, Icon, Table} from 'antd';
import 'antd/lib/button/style/css';
import 'antd/lib/card/style/css';
import 'antd/lib/col/style/css';
import 'antd/lib/icon/style/css';
import 'antd/lib/input/style/css';
import 'antd/lib/row/style/css';
import 'antd/lib/table/style/css';
import {connect} from "react-redux";
import modalShow from '../../actions/modal/modalShow';
import {INSTRUMENTATION_ENTRY_MODAL} from "../Feedback/Modal/types";
import {RESULTS_VIEW} from "../../paths";
import {push} from 'connected-react-router';
import getInstrumentationSubSection from "../../actions/instrumentationSubSection/getInstrumentationSubSection";
import createInstrumentationEntry from "../../actions/instrumentationSubSection/createInstrumentationEntry";
import updateInstrumentationEntry from "../../actions/instrumentationSubSection/updateInstrumentationEntry";
import deleteInstrumentationSubSectionEntryImage
  from "../../actions/instrumentationSubSection/deleteInstrumentationSubSectionEntryImage";
import {marginRight} from "../../const/styles";
import deleteInstrumentationEntry from "../../actions/instrumentationSubSection/deleteInstrumentationEntry";
import {confirmDelete} from "../../apis/authorizedDelete";

class Instrumentation extends React.Component {

  componentDidMount() {
    this.props.getInstrumentationSubSection(this.props.match.params.instrumentation_id);
  };

  openEntryModal = (entry, onSubmit) => {
    this.props.modalShow({
      modalType: INSTRUMENTATION_ENTRY_MODAL,
      modalProps: {instrumentationEntry: entry, onSubmit: onSubmit, deleteEntryImage: this.props.deleteInstrumentationSubSectionEntryImage}
    });
  };

  handleEntryCreate = (formValues) => {
    this.props.createInstrumentationEntry(formValues, this.props.match.params.instrumentation_id);
  };

  handleEntryUpdate = (formValues) => {
    this.props.updateInstrumentationEntry(formValues, this.props.match.params.instrumentation_id);
  };
  
  entryColumns = () => {
    return [{
      title: 'Titulo',
      dataIndex: 'title',
      key: 'title',
      render: (text) => {
        return <Icon type={text && text.length > 0 ? 'check' : 'close'}/>;
      }
    }, {
      title: 'Contenido',
      dataIndex: 'content',
      key: 'content',
      render: (text) => {
        return <Icon type={text && text.length > 15 ? 'check' : 'close'}/>;
      }
    }, {
      title: 'Imágenes',
      dataIndex: 'images',
      key: 'images',
      render: (text, record) => {
        return record.images.length;
      }
    }, {
      title: 'Comentario',
      dataIndex: 'comment',
      key: 'comment',
      render: (text) => {
        return <Icon type={text && text.length > 0 ? 'check' : 'close'}/>;
      }
    }, {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record) => {
        return (
            <div>
              <Button onClick={() => this.openEntryModal(record, this.handleEntryUpdate)} type={"primary"}
                      htmlType={'button'}>
                <Icon type="eye"/>
              </Button>
              <Button
                onClick={() => confirmDelete(() => this.props.deleteInstrumentationEntry(this.props.match.params.instrumentation_id, record.id))}
                type={"danger"}
                style={marginRight}
                htmlType={'button'}>
                <Icon type="delete"/>
              </Button>
            </div>
        );
      }
    }]
  };

  render() {
    return (
      <Card title={'Sección de instrumentación'}
            bodyStyle={{padding: 0}}
            extra={
                <div>
                  <Button type={'primary'}
                          htmlType={'button'}
                          onClick={() => this.openEntryModal(null, this.handleEntryCreate)}>
                    Agregar
                  </Button>
                  <Button htmlType={'button'}
                          type={'ghost'}
                          onClick={() => this.props.push(RESULTS_VIEW(this.props.match.params.id, this.props.match.params.results_id))}>Volver</Button>
                </div>
              }>
          <Table scroll={{x: '100%'}}
              dataSource={this.props.instrumentation_sub_section.kind === 'instrumentation_entries' ? this.props.instrumentation_sub_section.entries : []}
              rowKey={'id'} columns={this.entryColumns()}/>
        </Card>
    );
  }
}

const mapStateToProps = state => {
  return {
    instrumentation_sub_section: state.userList.selected_entry
  };
};

export default connect(
    mapStateToProps,
    {
      push,
      modalShow,
      getInstrumentationSubSection,
      createInstrumentationEntry,
      deleteInstrumentationEntry,
      updateInstrumentationEntry,
      deleteInstrumentationSubSectionEntryImage
    })(Instrumentation);