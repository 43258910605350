import {DELETE_NOT_STRUCTURAL_SUB_ENTRY_IMAGE} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedPut from "../../apis/authorizedPut";

export default (notStructuralEntryId, notStructuralSubEntryId, imageId, callback) => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedPut(
          `/not_structural_entries/${notStructuralEntryId}/entry_image`,{not_structural_entry: {entry_id: notStructuralSubEntryId, image_id: imageId}}
      ),
      simpleSuccess(DELETE_NOT_STRUCTURAL_SUB_ENTRY_IMAGE),
      null,
      [],
      [callback]);
};