import React, {useEffect} from 'react';
import {Field, FieldArray, reduxForm} from "redux-form";
import {Button, Card, Col, Form, Row} from "antd";
import _ from 'lodash';
import {CreateTextField} from "../Helpers/Input/InputCreator";
import {maxLength50, minLength5} from "../Helpers/Input/validations";
import QuillTextArea from "../Helpers/QuillTextArea";

const NotStructuralForm = ({fields, change}) => {
  
  useEffect(() => {
    if (fields.length === 0)
      fields.push({});
  }, []);
  
  return (
    fields.map((not_structural_entries_attributes, index) => {
      return (
        <div key={index}>
          <Row gutter={16}>
            <Col xs={24} sm={24}>
              <Field label={'Titulo'}
                     name={`${not_structural_entries_attributes}.title`}
                     component={CreateTextField}
                     placeholder="Titulo"
                     validate={[minLength5, maxLength50]}
                     hasFeedback/>
            </Col>
            <Col xs={24} sm={24}>
              <Field label={'Contenido'}
                     name={`${not_structural_entries_attributes}.content`}
                     component={QuillTextArea}
                     change={change}/>
            </Col>
          </Row>
          <Row className={'p-20'}>
            <Button type={'primary float-right'} htmlType={'submit'}>Guardar</Button>
          </Row>
        </div>
      )
    })
  );
};

const NotStructuralEntryForm = ({handleSubmit, initialize, change, onSubmit, notStructuralEntry}) => {
  
  useEffect(() => {
    if (notStructuralEntry)
      initialize({not_structural_section: {not_structural_entries_attributes: [_.omit(notStructuralEntry, ['avatar'])]}});
  }, []);
  
  return (
    <Card title={'Elementos no estructurales'}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FieldArray name={'not_structural_section[not_structural_entries_attributes]'} component={NotStructuralForm}
                    props={{notStructuralEntry: notStructuralEntry, change: change}}/>
      </Form>
    </Card>
  );
};

export default reduxForm({
  form: 'not_structural_entry_form',
  touchOnChange: true
})(NotStructuralEntryForm);