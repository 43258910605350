import React from 'react';
import {Modal} from "antd";
import 'antd/lib/modal/style/css';
import MonthlyWeekEntryForm from "./MonthlyWeekEntryForm";
import {noPadding} from "../../const/styles";

class MonthlyWeekEntryModal extends React.Component {
  render() {
    const {modalType, modalProps} = this.props;
    return (
      <Modal bodyStyle={noPadding} footer={null} className={'content-wrapper'} visible={modalType !== null} style={{ top: 30 }}
             width={720} {...modalProps}>
        <MonthlyWeekEntryForm
          monthlyWeekEntry={this.props.monthlyWeekEntry}
          onSubmit={this.props.onSubmit}
        />
      </Modal>
    );
  }
}

export default MonthlyWeekEntryModal;