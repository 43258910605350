import {DELETE_SECURITY_ENTRY} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedDelete from "../../apis/authorizedDelete";

export default (securitySectionId, entryId) => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedDelete(`/security_sections/${securitySectionId}/entry/${entryId}`),
      simpleSuccess(DELETE_SECURITY_ENTRY));
};