import axios from 'axios';
import qs from 'qs';
import {urls} from "./urls";

const server = axios.create({
  baseURL: urls.production,
  headers: {
    common: {
      ContentType: 'application/json',
      Accept: 'aic.api.v1'
    }
  }
});

server.defaults.paramsSerializer = (params) => {
  return qs.stringify(params, {encode: false});
};

server.interceptors.response.use((response) => {
  return (response)
}, error => {
  return (error.response)
});

export default server;
