import React from "react";
import {Button, Card, Icon, Table} from 'antd';
import 'antd/lib/button/style/css';
import 'antd/lib/card/style/css';
import 'antd/lib/col/style/css';
import 'antd/lib/icon/style/css';
import 'antd/lib/input/style/css';
import 'antd/lib/row/style/css';
import 'antd/lib/table/style/css';
import {connect} from "react-redux";
import modalShow from '../../actions/modal/modalShow';
import {MONTHLY_VIEW} from "../../paths";
import {push} from 'connected-react-router';
import {RAIN_ENTRY_MODAL} from "../Feedback/Modal/types";
import {marginRight} from "../../const/styles";
import updateRainEntry from "../../actions/rainSection/updateRainEntry";
import createRainEntry from "../../actions/rainSection/createRainEntry";
import getRainSection from "../../actions/rainSection/getRainSection";
import deleteRainEntry from "../../actions/rainSection/deleteRainEntry";
import {confirmDelete} from "../../apis/authorizedDelete";

class Administrative extends React.Component {

  componentDidMount() {
    this.props.getRainSection(this.props.match.params.rain_id);
  };

  openModal = (entry, onSubmit) => {
    this.props.modalShow({
      modalType: RAIN_ENTRY_MODAL,
      modalProps: {rainEntry: entry, onSubmit: onSubmit}
    });
  };

  handleCreate = (formValues) => {
    this.props.createRainEntry(formValues, this.props.match.params.rain_id);
  };

  handleUpdate = (formValues) => {
    this.props.updateRainEntry(formValues, this.props.match.params.rain_id);
  };

  columns = () => {
    return [{
      title: 'Contenido',
      dataIndex: 'content',
      key: 'content',
      render: (text) => {
        return <Icon type={text && text.length > 15 ? 'check' : 'close'}/>;
      }
    }, {
      title: 'Imagen',
      dataIndex: 'avatar',
      key: 'avatar',
      render: (text) => {
        return <Icon type={text ? 'check' : 'close'}/>;
      }
    }, {
      title: 'Comentario',
      dataIndex: 'comment',
      key: 'comment',
      render: (text) => {
        return <Icon type={text && text.length > 0 ? 'check' : 'close'}/>;
      }
    }, {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record) => {
        return (
            <div>
              <Button onClick={() => this.openModal(record, this.handleUpdate)} type={"primary"}
                      htmlType={'button'}>
                <Icon type="eye"/>
              </Button>
              <Button
                onClick={() => confirmDelete(() => this.props.deleteRainEntry(this.props.match.params.rain_id, record.id))}
                type={"danger"}
                style={marginRight}
                htmlType={'button'}>
                <Icon type="delete"/>
              </Button>
            </div>
        );
      }
    }]
  };


  render() {
    return (
        <Card bodyStyle={{padding: 0}}
              extra={
                <div>
                  <Button htmlType={'button'} type={'ghost'} style={marginRight}
                          onClick={() => this.props.push(MONTHLY_VIEW(this.props.match.params.id))}>Volver</Button>
                </div>
              }
              title={'Sección administrativa'}>
              <Card title={'Entradas de lluvias'}
                    bodyStyle={{padding: 0}}
                    extra={
                      <Button type={'primary'}
                              htmlType={'button'}
                              onClick={() => this.openModal(null, this.handleCreate)}>
                        Agregar
                      </Button>
                    }
              >
                <Table scroll={{x: '100%'}}
                    dataSource={this.props.rainSection.kind === 'rain_entries' ? this.props.rainSection.entries : []}
                    rowKey={'id'} columns={this.columns()}/>
              </Card>
        </Card>
    );
  }
}


const mapStateToProps = state => {
  return {
    rainSection: state.userList.selected_entry
  };
};


export default connect(mapStateToProps, {
  push,
  modalShow,
  getRainSection,
  createRainEntry,
  deleteRainEntry,
  updateRainEntry
})(Administrative);