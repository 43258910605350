import React from 'react';
import {Card, Col, List, Row} from "antd";

export default ({findingEntry}) => {

  const infoList = [
    {
      title: 'Fecha inicio',
      description: findingEntry.start_date
    },
    {
      title: 'Responsable',
      description: findingEntry.responsible
    },
    {
      title: 'Impacto',
      description: findingEntry.impact
    },
    {
      title: 'Procedimiento',
      description: findingEntry.procedure
    }
  ];
  if (findingEntry.end_date) {
    infoList.push({title: 'Fecha fin', description: findingEntry.end_date})
    infoList.push({title: 'Hecho', description: findingEntry.fact})
  }

  const renderClosureImage = () => {
    if(findingEntry.end_date)
      return (
        <React.Fragment>
          <b>Cierre:</b><br/>
          <img alt={''} src={findingEntry.closing} className={'entry-img'}/>
        </React.Fragment>
      );
  };

  return (
    <Card title={findingEntry.reference}>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <List
            grid={{gutter: 8, xs: 2, md: 1}}
            dataSource={infoList}
            renderItem={(item) => {
              return (
                <List.Item>
                  <List.Item.Meta {...item}/>
                </List.Item>
              )
            }}/>
        </Col>
        <Col xs={24} md={12}>
          <b>Evidencia:</b><br/>
          <img alt={''} src={findingEntry.evidence} className={'entry-img'}/>
          <br/>
          {renderClosureImage()}
        </Col>
      </Row>
    </Card>
  );
}