import React from 'react';
import {connect} from "react-redux";
import modalShow from "../../actions/modal/modalShow";
import getClient from "../../actions/clients/getClient";
import getClients from "../../actions/clients/getClients";
import createClient from "../../actions/clients/createClient";
import updateClient from "../../actions/clients/updateClient";
import {CLIENTS_MODAL} from "../Feedback/Modal/types";
import {Button, Card, Icon, Row, Table} from "antd";
import 'antd/lib/button/style/css';
import 'antd/lib/card/style/css';
import 'antd/lib/col/style/css';
import 'antd/lib/icon/style/css';
import 'antd/lib/row/style/css';
import 'antd/lib/table/style/css';
import 'antd/lib/input/style/css';
import 'antd/lib/avatar/style/css';
import StatusHelper from "../Helpers/StatusHelper";

class Clients extends React.Component {

  componentDidMount() {
    this.props.getClients();
  }

  fetchClient = (userId) => {
    this.props.getClient(userId).then(() => this.openClientModal(
        this.props.userList.selected_entry,
        (formValues) => this.props.updateClient(formValues, userId)
    ));
  };

  openClientModal = (user, onSubmit) => {
    this.props.modalShow({
      modalType: CLIENTS_MODAL,
      modalProps: {user: user, onSubmit: onSubmit}
    });
  };

  columns = () => {
    return [{
      title: '#',
      dataIndex: 'id',
      key: 'id'
    }, {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name'
    }, {
      title: 'Usuario',
      dataIndex: 'username',
      key: 'username'
    }, {
      title: 'Estado',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => <StatusHelper type={'user'} position={'right'} status={record.status}/>
    }, {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record) => {
        return (
          <Button type={"primary"}
                  htmlType={'button'}
                  onClick={() => this.fetchClient(record.id)}>
            <span><Icon type="eye"/> Ver</span>
          </Button>
        );
      }
    }]
  };

  render() {
    return (
        <Card
            bodyStyle={{padding: 0}} title={'Clientes'}
            extra={
              <Button type={'primary'}
                      htmlType={'button'}
                      onClick={() => this.openClientModal(null, this.props.createClient)}>
                Agregar
              </Button>
            }
        >
          <Row>
            <Table scroll={{x: '100%'}}  dataSource={this.props.userList.kind === 'clients' ? this.props.userList.entries : []} rowKey={'id'} columns={this.columns()}/>
          </Row>
        </Card>
    );
  }
};

const mapStateToProps = state => {
  return {
    userList: state.userList
  };
};

export default connect(mapStateToProps, {modalShow, getClient, getClients, createClient, updateClient})(Clients);