import React, {useEffect} from 'react';
import {Field, FieldArray, reduxForm} from "redux-form";
import {Button, Card, Col, Form, Row} from "antd";
import _ from 'lodash';
import QuillTextArea from "../Helpers/QuillTextArea";

const RecommendationSubForm = ({fields, change}) => {
  
  useEffect(() => {
    if (fields.length === 0)
      fields.push({});
  }, []);
  
  return (
    fields.map((recommendation_sub_entries_attributes, index) => {
      return (
        <div key={index}>
          <Row gutter={16}>
            <Col xs={24} sm={24}>
              <Field label={'Contenido'}
                     name={`${recommendation_sub_entries_attributes}.content`}
                     component={QuillTextArea}
                     change={change} />
            </Col>
          </Row>
          <Row className={'p-20'}>
            <Button type={'primary float-right'} htmlType={'submit'}>Guardar</Button>
          </Row>
        </div>
      )
    })
  );
};

const RecommendationSubEntryForm = ({handleSubmit, initialize, change, onSubmit, recommendationSubEntry}) => {
  
  useEffect(() => {
    if (recommendationSubEntry)
      initialize({monthly_week_entry: {recommendation_sub_entries_attributes: [_.omit(recommendationSubEntry, ['avatar'])]}});
  }, []);
  
  return (
    <Card title={'Sub sección de recomendaciones'}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FieldArray name={'monthly_week_entry[recommendation_sub_entries_attributes]'} component={RecommendationSubForm}
                    props={{recommendationSubEntry: recommendationSubEntry, change: change}}/>
      </Form>
    </Card>
  );
};

export default reduxForm({
  form: 'recommendation_sub_entry_form',
  touchOnChange: true
})(RecommendationSubEntryForm);