import {UPDATE_NETWORK_SUB_ENTRY} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import {formDataHelper} from "../functions/formDataHelper";
import _ from "lodash";
import authorizedPut from "../../apis/authorizedPut";

export default (formValues, networkEntryId, callback) => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedPut(
          `/network_entries/${networkEntryId}/entry`,
          formDataHelper(
              formValues,
              'network_entry',
              _.times(2, (index) => {return {name: `image${index}`, key: '[network_sub_entries_attributes][][images][]'}})
          )
      ),
      simpleSuccess(UPDATE_NETWORK_SUB_ENTRY),
      null,
      [],
      [callback]);
};