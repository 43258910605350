import {CREATE_CONCRETE_TABLE} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedPost from "../../apis/authorizedPost";
import modalDismiss from "../modal/modalDismiss";
import {formDataHelper} from "../functions/formDataHelper";

export default (formValues, subSectionId) => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedPost(
          `/concrete_sub_sections/${subSectionId}/table`,
          formDataHelper(formValues, 'concrete_sub_section', [{name: 'avatar', key: '[concrete_tables_attributes][][avatar]'}])
      ),
      simpleSuccess(CREATE_CONCRETE_TABLE),
      null,
      [modalDismiss]);
};