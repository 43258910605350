import {UPDATE_PROJECT_DOCUMENT} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedPut from "../../apis/authorizedPut";
import {formDataHelper} from "../functions/formDataHelper";

export default (formValues, projectId, callback) => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedPut(`/projects/${projectId}/documents`, formDataHelper(formValues, 'project', [{name: 'archive', key: '[documents_attributes][][archive]'}])),
      simpleSuccess(UPDATE_PROJECT_DOCUMENT),
      null,
      [],
      [callback]
  );
};