import {DELETE_CONNECTORS_ENTRY} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedDelete from "../../apis/authorizedDelete";

export default (subSectionId, entryId) => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedDelete(`/connectors_sub_sections/${subSectionId}/entry/${entryId}`),
      simpleSuccess(DELETE_CONNECTORS_ENTRY));
};