import React from 'react';
import {connect} from "react-redux";
import modalShow from "../../actions/modal/modalShow";
import getRequirement from "../../actions/requirements/getRequirement";
import getRequirements from "../../actions/requirements/getRequirements";
import createRequirement from "../../actions/requirements/createRequirement";
import updateRequirement from "../../actions/requirements/updateRequirement";
import {REQUIREMENTS_MODAL} from "../Feedback/Modal/types";
import {Button, Card, Icon, Row, Table} from "antd";
import StatusHelper from "../Helpers/StatusHelper";

class Requirements extends React.Component {

  componentDidMount() {
    this.props.getRequirements();
  }

  fetchRequirement = (requirementId) => {
    this.props.getRequirement(requirementId).then(() => this.openRequirementModal(
        this.props.userList.selected_entry,
        (formValues) => this.props.updateRequirement(formValues, requirementId)
    ));
  };

  openRequirementModal = (requirement, onSubmit) => {
    this.props.modalShow({
      modalType: REQUIREMENTS_MODAL,
      modalProps: {requirement: requirement, onSubmit: onSubmit}
    });
  };

  columns = () => {
    return [{
      title: '#',
      dataIndex: 'id',
      key: 'id'
    }, {
      title: 'Titulo',
      dataIndex: 'name',
      key: 'name'
    }, {
      title: 'Estado',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => <StatusHelper type={'requirement'} position={'right'} status={record.status}/>
    }, {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record) => {
        return (
          <Button type={"primary"}
                  htmlType={'button'}
                  onClick={() => this.fetchRequirement(record.id)}>
            <span><Icon type="eye"/> Ver</span>
          </Button>
        );
      }
    }]
  };

  render() {
    return (
        <Card
            bodyStyle={{padding: 0}} title={'Requerimientos'}
            extra={
              <Button type={'primary'}
                      htmlType={'button'}
                      onClick={() => this.openRequirementModal(null, this.props.createRequirement)}>
                Agregar
              </Button>
            }
        >
          <Row>
            <Table scroll={{x: '100%'}}  dataSource={this.props.userList.kind === 'requirements' ? this.props.userList.entries : []} rowKey={'id'} columns={this.columns()}/>
          </Row>
        </Card>
    );
  }
};

const mapStateToProps = state => {
  return {
    userList: state.userList
  };
};

export default connect(mapStateToProps, {modalShow, getRequirement, getRequirements, createRequirement, updateRequirement})(Requirements);