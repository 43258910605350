import React from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const QuillTextArea = ({input, change, label, ...rest}) => {
  
  const handleChange = (value) => {
    change(input.name, value);
  };

  const modules = {
    toolbar : [
      [{ 'header': [1, 2, 3, false] }],
      ['bold', 'italic', 'underline'],
      [{ 'align': []}],
      [{'list': 'ordered'}, {'list': 'bullet'}],
      ['link']
    ]
  }
  
  return (
    <div>
      <label>{label}</label>
      <ReactQuill modules={modules} value={input.value} onChange={handleChange} />
    </div>
  );
  
}


export default QuillTextArea;
