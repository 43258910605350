import {FINISH_SECURITY_SECTION} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedPut from "../../apis/authorizedPut";

export default (securitySectionId) => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedPut(`/security_sections/${securitySectionId}/finish`),
    simpleSuccess(FINISH_SECURITY_SECTION)
  );
};