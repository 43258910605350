import React from 'react';
import {Modal} from "antd";
import NotStructuralEntryForm from "./NotStructuralEntryForm";
import NotStructuralEntryResume from "./NotStructuralEntryResume";
import {noPadding} from "../../const/styles";

class NotStructuralEntryModal extends React.Component {
  
  handleRender = () => {
    if (this.props.onSubmit) {
      return (
        <NotStructuralEntryForm
          notStructuralEntry={this.props.notStructuralEntry}
          onSubmit={this.props.onSubmit}
        />
      );
    } else {
      return (
        <NotStructuralEntryResume/>
      );
    }
  };
  
  render() {
    const {modalType, modalProps} = this.props;
    return (
      <Modal bodyStyle={noPadding} footer={null} className={'content-wrapper'} visible={modalType !== null} style={{ top: 30 }}
             width={720} {...modalProps}>
        {this.handleRender()}
      </Modal>
    );
  }
}

export default NotStructuralEntryModal;