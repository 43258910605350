import React from "react";
import {Button, Card, Col, Row} from 'antd';
import 'antd/lib/card/style/css';
import {connect} from "react-redux";
import './dashboard.css';
import changePassword from "../../../actions/members/changePassword";
import modalShow from "../../../actions/modal/modalShow";
import {PASSWORD_MODAL} from "../../Feedback/Modal/types";
import createAlert from "../../../actions/alerts/createAlert";
import {marginRight} from "../../../const/styles";
import getDashboard from "../../../actions/helpers/getDashboard";
import StatusHelper from "../../Helpers/StatusHelper";

class Dashboard extends React.Component {

  componentDidMount() {
    this.props.getDashboard();
  };

  changePassword = () => {
    this.props.modalShow({
      modalType: PASSWORD_MODAL,
      modalProps: {onSubmit: this.props.changePassword}
    });
  };

  renderExtra = () => {
    if (this.props.auth.admin) {
      return null
    } else {
      return (
        <div>
          {this.props.auth.exit || this.props.auth.client ? '' :
            <Button style={marginRight} onClick={this.props.createAlert} type={"ghost"} htmlType={'button'}>
              Registrar Salida
            </Button>}
          <Button onClick={this.changePassword} type={"primary"} htmlType={'button'}>
            Cambiar contraseña
          </Button>
        </div>
      )
    }
  };

  renderCards = () => {
    let dashboard = this.props.auth.dashboard;
    return (
      Object.keys(dashboard).map((property) => {
        if (dashboard.hasOwnProperty(property)){
          return (
              <Col className={'mb-10'} xs={24} md={12} lg={8} key={property}>
                <Card bodyStyle={{minHeight: 140}} title={dashboard[property].title}>
                  {this.renderElement(dashboard[property])}
                </Card>
              </Col>
          );
        }
        else return <React.Fragment/>;
      })
    );
  };

  renderElement = (element) => {
    switch (element.kind) {
      case 'status':
        return (
            <div>
              <StatusHelper type={'daily'} position={'top'} status={element.value || 'warning'}/>
              <b style={{marginLeft: 10}}>{this.translateStatus(element.value)}</b>
            </div>
        );
      case 'alert':
        return (
            <div>
              <StatusHelper type={'alert'} position={'top'} status={element.value || 'revising'}/>
              <b style={{marginLeft: 10}}>{this.translateAlert(element.value)}</b>
            </div>
        );
      case 'count_alert':
        return (
            <div>
              <StatusHelper type={'alert_count'} position={'top'} status={element.value > 0 ? 'warning' : 'finished'}/>
              <b style={{marginLeft: 10, fontSize: 20}}>{element.value}</b>
            </div>
        );
      case 'count':
        return (
            <div>
              <b style={{fontSize: 20}}>{element.value}</b>
            </div>
        );
      default:
        return <div/>;
    };
  };

  translateStatus = (status) =>{
    switch(status) {
      case 'unfinished':
        return 'EN PROCESO';
      case 'finished':
        return 'TERMINADO';
      case 'checking':
        return 'EN REVISION';
      case 'fixing':
        return 'EN PROCESO';
      case 'revising':
        return 'EN REVISION';
      default:
        return 'PENDIENTE';
    };
  };

  translateAlert = (status) =>{
    switch(status) {
      case 'clear':
        return 'EN AREA';
      case 'warning':
        return 'FUERA DE AREA';
      case 'dismissed':
        return 'VERIFICADA';
      default:
        return 'SIN MARCAR';
    };
  };
  
  render() {
    return (
      <Card title={'Dashboard'} extra={this.renderExtra()}>
        <Row gutter={16}>
          {this.renderCards()}
        </Row>
      </Card>
    );
  }
}


const mapStateToProps = state => {
  return {
    auth: state.auth
  };
};


export default connect(mapStateToProps, {changePassword, modalShow, createAlert, getDashboard})(Dashboard);