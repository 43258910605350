import {UPDATE_WEEKLY_SUGGESTION} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedPut from "../../apis/authorizedPut";

export default (weeklyReportId, formValues, callback) => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedPut(`/weekly_reports/${weeklyReportId}/suggestion`, formValues),
      simpleSuccess(UPDATE_WEEKLY_SUGGESTION),
      null,
      [],
      [callback]
  );
};