import {CHECK_MONTHLY_REPORT} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedPut from "../../apis/authorizedPut";
import {MONTHLY_PATH} from "../../paths";

export default (monthlyReportId) => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedPut(`/monthly_reports/${monthlyReportId}/check`),
      simpleSuccess(CHECK_MONTHLY_REPORT),
      MONTHLY_PATH
  );
};