import {UPDATE_BUDGET_ENTRY} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedPut from "../../apis/authorizedPut";
import modalDismiss from "../modal/modalDismiss";
import {formDataHelper} from "../functions/formDataHelper";

export default (formValues, tracingSectionId) => async (dispatch) => {
  await loadingCall(
    dispatch,
    authorizedPut(
      `/administrative_sections/${tracingSectionId}/budget_entry`,
      formDataHelper(formValues, 'administrative_section', [{name: 'avatar', key: '[budget_entries_attributes][][avatar]'}])
    ),
    simpleSuccess(UPDATE_BUDGET_ENTRY),
    null,
    [modalDismiss]
  );
};