import {CREATE_NOT_STRUCTURAL_ENTRY} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedPost from "../../apis/authorizedPost";
import modalDismiss from "../modal/modalDismiss";

export default (formValues, notStructuralSectionId) => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedPost(`/not_structural_sections/${notStructuralSectionId}/entry`, formValues),
      simpleSuccess(CREATE_NOT_STRUCTURAL_ENTRY),
      null,
      [modalDismiss]);
};