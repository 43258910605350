import {
  DELETE_INSTRUMENTATION_ENTRY_IMAGE
} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedPut from "../../apis/authorizedPut";

export default (instrumentationEntryId, instrumentationId, imageId, callback) => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedPut(
          `/instrumentation_sub_sections/${instrumentationId}/entry_image`,{instrumentation_sub_section: {entry_id: instrumentationEntryId, image_id: imageId}}
      ),
      simpleSuccess(DELETE_INSTRUMENTATION_ENTRY_IMAGE),
      null,
      [],
      [callback]);
};