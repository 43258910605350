import React from "react";
import {Field, Form, reduxForm} from "redux-form";
import {CreateFileField, CreateSelectField} from "../Helpers/Input/InputCreator";
import {fileImage, fileRequired, fileVerification, required} from "../Helpers/Input/validations";
import {Alert, Col, Row, Select} from "antd";

const ProjectDocs = ({onSubmit, handleSubmit, actions, project, change}) => {
  const renderStatusSelect = () => {
    if(project)
      return (
          <Col xs={24} md={12}>
            <Field label={'Estado'}
                   name="project[status]"
                   component={CreateSelectField}
                   validate={[required]}
                   hasFeedback>
              <Select.Option key={1} value={'active'}>Activo</Select.Option>
              <Select.Option key={2} value={'inactive'}>Inactivo</Select.Option>
            </Field>
          </Col>
      );
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Alert message={'Sube los logos del proyecto'}/>
      <Row gutter={16}>
        {renderStatusSelect()}
        <Col xs={24} sm={12}>
          <Field label={'Logo del supervisor'}
                 name={'supervisor_logo'}
                 preview={project ? project.supervisor_logo : null}
                 component={CreateFileField}
                 validate={project ? [fileVerification, fileImage] : [fileRequired, fileVerification, fileImage]}
                 change={change}
                 isImage
                 type='file'
                 hasFeedback
          />
        </Col>
        <Col xs={24} sm={12}>
          <Field label={'Logo del constructor'}
                 name={'constructor_logo'}
                 preview={project ? project.constructor_logo : null}
                 component={CreateFileField}
                 validate={project ? [fileVerification, fileImage] : [fileRequired, fileVerification, fileImage]}
                 change={change}
                 isImage
                 type='file'
                 hasFeedback
          />
        </Col>
        <Col xs={24} sm={12}>
          <Field label={'Logo del proyecto'}
                 name={'project_logo'}
                 preview={project ? project.project_logo : null}
                 component={CreateFileField}
                 validate={project ? [fileVerification, fileImage] : [fileRequired, fileVerification, fileImage]}
                 change={change}
                 isImage
                 type='file'
                 hasFeedback
          />
        </Col>
      </Row>
      {actions}
    </Form>
  );
};

export default reduxForm({
  form: 'projects_form',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  touchOnChange: true
})(ProjectDocs);