import {DELETE_RAIN_ENTRY} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedDelete from "../../apis/authorizedDelete";

export default (rainSectionId, entryId) => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedDelete(`/rain_sections/${rainSectionId}/entry/${entryId}`),
      simpleSuccess(DELETE_RAIN_ENTRY));
};