import React from 'react';
import {connect} from "react-redux";
import * as Paths from "../../../paths";
import {push} from 'connected-react-router';
import logOut from "../../../actions/inner/logOut";
import {createMenu} from "../../../components/Helpers/Menu/MenuCreator";
import logoLight from "../../../resources/img/logo-white.png";
import {Icon, Layout, Menu, Select} from "antd";
import './privateSider.css';
import changeTarget from "../../../actions/members/changeTarget";

let menus = {
  admin: [{
    title: 'Proyectos',
    path: Paths.PROJECTS_PATH,
    icon: ['fas', 'barcode']
  },{
    title: 'Usuarios',
    path: Paths.USERS_PATH,
    icon: ['fas', 'user']
  },{
    title: 'Clientes',
    path: Paths.CLIENTS_PATH,
    icon: ['fas', 'user-tie']
  },{
    title: 'Protocolos',
    path: Paths.PROTOCOLS_PATH,
    icon: ['fas', 'briefcase']
  },{
    title: 'Recomendaciones',
    path: Paths.RECOMMENDATIONS_PATH,
    icon: ['fas', 'road']
  },{
    title: 'Requerimientos',
    path: Paths.REQUIREMENTS_PATH,
    icon: ['fas', 'plus']
  }, {
    title: 'Mensuales',
    path: Paths.MONTHLY_PATH,
    icon: ['fas', 'calendar-check']
  }, {
    title: 'Alertas',
    path: Paths.ALERTS_PATH,
    icon: ['fas', 'bell']
  },{
    title: 'Fotos y Videos',
    path: Paths.PROJECTS_MEDIA_RESUME,
    icon: ['fas', 'camera']
  }],
  users: [{
    title: 'Diarios',
    path: Paths.DAILY_PATH,
    icon: ['fas', 'calendar-day']
  },{
    title: 'Semanales',
    path: Paths.WEEKLY_PATH,
    icon: ['fas', 'calendar-week']
  },{
    title: 'Mensuales',
    path: Paths.MONTHLY_PATH,
    icon: ['fas', 'calendar-check']
  },{
    title: 'Hallazgos',
    path: Paths.FINDINGS_PATH,
    icon: ['fas', 'search']
  },{
    title: 'Documentos',
    path: Paths.DOCUMENTS_PATH,
    icon: ['fas', 'file']
  },{
    title: 'Mensajes',
    path: Paths.CHAT_PATH,
    icon: ['fas', 'comments']
  },{
    title: 'Fotos y Videos',
    path: Paths.PROJECT_MEDIA_RESUME,
    icon: ['fas', 'camera']
  }]
};

class PrivateSider extends React.PureComponent {

  state = {
    fixed: false,
    collapsed: window.innerWidth < 780
  };
  
  changeFixedSider = brakepoint => {
    this.setState({
      fixed: brakepoint,
    });
  };
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.auth.logged !== this.props.auth.logged && this.props.auth.logged && window.innerWidth < 780){
      this.setState({collapsed: true})
    }
  }
  
  toggle = () => {
    if(window.innerWidth < 780)
      this.setState({
        collapsed: !this.state.collapsed,
      });
  };

  renderMenu = () => {
    if (this.props.auth.admin) {
      return createMenu(menus.admin, this.props.auth.status, this.props.push, this.toggle);
    } else {
      if(this.props.auth.target_id)
        return createMenu(menus.users, this.props.auth.status, this.props.push, this.toggle);
    }
  };
  
  renderProjectMenu = () => {
    if (!this.props.auth.admin && this.props.auth.target_id) {
      return (
        <Menu.Item className={'project-menu-item'}>
          <Select value={this.props.auth.target_id} onChange={this.props.changeTarget}>
            {this.props.config.available_projects.map((option, index) => (
              <Select.Option key={index} title={option[0]} value={option[1]}>{option[0]}</Select.Option>
            ))}
          </Select>
        </Menu.Item>
      );
    }
  };

  render = () => {
    const {auth, pathname, push, logOut} = this.props;
    let selected = `/${pathname.split('/')[1]}`;
    if(auth.logged) {
      return (
          <Layout.Sider
              className={'ant-layout-sider-fixed'}
              breakpoint="sm"
              collapsedWidth="0"
              trigger={null}
              collapsed={this.state.collapsed}
              onBreakpoint={(broken) => {
                this.changeFixedSider(broken);
              }}
          >
            <div onClick={this.toggle} className={'sider-wrapper'}/>
            {!this.state.collapsed &&
            <div onClick={this.toggle} className={'sider-wrapper d-xs-block d-sm-block d-md-block d-lg-none'}/>}
            <span onClick={this.toggle}
                  className={'ant-layout-sider-zero-width-trigger ant-layout-sider-zero-width-trigger-left d-xs-block d-sm-block d-md-block d-lg-none'}>
                <Icon type={'bars'}/>
            </span>
            <img src={logoLight} className={'w-100 p-10'} alt={''}/>
            <Menu
                theme="dark"
                mode="inline"
                title={'menu'}
                defaultSelectedKeys={['/']}
                selectedKeys={[selected]}
                style={{lineHeight: '64px'}}
            >
              <Menu.ItemGroup key="g1">
                {this.renderProjectMenu()}
                {this.props.auth.status === 'active' && (this.props.auth.admin || this.props.auth.target_id) &&
                  <Menu.Item key={Paths.ROOT_PATH} onClick={() => {push(Paths.ROOT_PATH); this.toggle()}}>
                    <span className={'menu-title'}><Icon type="area-chart"/>Dashboard</span>
                  </Menu.Item>}
                {this.props.auth.arrival || this.props.auth.admin || this.props.auth.client ? this.renderMenu() : ''}

                <Menu.Item className={'logout-menu-item'} key={'logout'} onClick={logOut}>
                  <Icon type="logout" />
                  <span className={'menu-title'}> Salir</span>
                </Menu.Item>
              </Menu.ItemGroup>
            </Menu>
          </Layout.Sider>
      );
    } else {
      return <React.Fragment/>
    }
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    config: state.config,
    pathname: state.router.location.pathname
  }
};

export default connect(mapStateToProps, {push, logOut, changeTarget})(PrivateSider);