import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import verifyToken from '../../actions/helpers/verifyToken';
import RoutesContainer from "./RoutesContainer";
import NotificationCreator from "../Feedback/Notification";
import LoaderContainer from '../Feedback/Loader';
import ModalContainer from '../Feedback/Modal';
import PrivateSider from '../../layouts/Private/PrivateSider';
import {withRouter} from "react-router-dom";
import {Layout} from "antd";
import {library} from '@fortawesome/fontawesome-svg-core';
import {fab} from '@fortawesome/free-brands-svg-icons';
import {fas} from '@fortawesome/free-solid-svg-icons';

library.add(fab, fas);

const AppContainer = ({verifyToken, logged}) => {

  useEffect(() => {
    verifyToken();
  }, []);
  
  return (
    <Layout className={'h-100 w-100'}>
      <ModalContainer/>
      <LoaderContainer/>
      <NotificationCreator/>
      <PrivateSider/>
      <Layout className={logged ? 'p-20' : ''}>
        <RoutesContainer/>
      </Layout>
    </Layout>
  );
  
};

const mapStateToProps = (state) => {
  return {
    logged: state.auth.logged
  }
};

export default withRouter(connect(mapStateToProps, {verifyToken})(AppContainer));