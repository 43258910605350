import React from "react";
import {Button, Card, Row, Table} from 'antd';
import {connect} from "react-redux";
import StatusHelper from "../Helpers/StatusHelper";
import getAlerts from "../../actions/alerts/getAlerts";
import updateAlert from "../../actions/alerts/updateAlert";
import {COORDINATES_MODAL} from "../Feedback/Modal/types";
import modalShow from "../../actions/modal/modalShow";
import {marginRight} from "../../const/styles";

class Monthly extends React.Component {
  
  componentDidMount() {
    this.props.getAlerts();
  };
  
  openCoordinate = (row) => {
    this.props.modalShow({
      modalType: COORDINATES_MODAL,
      modalProps: {record: row}
    });
  };
  
  columns = () => {
    return [{
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    }, {
      title: 'Tipo',
      dataIndex: 'kind',
      key: 'kind'
    }, {
      title: 'Proyecto',
      dataIndex: 'project',
      key: 'project'
    }, {
      title: 'Usuario',
      dataIndex: 'user',
      key: 'user'
    }, {
      title: 'Fecha',
      dataIndex: 'date',
      key: 'date'
    }, {
      title: 'Hora',
      dataIndex: 'time',
      key: 'time'
    }, {
      title: 'Estado',
      dataIndex: 'status',
      key: 'status',
      render: (text) => <StatusHelper type={'alert'} position={'top'} status={text}/>
    }, {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, row) => (
        <div>
          <Button type={'ghost'} style={marginRight} icon={'environment'} onClick={() => this.openCoordinate(row)}/>
          {row.status === 'warning' ?
            <Button type={'primary'} icon={'like'} onClick={() => this.props.updateAlert(row.id)}/> : null}
        </div>
      )
    }]
  };
  
  render() {
    return (
      <Card bodyStyle={{padding: 0}} title={'Alertas'}>
        <Row>
          <Table scroll={{x: '100%'}}  dataSource={this.props.userList.kind === 'alerts' ? this.props.userList.entries : []}
                 rowKey={'id'} columns={this.columns()}/>
        </Row>
      </Card>
    )
  }
}


const mapStateToProps = state => {
  return {
    userList: state.userList
  };
};

export default connect(mapStateToProps,
  {
    getAlerts,
    updateAlert,
    modalShow
  })(Monthly);