import {GET_RESULT_SECTION} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedGet from "../../apis/authorizedGet";

export default (resultSectionId) => async (dispatch) => {
  await loadingCall(
    dispatch,
    authorizedGet(`/result_sections/${resultSectionId}`),
    simpleSuccess(GET_RESULT_SECTION)
  );
};