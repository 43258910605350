import React from "react";
import {Button, Card, Col, Modal, Row} from 'antd';
import 'antd/lib/button/style/css';
import 'antd/lib/card/style/css';
import 'antd/lib/col/style/css';
import 'antd/lib/icon/style/css';
import 'antd/lib/input/style/css';
import 'antd/lib/row/style/css';
import 'antd/lib/table/style/css';
import {connect} from "react-redux";
import modalShow from '../../actions/modal/modalShow';
import {
  ANCHORS_VIEW,
  CONCRETE_VIEW,
  CONNECTORS_VIEW,
  FREE_VIEW,
  INSTRUMENTATION_VIEW,
  MONTHLY_VIEW,
  MORTARS_VIEW,
  STEELS_VIEW
} from "../../paths";
import {push} from 'connected-react-router';
import StatusHelper from "../Helpers/StatusHelper";
import _ from "lodash";
import getResultSection from "../../actions/resultSection/getResultSection";
import createSteels from "../../actions/resultSection/createSteels";
import createMortars from "../../actions/resultSection/createMortars";
import createInstruments from "../../actions/resultSection/createInstruments";
import createConnectors from "../../actions/resultSection/createConnectors";
import createAnchors from "../../actions/resultSection/createAnchors";
import createConcrete from "../../actions/resultSection/createConcrete";
import createFree from "../../actions/resultSection/createFree";
import deleteConcrete from "../../actions/resultSection/deleteConcrete";
import deleteMortars from "../../actions/resultSection/deleteMortars";
import deleteSteels from "../../actions/resultSection/deleteSteels";
import deleteAnchors from "../../actions/resultSection/deleteAnchors";
import deleteInstruments from "../../actions/resultSection/deleteInstruments";
import deleteConnectors from "../../actions/resultSection/deleteConnectors";
import deleteFree from "../../actions/resultSection/deleteFree";
import {marginRight} from "../../const/styles";

class Results extends React.Component {

  componentDidMount() {
    this.props.getResultSection(this.props.match.params.results_id);
  };

  renderState(property, id) {
    if (id === null) {
      return (
          <div>
            <h2>
              <StatusHelper type={'monthly_property'} status={'inactive'} position={'top'}/> No creado
            </h2>
            <Button htmlType={'button'} type={'primary'} onClick={() => this.confirmCreate(property)}>
              Crear sección
            </Button>
          </div>
      );
    } else  {
      return (
          <div>
            <h2>
              <StatusHelper type={'monthly_property'} status={'finished'} position={'top'}/> Creado
            </h2>
            <Button htmlType={'button'} type={'ghost'} onClick={() => this.handleShow(property, id)}>
              Ver sección
            </Button>
            <Button
              onClick={() => this.confirmDelete(property)}
              type={"danger"}
              style={marginRight}
              htmlType={'button'}>
              Eliminar
            </Button>
          </div>
      );
    }
  };
  
  confirmCreate = (property) => {
    Modal.confirm({
      title: 'Espera!',
      content: `Seguro que quieres crear la sección ${this.handleTitle(property)}?`,
      onOk: () => this.handleCreate(property),
      okText: 'Crear sección'
    })
  };
  
  confirmDelete = (property) => {
    Modal.confirm({
      title: 'Espera!',
      content: `Seguro que quieres eliminar la sección ${this.handleTitle(property)}?`,
      onOk: () => this.handleDelete(property),
      okText: 'Eliminar sección'
    })
  };

  handleCreate(property) {
    switch (property) {
      case 'concrete_sub_section':
        this.props.createConcrete(this.props.match.params.results_id);
        break;
      case 'mortars_sub_section':
        this.props.createMortars(this.props.match.params.results_id);
        break;
      case 'steels_sub_section':
        this.props.createSteels(this.props.match.params.results_id);
        break;
      case 'anchors_sub_section':
        this.props.createAnchors(this.props.match.params.results_id);
        break;
      case 'instrumentation_sub_section':
        this.props.createInstruments(this.props.match.params.results_id);
        break;
      case 'connectors_sub_section':
        this.props.createConnectors(this.props.match.params.results_id);
        break;
      case 'free_sub_section':
        this.props.createFree(this.props.match.params.results_id);
        break;
      default:
        return null;
    }
  }

  handleDelete(property) {
    switch (property) {
      case 'concrete_sub_section':
        this.props.deleteConcrete(this.props.match.params.results_id);
        break;
      case 'mortars_sub_section':
        this.props.deleteMortars(this.props.match.params.results_id);
        break;
      case 'steels_sub_section':
        this.props.deleteSteels(this.props.match.params.results_id);
        break;
      case 'anchors_sub_section':
        this.props.deleteAnchors(this.props.match.params.results_id);
        break;
      case 'instrumentation_sub_section':
        this.props.deleteInstruments(this.props.match.params.results_id);
        break;
      case 'connectors_sub_section':
        this.props.deleteConnectors(this.props.match.params.results_id);
        break;
      case 'free_sub_section':
        this.props.deleteFree(this.props.match.params.results_id);
        break;
      default:
        return null;
    }
  }

  handleShow(property, id) {
    switch (property) {
      case 'concrete_sub_section':
        this.props.push(CONCRETE_VIEW(this.props.match.params.id, this.props.match.params.results_id, id));
        break;
      case 'mortars_sub_section':
        this.props.push(MORTARS_VIEW(this.props.match.params.id, this.props.match.params.results_id, id));
        break;
      case 'steels_sub_section':
        this.props.push(STEELS_VIEW(this.props.match.params.id, this.props.match.params.results_id, id));
        break;
      case 'anchors_sub_section':
        this.props.push(ANCHORS_VIEW(this.props.match.params.id, this.props.match.params.results_id, id));
        break;
      case 'instrumentation_sub_section':
        this.props.push(INSTRUMENTATION_VIEW(this.props.match.params.id, this.props.match.params.results_id, id));
        break;
      case 'connectors_sub_section':
        this.props.push(CONNECTORS_VIEW(this.props.match.params.id, this.props.match.params.results_id, id));
        break;
      case 'free_sub_section':
        this.props.push(FREE_VIEW(this.props.match.params.id, this.props.match.params.results_id, id));
        break;
      default:
        return null;
    }
  }

  handleTitle(property) {
    switch (property) {
      case 'concrete_sub_section':
        return 'Concretos';
      case 'mortars_sub_section':
        return 'Morteros';
      case 'steels_sub_section':
        return 'Aceros';
      case 'anchors_sub_section':
        return 'Anclajes';
      case 'instrumentation_sub_section':
        return 'Instrumentación';
      case 'connectors_sub_section':
        return 'Conectores';
      case 'free_sub_section':
        return 'Sección libre';
      default:
        return null;
    }
  }

  renderCards() {
    let {resultSection} = this.props;
    if (resultSection.kind === 'result_section')
      return (
          Object.keys(_.omit(resultSection, ['id', 'status', 'kind'])).map((property) => {
            if (resultSection.hasOwnProperty(property))
              return (
                  <Col className={'mb-10'} xs={24} md={12} lg={8} key={property}>
                    <Card title={this.handleTitle(property)}>
                      {this.renderState(property, resultSection[property])}
                    </Card>
                  </Col>
              );
            else return ''
          })
      );
  }
  
  render() {
    return (
        <Card title={'Sección de resultados'}
              extra={
                <Button htmlType={'button'}
                        type={'ghost'}
                        onClick={() => this.props.push(MONTHLY_VIEW(this.props.match.params.id))}>Volver</Button>
              }>
          <Row gutter={8}>
            {this.renderCards()}
          </Row>
        </Card>
    );
  }
}

const mapStateToProps = state => {
  return {
    resultSection: state.userList.selected_entry
  };
};

export default connect(
    mapStateToProps,
    {
      push,
      modalShow,
      getResultSection,
      createConcrete,
      createAnchors,
      createConnectors,
      createInstruments,
      createMortars,
      createSteels,
      createFree,
      deleteConcrete,
      deleteMortars,
      deleteSteels,
      deleteAnchors,
      deleteInstruments,
      deleteConnectors,
      deleteFree,
    })(Results);