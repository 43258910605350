import React, {useEffect} from 'react';
import {Button, Form, Row} from "antd";
import 'antd/lib/button/style/css';
import 'antd/lib/card/style/css';
import 'antd/lib/col/style/css';
import 'antd/lib/form/style/css';
import 'antd/lib/row/style/css';
import {Field, FieldArray, reduxForm} from "redux-form";
import {CreateTextArea} from "../Helpers/Input/InputCreator";
import {maxLength500, minLength1, required} from "../Helpers/Input/validations";

const SuggestionForm = ({fields, reportStatus}) => {
  
  useEffect(() => {
    if (fields.length === 0)
      fields.push({});
  }, []);
  
  return (
    fields.map((suggestions_attributes, index) => {
      return (
        <div key={index}>
          <Field label={'Contenido'}
                 name={`${suggestions_attributes}.content`}
                 component={CreateTextArea}
                 placeholder="Contenido"
                 disabled={reportStatus === 'finished'}
                 validate={[required, minLength1, maxLength500]}
                 hasFeedback/>
          <Row className={'p-20'}>
            {reportStatus !== 'finished' && <Button type={'primary float-right'} htmlType={'submit'}>Guardar</Button>}
          </Row>
        </div>
      )
    })
  );
};

const DailySuggestionForm = ({handleSubmit, initialize, onSubmit, suggestion, reportStatus}) => {
  
  useEffect(() => {
    if (suggestion)
      initialize({daily_report: {suggestions_attributes: [suggestion]}});
  }, []);
  
  return (
    <Form onSubmit={handleSubmit(onSubmit)} className={'p-20'}>
      <FieldArray name={'daily_report[suggestions_attributes]'} reportStatus={reportStatus} component={SuggestionForm}/>
    </Form>
  );
};

export default reduxForm({
  form: 'daily_suggestion_form',
  touchOnChange: true
})(DailySuggestionForm);