import React from "react";
import {Button, Card, Col, Icon, Row, Table} from 'antd';
import 'antd/lib/button/style/css';
import 'antd/lib/card/style/css';
import 'antd/lib/col/style/css';
import 'antd/lib/icon/style/css';
import 'antd/lib/input/style/css';
import 'antd/lib/row/style/css';
import 'antd/lib/table/style/css';
import {connect} from "react-redux";
import modalShow from '../../actions/modal/modalShow';
import {CONCEPT_ENTRY_MODAL} from "../Feedback/Modal/types";
import getConceptSection from "../../actions/conceptSection/getConceptSection";
import {MONTHLY_VIEW} from "../../paths";
import {push} from 'connected-react-router';
import createConceptEntry from "../../actions/conceptSection/createConceptEntry";
import updateConceptEntry from "../../actions/conceptSection/updateConceptEntry";
import {marginRight} from "../../const/styles";
import deleteConceptEntry from "../../actions/conceptSection/deleteConceptEntry";
import {confirmDelete} from "../../apis/authorizedDelete";

class Concept extends React.Component {
  
  componentDidMount() {
    this.props.getConceptSection(this.props.match.params.concept_id);
  };
  
  handleCreate = (formValues) => {
    this.props.createConceptEntry(formValues, this.props.match.params.concept_id);
  };
  
  handleUpdate = (formValues) => {
    this.props.updateConceptEntry(formValues, this.props.match.params.concept_id);
  };
  
  openEntryModal = (entry, onSubmit) => {
    this.props.modalShow({
      modalType: CONCEPT_ENTRY_MODAL,
      modalProps: {conceptEntry: entry, onSubmit: onSubmit}
    });
  };
  
  columns = () => {
    return [{
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    }, {
      title: 'Titulo',
      dataIndex: 'title',
      key: 'title',
      render: (text) => {
        return <Icon type={text && text.length > 0 ? 'check' : 'close'}/>;
      }
    }, {
      title: 'Contenido',
      dataIndex: 'content',
      key: 'content',
      render: (text) => {
        return <Icon type={text && text.length > 15 ? 'check' : 'close'}/>;
      }
    }, {
      title: 'Imagen',
      dataIndex: 'avatar',
      key: 'avatar',
      render: (text) => {
        return <Icon type={text ? 'check' : 'close'}/>;
      }
    }, {
      title: 'Comentario',
      dataIndex: 'comment',
      key: 'comment',
      render: (text) => {
        return <Icon type={text && text.length > 0 ? 'check' : 'close'}/>;
      }
    }, {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record) => {
        return (
          <div>
            <Button onClick={() => this.openEntryModal(record, this.handleUpdate)} type={"primary"} htmlType={'button'}>
              <Icon type="eye"/>
            </Button>
            <Button onClick={() => confirmDelete(() => this.props.deleteConceptEntry(this.props.match.params.concept_id, record.id))}
                    type={"danger"}
                    htmlType={'button'}>
              <Icon type="delete"/>
            </Button>
          </div>
        );
      }
    }]
  };
  
  render() {
    return (
      <Card bodyStyle={{padding: 0}}
            extra={<Button htmlType={'button'} type={'ghost'}
                           onClick={() => this.props.push(MONTHLY_VIEW(this.props.match.params.id))}>Volver</Button>}
            title={'Sección de conceptos'}
            >
        <Row gutter={16} className={'p-10'}>
          <Col span={24}>
            <Button type={'primary float-right'}
                    htmlType={'button'} style={marginRight}
                    onClick={() => this.openEntryModal(null, this.handleCreate)}>
              Agregar
            </Button>
          </Col>
        </Row>
        <Row>
          <Table scroll={{x: '100%'}}
            dataSource={this.props.conceptSection.kind === 'concept_entries' ? this.props.conceptSection.entries : []}
            rowKey={'id'} columns={this.columns()}/>
        </Row>
      </Card>
    );
  }
}


const mapStateToProps = state => {
  return {
    conceptSection: state.userList.selected_entry
  };
};


export default connect(mapStateToProps, {
  push,
  modalShow,
  getConceptSection,
  createConceptEntry,
  updateConceptEntry,
  deleteConceptEntry
})(Concept);