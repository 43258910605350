import {CREATE_PROJECT} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import {formDataHelper} from "../functions/formDataHelper";
import authorizedPost from "../../apis/authorizedPost";
import modalDismiss from "../modal/modalDismiss";

export default (formValues) => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedPost(
        '/projects',
        formDataHelper(
          formValues,
          'project',
          [
            {name: 'supervisor_logo', key: '[supervisor_logo]'},
            {name: 'constructor_logo', key: '[constructor_logo]'},
            {name: 'project_logo', key: '[project_logo]'}
          ]
        )
      ),
      simpleSuccess(CREATE_PROJECT),
      null,
      [modalDismiss]
  );
};