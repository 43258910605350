import React, {useEffect} from 'react';
import {Field, FieldArray, reduxForm} from "redux-form";
import {Button, Card, Col, Form, Row} from "antd";
import _ from 'lodash';
import {CreateFileField, CreateTextArea} from "../Helpers/Input/InputCreator";
import {fileImage, fileVerification,} from "../Helpers/Input/validations";
import {marginRight} from "../../const/styles";
import QuillTextArea from "../Helpers/QuillTextArea";

const NotStructuralSubForm = ({fields, change, notStructuralSubEntry}) => {
  
  useEffect(() => {
    if (fields.length === 0)
      fields.push({});
  }, []);
  
  return (
    fields.map((not_structural_sub_entries_attributes, index) => {
      return (
        <div key={index}>
          <Row gutter={16}>
            <Col xs={24} sm={24}>
              <Field label={'Contenido'}
                     name={`${not_structural_sub_entries_attributes}.content`}
                     component={QuillTextArea}
                     change={change} />
            </Col>
            <Col xs={24} sm={24}>
              <Field label={'Comentario de las imagenes'}
                     name={`${not_structural_sub_entries_attributes}.comment`}
                     component={CreateTextArea}
                     placeholder="Comentario de las imagenes"
                     hasFeedback />
            </Col>
          </Row>
          <Row gutter={16}>
            {_.times(notStructuralSubEntry && notStructuralSubEntry.images ? 2 - notStructuralSubEntry.images.length : 2, (index) => {
              return (
                <Col xs={24} sm={12} key={index}>
                  <Field label={`Imagen ${index + 1} (Opcional)`}
                         name={`image${index}`}
                         preview={null}
                         component={CreateFileField}
                         validate={[fileVerification, fileImage]}
                         change={change}
                         isImage
                         type='file'
                         hasFeedback
                  />
                </Col>
              );
            })}
          </Row>
          <Row className={'p-20'}>
            <Button type={'primary float-right'} htmlType={'submit'}>Guardar</Button>
          </Row>
        </div>
      )
    })
  );
};

const NotStructuralSubEntryForm = ({handleSubmit, initialize, change, onSubmit, notStructuralSubEntry, handleReturn}) => {
  
  useEffect(() => {
    if (notStructuralSubEntry)
      initialize({not_structural_entry: {not_structural_sub_entries_attributes: [_.omit(notStructuralSubEntry, ['images'])]}});
  }, []);
  
  return (
    <Card title={'Sub entrada elemento no estructural'}
          extra={<Button htmlType={'button'} style={marginRight} type={'ghost'} onClick={handleReturn}>Volver</Button>}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FieldArray name={'not_structural_entry[not_structural_sub_entries_attributes]'}
                    component={NotStructuralSubForm}
                    props={{notStructuralSubEntry: notStructuralSubEntry, change: change}}/>
      </Form>
    </Card>
  );
};

export default reduxForm({
  form: 'not_structural_sub_entry_form',
  touchOnChange: true
})(NotStructuralSubEntryForm);