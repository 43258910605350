import React, {useEffect} from 'react';
import {Field, FieldArray, reduxForm} from "redux-form";
import {Button, Card, Col, Form, Row} from "antd";
import 'antd/lib/button/style/css';
import 'antd/lib/card/style/css';
import 'antd/lib/col/style/css';
import 'antd/lib/form/style/css';
import 'antd/lib/row/style/css';
import _ from 'lodash';
import {CreateFileField, CreateTextArea} from "../Helpers/Input/InputCreator";
import {fileImage, fileVerification} from "../Helpers/Input/validations";
import {marginRight} from "../../const/styles";
import QuillTextArea from "../Helpers/QuillTextArea";

const ElementSubSubForm = ({fields, change, elementSubSubEntry}) => {
  
  useEffect(() => {
    if (fields.length === 0)
      fields.push({});
  }, []);
  
  return (
    fields.map((element_sub_sub_entries_attributes, index) => {
      return (
        <div key={index}>
          <Row gutter={16}>
            <Col xs={24} sm={24}>
              <Field label={'Contenido'}
                     name={`${element_sub_sub_entries_attributes}.content`}
                     component={QuillTextArea}
                     change={change} />
            </Col>
            <Col xs={24} sm={24}>
              <Field label={'Comentario para la foto'}
                     name={`${element_sub_sub_entries_attributes}.comment`}
                     component={CreateTextArea}
                     placeholder="Comentario para la foto"
                     hasFeedback/>
            </Col>
          </Row>
          <Row gutter={16}>
            {_.times(elementSubSubEntry && elementSubSubEntry.images ? 2 - elementSubSubEntry.images.length : 2, (index) => {
              return (
                <Col xs={24} sm={12} key={index}>
                  <Field label={`Imagen ${index + 1} (Opcional)`}
                         name={`image${index}`}
                         preview={null}
                         component={CreateFileField}
                         validate={[fileVerification, fileImage]}
                         change={change}
                         isImage
                         type='file'
                         hasFeedback
                  />
                </Col>
              );
            })}
          </Row>
          <Row className={'p-20'}>
            <Button type={'primary float-right'} htmlType={'submit'}>Guardar</Button>
          </Row>
        </div>
      )
    })
  );
};

const ElementSubSubEntryForm = ({handleSubmit, initialize, change, onSubmit, elementSubSubEntry, handleReturn}) => {
  
  useEffect(() => {
    if (elementSubSubEntry)
      initialize({element_sub_entry: {element_sub_sub_entries_attributes: [_.omit(elementSubSubEntry, ['images'])]}});
  }, []);
  
  return (
    <Card title={'Sub sub entrada de elementos'}
          extra={<Button htmlType={'button'} style={marginRight} type={'ghost'} onClick={handleReturn}>Volver</Button>}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FieldArray name={'element_sub_entry[element_sub_sub_entries_attributes]'} component={ElementSubSubForm}
                    props={{elementSubSubEntry: elementSubSubEntry, change: change}}/>
      </Form>
    </Card>
  );
};

export default reduxForm({
  form: 'element_sub_sub_entry_form',
  touchOnChange: true
})(ElementSubSubEntryForm);