import React from "react";
import {Button, Card, Icon, Table} from 'antd';
import 'antd/lib/button/style/css';
import 'antd/lib/card/style/css';
import 'antd/lib/col/style/css';
import 'antd/lib/icon/style/css';
import 'antd/lib/input/style/css';
import 'antd/lib/row/style/css';
import 'antd/lib/table/style/css';
import {connect} from "react-redux";
import modalShow from '../../actions/modal/modalShow';
import {FREE_ENTRY_MODAL} from "../Feedback/Modal/types";
import {RESULTS_VIEW} from "../../paths";
import {push} from 'connected-react-router';
import getFreeSubSection from "../../actions/freeSubSection/getFreeSubSection";
import createFreeEntry from "../../actions/freeSubSection/createFreeEntry";
import updateFreeEntry from "../../actions/freeSubSection/updateFreeEntry";
import deleteFreeSubSectionEntryImage from "../../actions/freeSubSection/deleteFreeSubSectionEntryImage";
import deleteFreeEntry from "../../actions/freeSubSection/deleteFreeEntry";
import {confirmDelete} from "../../apis/authorizedDelete";

class Free extends React.Component {
  
  componentDidMount() {
    this.props.getFreeSubSection(this.props.match.params.free_id);
  };
  
  openEntryModal = (entry, onSubmit) => {
    this.props.modalShow({
      modalType: FREE_ENTRY_MODAL,
      modalProps: {freeEntry: entry, onSubmit: onSubmit, deleteEntryImage: this.props.deleteFreeSubSectionEntryImage}
    });
  };
  
  handleEntryCreate = (formValues) => {
    this.props.createFreeEntry(formValues, this.props.match.params.free_id);
  };
  
  handleEntryUpdate = (formValues) => {
    this.props.updateFreeEntry(formValues, this.props.match.params.free_id);
  };
  
  entryColumns = () => {
    return [{
      title: 'Titulo',
      dataIndex: 'title',
      key: 'title',
      render: (text) => {
        return <Icon type={text && text.length > 0 ? 'check' : 'close'}/>;
      }
    }, {
      title: 'Contenido',
      dataIndex: 'content',
      key: 'content',
      render: (text) => {
        return <Icon type={text && text.length > 15 ? 'check' : 'close'}/>;
      }
    }, {
      title: 'Imagenes',
      dataIndex: 'images',
      key: 'images',
      render: (text, record) => {
        return record.images.length;
      }
    }, {
      title: 'Comentario',
      dataIndex: 'comment',
      key: 'comment',
      render: (text) => {
        return <Icon type={text && text.length > 0 ? 'check' : 'close'}/>;
      }
    }, {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record) => {
        return (
          <div>
            <Button onClick={() => this.openEntryModal(record, this.handleEntryUpdate)} type={"primary"}
                    htmlType={'button'}>
              <Icon type="eye"/>
            </Button>
            <Button onClick={() => confirmDelete(() => this.props.deleteFreeEntry(this.props.match.params.free_id, record.id))}
                    type={"danger"}
                    htmlType={'button'}>
              <Icon type="delete"/>
            </Button>
          </div>
        );
      }
    }]
  };
  
  render() {
    return (
      <Card title={'Sección libre'}
            bodyStyle={{padding: 0}}
            extra={
              <div>
                <Button type={'primary'}
                        htmlType={'button'}
                        onClick={() => this.openEntryModal(null, this.handleEntryCreate)}>
                  Agregar
                </Button>
                <Button htmlType={'button'}
                        type={'ghost'}
                        onClick={() => this.props.push(RESULTS_VIEW(this.props.match.params.id, this.props.match.params.results_id))}>Volver</Button>
              </div>
            }>
        <Table scroll={{x: '100%'}}
               dataSource={this.props.free_sub_section.kind === 'free_entries' ? this.props.free_sub_section.entries : []}
               rowKey={'id'} columns={this.entryColumns()}/>
      </Card>
    );
  }
}

const mapStateToProps = state => {
  return {
    free_sub_section: state.userList.selected_entry
  };
};

export default connect(
  mapStateToProps,
  {
    push,
    modalShow,
    getFreeSubSection,
    createFreeEntry,
    deleteFreeEntry,
    updateFreeEntry,
    deleteFreeSubSectionEntryImage
  })(Free);