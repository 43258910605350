import React from "react";
import {connect} from "react-redux";
import {Alert, Button, Card, Col, Form, Row} from "antd";
import getMessages from "../../../actions/messages/getMessages";
import createMessage from "../../../actions/messages/createMessage";
import {Field, formValueSelector, reduxForm} from "redux-form";
import {CreateTextArea} from "../../Helpers/Input/InputCreator";
import {maxLength300, required} from "../../Helpers/Input/validations";
import {MULTIMEDIA_MODAL} from "../../Feedback/Modal/types";
import modalShow from "../../../actions/modal/modalShow";

class UsersChat extends React.Component {
  componentDidMount() {
    this.interval = setInterval(() => {
      this.getMessages();
    }, 5000);
    this.getMessages();
  }

  getMessages = () => {
    this.props.getMessages(this.props.projectId).then(() => {
      this.scrollToBottom();
    });
  };

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  componentWillUpdate(nextProps, nextState, nextContext) {
    this.scrollToBottom();
  }

  scrollToBottom = () => {
    this.messagesEnd.scrollIntoView({behavior: "smooth"});
  };

  sendMessage = (formValues) => {
    this.props.createMessage(formValues, this.props.projectId).then(() => {
      this.props.reset();
    });
  };

  openMultimedia = () => {
    this.props.modalShow({
      modalType: MULTIMEDIA_MODAL,
      modalProps: {onSubmit: this.sendMessage, message: this.props.message}
    });
  };

  renderDescription = (message) => {
    return (
      <div>
        <p>
          {message.content}
        </p>
        {message.images && message.images.length > 0 ?
          <React.Fragment>
            {message.images.map((image, index) => {
              return (
                  <span key={index}><a target={'blank'} href={image.url}>Archivo {index + 1}</a>{'\t '} {' '}{' '}  </span>
              )
            })}
          </React.Fragment> : ''
        }
      </div>
    )
  };

  render() {
    const {handleSubmit, messages} = this.props;

    return (
      <Card style={{backgroundColor: 'transparent', border: 'none', height: '100%', margin: 0}}
            bodyStyle={{padding: 0}}>
        <div className={'scroll-container'}>
          <Row className={'messages-container'}>
            {messages.entries.map(message => (
              <Col key={message.id} span={24}>
                <Alert
                  message={message.name}
                  description={this.renderDescription(message)}
                  type={message.owner ? "success" : 'info'}
                  className={message.owner ? 'float-right' : 'float-left'}
                />
              </Col>
            ))}
            <div style={{float: "left", clear: "both"}}
                 ref={(el) => {
                   this.messagesEnd = el;
                 }}>
            </div>
          </Row>
        </div>
        <Row className={'chat-input'} gutter={4}>
          <Form onSubmit={handleSubmit(this.sendMessage.bind(this))}>
            <Row gutter={8}>
              <Col xs={18}>
                <Field name={`message[content]`}
                       component={CreateTextArea}
                       placeholder="Mensaje"
                       validate={[required, maxLength300]}
                       hasFeedback/>
              </Col>
              <Col xs={6}>
                <Button onClick={this.openMultimedia} htmlType={'button'} type={'ghost'} icon={'file'}/>
                <Button htmlType={'submit'} type={'primary'}>Enviar</Button>
              </Col>
            </Row>
          </Form>
        </Row>
      </Card>
    );
  }
}

const selector = formValueSelector('chat_form');

const mapStateToProps = state => {
  return {
    messages: state.userList,
    message: selector(state, 'message')
  };
};


export default connect(mapStateToProps, {getMessages, createMessage, modalShow})(reduxForm({
  form: 'chat_form'
})(UsersChat));