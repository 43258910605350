import {CREATE_DAILY_REPORT} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedPost from "../../apis/authorizedPost";
import modalDismiss from "../modal/modalDismiss";

export default () => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedPost('/daily_reports'),
      simpleSuccess(CREATE_DAILY_REPORT),
      null,
      [modalDismiss]
  );
};