import {CREATE_MESSAGE} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import {formDataHelper} from "../functions/formDataHelper";
import authorizedPost from "../../apis/authorizedPost";
import modalDismiss from "../modal/modalDismiss";
import _ from "lodash";

export default (formValues, projectId = null) => async (dispatch) => {
  const formData = formDataHelper(
    formValues,
    'message',
    _.times(2, (index) => {
      return {name: `image${index}`, key: '[images][]'}
    })
  );
  formData.append('project_id', projectId);
  await loadingCall(
    dispatch,
    authorizedPost(`/messages`, formData),
    simpleSuccess(CREATE_MESSAGE),
    null,
    [modalDismiss]
  );
};