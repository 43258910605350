import {CREATE_DAILY_SUGGESTION} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedPost from "../../apis/authorizedPost";

export default (dailyReportId, formValues, callback) => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedPost(`/daily_reports/${dailyReportId}/suggestion`, formValues),
      simpleSuccess(CREATE_DAILY_SUGGESTION),
      null,
      [],
      [callback]
  );
};