import React from "react";
import {Button, Card, Col, Icon, Row, Table} from 'antd';
import {connect} from "react-redux";
import getMonthlyReportsPanel from "../../actions/monthlyReports/getMonthlyReportsPanel";
import StatusHelper from "../Helpers/StatusHelper";
import createMonthlyReport from "../../actions/monthlyReports/createMonthlyReport";
import {push} from 'connected-react-router';
import moment from "moment";
import {MONTHLY_VIEW} from "../../paths";
import modalShow from "../../actions/modal/modalShow";
import getMonthlyReport from "../../actions/monthlyReports/getMonthlyReport";
import {CORRECTIONS_MODAL} from "../Feedback/Modal/types";

export const MonthlyIndexList = ({data, generateMonthlyReport}) => {
  
  const adminColumns = () => {
    return [{
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    }, {
      title: 'Fecha',
      dataIndex: 'date',
      key: 'date',
      render: (text) => moment(text).format('MM/YYYY')
    }, {
      title: 'Estado',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => <StatusHelper type={'monthly'} position={'right'} status={record.status}/>
    }, {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record) => {
        return (
          <div>
            <Button htmlType={'button'} type={'ghost'}
                    onClick={() => ["unfinished", "fixing"].includes(record.status) ?  generateMonthlyReport(record.id) : window.open(record.document)}>
              <Icon type="file-pdf"/>
            </Button>
          </div>
        );
      }
    }]
  };
  
  return (
    <Table scroll={{x: '100%'}} dataSource={data.kind === 'monthly_reports' ? data.entries : []}
           rowKey={'id'} columns={adminColumns()}/>
  )
};

class Monthly extends React.Component {
  
  componentDidMount() {
    this.props.getMonthlyReportsPanel();
  };
  
  openCorrectionsModal = (monthlyReportId) => {
    this.props.getMonthlyReport(monthlyReportId).then(() => {
      this.props.modalShow({
        modalType: CORRECTIONS_MODAL,
        modalProps: {}
      });
    });
  };
  
  columns = () => {
    return [{
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    }, {
      title: 'Fecha',
      dataIndex: 'date',
      key: 'date',
      render: (text) => moment(text).format('MM/YYYY')
    }, {
      title: 'Estado',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => <StatusHelper type={'monthly'} position={'right'} status={record.status}/>
    }, {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record) => {
        return (
          <div>
            {this.renderShowButton(record)}
            {this.renderViewButton(record)}
          </div>
        );
      }
    }]
  };
  
  adminColumns = () => {
    return [{
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    }, {
      title: 'Proyecto',
      dataIndex: 'project',
      key: 'project',
    }, {
      title: 'Fecha',
      dataIndex: 'date',
      key: 'date',
      render: (text) => moment(text).format('MM/YYYY')
    }, {
      title: 'Estado',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => <StatusHelper type={'monthly'} position={'right'} status={record.status}/>
    }, {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record) => {
        return (
          <div>
            <Button htmlType={'button'} type={'ghost'} onClick={() => window.open(record.document)}>
              <Icon type="file-pdf"/>
            </Button>
            {this.renderCorrectionsButton(record)}
          </div>
        );
      }
    }]
  };

  renderShowButton = (monthlyReport) => {
    if (!this.props.client && (monthlyReport.status === 'unfinished' || monthlyReport.status === 'fixing'))
      return (
        <Button onClick={() => this.props.push(MONTHLY_VIEW(monthlyReport.id))} type={"primary"} htmlType={'button'}>
          <Icon type="eye"/>
        </Button>
      );
  };
  
  renderCorrectionsButton = (monthlyReport) => {
    if (monthlyReport.status !== 'fixing')
      return (
        <Button htmlType={'button'} type={'primary'} onClick={() => this.openCorrectionsModal(monthlyReport.id)}>
          <Icon type="highlight"/>
        </Button>
      );
  };
  
  renderViewButton = (monthlyReport) => {
    if (monthlyReport.status === 'finished')
      return (
        <Button htmlType={'button'} type={'ghost'} onClick={() => window.open(monthlyReport.document)}>
          <Icon type="file-pdf"/>
        </Button>
      );
  };
  
  renderAddButton = () => {
    if (!this.props.client)
      return (
        <Col span={8}>
          <Button type={'primary'}
                  htmlType={'button'}
                  onClick={this.props.createMonthlyReport}>
            Agregar
          </Button>
        </Col>
      );
  };
  
  renderTable = () => {
    if (this.props.admin) {
      return (
        <Card bodyStyle={{padding: 0}} title={'Reportes Mensuales Vigentes'}>
          <Row>
            <Table scroll={{x: '100%'}}  dataSource={this.props.userList.kind === 'monthly_reports' ? this.props.userList.entries : []}
                   rowKey={'id'} columns={this.adminColumns()}/>
          </Row>
        </Card>)
    } else {
      return (
        <Card bodyStyle={{padding: 0}} title={'Reportes Mensuales'} extra={this.renderAddButton()}>
          <Row>
            <Table scroll={{x: '100%'}}  dataSource={this.props.userList.kind === 'monthly_reports' ? this.props.userList.entries : []}
                   rowKey={'id'} columns={this.columns()}/>
          </Row>
        </Card>
      );
    }
  };
  
  render() {
    return this.renderTable();
  }
}


const mapStateToProps = state => {
  return {
    admin: state.auth.admin,
    client: state.auth.client,
    userList: state.userList
  };
};


export default connect(mapStateToProps,
  {
    getMonthlyReportsPanel,
    createMonthlyReport,
    getMonthlyReport,
    push,
    modalShow
  })(Monthly);