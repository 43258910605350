import {DELETE_NOT_STRUCTURAL_ENTRY} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedDelete from "../../apis/authorizedDelete";

export default (notStructuralSectionId, entryId) => async (dispatch) => {
  await loadingCall(
      dispatch,
    authorizedDelete(`/not_structural_sections/${notStructuralSectionId}/entry/${entryId}`),
      simpleSuccess(DELETE_NOT_STRUCTURAL_ENTRY));
};