import {CREATE_FREE} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedPost from "../../apis/authorizedPost";

export default (resultSectionId) => async (dispatch) => {
  await loadingCall(
    dispatch,
    authorizedPost(`/result_sections/${resultSectionId}/free`),
    simpleSuccess(CREATE_FREE)
  );
};