import {UPDATE_DAILY_SUGGESTION} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedPut from "../../apis/authorizedPut";

export default (dailyReportId, formValues, callback) => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedPut(`/daily_reports/${dailyReportId}/suggestion`, formValues),
      simpleSuccess(UPDATE_DAILY_SUGGESTION),
      null,
      [],
      [callback]
  );
};