import {CREATE_ALERT, LOAD_START} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedPost from "../../apis/authorizedPost";
import getDashboard from "../helpers/getDashboard";

export default () => async (dispatch) => {
  
  dispatch({type: LOAD_START});
  navigator.geolocation.getCurrentPosition(async (position) => {
    await loadingCall(
      dispatch,
      authorizedPost(
        `/alerts`,
        {coordinate_attributes: {lat: position.coords.latitude, lng: position.coords.longitude}}
      ),
      simpleSuccess(CREATE_ALERT),
      null,
      [getDashboard]
    );
  });
  
};