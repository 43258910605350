import {CHANGE_TARGET, CREATE_ALERT, GET_DASHBOARD, LOG_IN, LOG_OUT, VERIFY_TOKEN} from "../actions/types";

const INITIAL_STATE = {
  logged: false,
  jwt_token: null,
  admin: false,
  status: '',
  client: true,
  username: '',
  env: '',
  target_id: null,
  target_title: null,
  arrival: false,
  exit: false,
  dashboard: {}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case VERIFY_TOKEN:
    case LOG_IN:
    case CHANGE_TARGET:
    case CREATE_ALERT:
    case GET_DASHBOARD:
      return {...state, ...action.payload.auth};
    case LOG_OUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};