import React from 'react';
import {Modal} from "antd";
import 'antd/lib/modal/style/css';

class RecommendationViewModal extends React.Component {

  render() {
    const {modalType, modalProps, record} = this.props;
    return (
      <Modal footer={null} className={'content-wrapper'} visible={modalType !== null} style={{ top: 30 }} width={720} {...modalProps}>
        <h2>{record.name}</h2>
        <div dangerouslySetInnerHTML={{ __html: record.content}} />
        <img src={record.avatar} alt={'Imagen'} style={{width: '100%'}} />
      </Modal>
    );
  };
};


export default RecommendationViewModal;