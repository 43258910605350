import React from "react";
import {Button, Card, Collapse, Icon, Table} from 'antd';
import 'antd/lib/button/style/css';
import 'antd/lib/card/style/css';
import 'antd/lib/col/style/css';
import 'antd/lib/icon/style/css';
import 'antd/lib/input/style/css';
import 'antd/lib/row/style/css';
import 'antd/lib/table/style/css';
import {connect} from "react-redux";
import modalShow from '../../actions/modal/modalShow';
import {ANCHORS_ENTRY_MODAL} from "../Feedback/Modal/types";
import {RESULTS_VIEW} from "../../paths";
import {push} from 'connected-react-router';
import AnchorsForm from "./AnchorForm";
import updateAnchorsSubSection from "../../actions/anchorsSubSection/updateAnchorsSubSection";
import getAnchorsSubSection from "../../actions/anchorsSubSection/getAnchorsSubSection";
import createAnchorsEntry from "../../actions/anchorsSubSection/createAnchorsEntry";
import updateAnchorsEntry from "../../actions/anchorsSubSection/updateAnchorsEntry";
import deleteAnchorSubSectionEntryImage from "../../actions/anchorsSubSection/deleteAnchorSubSectionEntryImage";
import deleteAnchorsEntry from "../../actions/anchorsSubSection/deleteAnchorsEntry";
import {confirmDelete} from "../../apis/authorizedDelete";

class Anchors extends React.Component {

  componentDidMount() {
    this.props.getAnchorsSubSection(this.props.match.params.anchor_id);
  };

  openEntryModal = (entry, onSubmit) => {
    this.props.modalShow({
      modalType: ANCHORS_ENTRY_MODAL,
      modalProps: {anchorsEntry: entry, onSubmit: onSubmit, deleteEntryImage: this.props.deleteAnchorSubSectionEntryImage}
    });
  };

  handleEntryCreate = (formValues) => {
    this.props.createAnchorsEntry(formValues, this.props.match.params.anchor_id);
  };

  handleEntryUpdate = (formValues) => {
    this.props.updateAnchorsEntry(formValues, this.props.match.params.anchor_id);
  };
  
  entryColumns = () => {
    return [{
      title: 'Contenido',
      dataIndex: 'content',
      key: 'content',
      render: (text) => {
        return <Icon type={text && text.length > 15 ? 'check' : 'close'}/>;
      }
    }, {
      title: 'Imagenes',
      dataIndex: 'images',
      key: 'images',
      render: (text, record) => {
        return record.images.length;
      }
    }, {
      title: 'Comentario',
      dataIndex: 'comment',
      key: 'comment',
      render: (text) => {
        return <Icon type={text && text.length > 0 ? 'check' : 'close'}/>;
      }
    }, {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record) => {
        return (
            <div>
              <Button onClick={() => this.openEntryModal(record, this.handleEntryUpdate)} type={"primary"}
                      htmlType={'button'}>
                <Icon type="eye"/>
              </Button>
              <Button
                onClick={() => confirmDelete(() => this.props.deleteAnchorsEntry(this.props.match.params.anchor_id, record.id))}
                type={"danger"}
                htmlType={'button'}>
                <Icon type="delete"/>
              </Button>
            </div>
        );
      }
    }]
  };

  render() {
    return (
        <Card title={'Sección de anclajes'}
              bodyStyle={{padding: 0}}
              extra={
                <Button htmlType={'button'}
                        type={'ghost'}
                        onClick={() => this.props.push(RESULTS_VIEW(this.props.match.params.id, this.props.match.params.results_id))}>Volver</Button>
              }>
            <Collapse>
              <Collapse.Panel header={'Información general'} key="1">
                <AnchorsForm onSubmit={this.props.updateAnchorsSubSection} anchors_sub_section={this.props.anchors_sub_section} />
              </Collapse.Panel>
              <Collapse.Panel header={'Entradas'} key="2">
                <Card title={'Entradas'}
                      bodyStyle={{padding: 0}}
                      extra={
                        <Button type={'primary'}
                                htmlType={'button'}
                                onClick={() => this.openEntryModal(null, this.handleEntryCreate)}>
                          Agregar
                        </Button>
                      }
                >
                  <Table scroll={{x: '100%'}}
                      dataSource={this.props.anchors_sub_section.kind === 'anchor_entries' ? this.props.anchors_sub_section.entries : []}
                      rowKey={'id'} columns={this.entryColumns()}/>
                </Card>
              </Collapse.Panel>
            </Collapse>

        </Card>
    );
  }
}

const mapStateToProps = state => {
  return {
    anchors_sub_section: state.userList.selected_entry
  };
};

export default connect(
    mapStateToProps,
    {
      push,
      modalShow,
      updateAnchorsSubSection,
      getAnchorsSubSection,
      createAnchorsEntry,
      updateAnchorsEntry,
      deleteAnchorSubSectionEntryImage,
      deleteAnchorsEntry
    })(Anchors);