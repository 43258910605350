import React, {useState} from 'react';
import {Button, Card, Icon, Table} from "antd";
import 'antd/lib/button/style/css';
import 'antd/lib/card/style/css';
import 'antd/lib/row/style/css';
import 'antd/lib/col/style/css';
import {connect} from "react-redux";
import ElementSubSubEntryForm from "./ElementSubSubEntryForm";
import ElementSubSubEntryResume from "./ElementSubSubEntryResume";
import createElementSubSubEntry from "../../actions/elementSubEntry/createElementSubSubEntry";
import updateElementSubSubEntry from "../../actions/elementSubEntry/updateElementSubSubEntry";
import _ from "lodash";
import deleteElementSubSubEntryImage from "../../actions/elementSubEntry/deleteElementSubSubEntryImage";
import {marginRight, noPadding} from "../../const/styles";
import deleteElementSubSubEntry from "../../actions/elementSubEntry/deleteElementSubSubEntry";
import {confirmDelete} from "../../apis/authorizedDelete";

const ElementSubEntryResume = ({elementEntry, createElementSubSubEntry, updateElementSubSubEntry, deleteElementSubSubEntryImage, deleteElementSubSubEntry}) => {
  
  const [elementSubSubEntry, setElementSubSubEntry] = useState(null);
  const [create, setCreate] = useState(false);
  
  const handleCreate = (formValues) => {
    createElementSubSubEntry(formValues, elementEntry.id, () => setCreate(false));
  };
  
  const handleUpdate = (formValues) => {
    updateElementSubSubEntry(formValues, elementEntry.id, () => setElementSubSubEntry(null));
  };
  
  const handleImageDelete = (elementSubSubEntryId, imageId) => {
    deleteElementSubSubEntryImage(elementEntry.id, elementSubSubEntryId, imageId, (data) => handleCallback(elementSubSubEntryId, data));
  };
  
  const handleCallback = (elementSubSubEntryId, data) => {
    setElementSubSubEntry(_.find(data.modal_list.selected_entry.entries, (entry) => entry.id === elementSubSubEntryId));
  };
  
  const columns = () => {
    return ([
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
        }, {
            title: 'Contenido',
            dataIndex: 'content',
            key: 'content',
            render: (text) => {
              return <Icon type={text && text.length > 15 ? 'check' : 'close'}/>;
            }
          }, {
          title: 'Imágenes',
            dataIndex: 'images',
            key: 'images',
            render: (text, record) => {
              return record.images.length;
            }
          }, {
            title: 'Comentario',
            dataIndex: 'comment',
            key: 'comment',
            render: (text) => {
              return <Icon type={text && text.length > 0 ? 'check' : 'close'}/>;
            }
          }, {
          title: 'Acciones',
          dataIndex: 'actions',
          key: 'actions',
          render: (text, record) => {
            return (
              <div>
                <Button onClick={() => setElementSubSubEntry(record)} type={"primary"} htmlType={'button'}>
                  <Icon type="eye"/>
                </Button>
                <Button onClick={() => confirmDelete(() => deleteElementSubSubEntry(elementEntry.id, record.id))}
                        type={"danger"}
                        htmlType={'button'}>
                  <Icon type="delete"/>
                </Button>
              </div>
            );
          }
        }]
    )
  };
  
  const handleRender = () => {
    if (create) {
      return <ElementSubSubEntryForm handleReturn={() => setCreate(false)}
                                     onSubmit={handleCreate}/>;
    } else if (elementSubSubEntry) {
      return <ElementSubSubEntryResume handleReturn={() => setElementSubSubEntry(null)}
                                       elementSubSubEntry={elementSubSubEntry}
                                       handleImageDelete={handleImageDelete}
                                       handleUpdate={handleUpdate}/>;
    } else {
      return (
        <Card
          title={`Sub entrada de las entradas semanales`}
          bodyStyle={noPadding}
          extra={
            <Button htmlType={'button'} style={marginRight} type={'ghost'}
                    onClick={() => setCreate(true)}>Crear</Button>
          }>
          <Table scroll={{x: '100%'}}  dataSource={elementEntry.entries} rowKey={'id'} columns={columns()}/>
        </Card>
      );
    }
  };
  
  return (
    handleRender()
  );
};

const mapStateToProps = state => {
  return ({
    elementEntry: state.modalList.selected_entry
  });
};

export default connect(mapStateToProps, {
  createElementSubSubEntry,
  deleteElementSubSubEntry,
  updateElementSubSubEntry,
  deleteElementSubSubEntryImage
})(ElementSubEntryResume);