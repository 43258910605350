import React from 'react';
import {Button, Card, Col, List, Modal, Row, Tabs} from "antd";
import {marginRight} from "../../const/styles";

const WeeklyReportResume = ({client, weeklyReport, handleCreate, handleUpdate, handleFinish, handleFindings, handleGenerate, handleImageDelete, handleSuggestions}) => {

  const renderAddButton = () => {
    if(weeklyReport.entries.length <= 3 && weeklyReport.status === 'unfinished' && !client)
      return (
        <Col xs={24} md={12} lg={4}>
          <Button block htmlType={'button'} style={marginRight} type={'primary'} onClick={handleCreate}>Agregar</Button>
        </Col>
      );
  };
  
  const confirmFinish = () => {
    Modal.confirm({
      title: 'Espera!',
      content: 'Seguro que quieres terminar el reporte semanal?',
      onOk: handleFinish,
      okText: 'Terminar'
    })
  };

  const renderUnfinishedButtons = () => {
    if(weeklyReport.status === 'unfinished' && !client) {
      return (
        <React.Fragment>
          <Col xs={24} md={12} lg={5}>
            <Button block htmlType={'button'} style={marginRight} type={'danger'}
                    onClick={confirmFinish}>Terminar</Button>
          </Col>
          <Col xs={24} md={12} lg={5}>
            <Button block htmlType={'button'} type={'ghost'} onClick={handleGenerate}>Pre Visualizar</Button>
          </Col>
        </React.Fragment>
      );
    } else if(weeklyReport.status === 'finished') {
      return (
        <Col xs={24} md={12} lg={5}>
          <Button block htmlType={'button'} style={marginRight} type={'primary'}
                  onClick={() => window.open(weeklyReport.document)}>Ver PDF</Button>
        </Col>
      );
    }
  };
  
  const renderTitle = () => {
    return (
      <Row>
        <Col span={24}>
          {`${weeklyReport.start_date} al ${weeklyReport.end_date}`}
        </Col>
        <Col span={24} className={'mt-10'}>
          <Row gutter={8} style={marginRight} justify={'end'}>
            {renderUnfinishedButtons()}
            {renderAddButton()}
            <Col xs={24} md={12} lg={5}>
              <Button block htmlType={'button'} type={'ghost'}
                      onClick={handleFindings}>Hallazgos({weeklyReport.findings.length})</Button>
            </Col>
            {!client ? <Col xs={24} md={12} lg={5}>
              <Button block style={marginRight} htmlType={'button'} type={'primary'}
                      onClick={handleSuggestions}>Sugerencias ({weeklyReport.suggestions.length})</Button>
            </Col> : ''}
          </Row>
        </Col>
      </Row>
    )
  };

  return (
      <Card
        bodyStyle={{padding: 0}}
        title={renderTitle()}>
        <Tabs
            defaultActiveKey={weeklyReport.entries.length.toString()}
            tabPosition={'left'}
            style={{height: '100%'}}
        >
          {weeklyReport.entries.map((item, index) => {
            return (
                <Tabs.TabPane key={index + 1} tab={index + 1}>
                  <Card
                      title={item.time}
                      extra={weeklyReport.status === 'unfinished' && !client ? <Button htmlType={'button'} type={'primary'} onClick={() => handleUpdate(item)}>EDITAR</Button> : null}>
                    <Row>
                      <p>{item.content}</p>
                    </Row>
                    <List
                      itemLayout="horizontal"
                      grid={{gutter: 16, xs: 1, lg: item.images.length === 1 ? 1 : 2}}
                      dataSource={item.images}
                      renderItem={image => (
                        <List.Item>
                          <div style={{minHeight: 300}}>
                            {weeklyReport.status === 'unfinished' && !client ?
                              <div className={'p-20 float-left'}>
                                <Button htmlType={'button'} type={'ghost'}
                                        onClick={() => handleImageDelete(item.id, image.id)}>
                                  ELIMINAR
                                </Button>
                              </div> : null}
                              <img src={image.url} alt={'Imagen'} style={{width: '100%'}} />
                          </div>
                        </List.Item>
                      )}
                    />
                  </Card>
                </Tabs.TabPane>
            );
          })}
        </Tabs>
      </Card>
  );
};

export default WeeklyReportResume;