import React from 'react';
import {reduxForm} from "redux-form";
import ProjectBasic from "./ProjectBasic";
import WizardHelper from "../Helpers/WizardHelper";
import ProjectThird from "./ProjectThird";
import {Card, Modal} from "antd";
import _ from 'lodash';
import ProjectDocs from "./ProjectDocs";
import {noPadding} from "../../const/styles";

class ProjectsModal extends React.Component {
  
  state = {supervisor_logo: null, constructor_logo: null, project_logo: null};

  componentDidMount() {
    if(this.props.project)
      this.props.initialize({project: _.omit(this.props.project, ['supervisor_logo', 'constructor_logo', 'project_logo'])})
  }

  handleOnDrop = (newImageFile, docKey) => {
    this.props.change(docKey, newImageFile);
    let newState = {};
    newState[docKey] = newImageFile;
    this.setState(newState);
  };

  steps = () => {
    return ([{
      title: 'Datos básicos',
      component: ProjectBasic,
      content: {}
    }, {
      title: 'Datos de terceros',
      component: ProjectThird,
      content: {}
    }, {
      title: 'Logos',
      component: ProjectDocs,
      content: {project: this.props.project, files: this.state, handleOnDrop: this.handleOnDrop}
    }]);
  };

  render() {
    const {modalType, modalProps, onSubmit} = this.props;
    return (
      <Modal bodyStyle={noPadding} footer={null} className={'content-wrapper'} visible={modalType !== null} style={{ top: 30 }} width={720} {...modalProps}>
        <Card bodyStyle={noPadding} title={'Proyecto'}>
          <WizardHelper steps={this.steps()} onSubmit={onSubmit}/>
        </Card>
      </Modal>
    );
  }
}

export default reduxForm({
  form: 'projects_form',
  forceUnregisterOnUnmount: true,
  touchOnChange: true
})(ProjectsModal);