import React from 'react';
import {Button, Card, Icon, Row, Table} from "antd";
import {noPadding} from "../../const/styles";

const ProjectsLocationsTable = ({handleCreate, handleUpdate, locations}) => {
  
  const columns = () => {
    return [{
      title: '#',
      dataIndex: 'id',
      key: 'id'
    }, {
      title: 'Título',
      dataIndex: 'title',
      key: 'title'
    }, {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record) => {
        return (
          <Row>
            <Button type={"primary"}
                    htmlType={'button'}
                    onClick={() => handleUpdate(record)}>
              <span><Icon type="eye"/> Ver</span>
            </Button>
          </Row>
        );
      }
    }]
  };
  
  return (
    <Card
        bodyStyle={noPadding} title={'Ubicaciones del proyecto'}
        extra={
          <Button type={'primary'}
                  htmlType={'button'}
                  onClick={handleCreate}>
            Agregar
          </Button>
        }
    >
      <Row>
        <Table scroll={{x: '100%'}}  dataSource={locations} rowKey={'id'} columns={columns()}/>
      </Row>
    </Card>
  );
};

export default ProjectsLocationsTable;