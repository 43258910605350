import React from "react";
import {Card} from 'antd';

const Inactive = () => {
  return (
    <Card title={'Usuario inactivo'}>
      <h1>Lo sentimos, tu usuario se encuentra inactivo :(</h1>
      <h3>Para mayor información contacta a los administradores del sistema!</h3>
    </Card>
  );
};

export default Inactive;