import {FINISH_DAILY_REPORT} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedPut from "../../apis/authorizedPut";
import modalDismiss from "../modal/modalDismiss";

export default (dailyReportId) => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedPut(`/daily_reports/${dailyReportId}/finish`),
      simpleSuccess(FINISH_DAILY_REPORT),
      null,
      [modalDismiss]
  );
};