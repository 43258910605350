import React, {useEffect} from 'react';
import {Field, FieldArray, reduxForm} from "redux-form";
import {Button, Card, Col, Form, Row} from "antd";
import 'antd/lib/button/style/css';
import 'antd/lib/card/style/css';
import 'antd/lib/col/style/css';
import 'antd/lib/form/style/css';
import 'antd/lib/row/style/css';
import _ from 'lodash';
import {CreateFileField, CreateTextArea, CreateTextField} from "../Helpers/Input/InputCreator";
import {fileImage, fileVerification, maxLength50, minLength5} from "../Helpers/Input/validations";

const MonthlyWeekForm = ({fields, change, monthlyWeekEntry}) => {
  
  useEffect(() => {
    if (fields.length === 0)
      fields.push({});
  }, []);
  
  return (
    fields.map((monthly_week_entries_attributes, index) => {
      return (
        <div key={index}>
          <Row gutter={16}>
            <Col xs={24} sm={24}>
              <Field label={'Titulo'}
                     name={`${monthly_week_entries_attributes}.title`}
                     component={CreateTextField}
                     placeholder="Titulo"
                     validate={[minLength5, maxLength50]}
                     hasFeedback/>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Field label={'Imagen de la entrada'}
                     name={'avatar'}
                     preview={monthlyWeekEntry ? monthlyWeekEntry.avatar : null}
                     component={CreateFileField}
                     validate={[fileVerification, fileImage]}
                     change={change}
                     isImage
                     type='file'
                     hasFeedback
              />
            </Col>
            <Col xs={24} sm={12}>
              <Field label={'Comentario para la foto'}
                     name={`${monthly_week_entries_attributes}.comment`}
                     component={CreateTextArea}
                     placeholder="Comentario para la foto"
                     hasFeedback/>
            </Col>
          </Row>
          <Row className={'p-20'}>
            <Button type={'primary float-right'} htmlType={'submit'}>Guardar</Button>
          </Row>
        </div>
      )
    })
  );
};

const MonthlyWeekEntryForm = ({handleSubmit, initialize, change, onSubmit, monthlyWeekEntry}) => {
  
  useEffect(() => {
    if (monthlyWeekEntry)
      initialize({tracing_section: {monthly_week_entries_attributes: [_.omit(monthlyWeekEntry, ['avatar'])]}});
  }, []);
  
  return (
    <Card title={'Entrada semanal'}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FieldArray name={'tracing_section[monthly_week_entries_attributes]'} component={MonthlyWeekForm}
                    props={{monthlyWeekEntry: monthlyWeekEntry, change: change}}/>
      </Form>
    </Card>
  );
};

export default reduxForm({
  form: 'monthly_week_entry_form',
  touchOnChange: true
})(MonthlyWeekEntryForm);