import authorizedDelete from "../../apis/authorizedDelete";
import loadingCall from "../functions/loadingCall"
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import { GET_PHOTOS_VIDEOS } from "../types";

export default (projectId, mediaId, assetId) => async(dispatch) => {
    await loadingCall(
        dispatch,
        authorizedDelete(`/projects/${projectId}/medias/${mediaId}/assets/${assetId}`),
        simpleSuccess(GET_PHOTOS_VIDEOS)
    );
};
