import {GET_ALERTS} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedGet from "../../apis/authorizedGet";

export default () => async (dispatch) => {
  
  await loadingCall(
    dispatch,
    authorizedGet(
      `/alerts`
    ),
    simpleSuccess(GET_ALERTS),
    null,
    []
  );
};