import {FINISH_RESULT_SECTION} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedPut from "../../apis/authorizedPut";

export default (resultSectionId) => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedPut(`/result_sections/${resultSectionId}/finish`),
    simpleSuccess(FINISH_RESULT_SECTION)
  );
};