import React from "react";
import {Button, Card, Collapse, Icon, Table} from 'antd';
import 'antd/lib/button/style/css';
import 'antd/lib/card/style/css';
import 'antd/lib/col/style/css';
import 'antd/lib/icon/style/css';
import 'antd/lib/input/style/css';
import 'antd/lib/row/style/css';
import 'antd/lib/table/style/css';
import {connect} from "react-redux";
import modalShow from '../../actions/modal/modalShow';
import {MONTHLY_VIEW} from "../../paths";
import {push} from 'connected-react-router';
import {
  AGREEMENT_ENTRY_MODAL,
  BUDGET_ENTRY_MODAL,
  CONTRACT_ENTRY_MODAL,
  PROGRAMMING_ENTRY_MODAL
} from "../Feedback/Modal/types";
import {marginRight} from "../../const/styles";
import getAdministrativeSection from "../../actions/administrativeSection/getAdministrativeSection";
import createContractEntry from "../../actions/administrativeSection/createContractEntry";
import {reducedText} from "../Helpers/Input/format";
import updateContractEntry from "../../actions/administrativeSection/updateContractEntry";
import createAgreementEntry from "../../actions/administrativeSection/createAgreementEntry";
import updateAgreementEntry from "../../actions/administrativeSection/updateAgreementEntry";
import createBudgetEntry from "../../actions/administrativeSection/createBudgetEntry";
import updateBudgetEntry from "../../actions/administrativeSection/updateBudgetEntry";
import createProgrammingEntry from "../../actions/administrativeSection/createProgramingEntry";
import updateProgrammingEntry from "../../actions/administrativeSection/updateProgramingEntry";
import deleteContractEntry from "../../actions/administrativeSection/deleteContractEntry";
import deleteAgreementEntry from "../../actions/administrativeSection/deleteAgreementEntry";
import deleteBudgetEntry from "../../actions/administrativeSection/deleteBudgetEntry";
import deleteProgramingEntry from "../../actions/administrativeSection/deleteProgramingEntry";
import {confirmDelete} from "../../apis/authorizedDelete";

class Administrative extends React.Component {
  
  componentDidMount() {
    this.props.getAdministrativeSection(this.props.match.params.administrative_id);
  };
  
  openContractModal = (entry, onSubmit) => {
    this.props.modalShow({
      modalType: CONTRACT_ENTRY_MODAL,
      modalProps: {contractEntry: entry, onSubmit: onSubmit}
    });
  };
  
  handleContractCreate = (formValues) => {
    this.props.createContractEntry(formValues, this.props.match.params.administrative_id);
  };
  
  handleContractUpdate = (formValues) => {
    this.props.updateContractEntry(formValues, this.props.match.params.administrative_id);
  };
  
  openAgreementModal = (entry, onSubmit) => {
    this.props.modalShow({
      modalType: AGREEMENT_ENTRY_MODAL,
      modalProps: {agreementEntry: entry, onSubmit: onSubmit}
    });
  };
  
  handleAgreementCreate = (formValues) => {
    this.props.createAgreementEntry(formValues, this.props.match.params.administrative_id);
  };
  
  handleAgreementUpdate = (formValues) => {
    this.props.updateAgreementEntry(formValues, this.props.match.params.administrative_id);
  };
  
  openBudgetModal = (entry, onSubmit) => {
    this.props.modalShow({
      modalType: BUDGET_ENTRY_MODAL,
      modalProps: {budgetEntry: entry, onSubmit: onSubmit}
    });
  };
  
  handleBudgetCreate = (formValues) => {
    this.props.createBudgetEntry(formValues, this.props.match.params.administrative_id);
  };
  
  handleBudgetUpdate = (formValues) => {
    this.props.updateBudgetEntry(formValues, this.props.match.params.administrative_id);
  };
  
  openProgrammingModal = (entry, onSubmit) => {
    this.props.modalShow({
      modalType: PROGRAMMING_ENTRY_MODAL,
      modalProps: {programingEntry: entry, onSubmit: onSubmit}
    });
  };
  
  handleProgrammingCreate = (formValues) => {
    this.props.createProgrammingEntry(formValues, this.props.match.params.administrative_id);
  };
  
  handleProgrammingUpdate = (formValues) => {
    this.props.updateProgrammingEntry(formValues, this.props.match.params.administrative_id);
  };
  
  contractColumns = () => {
    return [{
      title: 'Titulo',
      dataIndex: 'title',
      key: 'title',
      render: (text) => {
        return reducedText(text, 50);
      }
    }, {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record) => {
        return (
          <div>
            <Button onClick={() => this.openContractModal(record, this.handleContractUpdate)} type={"primary"}
                    htmlType={'button'}>
              <Icon type="eye"/>
            </Button>
            <Button
              onClick={() => confirmDelete(() => this.props.deleteContractEntry(this.props.match.params.administrative_id, record.id))}
              type={"danger"}
              htmlType={'button'}>
              <Icon type="delete"/>
            </Button>
          </div>
        );
      }
    }]
  };
  
  agreementColumns = () => {
    return [{
      title: 'Contenido',
      dataIndex: 'content',
      key: 'content',
      render: (text) => {
        return <Icon type={text && text.length > 15 ? 'check' : 'close'}/>;
      }
    }, {
      title: 'Imagen',
      dataIndex: 'avatar',
      key: 'avatar',
      render: (text) => {
        return <Icon type={text ? 'check' : 'close'}/>;
      }
    }, {
      title: 'Comentario',
      dataIndex: 'comment',
      key: 'comment',
      render: (text) => {
        return <Icon type={text && text.length > 0 ? 'check' : 'close'}/>;
      }
    }, {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record) => {
        return (
          <div>
            <Button onClick={() => this.openAgreementModal(record, this.handleAgreementUpdate)} type={"primary"}
                    htmlType={'button'}>
              <Icon type="eye"/>
            </Button>
            <Button
              onClick={() => confirmDelete(() => this.props.deleteAgreementEntry(this.props.match.params.administrative_id, record.id))}
              type={"danger"}
              htmlType={'button'}>
              <Icon type="delete"/>
            </Button>
          </div>
        );
      }
    }]
  };
  
  budgetColumns = () => {
    return [{
      title: 'Contenido',
      dataIndex: 'content',
      key: 'content',
      render: (text) => {
        return <Icon type={text && text.length > 15 ? 'check' : 'close'}/>;
      }
    }, {
      title: 'Imagen',
      dataIndex: 'avatar',
      key: 'avatar',
      render: (text) => {
        return <Icon type={text ? 'check' : 'close'}/>;
      }
    }, {
      title: 'Comentario',
      dataIndex: 'comment',
      key: 'comment',
      render: (text) => {
        return <Icon type={text && text.length > 0 ? 'check' : 'close'}/>;
      }
    }, {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record) => {
        return (
          <div>
            <Button onClick={() => this.openBudgetModal(record, this.handleBudgetUpdate)} type={"primary"}
                    htmlType={'button'}>
              <Icon type="eye"/>
            </Button>
            <Button onClick={() => confirmDelete(() => this.props.deleteBudgetEntry(this.props.match.params.administrative_id, record.id))}
                    type={"danger"}
                    htmlType={'button'}>
              <Icon type="delete"/>
            </Button>
          </div>
        );
      }
    }]
  };
  
  programingColumns = () => {
    return [{
      title: 'Contenido',
      dataIndex: 'content',
      key: 'content',
      render: (text) => {
        return <Icon type={text && text.length > 15 ? 'check' : 'close'}/>;
      }
    }, {
      title: 'Imagen',
      dataIndex: 'avatar',
      key: 'avatar',
      render: (text) => {
        return <Icon type={text ? 'check' : 'close'}/>;
      }
    }, {
      title: 'Comentario',
      dataIndex: 'comment',
      key: 'comment',
      render: (text) => {
        return <Icon type={text && text.length > 0 ? 'check' : 'close'}/>;
      }
    }, {
      title: 'Salto de pág.',
      dataIndex: 'pagebreak',
      key: 'pagebreak',
      render: (text) => {
        return <Icon type={text ? 'check' : 'close'}/>;
      }
    }, {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record) => {
        return (
          <div>
            <Button onClick={() => this.openProgrammingModal(record, this.handleProgrammingUpdate)} type={"primary"}
                    htmlType={'button'}>
              <Icon type="eye"/>
            </Button>
            <Button onClick={() => confirmDelete(() => this.props.deleteProgramingEntry(this.props.match.params.administrative_id, record.id))}
                    type={"danger"}
                    htmlType={'button'}>
              <Icon type="delete"/>
            </Button>
          </div>
        );
      }
    }]
  };
  
  
  render() {
    return (
      <Card bodyStyle={{padding: 0}}
            extra={
              <div>
                <Button htmlType={'button'} type={'ghost'} style={marginRight}
                        onClick={() => this.props.push(MONTHLY_VIEW(this.props.match.params.id))}>Volver</Button>
              </div>
            }
            title={'Sección administrativa'}>
        <Collapse>
          <Collapse.Panel header={'Entradas de contrato'} key="1">
            <Card title={'Entradas de contrato'}
                  bodyStyle={{padding: 0}}
                  extra={
                    <Button type={'primary'}
                            htmlType={'button'}
                            onClick={() => this.openContractModal(null, this.handleContractCreate)}>
                      Agregar
                    </Button>
                  }
            >
              <Table scroll={{x: '100%'}}
                dataSource={this.props.administrativeSection.kind === 'administrative_entries' ? this.props.administrativeSection.contract_entries : []}
                rowKey={'id'} columns={this.contractColumns()}/>
            </Card>
          </Collapse.Panel>
          <Collapse.Panel header={'Entradas de seguimiento de contrato'} key="2">
            <Card title={'Entradas de seguimiento de contrato'}
                  bodyStyle={{padding: 0}}
                  extra={
                    <Button type={'primary'}
                            htmlType={'button'}
                            onClick={() => this.openAgreementModal(null, this.handleAgreementCreate)}>
                      Agregar
                    </Button>
                  }
            >
              <Table scroll={{x: '100%'}}
                  dataSource={this.props.administrativeSection.kind === 'administrative_entries' ? this.props.administrativeSection.agreement_entries : []}
                  rowKey={'id'} columns={this.agreementColumns()}/>
            </Card>
          </Collapse.Panel>
          <Collapse.Panel header={'Seguimiento de presupuesto'} key="3">
            <Card title={'Seguimiento de presupuesto'}
                  bodyStyle={{padding: 0}}
                  extra={
                    <Button type={'primary'}
                            htmlType={'button'}
                            onClick={() => this.openBudgetModal(null, this.handleBudgetCreate)}>
                      Agregar
                    </Button>
                  }
            >
              <Table scroll={{x: '100%'}}
                  dataSource={this.props.administrativeSection.kind === 'administrative_entries' ? this.props.administrativeSection.budget_entries : []}
                  rowKey={'id'} columns={this.budgetColumns()}/>
            </Card>
          </Collapse.Panel>
          <Collapse.Panel header={'Corte de programación'} key="4">
            <Card title={'Corte de programación'}
                  bodyStyle={{padding: 0}}
                  extra={
                    <Button type={'primary'}
                            htmlType={'button'}
                            onClick={() => this.openProgrammingModal(null, this.handleProgrammingCreate)}>
                      Agregar
                    </Button>
                  }
            >
              <Table scroll={{x: '100%'}}
                  dataSource={this.props.administrativeSection.kind === 'administrative_entries' ? this.props.administrativeSection.programing_entries : []}
                  rowKey={'id'} columns={this.programingColumns()}/>
            </Card>
          </Collapse.Panel>
        </Collapse>
      </Card>
    );
  }
}


const mapStateToProps = state => {
  return {
    administrativeSection: state.userList.selected_entry
  };
};


export default connect(mapStateToProps, {
  push,
  modalShow,
  getAdministrativeSection,
  createContractEntry,
  updateContractEntry,
  createAgreementEntry,
  updateAgreementEntry,
  createBudgetEntry,
  updateBudgetEntry,
  createProgrammingEntry,
  updateProgrammingEntry,
  deleteContractEntry,
  deleteAgreementEntry,
  deleteBudgetEntry,
  deleteProgramingEntry
})(Administrative);