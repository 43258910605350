import {UPDATE_ELEMENT_SUB_ENTRY} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedPut from "../../apis/authorizedPut";
import modalDismiss from "../modal/modalDismiss";

export default (formValues, monthlyWeekEntryId) => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedPut(`/monthly_week_entries/${monthlyWeekEntryId}/element_entry`, formValues),
      simpleSuccess(UPDATE_ELEMENT_SUB_ENTRY),
      null,
      [modalDismiss]
  );
};