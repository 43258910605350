import React from 'react';
import PublicLayout from "../../../layouts/Public";
import {connect} from 'react-redux';
import logIn from '../../../actions/sessions/logIn';
import {Field, reduxForm} from "redux-form";
import {CreatePasswordField, CreateTextField} from "../../Helpers/Input/InputCreator";
import {minLength6, required} from "../../Helpers/Input/validations";
import {lowerCase} from "../../Helpers/Input/format";
import {Button, Form} from "antd";
import logo from "../../../resources/img/logo.png";

class LogIn extends React.Component {
  render() {
    return (
      <PublicLayout message={'Ingresa a tu cuenta'}>
        <div style={{textAlign: 'center', marginBottom: 30}}>
          <img src={logo} style={{width: '50%'}} alt={''}/>
        </div>
        <Form onSubmit={this.props.handleSubmit(this.props.logIn)}>
          <Field name="user[username]"
                 component={CreateTextField}
                 placeholder="Usuario"
                 validate={[required]}
                 format={lowerCase}
                 hasFeedback/>
          <Field name="user[password]"
                 component={CreatePasswordField}
                 placeholder="Contraseña"
                 validate={[required, minLength6]}
                 hasFeedback/>
          <Button type="primary" block htmlType="submit" style={{margin: "10px 0"}}>
            Iniciar sesión
          </Button>
        </Form>
      </PublicLayout>
    );
  };
};

export default connect(null, {logIn})(reduxForm({
  form: 'login_form',
  touchOnChange: true
})(LogIn));