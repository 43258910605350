import {CREATE_CLIENT} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedPost from "../../apis/authorizedPost";
import modalDismiss from "../modal/modalDismiss";

export default (formValues) => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedPost('/clients', formValues),
      simpleSuccess(CREATE_CLIENT),
      null,
      [modalDismiss]
  );
};