import {CREATE_PROJECT_DOCUMENT} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedPost from "../../apis/authorizedPost";
import {formDataHelper} from "../functions/formDataHelper";
import modalDismiss from "../modal/modalDismiss";

export default (formValues, projectId, callback) => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedPost(`/projects/${projectId}/documents`, formDataHelper(formValues, 'project', [{name: 'archive', key: '[documents_attributes][][archive]'}])),
      simpleSuccess(CREATE_PROJECT_DOCUMENT),
      null,
      [modalDismiss],
      [callback]);
};