import {UPDATE_PROTOCOL_SECTION} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedPut from "../../apis/authorizedPut";
import modalDismiss from "../modal/modalDismiss";

export default (protocolIds, protocolSectionId) => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedPut(
          `/protocol_sections/${protocolSectionId}`,
          {protocol_section: {protocol_ids: protocolIds}}
      ),
      simpleSuccess(UPDATE_PROTOCOL_SECTION),
      null,
      [modalDismiss]
  );
};