import {UPDATE_CLIENT} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedPut from "../../apis/authorizedPut";
import modalDismiss from "../modal/modalDismiss";

export default (formValues, userId) => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedPut('/clients/' + userId, formValues),
      simpleSuccess(UPDATE_CLIENT),
      null,
      [modalDismiss]
  );
};