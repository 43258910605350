import React from 'react';
import {Modal} from "antd";
import 'antd/lib/modal/style/css';
import AdvanceEntryForm from "./AdvanceEntryForm";
import {noPadding} from "../../const/styles";

class AdvanceEntryModal extends React.Component {
  render() {
    const {modalType, modalProps} = this.props;
    return (
      <Modal bodyStyle={noPadding} footer={null} className={'content-wrapper'} visible={modalType !== null} style={{ top: 30 }} width={720} {...modalProps}>
        <AdvanceEntryForm
            advanceEntry={this.props.advanceEntry}
            onSubmit={this.props.onSubmit}
        />
      </Modal>
    );
  }
}

export default AdvanceEntryModal;