import React from 'react';
import {connect} from 'react-redux'
import modalDismiss from '../../actions/modal/modalDismiss';
import {Modal} from "antd";
import WeeklyReportResume from "./WeeklyReportResume";
import WeeklyEntryForm from "./WeeklyEntryForm";
import createWeeklyEntry from "../../actions/weeklyReports/createWeeklyEntry";
import updateWeeklyEntry from "../../actions/weeklyReports/updateWeeklyEntry";
import finishWeeklyReport from "../../actions/weeklyReports/finishWeeklyReport";
import generateWeeklyReport from "../../actions/weeklyReports/generateWeeklyReport";
import deleteWeeklyEntryImage from "../../actions/weeklyReports/deleteWeeklyEntryImage";
import WeeklyFindings from "./WeeklyFindings";
import modalShow from "../../actions/modal/modalShow";
import WeeklySuggestions from "./WeeklySuggestions";
import createWeeklySuggestion from "../../actions/weeklyReports/createWeeklySuggestion";
import updateWeeklySuggestion from "../../actions/weeklyReports/updateWeeklySuggestion";

class UserWeeklyModal extends React.Component {
  state = {
    selected: false,
    update: null,
    findings: false,
    suggestions: false
  };

  handleFinish = () => {
    this.props.finishWeeklyReport(this.props.weeklyReport.id);
  };

  handleGenerate = () => {
    this.props.generateWeeklyReport(this.props.weeklyReport.id);
  };

  handleImageDelete = (weeklyEntryId, imageId) => {
    this.props.deleteWeeklyEntryImage(this.props.weeklyReport.id, weeklyEntryId, imageId);
  };

  handleCreate = () => {
    this.setState({ selected: true, update: null });
  };

  handleUpdate = (weeklyEntry) => {
    this.setState({update: weeklyEntry, selected: true});
  };

  handleFindings = () => {
    this.setState({findings: true});
  };

  handleReturn = () => {
    this.setState({selected: false, update: null, findings: false, suggestions: false});
  };
  
  handleSuggestions = () => {
    this.setState({suggestions: true});
  };

  onSubmit = (formValues) => {
    if(this.state.update) {
      this.props.updateWeeklyEntry(this.props.weeklyReport.id, formValues, this.handleReturn);
    } else {
      this.props.createWeeklyEntry(this.props.weeklyReport.id, formValues, this.handleReturn);
    }
  };

  handleRender = () => {
    if(this.state.selected) {
      return (
        <WeeklyEntryForm weeklyEntry={this.state.update}
                        handleReturn={this.handleReturn} onSubmit={this.onSubmit} />
      );
    } else if (this.state.findings) {
      return <WeeklyFindings handleReturn={this.handleReturn} weeklyReport={this.props.weeklyReport} modalShow={this.props.modalShow}/>;
    } else if (this.state.suggestions) {
      return <WeeklySuggestions suggestions={this.props.weeklyReport.suggestions}
                                weeklyReport={this.props.weeklyReport}
                                createWeeklySuggestion={this.props.createWeeklySuggestion}
                                updateWeeklySuggestion={this.props.updateWeeklySuggestion}
                                handleReturn={this.handleReturn}/>;
    } else {
      return (
        <WeeklyReportResume client={this.props.client}
                            handleUpdate={this.handleUpdate}
                            weeklyReport={this.props.weeklyReport}
                            handleFinish={this.handleFinish}
                            handleFindings={this.handleFindings}
                            handleSuggestions={this.handleSuggestions}
                            handleImageDelete={this.handleImageDelete}
                            handleGenerate={this.handleGenerate}
                            handleCreate={this.handleCreate}/>
      );
    }
  };
  
  render() {
    const {modalType, modalProps} = this.props;
    return (
      <Modal bodyStyle={{padding: 0}} footer={null} className={'content-wrapper'} visible={modalType !== null} style={{ top: 30 }} width={720} {...modalProps}>
        {this.handleRender()}
      </Modal>
    );
  };
}

const mapStateToProps = state => {
  return ({
    client: state.auth.client,
    weeklyReport: state.userList.selected_entry
  });
};

export default connect(mapStateToProps, {
  modalDismiss,
  modalShow,
  createWeeklyEntry,
  updateWeeklyEntry,
  finishWeeklyReport,
  generateWeeklyReport,
  deleteWeeklyEntryImage,
  createWeeklySuggestion,
  updateWeeklySuggestion
})(UserWeeklyModal);