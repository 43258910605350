import {DELETE_MORTAR_TABLE} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedDelete from "../../apis/authorizedDelete";

export default (subSectionId, entryId) => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedDelete(`/mortars_sub_sections/${subSectionId}/table/${entryId}`),
      simpleSuccess(DELETE_MORTAR_TABLE));
};