import authorizedGet from "../../apis/authorizedGet";
import loadingCall from "../functions/loadingCall"
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import { GET_PROJECTS_ASSETS_RESUME } from "../types";

export default () => async(dispatch) => {
    await loadingCall(
        dispatch,
        authorizedGet('/projects_assets_resume'),
        simpleSuccess(GET_PROJECTS_ASSETS_RESUME)
    );
};
