import React, { useState } from "react";
import {Row, Col, Button, Icon, Modal, Popconfirm } from "antd";

const ImageGallery = ({ assets, deleteAction }) => {

    const [modalVisible, setModalVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');

    const handlePreview = (image) => {
        setPreviewImage(image);
        setModalVisible(true);
    };

    const handleClose = () => {
        setModalVisible(false);
    };

    const handleDelete = (id) => {
        deleteAction(id);
    };

    if (!assets || assets.length === 0) {
        return (<div><h2>No hay elmentos en esta fecha!</h2></div>);
    }

    const elems_x_row = 6;
    const rows = Math.ceil(assets.length / elems_x_row); // Calculate the number of rows needed

    return (
        <div>
        {[...Array(rows)].map((_, rowIndex) => (
            <Row key={rowIndex}>
            {assets.slice(rowIndex * elems_x_row, rowIndex * elems_x_row + elems_x_row).map((asset, index) => (
                <Col key={index} span={4}>
                    <div style={{padding: '8px'}}>
                        <div style={{ paddingBottom: '100%', position: 'relative' }}>
                            <div
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                            }}
                            >
                            {asset.mediaType === 'image' ? (
                                <img src={asset.tumbnailUrl} alt={asset.filename} style={{ objectFit: 'cover', width: '100%', height: '100%' }} />
                            ) : (
                                <video controls src={asset.url} alt={asset.filename} style={{ objectFit: 'cover', width: '100%', height: '100%' }} />
                            )}
                            </div>
                        </div>
                        <div style={{padding: '3px 0px 0px 0px', textAlign: 'right'}}>
                            { asset.mediaType === 'image' && (
                                <Button type={'primary'} style={{ marginRight: '8px'}} onClick={() => handlePreview(asset.previewUrl)}>
                                    <Icon type='eye' />
                                </Button>
                            )}
                            <a href={asset.url + '?disposition=attachment'} download>
                                <Button type={'primary'}>
                                    <Icon type='download' />
                                </Button>
                            </a>
                            <Popconfirm title="¿Desea de elimimar este elemento? Esta acción no puede revertirse!" onConfirm={() => handleDelete(asset.id)} okText="Si, eliminar!" cancelText="No">
                                <Button type={'danger'} style={{ marginLeft: '8px'}}>
                                    <Icon type='delete' />
                                </Button>
                            </Popconfirm>
                        </div>
                    </div>
                </Col>
            ))}
            </Row>
        ))}
        <Modal visible={modalVisible} footer={null} onCancel={handleClose} width={800}>
            <img src={previewImage} alt="Preview" width="100%"></img>
        </Modal>
        </div>
    );
};

export default ImageGallery;