import React from "react";
import {Checkbox, Form, Input, Select, DatePicker} from "antd";
import moment from 'moment';
import DropZoneField from "../DropzoneField";
import './input.css';

const maxWidth = {width: '100%'};

const createField = (Component, options = {}) => ({style, input, meta, children, hasFeedback, label, placeholder, ...rest}) => {

  if (options.checkbox)
    input.checked = input.value === '' || input.value === 'false' ? false : input.value === 'true' ? true : input.value;
  if (options.multipleSelect)
    input.value = rest.items;
  if (options.timePicker) {
    input.value = input.value ? moment(input.value, 'HH:mm') : null;
    rest.format = 'HH:mm';
  }
  if (options.rangePicker) {
    if (Array.isArray(input.value)) {
      input.value = input.value ? [moment(input.value[0]), moment(input.value[1])] : null;
    }
  }
  if (options.labels){
    children = createOptions(rest.options, label);
    input.value = input.value ? input.value : undefined;
  }

  return (
    <Form.Item
      label={label}
      style={style}
      className={options.noErrorIcon ? 'no-error-icon' : ''}
      validateStatus={!meta.touched && !meta.initial ? "" : meta.invalid ? "error" : "success"}
      hasFeedback={hasFeedback}
      help={meta.touched && meta.error}
    >
      <Component label={label} meta={meta} placeholder={placeholder} style={maxWidth} {...input} {...rest}
                 children={children}/>
    </Form.Item>
  );
};

export const createOptions = (options) => {
  return (
    options.map((option, index) => (
      <Select.Option key={index} title={option[0]} value={option[1]}>{option[0]}</Select.Option>
    ))
  );
};


export const CreateTextField = createField(Input);
export const CreateTextArea = createField(Input.TextArea);
export const CreatePasswordField = createField(Input.Password);
export const CreateSelectField = createField(Select);
export const CreateCheckbox = createField(Checkbox, {checkbox: true, noErrorIcon: true});
export const CreateFileField = createField(DropZoneField, {noErrorIcon: true});
export const CreateDateRangeField = createField(DatePicker.RangePicker, { rangePicker: true });
