import {CREATE_ADMINISTRATIVE_SECTION} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedPost from "../../apis/authorizedPost";

export default (monthlyReportId) => async (dispatch) => {
  await loadingCall(
    dispatch,
    authorizedPost(`/monthly_reports/${monthlyReportId}/administrative_section`),
    simpleSuccess(CREATE_ADMINISTRATIVE_SECTION)
  );
};