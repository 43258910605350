import React from 'react';
import StatusHelper from "../Helpers/StatusHelper";
import {Button, Card, Icon, Row, Table} from "antd";
import {noPadding} from "../../const/styles";

const ProjectsDocumentsTable = ({handleCreate, handleUpdate, documents}) => {

  const columns = () => {
    return [{
      title: '#',
      dataIndex: 'id',
      key: 'id'
    }, {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name'
    }, {
      title: 'Tipo',
      dataIndex: 'kind',
      key: 'kind'
    }, {
      title: 'Fecha',
      dataIndex: 'date',
      key: 'date'
    }, {
      title: 'Archivo',
      key: 'archive',
      render: (text, record) => <a href={record.archive} target={'_blank'}><Button htmlType={'button'} type={'ghost'}>ABRIR</Button></a>
    }, {
      title: 'Estado',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => <StatusHelper type={'project'} position={'right'} status={record.status}/>
    }, {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record) => {
        return (
            <Row>
              <Button type={"primary"}
                      htmlType={'button'}
                      onClick={() => handleUpdate(record.id)}>
                <span><Icon type="eye"/> Ver</span>
              </Button>
            </Row>
        );
      }
    }]
  };

  return (
    <Card
        bodyStyle={noPadding} title={'Documentos del proyecto'}
        extra={
          <Button type={'primary'}
                  htmlType={'button'}
                  onClick={handleCreate}>
            Agregar
          </Button>
        }
    >
      <Row>
        <Table scroll={{x: '100%'}}  dataSource={documents} rowKey={'id'} columns={columns()}/>
      </Row>
    </Card>
  );
}

export default ProjectsDocumentsTable;