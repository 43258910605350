import React from 'react';
import {connect} from "react-redux";
import modalShow from "../../actions/modal/modalShow";
import getUser from "../../actions/members/getUser";
import getUsers from "../../actions/members/getUsers";
import createUser from "../../actions/members/createUser";
import updateUser from "../../actions/members/updateUser";
import {USERS_MODAL} from "../Feedback/Modal/types";
import {Avatar, Button, Card, Icon, Row, Table} from "antd";
import 'antd/lib/button/style/css';
import 'antd/lib/card/style/css';
import 'antd/lib/col/style/css';
import 'antd/lib/icon/style/css';
import 'antd/lib/row/style/css';
import 'antd/lib/table/style/css';
import 'antd/lib/input/style/css';
import 'antd/lib/avatar/style/css';
import StatusHelper from "../Helpers/StatusHelper";

class Users extends React.Component {

  state = { active: true };

  componentDidMount() {
    this.props.getUsers(this.state.active);
  }

  fetchUser = (userId) => {
    this.props.getUser(userId).then(() => this.openUserModal(
        this.props.userList.selected_entry,
        (formValues) => {
          this.setState({ active: true });
          this.props.updateUser(formValues, userId);
        }
    ));
  };

  openUserModal = (user, onSubmit) => {
    this.props.modalShow({
      modalType: USERS_MODAL,
      modalProps: {user: user, onSubmit: onSubmit}
    });
  };

  columns = () => {
    return [{
      title: '#',
      dataIndex: 'id',
      key: 'id'
    }, {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name'
    }, {
      title: 'Usuario',
      dataIndex: 'username',
      key: 'username'
    }, {
      title: 'Estado',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => <StatusHelper type={'user'} position={'right'} status={record.status}/>
    }, {
      title: 'Admin',
      dataIndex: 'admin',
      key: 'admin',
      render: (text, record) => <Avatar style={{backgroundColor: record.admin ? '#679818' : '#e83b1d'}}
                                        icon={record.admin ? 'like' : 'dislike'}/>
    }, {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record) => {
        return (
          <Button type={"primary"}
                  htmlType={'button'}
                  onClick={() => this.fetchUser(record.id)}>
            <span><Icon type="eye"/> Ver</span>
          </Button>
        );
      }
    }]
  };

  render() {
    return (
        <Card
            bodyStyle={{padding: 0}} title={'Usuarios'}
            extra={
              <div>
                <Button
                  type={'ghost'}
                  htmlType={'button'}
                  style={{marginRight: 10}}
                  onClick={() => this.setState({ active: !this.state.active }, () => this.props.getUsers(this.state.active))}
                >
                  { this.state.active ? 'Mostrar Inactivos' : 'Mostrar activos' }
                </Button>
                <Button
                  type={'primary'}
                  htmlType={'button'}
                  onClick={() => this.openUserModal(null, this.props.createUser)}
                >
                  Agregar
                </Button>
              </div>
            }
        >
          <Row>
            <Table scroll={{x: '100%'}}  dataSource={this.props.userList.kind === 'users' ? this.props.userList.entries : []} rowKey={'id'} columns={this.columns()}/>
          </Row>
        </Card>
    );
  }
};

const mapStateToProps = state => {
  return {
    userList: state.userList
  };
};

export default connect(mapStateToProps, {modalShow, getUser, getUsers, createUser, updateUser})(Users);