import {CREATE_RECOMMENDATION} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import {formDataHelper} from "../functions/formDataHelper";
import authorizedPost from "../../apis/authorizedPost";
import modalDismiss from "../modal/modalDismiss";

export default (formValues) => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedPost('/recommendations', formDataHelper(formValues, 'recommendation', [{name: 'avatar', key: '[avatar]'}])),
      simpleSuccess(CREATE_RECOMMENDATION),
      null,
      [modalDismiss]
  );
};