import React, {useEffect} from 'react';
import {Field, FieldArray, reduxForm} from "redux-form";
import {Button, Card, Col, Form, Row} from "antd";
import 'antd/lib/button/style/css';
import 'antd/lib/card/style/css';
import 'antd/lib/col/style/css';
import 'antd/lib/form/style/css';
import 'antd/lib/row/style/css';
import _ from 'lodash';
import QuillTextArea from "../Helpers/QuillTextArea";

const IndirectForm = ({fields, change, indirectEntry}) => {
  
  useEffect(() => {
    if (fields.length === 0)
      fields.push({});
  }, []);
  
  return (
    fields.map((indirect_entries_attributes, index) => {
      return (
        <div key={index}>
          <Row gutter={16}>
            <Col xs={24} sm={24}>
              <Field label={'Contenido'}
                     name={`${indirect_entries_attributes}.content`}
                     component={QuillTextArea}
                     change={change} />
            </Col>
          </Row>
          <Row className={'p-20'}>
            <Button type={'primary float-right'} htmlType={'submit'}>Guardar</Button>
          </Row>
        </div>
      )
    })
  );
};

const IndirectEntryForm = ({handleSubmit, initialize, change, onSubmit, indirectEntry}) => {
  
  useEffect(() => {
    if (indirectEntry)
      initialize({indirect_section: {indirect_entries_attributes: [_.omit(indirectEntry, ['avatar'])]}});
  }, []);
  
  return (
    <Card title={'Revisión de indirectos'}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FieldArray name={'indirect_section[indirect_entries_attributes]'} component={IndirectForm}
                    props={{indirectEntry: indirectEntry, change: change}}/>
      </Form>
    </Card>
  );
};

export default reduxForm({
  form: 'indirect_entry_form',
  touchOnChange: true
})(IndirectEntryForm);