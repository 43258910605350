import {DELETE_ELEMENT_SUB_SUB_ENTRY} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedDelete from "../../apis/authorizedDelete";

export default (elementSubEntryId, entryId) => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedDelete(`/element_sub_entries/${elementSubEntryId}/entry/${entryId}`),
      simpleSuccess(DELETE_ELEMENT_SUB_SUB_ENTRY));
};