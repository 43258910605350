import {CREATE_FINDING_ENTRY} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedPost from "../../apis/authorizedPost";
import {formDataHelper} from "../functions/formDataHelper";
import modalDismiss from "../modal/modalDismiss";

export default (formValues) => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedPost(
        `/finding_entries`,
        formDataHelper(
          formValues,
          'finding_entry',
          [{name: 'evidence', key: '[evidence]'}, {name: 'closing', key: '[closing]'}]
        )
      ),
      simpleSuccess(CREATE_FINDING_ENTRY),
      null,
      [modalDismiss]
  );
};