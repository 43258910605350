import React from "react";
import {Field, Form, reduxForm} from "redux-form";
import {CreateCheckbox, CreateTextArea, CreateTextField} from "../Helpers/Input/InputCreator";
import {maxLength200, minLength5, required} from "../Helpers/Input/validations";
import {Alert, Col, Row} from "antd";
import 'antd/lib/alert/style/css';
import 'antd/lib/col/style/css';
import 'antd/lib/row/style/css';

const ProjectThird = ({onSubmit, handleSubmit, actions}) => {
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Alert message={'Completa los datos de terceros del proyecto'}/>
      <Row gutter={16}>
        <Col xs={24} sm={8}>
          <Field name='project[supervision]'
                 component={CreateCheckbox}
                 hasFeedback>
            Superv. técnica
          </Field>
        </Col>
        <Col xs={24} sm={8}>
          <Field name='project[audit]'
                 component={CreateCheckbox}
                 hasFeedback>
            Interv. administrativa
          </Field>
        </Col>
        <Col xs={24} sm={8}>
          <Field name='project[techaudit]'
                 component={CreateCheckbox}
                 hasFeedback>
            Interv. técnica
          </Field>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Field label={'Estructura'}
                 name={`project[structure]`}
                 component={CreateTextArea}
                 placeholder="Estructura"
                 validate={[required, minLength5, maxLength200]}
                 hasFeedback/>
        </Col>
        <Col xs={24} sm={12}>
          <Field label={'Proposito'}
                 name={`project[purpose]`}
                 component={CreateTextArea}
                 placeholder="Proposito"
                 validate={[required, minLength5, maxLength200]}
                 hasFeedback/>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Field label={'Nombre del cliente'}
                 name={`project[client_name]`}
                 component={CreateTextField}
                 placeholder="Nombre del cliente"
                 validate={[required, minLength5, maxLength200]}
                 hasFeedback/>
        </Col>
        <Col xs={24} sm={12}>
          <Field label={'Nombre del representante'}
                 name={`project[representative_name]`}
                 component={CreateTextField}
                 placeholder="Nombre del representante"
                 validate={[required, minLength5, maxLength200]}
                 hasFeedback/>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Field label={'Nombre del constructor'}
                 name={`project[constructor_name]`}
                 component={CreateTextField}
                 placeholder="Nombre del constructor"
                 validate={[required, minLength5, maxLength200]}
                 hasFeedback/>
        </Col>
        <Col xs={24} sm={12}>
          <Field label={'Nombre del diseñador'}
                 name={`project[designer_name]`}
                 component={CreateTextField}
                 placeholder="Nombre del diseñador"
                 validate={[required, minLength5, maxLength200]}
                 hasFeedback/>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Field label={'Nombre del encargado de suelos'}
                 name={`project[floor_name]`}
                 component={CreateTextField}
                 placeholder="Nombre del encargado de suelos"
                 validate={[required, minLength5, maxLength200]}
                 hasFeedback/>
        </Col>
      </Row>
      {actions}
    </Form>
  );
};

export default reduxForm({
  form: 'projects_form',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  touchOnChange: true
})(ProjectThird);