import {CREATE_INSTRUMENTS} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedPost from "../../apis/authorizedPost";

export default (resultSectionId) => async (dispatch) => {
  await loadingCall(
    dispatch,
    authorizedPost(`/result_sections/${resultSectionId}/instruments`),
    simpleSuccess(CREATE_INSTRUMENTS)
  );
};