import { Card, Row, Divider } from "antd";
import React from "react";
import { connect } from "react-redux";
import getPhotosVideos from "../../actions/projectsmedia/getPhotosVideos";
import dayjs from "dayjs";
import ImageGallery from "./imageGallery";
import deletePhotosVideosSingleAsset from "../../actions/projectsmedia/deletePhotosVideoSingleAsset";


class PhotosVideosDetails extends React.Component {

    componentDidMount() {
        this.loadRecords();
    }

    loadRecords() {
        this.props.getPhotosVideos(this.props.match.params.id, this.props.match.params.asset_id);
    }

    deleteAsset (assetId) {
        this.props.deletePhotosVideosSingleAsset(this.props.match.params.id, this.props.match.params.asset_id, assetId);
    }
    
    formatDate(d) {
        return dayjs(d).format("DD/MM/YYYY");
    }

    render(){

        return(
            <Card title={'Fotos y videos'}>
                <div>
                    {[...Array(this.props.media_list.length)].map((_, rowIndex) => (
                        <Row key={rowIndex} gutter={[16, 16]}>
                            <Divider orientation="left" plain>
                                Semana del {this.formatDate(this.props.media_list[rowIndex].start)} al {this.formatDate(this.props.media_list[rowIndex].end)}
                            </Divider>
                            <ImageGallery assets={this.props.media_list[rowIndex].media} deleteAction={(id) => this.deleteAsset(id)} />
                        </Row>
                    ))}
                </div>
            </Card>
        );
    }
}

const mapStateToProps = state => {
    return {
        projectId: state.auth.target_id,
        media_list: state.userList.entries
    }
}

export default connect(mapStateToProps, {
    getPhotosVideos,
    deletePhotosVideosSingleAsset
})(PhotosVideosDetails);