import React from 'react';
import {Field} from "redux-form";
import {CreateFileField, CreateTextArea, CreateTextField} from "../Helpers/Input/InputCreator";
import {
  fileImage,
  fileRequired,
  fileVerification,
  logicDate,
  maxLength120,
  minLength5,
  required,
  validDate
} from "../Helpers/Input/validations";
import {Col, Row} from "antd";
import {dateFormat} from "../Helpers/Input/format";

const FindingClosureFields = ({change}) => {

  return (
    <React.Fragment>
      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Field label={'Fecha de cierre'}
                 name={`finding_entry[end_date]`}
                 component={CreateTextField}
                 placeholder="Fecha de cierre"
                 normalize={dateFormat}
                 validate={[required, validDate, logicDate]}
                 hasFeedback/>
        </Col>
        <Col xs={24} sm={12}>
          <Field label={'Reparación'}
                 name={`finding_entry[reparation]`}
                 component={CreateTextArea}
                 placeholder="Reparación"
                 validate={[required, minLength5, maxLength120]}
                 hasFeedback/>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Field label={'Evidencia de cierre'}
                 name={'closing'}
                 component={CreateFileField}
                 validate={[fileRequired, fileVerification, fileImage]}
                 change={change}
                 isImage
                 type='file'
                 hasFeedback
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default FindingClosureFields;