import {
  DELETE_FREE_ENTRY_IMAGE
} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedPut from "../../apis/authorizedPut";

export default (freeEntryId, freeId, imageId, callback) => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedPut(
          `/free_sub_sections/${freeId}/entry_image`,{free_sub_section: {entry_id: freeEntryId, image_id: imageId}}
      ),
      simpleSuccess(DELETE_FREE_ENTRY_IMAGE),
      null,
      [],
      [callback]);
};