import React from 'react';
import {connect} from 'react-redux';
import './Loader.css';

class LoaderContainer extends React.Component {
  
  renderLoader() {
    if (this.props.loading) {
      return (
        <div className="loading-container">
          <div id="circle">
            <div className="loader">
              <div className="loader">
                <div className="loader">
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
  
  
  render() {
    return (
      <React.Fragment>
        {this.renderLoader()}
      </React.Fragment>
    );
  }
  
}

const mapStateToProps = state => {
  return {
    loading: state.loading.loading
  };
};

export default connect(mapStateToProps)(LoaderContainer);