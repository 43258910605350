import React from "react";
import {Button, Card, Collapse, Icon, Row, Table} from 'antd';
import 'antd/lib/button/style/css';
import 'antd/lib/card/style/css';
import 'antd/lib/col/style/css';
import 'antd/lib/icon/style/css';
import 'antd/lib/input/style/css';
import 'antd/lib/row/style/css';
import 'antd/lib/table/style/css';
import {connect} from "react-redux";
import modalShow from '../../actions/modal/modalShow';
import {TRACING_VIEW} from "../../paths";
import {push} from 'connected-react-router';
import {ELEMENT_SUB_ENTRY_MODAL, PLANT_SUB_ENTRY_MODAL, RECOMMENDATION_SUB_ENTRY_MODAL} from "../Feedback/Modal/types";
import getMonthlyWeekEntry from "../../actions/monthlyWeekEntry/getMonthlyWeekEntry";
import createPlantSubEntry from "../../actions/monthlyWeekEntry/createPlantSubEntry";
import updatePlantSubEntry from "../../actions/monthlyWeekEntry/updatePlantSubEntry";
import createRecommendationSubEntry from "../../actions/monthlyWeekEntry/createRecommendationSubEntry";
import updateRecommendationSubEntry from "../../actions/monthlyWeekEntry/updateRecommendationSubEntry";
import createElementSubEntry from "../../actions/monthlyWeekEntry/createElementSubEntry";
import updateElementSubEntry from "../../actions/monthlyWeekEntry/updateElementSubEntry";
import getElementSubEntry from "../../actions/elementSubEntry/getElementSubEntry";
import {marginRight, noPadding} from "../../const/styles";
import deleteRecommendationSubEntry from "../../actions/monthlyWeekEntry/deleteRecommendationSubEntry";
import deletePlantSubEntry from "../../actions/monthlyWeekEntry/deletePlantSubEntry";
import deleteElementSubEntry from "../../actions/monthlyWeekEntry/deleteElementSubEntry";
import {confirmDelete} from "../../apis/authorizedDelete";

class MonthlyWeek extends React.Component {
  
  componentDidMount() {
    this.props.getMonthlyWeekEntry(this.props.match.params.monthly_week_id);
  };
  
  handlePlantCreate = (formValues) => {
    this.props.createPlantSubEntry(formValues, this.props.match.params.monthly_week_id);
  };
  
  handlePlantUpdate = (formValues) => {
    this.props.updatePlantSubEntry(formValues, this.props.match.params.monthly_week_id);
  };
  
  openPlantModal = (entry, onSubmit) => {
    this.props.modalShow({
      modalType: PLANT_SUB_ENTRY_MODAL,
      modalProps: {plantSubEntry: entry, onSubmit: onSubmit}
    });
  };
  
  handleRecommendationCreate = (formValues) => {
    this.props.createRecommendationSubEntry(formValues, this.props.match.params.monthly_week_id);
  };
  
  handleRecommendationUpdate = (formValues) => {
    this.props.updateRecommendationSubEntry(formValues, this.props.match.params.monthly_week_id);
  };
  
  openRecommendationModal = (entry, onSubmit) => {
    this.props.modalShow({
      modalType: RECOMMENDATION_SUB_ENTRY_MODAL,
      modalProps: {recommendationSubEntry: entry, onSubmit: onSubmit}
    });
  };
  
  handleElementCreate = (formValues) => {
    this.props.createElementSubEntry(formValues, this.props.match.params.monthly_week_id);
  };
  
  handleElementUpdate = (formValues) => {
    this.props.updateElementSubEntry(formValues, this.props.match.params.monthly_week_id);
  };
  
  fetchElementEntry = (elementSubEntryId) => {
    this.props.getElementSubEntry(elementSubEntryId).then(() => this.openElementEntryModal(null, null));
  };
  
  openElementEntryModal = (entry, onSubmit) => {
    this.props.modalShow({
      modalType: ELEMENT_SUB_ENTRY_MODAL,
      modalProps: {elementSubEntry: entry, onSubmit: onSubmit}
    });
  };
  
  plantColumns = () => {
    return [{
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    }, {
      title: 'Contenido',
      dataIndex: 'content',
      key: 'content',
      render: (text) => {
        return <Icon type={text && text.length > 15 ? 'check' : 'close'}/>;
      }
    }, {
      title: 'Imagen',
      dataIndex: 'avatar',
      key: 'avatar',
      render: (text) => {
        return <Icon type={text ? 'check' : 'close'}/>;
      }
    }, {
      title: 'Comentario',
      dataIndex: 'comment',
      key: 'comment',
      render: (text) => {
        return <Icon type={text && text.length > 0 ? 'check' : 'close'}/>;
      }
    }, {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record) => {
        return (
          <div>
            <Button onClick={() => this.openPlantModal(record, this.handlePlantUpdate)} type={"primary"}
                    htmlType={'button'}>
              <Icon type="eye"/>
            </Button>
            <Button
              onClick={() => confirmDelete(() => this.props.deletePlantSubEntry(this.props.match.params.monthly_week_id, record.id))}
              type={"danger"}
              style={marginRight}
              htmlType={'button'}>
              <Icon type="delete"/>
            </Button>
          </div>
        );
      }
    }]
  };
  
  recommendationColumns = () => {
    return [{
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    }, {
      title: 'Contenido',
      dataIndex: 'content',
      key: 'content',
      render: (text) => {
        return <Icon type={text && text.length > 15 ? 'check' : 'close'}/>;
      }
    }, {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record) => {
        return (
          <div>
            <Button onClick={() => this.openRecommendationModal(record, this.handleRecommendationUpdate)}
                    type={"primary"} htmlType={'button'}>
              <Icon type="eye"/>
            </Button>
            <Button
              onClick={() => confirmDelete(() => this.props.deleteRecommendationSubEntry(this.props.match.params.monthly_week_id, record.id))}
              type={"danger"}
              style={marginRight}
              htmlType={'button'}>
              <Icon type="delete"/>
            </Button>
          </div>
        );
      }
    }]
  };
  
  elementColumns = () => {
    return [{
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    }, {
      title: 'Titulo',
      dataIndex: 'title',
      key: 'title',
      render: (text) => {
        return <Icon type={text && text.length > 0 ? 'check' : 'close'}/>;
      }
    }, {
      title: 'Contenido',
      dataIndex: 'content',
      key: 'content',
      render: (text) => {
        return <Icon type={text && text.length > 15 ? 'check' : 'close'}/>;
      }
    }, {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record) => {
        return (
          <div>
            <Button style={marginRight} onClick={() => this.openElementEntryModal(record, this.handleElementUpdate)}
                    type={"primary"}
                    htmlType={'button'}>
              <Icon type="eye"/>
            </Button>
            <Button style={marginRight} onClick={() => this.fetchElementEntry(record.id)} type={"ghost"}
                    htmlType={'button'}>
              Sub Entradas
            </Button>
            <Button
              onClick={() => confirmDelete(() => this.props.deleteElementSubEntry(this.props.match.params.monthly_week_id, record.id))}
              type={"danger"}
              style={marginRight}
              htmlType={'button'}>
              <Icon type="delete"/>
            </Button>
          </div>
        );
      }
    }]
  };
  
  render() {
    return (
      <Card bodyStyle={{padding: 0}}
            extra={
              <div>
                <Button htmlType={'button'} type={'ghost'}
                        onClick={() => this.props.push(TRACING_VIEW(this.props.match.params.id, this.props.match.params.tracing_id))}>Volver</Button>
              </div>
            }
            title={'Entrada de seguimiento semanal'}>
        <Collapse>
          <Collapse.Panel header={'Sub entradas de planta'} key="1">
            <Card
                bodyStyle={noPadding} title={'Sub entradas de planta'}
                extra={
                  <Button type={'primary  float-right'}
                          htmlType={'button'}
                          onClick={() => this.openPlantModal(null, this.handlePlantCreate)}>
                    Agregar
                  </Button>
                }
            >
              <Row>
                <Table scroll={{x: '100%'}}
                  dataSource={this.props.monthlyWeekEntry.kind === 'monthly_week_entries' ? this.props.monthlyWeekEntry.plant_entries : []}
                  rowKey={'id'} columns={this.plantColumns()}/>
              </Row>
            </Card>
          </Collapse.Panel>
          <Collapse.Panel header={'Sub entradas de recomendaciones'} key="2">
            <Card
                bodyStyle={noPadding} title={'' +
            'Sub entradas de recomendaciones'}
                extra={
                  <Button type={'primary float-right'}
                          htmlType={'button'}
                          onClick={() => this.openRecommendationModal(null, this.handleRecommendationCreate)}>
                    Agregar
                  </Button>
                }
            >
              <Row>
                <Table scroll={{x: '100%'}}
                  dataSource={this.props.monthlyWeekEntry.kind === 'monthly_week_entries' ? this.props.monthlyWeekEntry.recommendation_entries : []}
                  rowKey={'id'} columns={this.recommendationColumns()}/>
              </Row>
            </Card>
          </Collapse.Panel>
          <Collapse.Panel header={'Sub entradas semanales'} key="3">
            <Card
                bodyStyle={noPadding} title={'Sub entradas semanales'}
                extra={
                  <Button type={'primary  float-right'}
                          htmlType={'button'}
                          onClick={() => this.openElementEntryModal(null, this.handleElementCreate)}>
                    Agregar
                  </Button>
                }
            >
              <Row>
                <Table scroll={{x: '100%'}}
                  dataSource={this.props.monthlyWeekEntry.kind === 'monthly_week_entries' ? this.props.monthlyWeekEntry.element_entries : []}
                  rowKey={'id'} columns={this.elementColumns()}/>
              </Row>
            </Card>
          </Collapse.Panel>
        </Collapse>
      </Card>
    );
  }
}


const mapStateToProps = state => {
  return {
    monthlyWeekEntry: state.userList.selected_entry
  };
};


export default connect(mapStateToProps, {
  push,
  modalShow,
  getMonthlyWeekEntry,
  createPlantSubEntry,
  deletePlantSubEntry,
  updatePlantSubEntry,
  createRecommendationSubEntry,
  deleteRecommendationSubEntry,
  updateRecommendationSubEntry,
  createElementSubEntry,
  deleteElementSubEntry,
  updateElementSubEntry,
  getElementSubEntry
})(MonthlyWeek);