import {combineReducers} from 'redux';
import {reducer as formReducer} from 'redux-form';
import {connectRouter} from 'connected-react-router';
import modalReducer from "./modalReducer";
import loadingReducer from "./loadingReducer";
import infoReducer from "./infoReducer";
import authReducer from './authReducer';
import userListReducer from './userListReducer';
import modalListReducer from './modalListReducer';
import configReducer from './configReducer';

export default (history) => combineReducers({
  router: connectRouter(history),
  form: formReducer,
  modal: modalReducer,
  loading: loadingReducer,
  info: infoReducer,
  auth: authReducer,
  userList: userListReducer,
  modalList: modalListReducer,
  config: configReducer
});