import {DELETE_RECOMMENDATION_SUB_ENTRY} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedDelete from "../../apis/authorizedDelete";

export default (monthlyWeekEntryId, entryId) => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedDelete(`/monthly_week_entries/${monthlyWeekEntryId}/recommendation_entry/${entryId}`),
      simpleSuccess(DELETE_RECOMMENDATION_SUB_ENTRY));
};