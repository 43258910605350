import React, {useEffect} from 'react';
import {Field, FieldArray, reduxForm} from "redux-form";
import {Button, Card, Col, Form, Row} from "antd";
import 'react-quill/dist/quill.snow.css';
import 'antd/lib/button/style/css';
import 'antd/lib/card/style/css';
import 'antd/lib/col/style/css';
import 'antd/lib/form/style/css';
import 'antd/lib/row/style/css';
import _ from 'lodash';
import {CreateFileField, CreateTextArea, CreateTextField} from "../Helpers/Input/InputCreator";
import {fileImage, fileVerification, maxLength50, minLength5,} from "../Helpers/Input/validations";
import QuillTextArea from "../Helpers/QuillTextArea";

const NetworkForm = ({fields, change, networkEntry}) => {
  
  useEffect(() => {
    if (fields.length === 0)
      fields.push({});
  }, []);
  
  return (
    fields.map((network_entries_attributes, index) => {
      return (
        <div key={index}>
          <Row gutter={16}>
            <Col xs={24} sm={24}>
              <Field label={'Titulo'}
                     name={`${network_entries_attributes}.title`}
                     component={CreateTextField}
                     placeholder="Titulo"
                     validate={[minLength5, maxLength50]}
                     hasFeedback/>
            </Col>
            <Col xs={24} sm={24}>
              <Field label={'Contenido'}
                     name={`${network_entries_attributes}.content`}
                     component={QuillTextArea}
                     change={change} />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Field label={'Imagen de la revisión'}
                     name={'avatar'}
                     preview={networkEntry ? networkEntry.avatar : null}
                     component={CreateFileField}
                     validate={[fileVerification, fileImage]}
                     change={change}
                     isImage
                     type='file'
                     hasFeedback
              />
            </Col>
            <Col xs={24} sm={12}>
              <Field label={'Comentario para la foto'}
                     name={`${network_entries_attributes}.comment`}
                     component={CreateTextArea}
                     placeholder="Comentario para la foto"
                     hasFeedback/>
            </Col>
          </Row>
          <Row className={'p-20'}>
            <Button type={'primary float-right'} htmlType={'submit'}>Guardar</Button>
          </Row>
        </div>
      )
    })
  );
};

const NetworkEntryForm = ({handleSubmit, initialize, change, onSubmit, networkEntry}) => {
  
  useEffect(() => {
    if (networkEntry)
      initialize({network_section: {network_entries_attributes: [_.omit(networkEntry, ['avatar'])]}});
  }, []);
  
  return (
    <Card title={'Revisión de redes'}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FieldArray name={'network_section[network_entries_attributes]'} component={NetworkForm}
                    props={{networkEntry: networkEntry, change: change}}/>
      </Form>
    </Card>
  );
};

export default reduxForm({
  form: 'network_entry_form',
  touchOnChange: true
})(NetworkEntryForm);