import {FINISH_ADMINISTRATIVE_SECTION} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedPut from "../../apis/authorizedPut";

export default (administrativeSectionId) => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedPut(`/administrative_sections/${administrativeSectionId}/finish`),
    simpleSuccess(FINISH_ADMINISTRATIVE_SECTION)
  );
};