import React from 'react';
import {connect} from "react-redux";
import ProjectsDocumentsTable from "./ProjectsDocumentsTable";
import ProjectsDocumentsForm from "./ProjectsDocumentsForm";
import createProjectDocument from "../../actions/projects/createProjectDocument";
import getProjectDocument from "../../actions/projects/getProjectDocument";
import updateProjectDocument from "../../actions/projects/updateProjectDocument";
import {Modal} from "antd";
import 'antd/lib/modal/style/css';
import {noPadding} from "../../const/styles";
import modalDismiss from "../../actions/modal/modalDismiss";

class ProjectsDocumentsModal extends React.Component {
  state = {
    selected: this.props.selected,
    update: this.props.update
  };

  handleCreate = () => {
    this.setState({ selected: true });
  };

  handleUpdate = (documentId) => {
    this.props.getProjectDocument(this.props.projectId, documentId).then(() => {
      this.setState({selected: true, update: true});
    });
  };

  handleReturn = () => {
    if (this.props.user)
      this.props.modalDismiss();
    else
      this.setState({selected: false, update: false});
  };

  handleRender = () => {
    if(this.state.selected) {
      return (
        <ProjectsDocumentsForm
          user={this.props.user}
          document={this.state.update ? this.props.user ? this.props.entry : this.props.modalList.selected_entry : null}
          handleReturn={this.handleReturn}
          onSubmit={this.state.update ?
                (formValues) => this.props.updateProjectDocument(formValues, this.props.projectId, this.handleReturn) :
                (formValues) => this.props.createProjectDocument(formValues, this.props.projectId, this.handleReturn)
            }
        />
      );
    } else {
      return (
        <ProjectsDocumentsTable
            handleCreate={this.handleCreate}
            handleUpdate={this.handleUpdate}
            documents={this.props.modalList.kind === 'documents' ? this.props.modalList.entries : []}/>
      );
    }
  };

  render() {
    const {modalType, modalProps} = this.props;
    return (
      <Modal bodyStyle={noPadding} footer={null} className={'content-wrapper'} visible={modalType !== null} style={{ top: 30 }}
             width={720} {...modalProps}>
        {this.handleRender()}
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return ({
    modalList: state.modalList
  });
};

export default connect(mapStateToProps, {
  createProjectDocument,
  getProjectDocument,
  updateProjectDocument,
  modalDismiss
})(ProjectsDocumentsModal);