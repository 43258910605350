import {
  CREATE_ADMINISTRATIVE_SECTION,
  CREATE_ADVANCE_ENTRY,
  CREATE_AGREEMENT_ENTRY,
  CREATE_ANCHORS,
  CREATE_ANCHORS_ENTRY,
  CREATE_BUDGET_ENTRY, CREATE_CLIENT,
  CREATE_CONCEPT_ENTRY,
  CREATE_CONCEPT_SECTION,
  CREATE_CONCRETE,
  CREATE_CONCRETE_GRAPH,
  CREATE_CONCRETE_TABLE,
  CREATE_CONNECTORS,
  CREATE_CONNECTORS_ENTRY,
  CREATE_CONTRACT_ENTRY,
  CREATE_DAILY_ENTRY,
  CREATE_DAILY_REPORT,
  CREATE_DAILY_SUGGESTION,
  CREATE_ELEMENT_SUB_ENTRY,
  CREATE_FINDING_ENTRY,
  CREATE_FREE,
  CREATE_FREE_ENTRY,
  CREATE_INDIRECT_ENTRY,
  CREATE_INDIRECT_SECTION,
  CREATE_INSTRUMENTATION_ENTRY,
  CREATE_INSTRUMENTS,
  CREATE_LOCATION_SECTION,
  CREATE_MESSAGE,
  CREATE_MONTHLY_REPORT,
  CREATE_MONTHLY_WEEK_ENTRY,
  CREATE_MORTAR_GRAPH,
  CREATE_MORTAR_TABLE,
  CREATE_MORTARS,
  CREATE_NETWORK_ENTRY,
  CREATE_NETWORK_SECTION,
  CREATE_NOT_STRUCTURAL_ENTRY,
  CREATE_NOT_STRUCTURAL_SECTION,
  CREATE_PLANT_SUB_ENTRY,
  CREATE_PROGRAMMING_ENTRY,
  CREATE_PROJECT,
  CREATE_PROJECT_CLIENT,
  CREATE_PROJECT_DOCUMENT,
  CREATE_PROTOCOL,
  CREATE_RAIN_ENTRY,
  CREATE_RAIN_SECTION,
  CREATE_RECOMMENDATION,
  CREATE_RECOMMENDATION_SECTION,
  CREATE_RECOMMENDATION_SUB_ENTRY,
  CREATE_REQUIREMENT,
  CREATE_RESULT_SECTION,
  CREATE_SECURITY_ENTRY,
  CREATE_SECURITY_SECTION,
  CREATE_STEELS,
  CREATE_STEELS_ENTRY,
  CREATE_TRACING_SECTION,
  CREATE_USER,
  CREATE_WEEKLY_ENTRY,
  CREATE_WEEKLY_REPORT,
  CREATE_WEEKLY_SUGGESTION,
  DELETE_ADMINISTRATIVE_SECTION,
  DELETE_ADVANCE_ENTRY,
  DELETE_AGREEMENT_ENTRY,
  DELETE_ANCHORS,
  DELETE_ANCHORS_ENTRY,
  DELETE_BUDGET_ENTRY,
  DELETE_CONCEPT_ENTRY,
  DELETE_CONCEPT_SECTION,
  DELETE_CONCRETE,
  DELETE_CONCRETE_GRAPH,
  DELETE_CONCRETE_TABLE,
  DELETE_CONNECTORS,
  DELETE_CONNECTORS_ENTRY,
  DELETE_CONTRACT_ENTRY,
  DELETE_DAILY_ENTRY_IMAGE,
  DELETE_ELEMENT_SUB_ENTRY,
  DELETE_FREE,
  DELETE_FREE_ENTRY,
  DELETE_INDIRECT_ENTRY,
  DELETE_INDIRECT_SECTION,
  DELETE_INSTRUMENTATION_ENTRY,
  DELETE_INSTRUMENTATION_ENTRY_IMAGE,
  DELETE_INSTRUMENTS,
  DELETE_LOCATION_SECTION,
  DELETE_MONTHLY_WEEK_ENTRY,
  DELETE_MORTAR_GRAPH,
  DELETE_MORTAR_TABLE,
  DELETE_MORTARS,
  DELETE_NETWORK_ENTRY,
  DELETE_NETWORK_SECTION,
  DELETE_NETWORK_SUB_ENTRY,
  DELETE_NOT_STRUCTURAL_ENTRY,
  DELETE_NOT_STRUCTURAL_SECTION,
  DELETE_PLANT_SUB_ENTRY,
  DELETE_PROGRAMMING_ENTRY,
  DELETE_RAIN_ENTRY,
  DELETE_RAIN_SECTION,
  DELETE_RECOMMENDATION_SECTION,
  DELETE_RECOMMENDATION_SUB_ENTRY,
  DELETE_RESULT_SECTION,
  DELETE_SECURITY_ENTRY,
  DELETE_SECURITY_SECTION,
  DELETE_STEELS,
  DELETE_STEELS_ENTRY,
  DELETE_TRACING_SECTION,
  DELETE_WEEKLY_ENTRY_IMAGE,
  FINISH_ADMINISTRATIVE_SECTION,
  FINISH_CONCEPT_SECTION,
  FINISH_DAILY_REPORT,
  FINISH_INDIRECT_SECTION,
  FINISH_MONTHLY_REPORT,
  FINISH_NETWORK_SECTION,
  FINISH_NOT_STRUCTURAL_SECTION,
  FINISH_RAIN_SECTION,
  FINISH_RECOMMENDATION_SECTION,
  FINISH_RESULT_SECTION,
  FINISH_SECURITY_SECTION,
  FINISH_TRACING_SECTION,
  FINISH_WEEKLY_REPORT,
  FIX_MONTHLY_REPORT,
  GET_ADMINISTRATIVE_SECTION,
  GET_ALERTS,
  GET_ANCHORS_SUB_SECTION, GET_CLIENT, GET_CLIENTS,
  GET_CONCEPT_SECTION,
  GET_CONCRETE_SUB_SECTION,
  GET_CONNECTORS_SUB_SECTION,
  GET_DAILY_REPORT,
  GET_DAILY_REPORTS_PANEL,
  GET_FINDING_ENTRIES_PANEL,
  GET_FINDING_ENTRY,
  GET_FREE_SUB_SECTION,
  GET_INDIRECT_SECTION,
  GET_INSTRUMENTATION_SUB_SECTION,
  GET_MESSAGES,
  GET_MONTHLY_REPORT,
  GET_MONTHLY_REPORTS_PANEL,
  GET_MONTHLY_WEEK_ENTRY,
  GET_MORTAR_SUB_SECTION,
  GET_NETWORK_SECTION,
  GET_NOT_STRUCTURAL_SECTION,
  GET_PROJECT, GET_PROJECT_CLIENTS, GET_PROJECT_DAILY, GET_PROJECT_FINDINGS, GET_PROJECT_MONTHLY,
  GET_PROJECT_PROTOCOLS,
  GET_PROJECT_USERS, GET_PROJECT_WEEKLY,
  GET_PROJECTS,
  GET_PROTOCOL,
  GET_PROTOCOLS,
  GET_RAIN_SECTION,
  GET_RECOMMENDATION,
  GET_RECOMMENDATION_SECTION,
  GET_RECOMMENDATIONS,
  GET_REQUIREMENT,
  GET_REQUIREMENTS,
  GET_RESULT_SECTION,
  GET_SECURITY_SECTION,
  GET_STEELS_SUB_SECTION,
  GET_TRACING_SECTION,
  GET_USER,
  GET_USERS,
  GET_WEEKLY_REPORT,
  GET_WEEKLY_REPORTS_PANEL,
  LOG_OUT,
  UPDATE_ADVANCE_ENTRY,
  UPDATE_AGREEMENT_ENTRY,
  UPDATE_ALERT,
  UPDATE_ANCHORS_ENTRY,
  UPDATE_ANCHORS_SUB_SECTION,
  UPDATE_BUDGET_ENTRY, UPDATE_CLIENT,
  UPDATE_CONCEPT_ENTRY,
  UPDATE_CONCRETE_GRAPH,
  UPDATE_CONCRETE_SUB_SECTION,
  UPDATE_CONCRETE_TABLE,
  UPDATE_CONNECTORS_ENTRY,
  UPDATE_CONNECTORS_SUB_SECTION,
  UPDATE_CONTRACT_ENTRY,
  UPDATE_DAILY_ENTRY,
  UPDATE_DAILY_SUGGESTION,
  UPDATE_ELEMENT_SUB_ENTRY,
  UPDATE_FINDING_ENTRY,
  UPDATE_FREE_ENTRY,
  UPDATE_INDIRECT_ENTRY,
  UPDATE_INSTRUMENTATION_ENTRY,
  UPDATE_MONTHLY_WEEK_ENTRY,
  UPDATE_MORTAR_GRAPH,
  UPDATE_MORTAR_SUB_SECTION,
  UPDATE_MORTAR_TABLE,
  UPDATE_NETWORK_ENTRY,
  UPDATE_NOT_STRUCTURAL_ENTRY,
  UPDATE_PLANT_SUB_ENTRY,
  UPDATE_PROGRAMMING_ENTRY,
  UPDATE_PROJECT,
  UPDATE_PROJECT_CLIENTS,
  UPDATE_PROJECT_DOCUMENT,
  UPDATE_PROJECT_PROTOCOLS,
  UPDATE_PROJECT_USERS,
  UPDATE_PROTOCOL,
  UPDATE_RAIN_ENTRY,
  UPDATE_RECOMMENDATION,
  UPDATE_RECOMMENDATION_SECTION,
  UPDATE_RECOMMENDATION_SUB_ENTRY,
  UPDATE_REQUIREMENT,
  UPDATE_SECURITY_ENTRY,
  UPDATE_STEELS_ENTRY,
  UPDATE_STEELS_SUB_SECTION,
  UPDATE_USER,
  UPDATE_WEEKLY_ENTRY,
  UPDATE_WEEKLY_SUGGESTION,
  DELETE_PROTOCOL_SECTION,
  CREATE_PROTOCOL_SECTION,
  GET_PROTOCOL_SECTION,
  UPDATE_PROTOCOL_SECTION,
  FINISH_PROTOCOL_SECTION,
  GET_PROJECT_MEDIA,
  UPLOAD_PROJECT_MEDIA,
  GET_PHOTOS_VIDEOS,
  GET_PROJECTS_ASSETS_RESUME
} from "../actions/types";

const INITIAL_STATE = {
  kind: '',
  entries: [],
  total_count: 0,
  selected_entry: {}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PROJECTS:
    case GET_PROJECT:
    case CREATE_PROJECT:
    case UPDATE_PROJECT:
    case GET_PROJECT_USERS:
    case UPDATE_PROJECT_USERS:
    case GET_PROJECT_PROTOCOLS:
    case UPDATE_PROJECT_PROTOCOLS:
    case CREATE_PROJECT_CLIENT:
    case UPDATE_PROJECT_CLIENTS:
    case CREATE_PROJECT_DOCUMENT:
    case UPDATE_PROJECT_DOCUMENT:
    case GET_PROJECT_DAILY:
    case GET_PROJECT_WEEKLY:
    case GET_PROJECT_MONTHLY:
    case GET_PROJECT_FINDINGS:
    case GET_PROJECT_CLIENTS:
    case GET_USERS:
    case GET_USER:
    case CREATE_USER:
    case UPDATE_USER:
    case GET_CLIENTS:
    case GET_CLIENT:
    case CREATE_CLIENT:
    case UPDATE_CLIENT:
    case GET_PROTOCOLS:
    case GET_PROTOCOL:
    case CREATE_PROTOCOL:
    case UPDATE_PROTOCOL:
    case GET_RECOMMENDATIONS:
    case GET_RECOMMENDATION:
    case CREATE_RECOMMENDATION:
    case UPDATE_RECOMMENDATION:
    case GET_REQUIREMENTS:
    case GET_REQUIREMENT:
    case CREATE_REQUIREMENT:
    case UPDATE_REQUIREMENT:
    case GET_DAILY_REPORTS_PANEL:
    case GET_DAILY_REPORT:
    case CREATE_DAILY_REPORT:
    case FINISH_DAILY_REPORT:
    case CREATE_DAILY_ENTRY:
    case UPDATE_DAILY_ENTRY:
    case DELETE_DAILY_ENTRY_IMAGE:
    case GET_WEEKLY_REPORTS_PANEL:
    case GET_WEEKLY_REPORT:
    case CREATE_WEEKLY_REPORT:
    case FINISH_WEEKLY_REPORT:
    case CREATE_WEEKLY_ENTRY:
    case UPDATE_WEEKLY_ENTRY:
    case DELETE_WEEKLY_ENTRY_IMAGE:
    case GET_FINDING_ENTRIES_PANEL:
    case CREATE_FINDING_ENTRY:
    case UPDATE_FINDING_ENTRY:
    case GET_FINDING_ENTRY:
    case GET_MONTHLY_REPORTS_PANEL:
    case CREATE_MONTHLY_REPORT:
    case GET_MONTHLY_REPORT:
    case CREATE_LOCATION_SECTION:
    case CREATE_NETWORK_SECTION:
    case CREATE_RECOMMENDATION_SECTION:
    case CREATE_PROTOCOL_SECTION:
    case CREATE_NOT_STRUCTURAL_SECTION:
    case GET_NOT_STRUCTURAL_SECTION:
    case CREATE_CONCEPT_SECTION:
    case CREATE_SECURITY_SECTION:
    case CREATE_TRACING_SECTION:
    case CREATE_ADMINISTRATIVE_SECTION:
    case GET_ADMINISTRATIVE_SECTION:
    case CREATE_CONTRACT_ENTRY:
    case UPDATE_CONTRACT_ENTRY:
    case CREATE_AGREEMENT_ENTRY:
    case UPDATE_AGREEMENT_ENTRY:
    case CREATE_BUDGET_ENTRY:
    case UPDATE_BUDGET_ENTRY:
    case CREATE_PROGRAMMING_ENTRY:
    case UPDATE_PROGRAMMING_ENTRY:
    case CREATE_INDIRECT_SECTION:
    case GET_CONCRETE_SUB_SECTION:
    case UPDATE_CONCRETE_SUB_SECTION:
    case CREATE_CONCRETE_GRAPH:
    case UPDATE_CONCRETE_GRAPH:
    case CREATE_CONCRETE_TABLE:
    case UPDATE_CONCRETE_TABLE:
    case GET_MORTAR_SUB_SECTION:
    case UPDATE_MORTAR_SUB_SECTION:
    case CREATE_MORTAR_GRAPH:
    case UPDATE_MORTAR_GRAPH:
    case CREATE_MORTAR_TABLE:
    case UPDATE_MORTAR_TABLE:
    case CREATE_RAIN_SECTION:
    case GET_TRACING_SECTION:
    case CREATE_ADVANCE_ENTRY:
    case UPDATE_ADVANCE_ENTRY:
    case CREATE_MONTHLY_WEEK_ENTRY:
    case UPDATE_MONTHLY_WEEK_ENTRY:
    case GET_NETWORK_SECTION:
    case CREATE_NETWORK_ENTRY:
    case UPDATE_NETWORK_ENTRY:
    case GET_INDIRECT_SECTION:
    case CREATE_INDIRECT_ENTRY:
    case UPDATE_INDIRECT_ENTRY:
    case CREATE_NOT_STRUCTURAL_ENTRY:
    case UPDATE_NOT_STRUCTURAL_ENTRY:
    case GET_CONCEPT_SECTION:
    case CREATE_CONCEPT_ENTRY:
    case UPDATE_CONCEPT_ENTRY:
    case GET_SECURITY_SECTION:
    case CREATE_SECURITY_ENTRY:
    case UPDATE_SECURITY_ENTRY:
    case GET_MONTHLY_WEEK_ENTRY:
    case CREATE_PLANT_SUB_ENTRY:
    case UPDATE_PLANT_SUB_ENTRY:
    case CREATE_RECOMMENDATION_SUB_ENTRY:
    case UPDATE_RECOMMENDATION_SUB_ENTRY:
    case CREATE_ELEMENT_SUB_ENTRY:
    case UPDATE_ELEMENT_SUB_ENTRY:
    case GET_RECOMMENDATION_SECTION:
    case GET_PROTOCOL_SECTION:
    case UPDATE_RECOMMENDATION_SECTION:
    case UPDATE_PROTOCOL_SECTION:
    case FINISH_RECOMMENDATION_SECTION:
    case FINISH_PROTOCOL_SECTION:
    case FINISH_NETWORK_SECTION:
    case FINISH_INDIRECT_SECTION:
    case FINISH_ADMINISTRATIVE_SECTION:
    case FINISH_RAIN_SECTION:
    case FINISH_RESULT_SECTION:
    case FINISH_CONCEPT_SECTION:
    case FINISH_TRACING_SECTION:
    case FINISH_SECURITY_SECTION:
    case FINISH_NOT_STRUCTURAL_SECTION:
    case CREATE_DAILY_SUGGESTION:
    case UPDATE_DAILY_SUGGESTION:
    case CREATE_WEEKLY_SUGGESTION:
    case UPDATE_WEEKLY_SUGGESTION:
    case FIX_MONTHLY_REPORT:
    case FINISH_MONTHLY_REPORT:
    case GET_MESSAGES:
    case GET_ALERTS:
    case CREATE_RESULT_SECTION:
    case CREATE_RAIN_ENTRY:
    case UPDATE_RAIN_ENTRY:
    case GET_RAIN_SECTION:
    case UPDATE_ALERT:
    case CREATE_CONCRETE:
    case CREATE_MORTARS:
    case CREATE_STEELS:
    case CREATE_ANCHORS:
    case CREATE_INSTRUMENTS:
    case CREATE_FREE:
    case CREATE_CONNECTORS:
    case GET_RESULT_SECTION:
    case CREATE_MESSAGE:
    case GET_CONNECTORS_SUB_SECTION:
    case UPDATE_CONNECTORS_SUB_SECTION:
    case CREATE_CONNECTORS_ENTRY:
    case UPDATE_CONNECTORS_ENTRY:
    case GET_STEELS_SUB_SECTION:
    case UPDATE_STEELS_SUB_SECTION:
    case CREATE_STEELS_ENTRY:
    case UPDATE_STEELS_ENTRY:
    case GET_ANCHORS_SUB_SECTION:
    case UPDATE_ANCHORS_SUB_SECTION:
    case CREATE_ANCHORS_ENTRY:
    case UPDATE_ANCHORS_ENTRY:
    case GET_INSTRUMENTATION_SUB_SECTION:
    case CREATE_INSTRUMENTATION_ENTRY:
    case UPDATE_INSTRUMENTATION_ENTRY:
    case DELETE_INSTRUMENTATION_ENTRY_IMAGE:
    case GET_FREE_SUB_SECTION:
    case CREATE_FREE_ENTRY:
    case UPDATE_FREE_ENTRY:
    case DELETE_LOCATION_SECTION:
    case DELETE_NETWORK_SECTION:
    case DELETE_RECOMMENDATION_SECTION:
    case DELETE_PROTOCOL_SECTION:
    case DELETE_NOT_STRUCTURAL_SECTION:
    case DELETE_CONCEPT_SECTION:
    case DELETE_SECURITY_SECTION:
    case DELETE_TRACING_SECTION:
    case DELETE_ADMINISTRATIVE_SECTION:
    case DELETE_INDIRECT_SECTION:
    case DELETE_RAIN_SECTION:
    case DELETE_RESULT_SECTION:
    case DELETE_AGREEMENT_ENTRY:
    case DELETE_BUDGET_ENTRY:
    case DELETE_CONTRACT_ENTRY:
    case DELETE_PROGRAMMING_ENTRY:
    case DELETE_ANCHORS_ENTRY:
    case DELETE_CONCEPT_ENTRY:
    case DELETE_CONCRETE_GRAPH:
    case DELETE_CONCRETE_TABLE:
    case DELETE_CONNECTORS_ENTRY:
    case DELETE_FREE_ENTRY:
    case DELETE_INDIRECT_ENTRY:
    case DELETE_INSTRUMENTATION_ENTRY:
    case DELETE_ELEMENT_SUB_ENTRY:
    case DELETE_PLANT_SUB_ENTRY:
    case DELETE_RECOMMENDATION_SUB_ENTRY:
    case DELETE_MORTAR_GRAPH:
    case DELETE_MORTAR_TABLE:
    case DELETE_NETWORK_SUB_ENTRY:
    case DELETE_NETWORK_ENTRY:
    case DELETE_NOT_STRUCTURAL_ENTRY:
    case DELETE_RAIN_ENTRY:
    case DELETE_ANCHORS:
    case DELETE_CONCRETE:
    case DELETE_CONNECTORS:
    case DELETE_FREE:
    case DELETE_INSTRUMENTS:
    case DELETE_MORTARS:
    case DELETE_STEELS:
    case DELETE_SECURITY_ENTRY:
    case DELETE_STEELS_ENTRY:
    case DELETE_ADVANCE_ENTRY:
    case DELETE_MONTHLY_WEEK_ENTRY:
    case GET_PROJECT_MEDIA:
    case UPLOAD_PROJECT_MEDIA:
    case GET_PHOTOS_VIDEOS:
    case GET_PROJECTS_ASSETS_RESUME:
      return {...state, ...action.payload.user_list};
    case LOG_OUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};