import {DELETE_CONCEPT_ENTRY} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedDelete from "../../apis/authorizedDelete";

export default (conceptSectionId, entryId) => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedDelete(`/concept_sections/${conceptSectionId}/entry/${entryId}`),
      simpleSuccess(DELETE_CONCEPT_ENTRY));
};