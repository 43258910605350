import React from "react";
import {connect} from "react-redux";
import Login from './Login';
import Dashboard from "./Dashboard";
import Inactive from "./Inactive";
import NoProjects from "./NoProjects";
import ArrivalModal from "./ArrivalModal";
import createAlert from "../../actions/alerts/createAlert";

class Root extends React.Component {

  decideLayout = () => {
  
    const {auth, createAlert} = this.props;
  
    if (auth.logged && auth.status === 'active' && !auth.admin && !auth.target_id) {
      return <NoProjects/>
    } else if (auth.logged && !auth.arrival && !auth.client && !auth.admin) {
      return <ArrivalModal createAlert={createAlert}/>
    } else if (auth.logged && auth.status === 'active') {
      return <Dashboard/>
    } else if (auth.logged && auth.status === 'inactive') {
      return <Inactive/>
    } else {
      return <Login/>
    }
  };
  
  render() {
    return (
      this.decideLayout()
    );
  }
}


const mapStateToProps = state => {
  return {
    auth: state.auth
  };
};


export default connect(mapStateToProps, {createAlert})(Root);