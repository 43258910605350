import {FINISH_MONTHLY_REPORT} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedPut from "../../apis/authorizedPut";
import modalDismiss from "../modal/modalDismiss";

export default (monthlyReportId) => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedPut(`/monthly_reports/${monthlyReportId}/finish`),
      simpleSuccess(FINISH_MONTHLY_REPORT),
      null,
      [modalDismiss]
  );
};