import server from '../../apis/server';
import {LOG_IN} from "../types";
import loadingCall from '../functions/loadingCall';

export default (formValues) => async (dispatch) => {
  await loadingCall(dispatch, server.post('/log-in', formValues), loginSuccess);
};

const loginSuccess = (dispatch, data) => {
  localStorage.setItem('jwt_token', data.auth.jwt_token);
  dispatch({type: LOG_IN, payload: data});
};