import {UPDATE_PROTOCOL} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import {formDataHelper} from "../functions/formDataHelper";
import authorizedPut from "../../apis/authorizedPut";
import modalDismiss from "../modal/modalDismiss";

export default (formValues, protocolId) => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedPut('/protocols/' + protocolId, formDataHelper(formValues, 'protocol', [{name: 'avatar', key: '[avatar]'}])),
      simpleSuccess(UPDATE_PROTOCOL),
      null,
      [modalDismiss]
  );
};