import React from 'react';
import {Avatar, Tooltip} from "antd";

export const statusList = {
  active: {
    icon: 'check',
    color: '#679818',
    message: {
      user: 'Usuario activo!',
      project: 'Proyecto activo!',
      protocol: 'Protocolo activo!',
      recommendation: 'Recomendación activa!',
      requirement: 'Requerimiento activo!'
    }
  },
  inactive: {
    icon: 'close',
    color: '#e83b1d',
    message: {
      user: 'Usuario inactivo!',
      project: 'Proyecto inactivo!',
      protocol: 'Protocolo inactivo!',
      recommendation: 'Recomendación inactiva!',
      requirement: 'Requerimiento inactivo!',
      monthly_property: 'Sección no creada!'
    }
  },
  finished: {
    icon: 'check',
    color: '#679818',
    message: {
      daily: 'Reporte diario terminado!',
      weekly: 'Reporte semanal terminado!',
      finding: 'Hallazgo cerrado!',
      monthly: 'Reporte mensual terminado!',
      monthly_property: 'Sección terminada!',
      alert_count: 'Todo en orden'
    }
  },
  unfinished: {
    icon: 'clock-circle',
    color: '#ffbb00',
    message: {
      daily: 'Reporte diario en proceso!',
      weekly: 'Reporte semanal en proceso!',
      finding: 'Hallazgo abierto!',
      monthly: 'Reporte mensual en proceso!',
      monthly_property: 'Sección pendiente!'
    }
  },
  checking: {
    icon: 'highlight',
    color: '#085DAD',
    message: {
      monthly: 'Reporte mensual en proceso de correccion!'
    }
  },
  fixing: {
    icon: 'warning',
    color: '#ffd800',
    message: {
      monthly: 'Reporte mensual por corregir!'
    }
  },
  revising: {
    icon: 'issues-close',
    color: '#ffbb00',
    message: {
      monthly: 'Reporte mensual en revision de correcciones!',
      alert: 'Aun hace falta'
    }
  },
  clear: {
    icon: 'check-circle',
    color: '#679818',
    message: {
      alert: 'En area'
    }
  },
  warning: {
    icon: 'warning',
    color: '#ffbb00',
    message: {
      alert: 'Fuera de area',
      daily: 'Falta hacer el reporte!',
      alert_count: 'Elementos pendientes!'
    }
  },
  dismissed: {
    icon: 'like',
    color: '#679818',
    message: {
      alert: 'Solucionado'
    }
  }
};

const StatusHelper = ({status, type, position, ...rest}) => {
  if (status && statusList[status]) {
    return (
      <Tooltip placement={position} title={statusList[status].message[type]}>
        <Avatar {...rest} style={{backgroundColor: statusList[status].color}} icon={statusList[status].icon}/>
      </Tooltip>
    );
  } else {
    return null;
  }
};

export default StatusHelper;