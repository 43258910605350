import React from 'react';
import {Card, Layout, Row, Col} from "antd";
import './PublicLayout.css';

const PublicLayout = (props) => {
  
  return (
    <Layout className="h-100 bg-blue">
      <Layout.Content>
        <Row type="flex" justify="space-around" align="middle" className={'h-100'}>
          <Col xs={24} sm={20} md={14} xl={8}>
            <Card title={props.title}>
              <div>
                {props.children}
              </div>
            </Card>
          </Col>
        </Row>
      </Layout.Content>
    </Layout>
  );
};

export default PublicLayout;
