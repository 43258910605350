import React from 'react';
import {Modal} from "antd";
import {GoogleMap, Marker, useLoadScript} from "@react-google-maps/api";

const googleMapsApiKey = 'AIzaSyDX0Q6Yx_yRbiS2IMrQ50FuR4ARWM4X5qM';

const CoordinatesModal = ({modalType, modalProps, record}) => {

  const bootstrapURLKeys = {
    googleMapsApiKey: googleMapsApiKey,
    libraries: ['drawing']
  };
  const {isLoaded} = useLoadScript(bootstrapURLKeys);

  let coordinates = {lat: parseFloat(record.coordinate.lat),lng: parseFloat(record.coordinate.lng)}
  
  return (
    <Modal footer={null} className={'content-wrapper'} bodyStyle={{padding: 0}} visible={modalType !== null}
           style={{top: 30}} width={720} {...modalProps}>
      {isLoaded ?
        <GoogleMap
          id="marker-example"
          mapContainerStyle={{
            height: "400px",
            width: "800px"
          }}
          zoom={17}
          center={{lat: parseFloat(record.coordinate.lat),lng: parseFloat(record.coordinate.lng)}}
        >
          <Marker
            onLoad={marker => {
            }}
            position={coordinates}
          />
        </GoogleMap> : ''}
    </Modal>
  );
};


export default CoordinatesModal;