import React, {useEffect} from 'react';
import {Button, Card, Col, Form, Modal, Row} from "antd";
import 'antd/lib/modal/style/css';
import {noPadding} from "../../../const/styles";
import {Field, reduxForm} from "redux-form";
import {CreateFileField, CreateTextArea} from "../../Helpers/Input/InputCreator";
import {fileDoc, fileVerification, maxLength300, required} from "../../Helpers/Input/validations";

const MultimediaForm = ({change}) => {
  
  return (
    <div>
      <Row gutter={16}>
        <Col xs={24}>
          <Field name={`message.content`}
                 component={CreateTextArea}
                 placeholder="Mensaje"
                 validate={[required, maxLength300]}
                 hasFeedback/>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Field name={`image0`}
                 component={CreateFileField}
                 validate={[fileVerification, fileDoc]}
                 change={change}
                 isImage
                 type='file'
                 hasFeedback
          />
        </Col>
        <Col xs={24} sm={12}>
          <Field name={`image1`}
                 component={CreateFileField}
                 validate={[fileVerification, fileDoc]}
                 change={change}
                 isImage
                 type='file'
                 hasFeedback
          />
        </Col>
      </Row>
      <Row className={'p-20'}>
        <Button type={'primary float-right'} htmlType={'submit'}>Guardar</Button>
      </Row>
    </div>
  )
};

const MultimediaModal = ({message, modalType, modalProps, handleSubmit, change, onSubmit, initialize}) => {
  
  useEffect(() => {
    if (message)
      initialize({message});
  }, []);
  
  return (
    <Modal bodyStyle={noPadding} footer={null} className={'content-wrapper'} visible={modalType !== null}
           style={{top: 30}}
           width={720} {...modalProps}>
      <Card title={'Enviar multimedia'}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <MultimediaForm change={change}/>
        </Form>
      </Card>
    </Modal>
  );
};

export default reduxForm({
  form: 'chat_multimedia_form',
  touchOnChange: true
})(MultimediaModal);