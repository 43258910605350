import React, {useEffect} from 'react';
import {connect} from "react-redux";
import getProjectMonthlyReports from "../../actions/projects/getProjectMonthlyReports";
import getProjectWeeklyReports from "../../actions/projects/getProjectWeeklyReports";
import getProjectDailyReports from "../../actions/projects/getProjectDailyReports";
import getProjectFindings from "../../actions/projects/getProjectFindings";
import generateDailyReport from "../../actions/dailyReports/generateDailyReport";
import generateWeeklyReport from "../../actions/weeklyReports/generateWeeklyReport";
import generateMonthlyReport from "../../actions/monthlyReports/generateMonthlyReport";
import getFindingEntry from "../../actions/findingEntries/getFindingEntry";
import getProjectFindingsReport from "../../actions/projects/getProjectFindingsReport";
import {Card, Row, Tabs} from "antd";
import {DailyIndexTable} from "../Daily";
import {WeeklyIndexTable} from "../Weekly";
import {MonthlyIndexList} from "../Monthly";
import {FindingsIndexList} from "../Findings";
import modalShow from "../../actions/modal/modalShow";

const ProjectView = ({
                       userList,
                       getProjectMonthlyReports,
                       getProjectWeeklyReports,
                       getProjectDailyReports,
                       getProjectFindings,
                       generateDailyReport,
                       generateWeeklyReport,
                       generateMonthlyReport,
                       getFindingEntry,
                       modalShow,
                       match: {params: {id}},
                       getProjectFindingsReport,
                     }) => {

  useEffect(() => {
    getProjectDailyReports(id);
  }, []);

  const changeTab = (tab) => {
    switch (tab) {
      case '1':
        getProjectDailyReports(id);
        break;
      case '2':
        getProjectWeeklyReports(id);
        break;
      case '3':
        getProjectMonthlyReports(id);
        break;
      case '4':
        getProjectFindings(id);
        break;
      default:
        getProjectDailyReports(id);
        break;
    }
  };


  return (
    <Card
      bodyStyle={{padding: 0}} title={'Reportes de proyecto'}
    >
      <Row>
        <Tabs defaultActiveKey="1" onChange={changeTab}>
          <Tabs.TabPane tab="Reportes diarios" key="1">
            <DailyIndexTable admin generateDailyReport={generateDailyReport} data={userList}/>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Reportes semanales" key="2">
            <WeeklyIndexTable admin generateWeeklyReport={generateWeeklyReport} data={userList}/>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Reportes mensuales" key="3">
            <MonthlyIndexList admin generateMonthlyReport={generateMonthlyReport} data={userList}/>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Hallazgos" key="4">
            <FindingsIndexList userList={userList} getFindingEntry={getFindingEntry} modalShow={modalShow} getProjectFindingsReport={(formValues) => getProjectFindingsReport(id, formValues)} />
          </Tabs.TabPane>
        </Tabs>
      </Row>
    </Card>
  );
};

const mapStateToProps = (state) => {
  return {
    userList: state.userList
  }
};

export default connect(mapStateToProps, {
  getProjectMonthlyReports,
  getProjectWeeklyReports,
  getProjectDailyReports,
  getProjectFindings,
  generateDailyReport,
  generateWeeklyReport,
  generateMonthlyReport,
  getFindingEntry,
  modalShow,
  getProjectFindingsReport,
})(ProjectView);