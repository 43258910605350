import {CREATE_MORTARS} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedPost from "../../apis/authorizedPost";

export default (resultSectionId) => async (dispatch) => {
  await loadingCall(
    dispatch,
    authorizedPost(`/result_sections/${resultSectionId}/mortars`),
    simpleSuccess(CREATE_MORTARS)
  );
};