import {UPDATE_NOT_STRUCTURAL_ENTRY} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedPut from "../../apis/authorizedPut";
import modalDismiss from "../modal/modalDismiss";

export default (formValues, notStructuralSectionId) => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedPut(`/not_structural_sections/${notStructuralSectionId}/entry`, formValues),
      simpleSuccess(UPDATE_NOT_STRUCTURAL_ENTRY),
      null,
      [modalDismiss]
  );
};