import {UPDATE_ADVANCE_ENTRY} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedPut from "../../apis/authorizedPut";
import modalDismiss from "../modal/modalDismiss";
import {formDataHelper} from "../functions/formDataHelper";

export default (formValues, tracingSectionId) => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedPut(
          `/tracing_sections/${tracingSectionId}/advance_entry`,
          formDataHelper(formValues, 'tracing_section', [{name: 'avatar', key: '[advance_entries_attributes][][avatar]'}])
      ),
      simpleSuccess(UPDATE_ADVANCE_ENTRY),
      null,
      [modalDismiss]
  );
};