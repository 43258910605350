import * as Paths from "./paths";
import Root from "./components/Root";
import Daily from './components/Daily';
import Monthly from './components/Monthly';
import Weekly from './components/Weekly';
import Findings from './components/Findings';
import Chat from './components/Chat';
import Projects from "./components/Projects";
import Users from "./components/Users";
import Clients from "./components/Clients";
import Protocols from "./components/Protocols";
import Recommendations from "./components/Recommendations";
import Requirements from "./components/Requirements";
import Documents from "./components/Documents";
import MonthlyView from "./components/MonthlyView";
import Network from "./components/Network";
import NotStructural from "./components/NotStructural";
import Concept from "./components/Concept";
import Security from "./components/Security";
import Protocol from "./components/Protocol";
import Recommendation from "./components/Recommendation";
import Tracing from "./components/Tracing";
import MonthlyWeek from "./components/MonthlyWeek";
import Administrative from "./components/Administrative";
import Indirect from "./components/Indirect";
import Alerts from "./components/Alerts";
import Results from "./components/Results";
import Rain from "./components/Rain";
import Steel from "./components/Steel";
import Mortar from "./components/Mortar";
import Concrete from "./components/Concrete";
import Anchor from "./components/Anchor";
import Instrumentation from "./components/Instrumentation";
import Free from "./components/Free";
import Connector from "./components/Connector";
import ProjectView from "./components/ProjectsView";
import PhotosVideos from "./components/PhotosVideos";
import PhotosVideosDetails from "./components/PhotosVideosDetails";
import AllProjectsPhotosVideos from "./components/ProjectsPhotosVideos";

export const routes = [
  {
    title: 'Inicio',
    path: Paths.ROOT_PATH,
    exact: true,
    component: Root
  }, {
    title: 'Alertas',
    path: Paths.ALERTS_PATH,
    exact: true,
    component: Alerts
  },
  {
    title: 'Informes diarios',
    path: Paths.DAILY_PATH,
    exact: true,
    component: Daily
  },
  {
    title: 'Informes semanales',
    path: Paths.WEEKLY_PATH,
    exact: true,
    component: Weekly
  },
  {
    title: 'Informes mensuales',
    path: Paths.MONTHLY_PATH,
    exact: true,
    component: Monthly
  },
  {
    title: 'Informe mensual',
    path: Paths.MONTHLY_VIEW(':id'),
    exact: true,
    component: MonthlyView
  },
  {
    title: 'Sección de redes',
    path: Paths.NETWORK_VIEW(':id', ':network_id'),
    exact: true,
    component: Network
  },
  {
    title: 'Sección de elementos no estructurales',
    path: Paths.NOT_STRUCTURAL_VIEW(':id', ':not_structural_id'),
    exact: true,
    component: NotStructural
  },
  {
    title: 'Sección de conceptos',
    path: Paths.CONCEPT_VIEW(':id', ':concept_id'),
    exact: true,
    component: Concept
  },
  {
    title: 'Sección de recomendaciones',
    path: Paths.RECOMMENDATION_VIEW(':id', ':recommendation_id'),
    exact: true,
    component: Recommendation
  },
  {
    title: 'Sección de protocolos',
    path: Paths.PROTOCOL_VIEW(':id', ':protocol_id'),
    exact: true,
    component: Protocol
  },
  {
    title: 'Sección administrativa',
    path: Paths.ADMINISTRATIVE_VIEW(':id', ':administrative_id'),
    exact: true,
    component: Administrative
  },
  {
    title: 'Sección indirecta',
    path: Paths.INDIRECT_VIEW(':id', ':indirect_id'),
    exact: true,
    component: Indirect
  },
  {
    title: 'Sección de seguridad',
    path: Paths.SECURITY_VIEW(':id', ':security_id'),
    exact: true,
    component: Security
  },
  {
    title: 'Sección de resultados',
    path: Paths.RESULTS_VIEW(':id', ':results_id'),
    exact: true,
    component: Results
  },
  {
    title: 'Sección de lluvias',
    path: Paths.RAIN_VIEW(':id', ':rain_id'),
    exact: true,
    component: Rain
  },
  {
    title: 'Sección de seguimiento',
    path: Paths.TRACING_VIEW(':id', ':tracing_id'),
    exact: true,
    component: Tracing
  },
  {
    title: 'Sección de concretos',
    path: Paths.CONCRETE_VIEW(':id', ':results_id', ':concrete_id'),
    exact: true,
    component: Concrete
  },
  {
    title: 'Sección de morteros',
    path: Paths.MORTARS_VIEW(':id', ':results_id', ':mortars_id'),
    exact: true,
    component: Mortar
  },
  {
    title: 'Sección de aceros',
    path: Paths.STEELS_VIEW(':id', ':results_id', ':steel_id'),
    exact: true,
    component: Steel
  },
  {
    title: 'Sección de anclajes',
    path: Paths.ANCHORS_VIEW(':id', ':results_id', ':anchor_id'),
    exact: true,
    component: Anchor
  },
  {
    title: 'Sección de instrumentación',
    path: Paths.INSTRUMENTATION_VIEW(':id', ':results_id', ':instrumentation_id'),
    exact: true,
    component: Instrumentation
  },
  {
    title: 'Sección libre',
    path: Paths.FREE_VIEW(':id', ':results_id', ':free_id'),
    exact: true,
    component: Free
  },
  {
    title: 'Sección de conectores',
    path: Paths.CONNECTORS_VIEW(':id', ':results_id', ':connector_id'),
    exact: true,
    component: Connector
  },
  {
    title: 'Entrada mensual semanal',
    path: Paths.MONTHLY_WEEK_VIEW(':id', ':tracing_id', ':monthly_week_id'),
    exact: true,
    component: MonthlyWeek
  },
  {
    title: 'Informe de hallazgos',
    path: Paths.FINDINGS_PATH,
    exact: true,
    component: Findings
  },
  {
    title: 'Chat',
    path: Paths.CHAT_PATH,
    exact: true,
    component: Chat
  },
  {
    title: 'Proyectos',
    path: Paths.PROJECTS_PATH,
    exact: true,
    component: Projects
  },
  {
    title: 'Usuarios',
    path: Paths.USERS_PATH,
    exact: true,
    component: Users
  },
  {
    title: 'Clientes',
    path: Paths.CLIENTS_PATH,
    exact: true,
    component: Clients
  },
  {
    title: 'Protocolos',
    path: Paths.PROTOCOLS_PATH,
    exact: true,
    component: Protocols
  },
  {
    title: 'Recomendaciones',
    path: Paths.RECOMMENDATIONS_PATH,
    exact: true,
    component: Recommendations
  },
  {
    title: 'Requerimientos',
    path: Paths.REQUIREMENTS_PATH,
    exact: true,
    component: Requirements
  },
  {
    title: 'Documentos',
    path: Paths.DOCUMENTS_PATH,
    exact: true,
    component: Documents
  },
  {
    title: 'Ver proyecto',
    path: Paths.PROJECTS_VIEW(':id'),
    exact: true,
    component: ProjectView
  },
  {
    title: 'Chat',
    path: Paths.PROJECTS_CHAT(':id'),
    exact: true,
    component: Chat
  },
  {
    title: 'Fotos y videos proyectos',
    path: Paths.PROJECTS_MEDIA_RESUME,
    exact: true,
    component: AllProjectsPhotosVideos
  },
  {
    title: 'Resumen mensual fotos y videos',
    path: Paths.PROJECT_MONTHLY_MEDIA_RESUME(':id'),
    exact: true,
    component: PhotosVideos
  },
  {
    title: 'Fotos y videos',
    path: Paths.PROJECT_MEDIA_RESUME,
    exact: true,
    component: PhotosVideos
  },
  {
    title: 'Detalles fotos y videos',
    path: Paths.PROJECTS_MEDIA_ASSETS(':id', ':asset_id'),
    exact: true,
    component: PhotosVideosDetails
  }
];