import {DELETE_BUDGET_ENTRY} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedDelete from "../../apis/authorizedDelete";

export default (administrativeSectionId, entryId) => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedDelete(`/administrative_sections/${administrativeSectionId}/budget_entry/ ${entryId}`),
      simpleSuccess(DELETE_BUDGET_ENTRY));
};