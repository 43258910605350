import {UPDATE_PROJECT_CLIENTS} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedPut from "../../apis/authorizedPut";
import modalDismiss from "../modal/modalDismiss";

export default (userIds, projectId) => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedPut(`/projects/${projectId}/clients`, {project: {user_ids: userIds}}),
      simpleSuccess(UPDATE_PROJECT_CLIENTS),
      null,
      [modalDismiss]
  );
};