import {
  LOG_OUT,
  GET_PROJECT_CLIENTS,
  GET_PROJECT_CLIENT,
  CREATE_PROJECT_CLIENT,
  UPDATE_PROJECT_CLIENTS,
  GET_PROJECT_DOCUMENTS,
  GET_PROJECT_DOCUMENT,
  UPDATE_PROJECT_DOCUMENT,
  CREATE_PROJECT_DOCUMENT,
  UPDATE_NOT_STRUCTURAL_SUB_ENTRY,
  CREATE_NOT_STRUCTURAL_SUB_ENTRY,
  GET_NOT_STRUCTURAL_ENTRY,
  UPDATE_NETWORK_SUB_ENTRY,
  CREATE_NETWORK_SUB_ENTRY,
  GET_NETWORK_ENTRY,
  DELETE_NETWORK_SUB_ENTRY_IMAGE,
  DELETE_NOT_STRUCTURAL_SUB_ENTRY_IMAGE,
  GET_ELEMENT_SUB_ENTRY,
  CREATE_ELEMENT_SUB_SUB_ENTRY,
  UPDATE_ELEMENT_SUB_SUB_ENTRY,
  DELETE_ELEMENT_SUB_SUB_ENTRY_IMAGE,
  GET_INDIRECT_ENTRY,
  CREATE_INDIRECT_SUB_ENTRY,
  UPDATE_INDIRECT_SUB_ENTRY,
  DELETE_INDIRECT_SUB_ENTRY_IMAGE,
  GET_PROJECT_LOCATIONS,
  CREATE_PROJECT_LOCATIONS,
  UPDATE_PROJECT_LOCATIONS,
  DELETE_ANCHORS_ENTRY_IMAGE,
  DELETE_ELEMENT_SUB_SUB_ENTRY, DELETE_INDIRECT_SUB_ENTRY, DELETE_NETWORK_SUB_ENTRY, DELETE_NOT_STRUCTURAL_SUB_ENTRY
} from "../actions/types";

const INITIAL_STATE = {
  kind: '',
  entries: [],
  total_count: 0,
  selected_entry: {}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PROJECT_CLIENTS:
    case GET_PROJECT_CLIENT:
    case UPDATE_PROJECT_CLIENTS:
    case CREATE_PROJECT_CLIENT:
    case DELETE_ANCHORS_ENTRY_IMAGE:
    case GET_PROJECT_DOCUMENTS:
    case GET_PROJECT_DOCUMENT:
    case UPDATE_PROJECT_DOCUMENT:
    case CREATE_PROJECT_DOCUMENT:
    case GET_NETWORK_ENTRY:
    case CREATE_NETWORK_SUB_ENTRY:
    case UPDATE_NETWORK_SUB_ENTRY:
    case GET_INDIRECT_ENTRY:
    case CREATE_INDIRECT_SUB_ENTRY:
    case UPDATE_INDIRECT_SUB_ENTRY:
    case GET_NOT_STRUCTURAL_ENTRY:
    case CREATE_NOT_STRUCTURAL_SUB_ENTRY:
    case UPDATE_NOT_STRUCTURAL_SUB_ENTRY:
    case DELETE_NETWORK_SUB_ENTRY_IMAGE:
    case DELETE_INDIRECT_SUB_ENTRY_IMAGE:
    case DELETE_NOT_STRUCTURAL_SUB_ENTRY_IMAGE:
    case GET_ELEMENT_SUB_ENTRY:
    case CREATE_ELEMENT_SUB_SUB_ENTRY:
    case UPDATE_ELEMENT_SUB_SUB_ENTRY:
    case DELETE_ELEMENT_SUB_SUB_ENTRY_IMAGE:
    case GET_PROJECT_LOCATIONS:
    case CREATE_PROJECT_LOCATIONS:
    case UPDATE_PROJECT_LOCATIONS:
    case DELETE_ELEMENT_SUB_SUB_ENTRY:
    case DELETE_INDIRECT_SUB_ENTRY:
    case DELETE_NETWORK_SUB_ENTRY:
    case DELETE_NOT_STRUCTURAL_SUB_ENTRY:
      return {...state, ...action.payload.modal_list};
    case LOG_OUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};