import {FINISH_TRACING_SECTION} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedPut from "../../apis/authorizedPut";

export default (tracingSectionId) => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedPut(`/tracing_sections/${tracingSectionId}/finish`),
    simpleSuccess(FINISH_TRACING_SECTION)
  );
};