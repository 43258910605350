import {push} from "connected-react-router";
import {INFO_SHOW, LOG_OUT} from "../types";
import infoPayload from "./payloadHandlers/infoPayload";
import {notification} from "antd";

export default (dispatch, response, onSuccess, path = null, postActions = [], callbacks = []) => {
  showNotification(response);
  if (response === undefined) {
    dispatch({
      type: INFO_SHOW,
      payload: infoPayload('Error al comunicarse con el servidor, intenta de nuevo mas tarde', 'error')
    });
  } else if (response.data.success) {
    onSuccess(dispatch, response.data);
    if(path !== null)
      dispatch(push(path));
    if (postActions.length !== 0) {
      postActions.forEach((action) => {dispatch(action(response.data))})
    }
    if (callbacks.length)
      callbacks.forEach((action) => action(response.data))
  } else if (!response.data.success) {
    switch (response.data.code) {
      case 404:
        // TODO - push a not found page!
        break;
      case 401:
        localStorage.removeItem('jwt_token');
        dispatch({
          type: LOG_OUT,
          payload: response.data
        });
        dispatch(push('/'));
        break;
      default:
        break;
    }
  }
}

const showNotification = (response) => {
  if (response.data.info) {
    notification[response.data.info.type ? response.data.info.type : 'error']({
      message: response.data.info.message,
      duration: 5
    });
  }
};