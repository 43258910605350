import React, {useEffect} from 'react';
import {Field, FieldArray, reduxForm} from "redux-form";
import {Button, Card, Col, Form, Row, Select} from "antd";
import {CreateFileField, CreateSelectField, CreateTextField} from "../Helpers/Input/InputCreator";
import {
  fileDoc,
  fileRequired,
  fileVerification,
  logicDate,
  maxLength30,
  minLength5,
  required,
  validDate
} from "../Helpers/Input/validations";
import _ from "lodash";
import {dateFormat} from "../Helpers/Input/format";
import {marginRight} from "../../const/styles";

const DocumentsForm = ({fields, document, change, archive}) => {

  useEffect(() => {
    if (fields.length === 0)
      fields.push({});
  }, []);

  const renderStatusSelect = (documents_attributes) => {
    if(document)
      return (
        <Col xs={24} md={12}>
          <Field label={'Estado'}
                 name={`${documents_attributes}.status`}
                 component={CreateSelectField}
                 validate={[required]}
                 hasFeedback>
            <Select.Option key={1} value={'active'}>Activo</Select.Option>
            <Select.Option key={2} value={'inactive'}>Inactivo</Select.Option>
          </Field>
        </Col>
      );
  };

  return (
    fields.map((documents_attributes, index) => {
      return (
        <div key={index}>
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Field label={'Nombre'}
                     name={`${documents_attributes}.name`}
                     component={CreateTextField}
                     placeholder="Nombre"
                     validate={[required, minLength5, maxLength30]}
                     hasFeedback/>
            </Col>
            {renderStatusSelect(documents_attributes)}
          </Row>
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <Field label={'Tipo'}
                     name={`${documents_attributes}.kind`}
                     component={CreateSelectField}
                     placeholder={'Seleccione el tipo de documento'}
                     validate={[required]}
                     hasFeedback>
                <Select.Option key={1} value={''}>Seleccione el tipo de documento</Select.Option>
                <Select.Option key={1} value={'certificate'}>Certificado</Select.Option>
              </Field>
            </Col>
            <Col xs={24} sm={12}>
              <Field label={'Fecha (dd/mm/yyyy)'}
                     name={`${documents_attributes}.date`}
                     component={CreateTextField}
                     placeholder="Fecha (dd/mm/yyyy)"
                     validate={[required, validDate, logicDate]}
                     normalize={dateFormat}
                     hasFeedback/>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Field label={'Documento'}
                     name={'archive'}
                     preview={archive ? archive : null}
                     component={CreateFileField}
                     validate={archive ? [fileVerification, fileDoc] : [fileRequired, fileVerification, fileDoc]}
                     change={change}
                     isFile={true}
                     type='file'
                     hasFeedback
              />
            </Col>
          </Row>
          <Row className={'p-20'}>
            <Button type={'primary float-right'} htmlType={'submit'}>Guardar</Button>
          </Row>
        </div>
      )
    })
  );
};

const ProjectsDocumentsForm = ({handleReturn, handleSubmit, initialize, onSubmit, document, change, user}) => {
  
  useEffect(() => {
    if(document)
      initialize(_.omit(document, ['archive']));
  }, []);

  return (
    <Card title={'Agregar documentos al proyecto'}
          extra={user ? '' : <Button style={marginRight} htmlType={'button'} type={'ghost'}
                                     onClick={handleReturn}>Regresar</Button>}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FieldArray
          name={'project[documents_attributes]'}
          component={DocumentsForm}
          change={change}
          props={{document: document, archive: document ? document.archive : null}}/>
      </Form>
    </Card>
  );
};

export default reduxForm({
  form: 'documents_form',
  touchOnChange: true
})(ProjectsDocumentsForm);