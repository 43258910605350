import React from 'react';
import { Button, Card, Icon, Table } from "antd";
import { connect } from "react-redux";
import getProjectsAssetsResume from "../../actions/projectsmedia/getProjectsAssetsResume";
import { PROJECT_MONTHLY_MEDIA_RESUME } from '../../paths';
import {push} from 'connected-react-router';

const { Column } = Table;

class ProjectsPhotosVideos extends React.Component {

    componentDidMount() {
        this.loadRecords();
    }

    loadRecords() {
        this.props.getProjectsAssetsResume();
    }

    renderActions(record) {
        return(
            <Button type={'primary'} htmlType={'button'} disabled={record.videos === 0 && record.photos === 0} 
                    onClick={() => this.props.push(PROJECT_MONTHLY_MEDIA_RESUME(record.id))}>
                <Icon type='eye' />
            </Button>
        );
    }

    render() {
        return(
            <Card title={'Fotos y videos'}>
                <Table dataSource={this.props.projects_assets} rowKey='id'>
                    <Column key="1" title="Projecto" dataIndex="name" />
                    <Column key="2" title="Fotos" dataIndex="photos" />
                    <Column key="3" title="Videos" dataIndex="videos" />
                    <Column key="4" title="Acciones" render={(text, record) => { return this.renderActions(record)}} />
                </Table>
            </Card>
        );
    }
}
const mapStateToProps = state => {
    return {
        projectId: state.auth.target_id,
        projects_assets: state.userList.entries
    }
}

export default connect(mapStateToProps, {
    getProjectsAssetsResume,
    push
})(ProjectsPhotosVideos);