import React, {useEffect} from 'react';
import {Field, FieldArray, reduxForm} from "redux-form";
import {Button, Card, Col, Form, Row} from "antd";
import 'antd/lib/button/style/css';
import 'antd/lib/card/style/css';
import 'antd/lib/col/style/css';
import 'antd/lib/form/style/css';
import 'antd/lib/row/style/css';
import _ from 'lodash';
import {CreateFileField, CreateTextArea} from "../Helpers/Input/InputCreator";
import {fileImage, fileVerification,} from "../Helpers/Input/validations";
import QuillTextArea from "../Helpers/QuillTextArea";

const PlantSubForm = ({fields, change, plantSubEntry}) => {
  
  useEffect(() => {
    if (fields.length === 0)
      fields.push({});
  }, []);
  
  return (
    fields.map((plant_sub_entries_attributes, index) => {
      return (
        <div key={index}>
          <Row gutter={16}>
            <Col xs={24} sm={24}>
              <Field label={'Contenido'}
                     name={`${plant_sub_entries_attributes}.content`}
                     component={QuillTextArea}
                     change={change} />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Field label={'Imagen de la entrada'}
                     name={'avatar'}
                     preview={plantSubEntry ? plantSubEntry.avatar : null}
                     component={CreateFileField}
                     validate={[fileVerification, fileImage]}
                     change={change}
                     isImage
                     type='file'
                     hasFeedback
              />
            </Col>
            <Col xs={24} sm={12}>
              <Field label={'Comentario para la foto'}
                     name={`${plant_sub_entries_attributes}.comment`}
                     component={CreateTextArea}
                     placeholder="Comentario para la foto"
                     hasFeedback/>
            </Col>
          </Row>
          <Row className={'p-20'}>
            <Button type={'primary float-right'} htmlType={'submit'}>Guardar</Button>
          </Row>
        </div>
      )
    })
  );
};

const PlantSubEntryForm = ({handleSubmit, initialize, change, onSubmit, plantSubEntry}) => {
  
  useEffect(() => {
    if (plantSubEntry)
      initialize({monthly_week_entry: {plant_sub_entries_attributes: [_.omit(plantSubEntry, ['avatar'])]}});
  }, []);
  
  return (
    <Card title={'Sub sección de planta'}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FieldArray name={'monthly_week_entry[plant_sub_entries_attributes]'} component={PlantSubForm}
                    props={{plantSubEntry: plantSubEntry, change: change}}/>
      </Form>
    </Card>
  );
};

export default reduxForm({
  form: 'plant_sub_entry_form',
  touchOnChange: true
})(PlantSubEntryForm);