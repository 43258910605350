import React, {useEffect} from 'react';
import {Field, FieldArray, reduxForm} from "redux-form";
import {Button, Card, Col, Form, Row} from "antd";
import 'antd/lib/button/style/css';
import 'antd/lib/card/style/css';
import 'antd/lib/col/style/css';
import 'antd/lib/form/style/css';
import 'antd/lib/row/style/css';
import _ from 'lodash';
import {CreateTextField} from "../Helpers/Input/InputCreator";
import {maxLength50, minLength5} from "../Helpers/Input/validations";
import QuillTextArea from "../Helpers/QuillTextArea";

const ElementSubForm = ({fields, change}) => {
  
  useEffect(() => {
    if (fields.length === 0)
      fields.push({});
  }, []);
  
  return (
    fields.map((element_sub_entries_attributes, index) => {
      return (
        <div key={index}>
          <Row gutter={16}>
            <Col xs={24} sm={24}>
              <Field label={'Titulo'}
                     name={`${element_sub_entries_attributes}.title`}
                     component={CreateTextField}
                     placeholder="Titulo"
                     validate={[minLength5, maxLength50]}
                     hasFeedback/>
            </Col>
            <Col xs={24} sm={24}>
              <Field label={'Contenido'}
                     name={`${element_sub_entries_attributes}.content`}
                     component={QuillTextArea}
                     change={change} />
            </Col>
          </Row>
          <Row className={'p-20'}>
            <Button type={'primary float-right'} htmlType={'submit'}>Guardar</Button>
          </Row>
        </div>
      )
    })
  );
};

const ElementSubEntryForm = ({handleSubmit, initialize, change, onSubmit, elementSubEntry}) => {
  
  useEffect(() => {
    if (elementSubEntry)
      initialize({monthly_week_entry: {element_sub_entries_attributes: [_.omit(elementSubEntry, ['avatar'])]}});
  }, []);
  
  return (
    <Card title={'Sub entrada semanal'}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FieldArray name={'monthly_week_entry[element_sub_entries_attributes]'} component={ElementSubForm}
                    props={{elementSubEntry: elementSubEntry, change: change}}/>
      </Form>
    </Card>
  );
};

export default reduxForm({
  form: 'element_sub_entry_form',
  touchOnChange: true
})(ElementSubEntryForm);