import {FINISH_NETWORK_SECTION} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedPut from "../../apis/authorizedPut";

export default (networkSectionId) => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedPut(`/network_sections/${networkSectionId}/finish`),
    simpleSuccess(FINISH_NETWORK_SECTION)
  );
};