import ProtocolsModal from "../../Protocols/ProtocolsModal";
import RecommendationModal from "../../Recommendations/RecommendationsModal";
import RequirementsModal from "../../Requirements/RequirementsModal";
import UsersModal from "../../Users/UsersModal";
import ClientsModal from "../../Clients/ClientsModal";
import ProjectsModal from "../../Projects/ProjectsModal";
import ProjectsUsersModal from "../../Projects/ProjectsUsersModal";
import ProjectsClientsModal from "../../Projects/ProjectsClientsModal";
import ProjectsDocumentsModal from "../../Projects/ProjectsDocumentsModal";
import ProjectsProtocolsModal from "../../Projects/ProjectsProtocolsModal";
import UserDailyModal from "../../Daily/UserDailyModal";
import UserWeeklyModal from "../../Weekly/UserWeeklyModal";
import FindingModal from "../../Findings/FindingModal";
import NetworkEntryModal from "../../Network/NetworkEntryModal";
import SecurityEntryModal from "../../Security/SecurityEntryModal";
import ConceptEntryModal from "../../Concept/ConceptEntryModal";
import NotStructuralEntryModal from "../../NotStructural/NotStructuralEntryModal";
import AdvanceEntryModal from "../../Tracing/AdvanceEntryModal";
import MonthlyWeekEntryModal from "../../Tracing/MonthlyWeekEntryModal";
import RecommendationSubEntryModal from "../../MonthlyWeek/RecommendationSubEntryModal";
import PlantSubEntryModal from "../../MonthlyWeek/PlantSubEntryModal";
import ElementSubEntryModal from "../../MonthlyWeek/ElementSubEntryModal";
import LocationSectionModal from "../../MonthlyView/LocationSectionModal";
import RecommendationViewModal from "../../Recommendation/RecommendationViewModal";
import PasswordModal from "../../Root/Dashboard/PasswordModal";
import CorrectionsModal from "../../Monthly/CorrectionsModal";
import RainSectionModal from "../../MonthlyView/RainSectionModal";
import ContractEntryModal from "../../Administrative/ContractEntryModal";
import AgreementEntryModal from "../../Administrative/AgreementEntryModal";
import BudgetEntryModal from "../../Administrative/BudgetEntryModal";
import ProgrammingEntryModal from "../../Administrative/ProgramingEntryModal";
import IndirectEntryModal from "../../Indirect/IndirectEntryModal";
import MultimediaModal from "../../Chat/Users/MultimediaModal";
import ProjectsLocationsModal from "../../Projects/ProjectsLocationsModal";
import CoordinatesModal from "../../Alerts/CoordinatesModal";
import RainEntryModal from "../../Rain/RainEntryModal";
import ConcreteTableModal from "../../Concrete/ConcreteTableModal";
import ConcreteGraphModal from "../../Concrete/ConcreteGraphModal";
import MortarGraphModal from "../../Mortar/MortarGraphModal";
import MortarTableModal from "../../Mortar/MortarTableModal";
import SteelEntryModal from "../../Steel/SteelEntryModal";
import ConnectorEntryModal from "../../Connector/ConnectorEntryModal";
import AnchorEntryModal from "../../Anchor/AnchorEntryModal";
import InstrumentationEntryModal from "../../Instrumentation/InstrumentationEntryModal";
import FreeEntryModal from "../../Free/FreeEntryModal";
import ProtocolViewModal from "../../Protocol/ProtocolViewModal";
import UploadMediaModal from "../../PhotosVideos/UploadMediaModal";

export const USERS_MODAL = UsersModal;
export const CLIENTS_MODAL = ClientsModal;
export const PROJECTS_MODAL = ProjectsModal;
export const PROJECTS_CLIENTS_MODAL = ProjectsClientsModal;
export const PROJECTS_DOCUMENTS_MODAL = ProjectsDocumentsModal;
export const PROJECTS_USERS_MODAL = ProjectsUsersModal;
export const PROJECTS_PROTOCOLS_MODAL = ProjectsProtocolsModal;
export const PROTOCOLS_MODAL = ProtocolsModal;
export const RECOMMENDATIONS_MODAL = RecommendationModal;
export const REQUIREMENTS_MODAL = RequirementsModal;
export const USER_DAILY_MODAL = UserDailyModal;
export const USER_WEEKLY_MODAL = UserWeeklyModal;
export const FINDING_MODAL = FindingModal;
export const LOCATION_SECTION_MODAL = LocationSectionModal;
export const RAIN_SECTION_MODAL = RainSectionModal;
export const NETWORK_ENTRY_MODAL = NetworkEntryModal;
export const NOT_STRUCTURAL_ENTRY_MODAL = NotStructuralEntryModal;
export const SECURITY_ENTRY_MODAL = SecurityEntryModal;
export const CONCEPT_ENTRY_MODAL = ConceptEntryModal;
export const ADVANCE_ENTRY_MODAL = AdvanceEntryModal;
export const MONTHLY_WEEK_ENTRY_MODAL = MonthlyWeekEntryModal;
export const PLANT_SUB_ENTRY_MODAL = PlantSubEntryModal;
export const RECOMMENDATION_SUB_ENTRY_MODAL = RecommendationSubEntryModal;
export const ELEMENT_SUB_ENTRY_MODAL = ElementSubEntryModal;
export const RECOMMENDATION_VIEW_MODAL = RecommendationViewModal;
export const PROTOCOL_VIEW_MODAL = ProtocolViewModal;
export const PASSWORD_MODAL = PasswordModal;
export const CORRECTIONS_MODAL = CorrectionsModal;
export const CONTRACT_ENTRY_MODAL = ContractEntryModal;
export const AGREEMENT_ENTRY_MODAL = AgreementEntryModal;
export const BUDGET_ENTRY_MODAL = BudgetEntryModal;
export const PROGRAMMING_ENTRY_MODAL = ProgrammingEntryModal;
export const INDIRECT_ENTRY_MODAL = IndirectEntryModal;
export const MULTIMEDIA_MODAL = MultimediaModal;
export const PROJECT_LOCATIONS = ProjectsLocationsModal;
export const COORDINATES_MODAL = CoordinatesModal;
export const RAIN_ENTRY_MODAL = RainEntryModal;
export const CONCRETE_TABLE_MODAL = ConcreteTableModal;
export const CONCRETE_GRAPH_MODAL = ConcreteGraphModal;
export const MORTARS_TABLE_MODAL = MortarTableModal;
export const MORTARS_GRAPH_MODAL = MortarGraphModal;
export const STEELS_ENTRY_MODAL = SteelEntryModal;
export const CONNECTORS_ENTRY_MODAL = ConnectorEntryModal;
export const ANCHORS_ENTRY_MODAL = AnchorEntryModal;
export const INSTRUMENTATION_ENTRY_MODAL = InstrumentationEntryModal;
export const FREE_ENTRY_MODAL = FreeEntryModal;
export const MEDIA_UPLOAD_MODAL = UploadMediaModal;
