import React from "react";
import {Button, Card, Icon, Table} from 'antd';
import 'antd/lib/button/style/css';
import 'antd/lib/card/style/css';
import 'antd/lib/col/style/css';
import 'antd/lib/icon/style/css';
import 'antd/lib/input/style/css';
import 'antd/lib/row/style/css';
import 'antd/lib/table/style/css';
import {connect} from "react-redux";
import modalShow from '../../actions/modal/modalShow';
import {INDIRECT_ENTRY_MODAL} from "../Feedback/Modal/types";
import getIndirectSection from "../../actions/indirectSection/getIndirectSection";
import {MONTHLY_VIEW} from "../../paths";
import {push} from 'connected-react-router';
import createIndirectEntry from "../../actions/indirectSection/createIndirectEntry";
import updateIndirectEntry from "../../actions/indirectSection/updateIndirectEntry";
import getIndirectEntry from "../../actions/indirectEntry/getIndirectEntry";
import {marginRight} from "../../const/styles";
import deleteIndirectEntry from "../../actions/indirectSection/deleteIndirectEntry";
import {confirmDelete} from "../../apis/authorizedDelete";

class Indirect extends React.Component {
  
  componentDidMount() {
    this.props.getIndirectSection(this.props.match.params.indirect_id);
  };
  
  handleCreate = (formValues) => {
    this.props.createIndirectEntry(formValues, this.props.match.params.indirect_id);
  };
  
  handleUpdate = (formValues) => {
    this.props.updateIndirectEntry(formValues, this.props.match.params.indirect_id);
  };
  
  fetchEntry = (indirectEntryId) => {
    this.props.getIndirectEntry(indirectEntryId).then(() => this.openEntryModal(null, null));
  };
  
  openEntryModal = (entry, onSubmit) => {
    this.props.modalShow({
      modalType: INDIRECT_ENTRY_MODAL,
      modalProps: {indirectEntry: entry, onSubmit: onSubmit}
    });
  };
  
  columns = () => {
    return [{
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    }, {
      title: 'Contenido',
      dataIndex: 'content',
      key: 'content',
      render: (text) => {
        return <Icon type={text && text.length > 15 ? 'check' : 'close'}/>;
      }
    }, {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record) => {
        return (
          <div>
            <Button onClick={() => this.openEntryModal(record, this.handleUpdate)} style={marginRight} type={"primary"}
                    htmlType={'button'}>
              <Icon type="eye"/>
            </Button>
            <Button
              style={marginRight} onClick={() => this.fetchEntry(record.id)} type={"ghost"} htmlType={'button'}>
              Sub entradas
            </Button>
            <Button onClick={() => confirmDelete(() => this.props.deleteIndirectEntry(this.props.match.params.indirect_id, record.id))}
                    type={"danger"}
                    style={marginRight}
                    htmlType={'button'}>
              <Icon type="delete"/>
            </Button>
          </div>
        );
      }
    }]
  };
  
  render() {
    return (
      <Card bodyStyle={{padding: 0}}
            extra={
              <Button htmlType={'button'}
                      type={'ghost'}
                      onClick={() => this.props.push(MONTHLY_VIEW(this.props.match.params.id))}>Volver</Button>
            }
            title={'Indirectos de obra'}
            >
        <Card bodyStyle={{padding: 0}}
              extra={
                <Button type={'primary'}
                        style={marginRight}
                        htmlType={'button'}
                        onClick={() => this.openEntryModal(null, this.handleCreate)}>
                  Agregar
                </Button>
              }
              title={'Entradas de indirectos'}>
          <Table scroll={{x: '100%'}}
            dataSource={this.props.indirectSection.kind === 'indirect_entries' ? this.props.indirectSection.entries : []}
            rowKey={'id'} columns={this.columns()}/>
        </Card>
      </Card>
    );
  }
}


const mapStateToProps = state => {
  return {
    indirectSection: state.userList.selected_entry
  };
};


export default connect(mapStateToProps, {
  push,
  modalShow,
  getIndirectSection,
  createIndirectEntry,
  deleteIndirectEntry,
  updateIndirectEntry,
  getIndirectEntry
})(Indirect);