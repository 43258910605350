//Auth MODULE
export const ROOT_PATH = '/';
export const DAILY_PATH = '/daily';
export const WEEKLY_PATH = '/weekly';
export const FINDINGS_PATH = '/findings';
export const DOCUMENTS_PATH = '/documents';
export const CHAT_PATH = '/chat';
export const PROJECTS_PATH = '/projects';
export const USERS_PATH = '/members';
export const CLIENTS_PATH = '/clients';
export const PROTOCOLS_PATH = '/protocols';
export const RECOMMENDATIONS_PATH = '/recommendations';
export const REQUIREMENTS_PATH = '/requirements';
export const ALERTS_PATH = '/alerts';
export const MONTHLY_PATH = '/monthly';

export const PROJECTS_MEDIA_RESUME = '/projects_media';
export const PROJECT_MEDIA_RESUME = '/project_media';
export const PROJECT_MONTHLY_MEDIA_RESUME = (id) => `/projects/${id}/media_assets_resume`;
export const PROJECTS_MEDIA_ASSETS = (id, asset_id) => `/projects/${id}/media_assets/${asset_id}`;

export const PROJECTS_VIEW = (id) => PROJECTS_PATH + `/${id}`;
export const PROJECTS_CHAT = (id) => PROJECTS_PATH + `/${id}/chat`;
export const MONTHLY_VIEW = (id) => MONTHLY_PATH + `/${id}`;
export const NETWORK_VIEW = (id, networkId) => MONTHLY_PATH + `/${id}/network/${networkId}`;
export const PROTOCOL_VIEW = (id, protocolId) => MONTHLY_PATH + `/${id}/protocol/${protocolId}`;
export const RECOMMENDATION_VIEW = (id, networkId) => MONTHLY_PATH + `/${id}/recommendation/${networkId}`;
export const ADMINISTRATIVE_VIEW = (id, administrativeId) => MONTHLY_PATH + `/${id}/administrative/${administrativeId}`;
export const INDIRECT_VIEW = (id, indirectId) => MONTHLY_PATH + `/${id}/indirect/${indirectId}`;
export const NOT_STRUCTURAL_VIEW = (id, notStructuralId) => MONTHLY_PATH + `/${id}/not_structural/${notStructuralId}`;
export const CONCEPT_VIEW = (id, conceptId) => MONTHLY_PATH + `/${id}/concept/${conceptId}`;
export const SECURITY_VIEW = (id, securityId) => MONTHLY_PATH + `/${id}/security/${securityId}`;
export const RESULTS_VIEW = (id, resultsId) => MONTHLY_PATH + `/${id}/results/${resultsId}`;
export const RAIN_VIEW = (id, rainId) => MONTHLY_PATH + `/${id}/rain/${rainId}`;
export const TRACING_VIEW = (id, tracingId) => MONTHLY_PATH + `/${id}/tracing/${tracingId}`;
export const MONTHLY_WEEK_VIEW = (id, tracingId, monthlyWeekId) => MONTHLY_PATH + `/${id}/tracing/${tracingId}/monthly_week/${monthlyWeekId}`;
export const CONCRETE_VIEW = (id, resultsId, concreteId) => MONTHLY_PATH + `/${id}/results/${resultsId}/concrete/${concreteId}`;
export const MORTARS_VIEW = (id, resultsId, mortarsId) => MONTHLY_PATH + `/${id}/results/${resultsId}/mortars/${mortarsId}`;
export const STEELS_VIEW = (id, resultsId, steelsId) => MONTHLY_PATH + `/${id}/results/${resultsId}/steels/${steelsId}`;
export const ANCHORS_VIEW = (id, resultsId, anchorsId) => MONTHLY_PATH + `/${id}/results/${resultsId}/anchors/${anchorsId}`;
export const INSTRUMENTATION_VIEW = (id, resultsId, instrumentationId) => MONTHLY_PATH + `/${id}/results/${resultsId}/instrumentation/${instrumentationId}`;
export const FREE_VIEW = (id, resultsId, freeId) => MONTHLY_PATH + `/${id}/results/${resultsId}/free/${freeId}`;
export const CONNECTORS_VIEW = (id, resultsId, connectorsId) => MONTHLY_PATH + `/${id}/results/${resultsId}/connectors/${connectorsId}`;