import {DELETE_WEEKLY_ENTRY_IMAGE} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedPut from "../../apis/authorizedPut";

export default (weeklyReportId, weeklyEntryId, imageId) => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedPut(
        `/weekly_reports/${weeklyReportId}/entry_image`, {weekly_report: {entry_id: weeklyEntryId, image_id: imageId}}
      ),
      simpleSuccess(DELETE_WEEKLY_ENTRY_IMAGE)
  );
};