import React from 'react';
import {connect} from 'react-redux'
import modalDismiss from '../../actions/modal/modalDismiss';
import {Modal} from "antd";
import DailyReportResume from "./DailyReportResume";
import DailyEntryForm from "./DailyEntryForm";
import createDailyEntry from "../../actions/dailyReports/createDailyEntry";
import updateDailyEntry from "../../actions/dailyReports/updateDailyEntry";
import finishDailyReport from "../../actions/dailyReports/finishDailyReport";
import generateDailyReport from "../../actions/dailyReports/generateDailyReport";
import deleteDailyEntryImage from "../../actions/dailyReports/deleteDailyEntryImage";
import DailySuggestions from "./DailySuggestions";
import createDailySuggestion from "../../actions/dailyReports/createDailySuggestion";
import updateDailySuggestion from "../../actions/dailyReports/updateDailySuggestion";

class UserDailyModal extends React.Component {
  state = {
    selected: false,
    update: null,
    suggestions: false
  };

  handleFinish = () => {
    this.props.finishDailyReport(this.props.dailyReport.id);
  };

  handleGenerate = () => {
    this.props.generateDailyReport(this.props.dailyReport.id);
  };

  handleImageDelete = (dailyEntryId, imageId) => {
    this.props.deleteDailyEntryImage(this.props.dailyReport.id, dailyEntryId, imageId);
  };

  handleCreate = () => {
    this.setState({ selected: true, update: null });
  };

  handleUpdate = (dailyEntry) => {
    this.setState({update: dailyEntry, selected: true});
  };

  handleReturn = () => {
    this.setState({ selected: false, update: null, suggestions: false });
  };

  handleSuggestions = () => {
    this.setState({ suggestions: true });
  };

  onSubmit = (formValues) => {
    if(this.state.update) {
      this.props.updateDailyEntry(this.props.dailyReport.id, formValues, this.handleReturn);
    } else {
      this.props.createDailyEntry(this.props.dailyReport.id, formValues, this.handleReturn);
    }
  };

  handleRender = () => {
    if(this.state.selected) {
      return (
        <DailyEntryForm dailyEntry={this.state.update}
                        handleReturn={this.handleReturn} onSubmit={this.onSubmit} />
      );
    } else if(this.state.suggestions) {
      return <DailySuggestions suggestions={this.props.dailyReport.suggestions}
                               dailyReport={this.props.dailyReport}
                               createDailySuggestion = {this.props.createDailySuggestion}
                               updateDailySuggestion = {this.props.updateDailySuggestion}
                               handleReturn={this.handleReturn}/>;
    } else {
      return (
        <DailyReportResume client={this.props.client}
                           handleUpdate={this.handleUpdate}
                           dailyReport={this.props.dailyReport}
                           handleFinish={this.handleFinish}
                           handleSuggestions={this.handleSuggestions}
                           handleImageDelete={this.handleImageDelete}
                           handleGenerate={this.handleGenerate}
                           handleCreate={this.handleCreate}/>
      );
    }
  };
  
  render() {
    const {modalType, modalProps} = this.props;
    return (
      <Modal footer={null} className={'content-wrapper'} bodyStyle={{padding: 0}} visible={modalType !== null} style={{ top: 30 }} width={720} {...modalProps}>
        {this.handleRender()}
      </Modal>
    );
  };
}

const mapStateToProps = state => {
  return ({
    client: state.auth.client,
    dailyReport: state.userList.selected_entry
  });
};

export default connect(mapStateToProps, {modalDismiss, createDailyEntry, updateDailyEntry, finishDailyReport, generateDailyReport, deleteDailyEntryImage, createDailySuggestion, updateDailySuggestion})(UserDailyModal);