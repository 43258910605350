import {FINISH_CONCEPT_SECTION} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedPut from "../../apis/authorizedPut";

export default (conceptSectionId) => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedPut(`/concept_sections/${conceptSectionId}/finish`),
    simpleSuccess(FINISH_CONCEPT_SECTION)
  );
};