import React from 'react';
import {Modal} from "antd";
import 'antd/lib/modal/style/css';
import NetworkEntryForm from "./NetworkEntryForm";
import NetworkEntryResume from "./NetworkEntryResume";
import {noPadding} from "../../const/styles";

class NetworkEntryModal extends React.Component {
  
  handleRender = () => {
    if (this.props.onSubmit) {
      return (
        <NetworkEntryForm
          networkEntry={this.props.networkEntry}
          onSubmit={this.props.onSubmit}
        />
      );
    } else {
      return (
        <NetworkEntryResume/>
      );
    }
  };
  
  render() {
    const {modalType, modalProps} = this.props;
    return (
      <Modal bodyStyle={noPadding} footer={null} className={'content-wrapper'} visible={modalType !== null} style={{ top: 30 }}
             width={720} {...modalProps}>
        {this.handleRender()}
      </Modal>
    );
  }
}

export default NetworkEntryModal;