import React, {useEffect} from 'react';
import {Field, FieldArray, reduxForm} from "redux-form";
import {Button, Card, Col, Form, Row} from "antd";
import 'antd/lib/button/style/css';
import 'antd/lib/card/style/css';
import 'antd/lib/col/style/css';
import 'antd/lib/form/style/css';
import 'antd/lib/row/style/css';
import _ from 'lodash';
import {CreateFileField, CreateTextArea} from "../Helpers/Input/InputCreator";
import {fileImage, fileVerification,} from "../Helpers/Input/validations";
import QuillTextArea from "../Helpers/QuillTextArea";

const AdvanceForm = ({fields, change, advanceEntry}) => {
  
  useEffect(() => {
    if (fields.length === 0)
      fields.push({});
  }, []);
  
  return (
    fields.map((advance_entries_attributes, index) => {
      return (
        <div key={index}>
          <Row gutter={16}>
            <Col xs={24} sm={24}>
              <Field label={'Contenido'}
                     name={`${advance_entries_attributes}.content`}
                     component={QuillTextArea}
                     change={change} />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Field label={'Imagen del avance'}
                     name={'avatar'}
                     preview={advanceEntry ? advanceEntry.avatar : null}
                     component={CreateFileField}
                     validate={[fileVerification, fileImage]}
                     change={change}
                     isImage
                     type='file'
                     hasFeedback
              />
            </Col>
            <Col xs={24} sm={12}>
              <Field label={'Comentario para la foto'}
                     name={`${advance_entries_attributes}.comment`}
                     component={CreateTextArea}
                     placeholder="Comentario para la foto"
                     hasFeedback/>
            </Col>
          </Row>
          <Row className={'p-20'}>
            <Button type={'primary float-right'} htmlType={'submit'}>Guardar</Button>
          </Row>
        </div>
      )
    })
  );
};

const AdvanceEntryForm = ({handleSubmit, initialize, change, onSubmit, advanceEntry}) => {
  
  useEffect(() => {
    if (advanceEntry)
      initialize({tracing_section: {advance_entries_attributes: [_.omit(advanceEntry, ['avatar'])]}});
  }, []);
  
  return (
    <Card title={'Entrada de Avance'}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FieldArray name={'tracing_section[advance_entries_attributes]'} component={AdvanceForm}
                    props={{advanceEntry: advanceEntry, change: change}}/>
      </Form>
    </Card>
  );
};

export default reduxForm({
  form: 'advance_entry_form',
  touchOnChange: true
})(AdvanceEntryForm);