import {UPDATE_USER} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import {formDataHelper} from "../functions/formDataHelper";
import authorizedPut from "../../apis/authorizedPut";
import modalDismiss from "../modal/modalDismiss";

export default (formValues, userId) => async (dispatch) => {
  await loadingCall(
      dispatch,
      authorizedPut('/members/' + userId, formDataHelper(formValues, 'user', [{name: 'signature', key: '[profile_attributes][signature]'}])),
      simpleSuccess(UPDATE_USER),
      null,
      [modalDismiss]
  );
};