import {UPDATE_CONTRACT_ENTRY} from "../types";
import loadingCall from "../functions/loadingCall";
import simpleSuccess from "../functions/successHandlers/simpleSuccess";
import authorizedPut from "../../apis/authorizedPut";
import modalDismiss from "../modal/modalDismiss";

export default (formValues, tracingSectionId) => async (dispatch) => {
  await loadingCall(
    dispatch,
    authorizedPut(
      `/administrative_sections/${tracingSectionId}/contract_entry`,
      formValues
    ),
    simpleSuccess(UPDATE_CONTRACT_ENTRY),
    null,
    [modalDismiss]
  );
};