import {LOG_OUT} from "../types";
import {push} from "connected-react-router";
import infoPayload from "../functions/payloadHandlers/infoPayload";
import {ROOT_PATH} from "../../paths";

export default () => async (dispatch) => {
  localStorage.removeItem('jwt_token');
  dispatch({
    type: LOG_OUT,
    payload: infoPayload('Sesión terminada!', 'success')
  });
  dispatch(push(ROOT_PATH));
};